<template>
  <v-wait for="isUpdatingDashboard">
    <core-spinner slot="waiting" />
    <TitleBar />
    <div
      class="grid-container"
      :class="{ 'expandable-card-container': isCardExpanded }"
    >
      <!-- Production -->
      <Overview
        class="production-overview"
        :title="$t('views.technicalDirector.titles.productionOverview')"
        :data="productionData"
      />
      <Suppliers
        class="suppliers"
        :items="suppliers"
      />
      <ProductionAlerts
        class="production-alerts"
        :items="alerts.production"
      />

      <!-- Travaux -->
      <Overview
        class="work-overview"
        :title="$t('views.technicalDirector.titles.workOverview')"
        :data="workData"
      />
      <Projects
        class="projects"
        :items="projects"
      />
      <WorkAlerts
        class="work-alerts"
        :items="alerts.work"
      />

      <!-- Contrôle -->
      <Overview
        class="control-overview"
        :title="$t('views.technicalDirector.titles.controlOverview')"
        :data="controlData"
      />
      <Technicians
        class="technicians"
        :items="technicians"
      />
      <ControlAlerts
        class="control-alerts"
        :items="alerts.control"
      />
    </div>
  </v-wait>
</template>

<script>
import TitleBar from '@/components/core/TitleBar.vue'
import {
  aggregateSamplePropertyNames,
  asphaltSamplePropertyNames,
  kpiTypes,
  productTypes,
  risk,
} from '@/utils/enum'
import { supplierName } from '@/utils/format'
import { computeRiskFromWeight } from '@/utils/kpi'
import { mapActions, mapGetters } from 'vuex'
import ControlAlerts from './TDControlAlerts.vue'
import Overview from './TDOverview.vue'
import ProductionAlerts from './TDProductionAlerts.vue'
import Projects from './TDProjects.vue'
import Suppliers from './TDSuppliers.vue'
import Technicians from './TDTechnicians.vue'
import WorkAlerts from './TDWorkAlerts.vue'

export default {
  name: 'TechnicalManager',
  components: {
    Overview,
    ProductionAlerts,
    WorkAlerts,
    ControlAlerts,
    Suppliers,
    Projects,
    Technicians,
    TitleBar,
  },

  data() {
    return {
      suppliers: [],
      projects: [],
      technicians: [],
      alerts: {
        production: [],
        work: [],
        control: [],
      },
      stats: {
        production: {},
        work: {},
        control: {},
      },
      workKpisCount: 0,
      controlKpisCount: 0,
      projectsWithWorkResults: 0,
      projectsWithControlResults: 0,
      totalRecentTests: 0,
      workKpiTypes: new Set([
        kpiTypes.RMEMO,
        kpiTypes.ECONFORMITE,
        kpiTypes.GCONFORMITE,
        kpiTypes.RTHERMOAVIS,
        kpiTypes.RTPOSE,
        kpiTypes.RTEMPVAR,
        kpiTypes.RUNI,
      ]),
      projectIdToTitle: {},
      projectIdToWeather: {},
    }
  },

  computed: {
    ...mapGetters(['isCardExpanded']),

    productionData() {
      return {
        counts: [
          {
            name: this.$t('views.technicalDirector.overview.suppliers'),
            value: this.stats.production.productionSites,
          },
          {
            name: this.$t('views.technicalDirector.overview.products'),
            value: this.stats.production.products,
          },
          {
            name: this.$t('views.technicalDirector.overview.samples'),
            value: this.stats.production.samples,
          },
        ],
        recentlyActive: {
          name: this.$t('views.technicalDirector.overview.suppliersWithData'),
          dividend: this.stats.production.productionSitesWithSamplesCount,
          divisor: this.stats.production.productionSites,
        },
        compliance: {
          name: this.$t('views.technicalDirector.overview.nonCompliances'),
          dividend: this.totalRecentTests - this.alerts.production.length,
          divisor: this.totalRecentTests,
        },
      }
    },

    workData() {
      return {
        counts: [
          {
            name: this.$t('views.technicalDirector.overview.sites'),
            value: this.stats.work.projects,
          },
          {
            name: this.$t('views.technicalDirector.overview.reports'),
            value: this.stats.work.reports,
          },
          {
            name: this.$t('views.technicalDirector.overview.controls'),
            value: this.stats.work.controls,
          },
        ],
        recentlyActive: {
          name: this.$t('views.technicalDirector.overview.sitesWithData'),
          dividend: this.projectsWithWorkResults,
          divisor: this.stats.work.projects,
        },
        compliance: {
          name: this.$t('views.technicalDirector.overview.nonCompliances'),
          dividend: this.workKpisCount - this.alerts.work.length,
          divisor: this.workKpisCount,
        },
      }
    },

    controlData() {
      return {
        counts: [
          {
            name: this.$t('views.technicalDirector.overview.sites'),
            value: this.stats.work.projects,
          },
          {
            name: this.$t('views.technicalDirector.overview.compactions'),
            value: this.stats.control.compactions,
          },
          {
            name: this.$t('views.technicalDirector.overview.thermographies'),
            value: this.stats.control.thermographies,
          },
        ],
        recentlyActive: {
          name: this.$t('views.technicalDirector.overview.sitesWithData'),
          dividend: this.projectsWithControlResults,
          divisor: this.stats.work.projects,
        },
        compliance: {
          name: this.$t('views.technicalDirector.overview.nonCompliances'),
          dividend: this.controlKpisCount - this.alerts.control.length,
          divisor: this.controlKpisCount,
        },
      }
    },
  },

  async created() {
    this.$wait.start('isUpdatingDashboard')

    const {
      suppliers,
      projects,
      technicians,
      siteKpis,
      productionStats,
      workStats,
      controlStats,
    } = await this.updateTechnicalManagerDashboard()

    // Fill out production row
    this.formatSuppliers(suppliers).then(() => {
      this.generateProductionAlerts()
    })

    // Fill out work & control rows
    this.setStats(productionStats, workStats, controlStats)
    this.formatProjects(projects).then(() => {
      this.formatTechnicians(technicians)
      this.generateJobsiteAlerts(siteKpis)
    })

    this.$wait.end('isUpdatingDashboard')
  },

  methods: {
    ...mapActions(['updateTechnicalManagerDashboard']),
    ...mapActions('weather', ['getProjectCurrent', 'getSupplierCurrent']),

    async formatSuppliers(suppliers) {
      for (const supplier of suppliers) {
        const kpi = {
          trend: supplier.kpi.trend,
          date: new Date(supplier.kpi.date),
        }

        this.suppliers.push({
          ...supplier,
          id: supplier._id,
          formattedName: supplierName(supplier),
          location: supplier.supplierAddressCity,
          weather: '-',
          kpi,
        })
      }

      for (const supplier of this.suppliers) {
        try {
          const weather = await this.getSupplierCurrent(supplier)

          if (!weather) {
            continue
          }

          supplier.weather = weather
          supplier.location = weather.location

          const index = this.suppliers.indexOf(supplier)
          this.$set(this.suppliers, index, supplier)
        } catch (error) {
          console.error('Failed to fetch weather:', error)
        }
      }
    },

    async formatProjects(projects) {
      for (const project of projects) {
        const kpi = {
          trend: 0,
          date: null,
        }

        if (project.kpi && project.kpi.trend) {
          kpi.trend = project.kpi.trend
          kpi.date = new Date(project.kpi.updatedAt)
        }

        this.projects.push({
          ...project,
          project: project.title || '-',
          location: '-',
          weather: '-',
          kpi,
        })

        this.projectIdToTitle[project._id] = project.title
      }

      for (const project of this.projects) {
        try {
          const weather = await this.getProjectCurrent(project)

          if (!weather) {
            continue
          }

          project.weather = weather
          project.location = weather.location
          this.projectIdToWeather[project._id] = weather

          const index = this.projects.indexOf(project)
          this.$set(this.projects, index, project)
        } catch (error) {
          console.error('Failed to fetch weather:', error)
        }
      }
    },

    formatTechnicians(technicians) {
      for (const technician of technicians) {
        this.technicians.push({
          ...technician,
          name: technician.name,
          project: this.projectIdToTitle[technician.kpi.project],
          weather: this.projectIdToWeather[technician.kpi.project],
          date: technician.kpi.date,
          kpi: {
            risk: computeRiskFromWeight(technician.kpi),
            date: new Date(technician.kpi.date),
            project: technician.kpi.project,
          },
        })
      }
    },

    generateProductionAlerts() {
      for (const supplier of this.suppliers) {
        for (const product of supplier.products) {
          const specifications = new Set([
            ...Object.keys(product.specifications),
          ])
          let availableProperties = []
          let propertiesLocation = ''

          if (product.type === productTypes.ASPHALT) {
            availableProperties = asphaltSamplePropertyNames
              .toList()
              .filter((property) => specifications.has(property))
            propertiesLocation = 'asphaltProperties'
          } else {
            availableProperties = aggregateSamplePropertyNames
              .toList()
              .filter((property) => specifications.has(property))
            propertiesLocation = 'aggregateProperties'
          }

          for (const property of availableProperties) {
            const latestSampleWithProperty = product.samples.find(
              (sample) => !!sample[propertiesLocation],
            )
            if (!latestSampleWithProperty) {
              continue
            }
            this.totalRecentTests++
            const middle = product.specifications[property][3]
            const lowerBound = product.specifications[property][0]
            const upperBound = product.specifications[property][6]
            const value = latestSampleWithProperty[propertiesLocation][property]
            let diff = 0
            let maxDiff = 0
            let result = 0
            if (value > middle) {
              diff = value - middle
              maxDiff = upperBound - middle
              result = (diff / maxDiff) * 100
            } else {
              diff = middle - value
              maxDiff = middle - lowerBound
              result = (diff / maxDiff) * 100
            }

            if (result > 66) {
              if (product.type === productTypes.ASPHALT) {
                this.alerts.production.push({
                  supplier: supplier.formattedName,
                  product: product.productName,
                  sampleNumber: latestSampleWithProperty.sampleNumber,
                  kpi: {
                    risk: risk.HR,
                    date: new Date(latestSampleWithProperty.date),
                  },
                  type: productTypes.ASPHALT,
                  supplierId: supplier._id,
                  mixDesignId: product.mixDesign,
                })
              } else {
                this.alerts.production.push({
                  supplier: supplier.formattedName,
                  product: product.productName,
                  sampleNumber: latestSampleWithProperty.sampleNumber,
                  kpi: {
                    risk: risk.HR,
                    date: new Date(latestSampleWithProperty.date),
                  },
                  type: productTypes.AGGREGATE,
                  supplierId: supplier._id,
                  specification: product.specification,
                  stockpile: product.stockpile,
                })
              }
            }
          }
        }
      }
    },

    generateJobsiteAlerts(siteKpis) {
      const projectsWithWorkResults = new Set()
      const projectsWithControlResults = new Set()

      for (const kpi of siteKpis) {
        const isWorkAlert = this.workKpiTypes.has(kpi.type)

        if (isWorkAlert) {
          this.workKpisCount++
          projectsWithWorkResults.add(kpi.project)
        } else {
          this.controlKpisCount++
          projectsWithControlResults.add(kpi.project)
        }
        if (kpi.risk === risk.HR) {
          const hasTranslation = this.$te(
            `views.notifications.RBAI.${kpi.type}.${kpi.state}`,
          )
          if (hasTranslation) {
            const result = this.$t(
              `views.notifications.RBAI.${kpi.type}.${kpi.state}`,
            )

            const alert = {
              project: this.projectIdToTitle[kpi.project],
              projectId: kpi.project,
              user: kpi.user,
              date: kpi.date,
              result,
              kpi: {
                trend: kpi.trend,
                risk: kpi.risk,
                date: new Date(kpi.date),
                type: kpi.type,
              },
            }

            if (isWorkAlert) {
              this.alerts.work.push(alert)
            } else {
              this.alerts.control.push(alert)
            }
          }
        }
      }

      this.projectsWithWorkResults = projectsWithWorkResults.size
      this.projectsWithControlResults = projectsWithControlResults.size
    },

    setStats(productionStats, workStats, controlStats) {
      this.stats.production = productionStats
      this.stats.work = workStats
      this.stats.control = controlStats
    },
  },
}
</script>

<style scoped>
.production-overview {
  grid-row: 1 / span 4;
  grid-column: 1 / span 2;
}
.suppliers {
  grid-row: 1 / span 4;
  grid-column: 3 / span 5;
}

.production-alerts {
  grid-row: 1 / span 4;
  grid-column: 8 / span 5;
}

.work-overview {
  grid-row: 5 / span 4;
  grid-column: 1 / span 2;
}

.projects {
  grid-row: 5 / span 4;
  grid-column: 3 / span 5;
}

.work-alerts {
  grid-row: 5 / span 4;
  grid-column: 8 / span 5;
}

.control-overview {
  grid-row: 9 / span 4;
  grid-column: 1 / span 2;
}

.technicians {
  grid-row: 9 / span 4;
  grid-column: 3 / span 5;
}

.control-alerts {
  grid-row: 9 / span 4;
  grid-column: 8 / span 5;
}
</style>

import axios from 'axios'

const PAGE_SIZE = 12

export default {
  async uploadImage(_, payload) {
    const { file, location, sourceId, projectId, date, layer } = payload

    let formData = new FormData()
    formData.set(
      'data',
      JSON.stringify({ location, sourceId, projectId, date, layer }),
    )
    formData.set('file', file)

    await axios.post(`/images`, formData)
  },

  async get({ dispatch, commit }, projectId) {
    commit('clear')
    return dispatch('getMore', projectId)
  },

  async getMore({ commit, getters }, projectId) {
    const currentItems = getters.getByProject(projectId)
    const { data } = await axios.get('/images', {
      params: {
        project: projectId,
        skip: currentItems.length,
        limit: PAGE_SIZE,
        sortBy: '-createdAt',
      },
    })
    commit('append', data)
    return {
      hasMore: data.length === PAGE_SIZE,
      items: data,
    }
  },

  async deleteImage({ commit }, imageId) {
    try {
      await axios.post(`/images/delete/${imageId}`)
      commit('deleteImage', imageId)
    } catch (error) {
      console.error('error', error)
    }
  },

  async deleteImages({ commit }, imageIds) {
    try {
      await axios.post('/images/delete', { imageIds })
      commit('deleteImages', imageIds)
    } catch (error) {
      console.error('error', error)
    }
  },

  async restoreImages({ commit }, imageIds) {
    try {
      await axios.post('/images/restore', { imageIds })
      commit('restoreImages', imageIds)
    } catch (error) {
      console.error('error', error)
    }
  },

  async getProjectImages(_, projectId) {
    const { data } = await axios.get('/images', {
      params: {
        project: projectId,
        sortBy: '-createdAt',
        populate: ['datapoint'],
      },
    })
    return data
  },

  async getAllImages({ commit }, projectId) {
    const { data } = await axios.get('/images', {
      params: {
        project: projectId,
        sortBy: '-createdAt',
        populate: ['datapoint'],
        includeWithMeasurement: true,
      },
    })

    commit('setImages', data)
  },

  async uploadImages(_, payload) {
    await axios.post('/images/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  async patchImage({ state, commit }, { id, payload }) {
    try {
      const { data } = await axios.patch(`/images/${id}`, payload)

      if (!data) {
        throw new Error('No data returned')
      }

      const newItems = state.items.filter((item) => item._id !== id)
      data._id = id
      newItems.push(data)

      commit('setCurrentImage', data)
      commit('setImages', newItems)
    } catch (error) {
      console.error(error)
    }
  },
}

<template>
  <div>
    <slot name="title" />
    <div
      :id="id"
      ref="ring_id"
    >
      <div
        v-if="showMiddleSlot"
        class="middleSlot"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { colorHexValues, colorMinimum } from '../../../utils/constants'

export default {
  name: 'RingChart',
  props: {
    id: {
      type: String,
      required: true,
    },
    percentile: {
      type: Number,
      default: 0,
      validator: function (value) {
        return (value >= 0 && value <= 100) || value === -1
      },
    },
    innerRadius: {
      type: Number,
      default: 20,
    },
    outerRadius: {
      type: Number,
      default: 23,
    },
    backgroundColor: {
      type: String,
      default: '#e0e0e5',
      validator: function (value) {
        const regex = new RegExp('^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$')
        return regex.test(value)
      },
    },
    noBackgroundColor: {
      type: Boolean,
      default: false,
    },
    middleGap: {
      type: Number,
      default: 3,
    },
    showMiddleSlot: {
      type: Boolean,
      default: true,
    },
    yellowMin: {
      type: Number,
      default: undefined,
    },
    greenMin: {
      type: Number,
      default: undefined,
    },
  },
  data: function () {
    return {
      container: null,
    }
  },
  computed: {
    color() {
      if (this.greenMin && this.yellowMin) {
        if (this.percentile >= this.greenMin) return colorHexValues.green
        if (this.percentile >= this.yellowMin) return colorHexValues.yellow
        return colorHexValues.red
      }

      if (this.percentile >= colorMinimum.green) return colorHexValues.green
      if (this.percentile >= colorMinimum.yellow) return colorHexValues.yellow
      return colorHexValues.red
    },

    endAngle() {
      let result = this.percentile
      // show 0 if undefined
      if (result === -1) {
        result = 0
        // always show at least 5%
      } else if (result < 5) {
        result = 5
      }
      return -1 * (result / 100) * 2 * Math.PI
    },
  },
  watch: {
    percentile: function () {
      this.setPercentileRing()
    },
    color: function () {
      this.setPercentileRing()

      if (this.showMiddleSlot) {
        const parent = this.$refs.ring_id
        const middleProp = parent.getElementsByClassName('middleSlot')[0]
        middleProp.style.backgroundColor = this.noBackgroundColor
          ? this.backgroundColor
          : this.color
      }
    },
  },
  mounted() {
    const parent = document.getElementById(this.id)

    parent.style.height = `${this.outerRadius * 2}px`
    parent.style.width = `${this.outerRadius * 2}px`
    parent.style.position = 'relative'

    if (this.showMiddleSlot) {
      const middleSlot = parent.getElementsByClassName('middleSlot')[0]
      const icon = middleSlot.getElementsByClassName('v-icon')

      middleSlot.style.height = `${(this.innerRadius - this.middleGap) * 2}px`
      middleSlot.style.width = `${(this.innerRadius - this.middleGap) * 2}px`
      middleSlot.style.backgroundColor = this.noBackgroundColor
        ? this.backgroundColor
        : this.color

      if (icon.length == 1) {
        icon[0].style.fontSize = `${
          (this.innerRadius - this.middleGap) * 2 * 0.5
        }px`
        icon[0].style.color = this.backgroundColor
      } else if (icon.length > 1) {
        throw new TypeError('A RingChart cannot contain more than one icon')
      }
    }

    this.renderBackground()
    this.setPercentileRing()
  },
  methods: {
    renderBackground() {
      this.container = d3
        .select(`#${this.id}`)
        .append('svg')
        .attr('height', this.outerRadius * 2)
        .attr('width', this.outerRadius * 2)
        .append('g')
        .attr(
          'transform',
          'translate(' + this.outerRadius + ',' + this.outerRadius + ')',
        )

      const ringBackground = d3
        .arc()
        .innerRadius(this.innerRadius)
        .outerRadius(this.outerRadius)
        .startAngle(0)
        .endAngle(2 * Math.PI)

      this.container
        .append('path')
        .attr('d', ringBackground)
        .attr('fill', this.backgroundColor)
    },

    setPercentileRing() {
      if (this.container._groups[0][0].childNodes.length == 2) {
        this.container.selectAll('path.percentileRing').remove()
      }

      const ringPercentile = d3
        .arc()
        .innerRadius(this.innerRadius)
        .outerRadius(this.outerRadius)
        .cornerRadius(this.outerRadius - this.innerRadius)
        .startAngle(0)
        .endAngle(this.endAngle)

      this.container
        .append('path')
        .attr('d', ringPercentile)
        .attr('fill', this.color)
        .attr('class', 'percentileRing')
    },
  },
}
</script>

<style scoped>
.middleSlot {
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-items: center;
}

.middleSlot .v-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.middleSlot img {
  width: 100%;
}
</style>

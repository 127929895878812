import dayjs from 'dayjs'
import { uniqBy, uniq } from 'lodash'

export function toShortDate(date, toUtc = false) {
  let shortDate

  if (toUtc) {
    shortDate =
      date.getUTCFullYear() +
      '-' +
      String(date.getUTCMonth() + 1).padStart(2, '0') +
      '-' +
      String(date.getUTCDate()).padStart(2, '0')
  } else {
    shortDate =
      date.getFullYear() +
      '-' +
      String(date.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(date.getDate()).padStart(2, '0')
  }

  return shortDate
}

export function formatDate(date, defaultValue) {
  return date
    ? dayjs(date.replace(/0Z$/, '')).format('YYYY-MM-DD')
    : defaultValue
}

export function formatDatetime(date, defaultValue) {
  return date ? dayjs(date).format('YYYY-MM-DD HH[h]mm') : defaultValue
}

export function formatDateAsIs(date) {
  return typeof date == 'string'
    ? dayjs.tz(date, dayjs.tz.guess()).format('YYYY-MM-DD') // If the date is a string, use the tz parser to
    : dayjs.utc(date).format('YYYY-MM-DD')
}

export function formatLongDateAsIs(date) {
  return typeof date == 'string'
    ? dayjs.tz(date, dayjs.tz.guess()).format('YYYY-MM-DD HH:mm') // If the date is a string, use the tz parser to
    : dayjs.utc(date).format('YYYY-MM-DD HH:mm')
}

export function formatDateTimeAsIs(date, defaultValue) {
  return date
    ? typeof date == 'string'
      ? dayjs.tz(date, dayjs.tz.guess()).format('YYYY-MM-DD hh:mm') // If the date is a string, use the tz parser to
      : toShortDate(date, true)
    : defaultValue
}

export function sortDatapointDates(datapoints) {
  const uniqueDates = uniqBy(datapoints, 'date').map((d) => d.date)
  const sortedUniqueDates = uniqueDates.sort((a, b) => {
    if (a < b) return 1
    if (a > b) return -1
    return 0
  })
  return sortedUniqueDates
}

export function getSortedTimestampDates(datapoints) {
  const uniqueDates = uniq(datapoints.map((d) => formatDate(d.date)))
  const sortedUniqueDates = uniqueDates.sort((a, b) => {
    if (a < b) return 1
    if (a > b) return -1
    return 0
  })
  return sortedUniqueDates
}

export function isValidYearMonthString(dateString) {
  const regex = /\d{4}-\d{2}/
  return regex.test(dateString)
}

export function isValidYearMonthDayString(dateString) {
  const regex = /\d{4}-\d{2}-\d{2}/
  return regex.test(dateString)
}

export function toMonthYear(date) {
  return dayjs(date).format('YYYY-MM')
}

export function toMonthYearDay(date) {
  return dayjs(date).format('YYYY-MM-DD')
}

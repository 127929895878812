<template>
  <v-file-input
    v-model="files"
    accept="image/png, image/jpeg"
    counter
    chips
    multiple
    prepend-inner-icon="$fas fa-images"
    prepend-icon=""
    :label="$t('views.image-upload.label')"
    :rules="rules"
    :show-size="1000"
    @change="onChange"
  >
    <template #selection="{ index, text }">
      <v-chip
        v-if="index < 2"
        label
        small
      >
        {{ text }}
      </v-chip>

      <span
        v-else-if="index === 2"
        class="text-overline grey--text text--darken-3 mx-2"
      >
        +{{ files.length - 2 }}
        {{ $t('views.image-upload.files') }}
      </span>
    </template>
  </v-file-input>
</template>

<script>
export default {
  name: 'ImageInput',
  props: {
    rules: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      files: [],
    }
  },

  methods: {
    onChange() {
      this.$emit('onChange', this.files)
    },
  },
}
</script>

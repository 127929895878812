import Vue from 'vue'
import { set } from '@/utils/vuex'
import resetState from '@/utils/resetState'
import { initialState } from './state'

export default {
  updatePlants(state, plants) {
    Vue.set(state, 'plants', plants)
  },
  selectPlant(state, plantId) {
    Vue.set(state, 'selectedPlantId', plantId)
  },
  updateSuppliers(state, suppliers) {
    Vue.set(state, 'suppliers', suppliers)
  },
  selectSupplier(state, supplierId) {
    Vue.set(state, 'selectedSupplierId', supplierId)
  },
  updateMixedSuppliers: set('mixedSuppliers'),
  updateAggregates: set('aggregates'),
  updateAggregateKpis: set('aggregateKpis'),
  selectAggregateSpecification: set('selectedAggregateSpecification'),
  selectAggregateStockpile: set('selectedAggregateStockpile'),
  selectAggregateSampleProperty: set('selectedAggregateSampleProperty'),
  selectMixedSupplier: set('selectedMixedSupplierId'),
  updateAsphaltSamples(state, asphaltSamples) {
    Vue.set(state, 'asphaltSamples', asphaltSamples)
  },
  updateAsphaltKpis(state, kpis) {
    Vue.set(state, 'asphaltKpis', kpis)
  },
  selectAsphaltSample(state, id) {
    Vue.set(state, 'selectedSampleId', id)
  },
  selectAsphaltSampleProperty(state, id) {
    Vue.set(state, 'selectedSamplePropertyId', id)
  },

  updateMaterialSamples(state, samples) {
    Vue.set(state, 'materialSamples', samples)
  },
  selectAggregate(state, index) {
    Vue.set(state, 'selectedAggregateIndex', index)
  },
  selectMaterial(state, materialId) {
    Vue.set(state, 'selectedMaterialId', materialId)
  },
  selectMaterialSample(state, id) {
    Vue.set(state, 'selectedMaterialSampleId', id)
  },
  selectMaterialSampleProperty(state, id) {
    Vue.set(state, 'selectedMaterialPropertyId', id)
  },

  updateMixDesigns(state, mixDesigns) {
    Vue.set(state, 'mixDesigns', mixDesigns)
  },
  selectMixDesign(state, mixDesignId) {
    Vue.set(state, 'selectedMixId', mixDesignId)
  },

  clearState(state) {
    resetState(state, initialState)
  },
}

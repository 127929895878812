<template>
  <material-card outlined>
    <div class="subtitle-1 ml-2 text-center">
      {{ $t('views.imageGroup.title') }}
    </div>

    <div
      v-if="filteredImages.length"
      class="overflow-auto"
    >
      <v-card
        v-for="image in filteredImages"
        :key="image._id"
        outlined
        class="image-card"
      >
        <v-img :src="image.src" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            icon
            @click="navigateToDataPoint(image, true)"
          >
            <v-icon>$fas fa-map-marker-alt</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="open(image)"
          >
            <v-icon>$fas fa-expand-alt</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div
      v-else
      class="no-images"
    >
      <p class="text-center">
        {{ $t('views.imageGroup.noImages') }}
      </p>
    </div>
    <v-dialog
      v-model="dialog"
      width="80%"
    >
      <v-card>
        <v-img
          :class="isLoading ? 'blurry' : ''"
          :src="src"
          :height="height"
          class="grey darken-4"
          contain
        />
        <v-card-text v-if="!isLoading">
          <v-slider
            v-model="height"
            :label="$t('views.image-upload.size')"
            thumb-color="#e2632c"
            class="align-self-stretch"
            min="350"
            max="1350"
            step="1"
            thumb-label="always"
          />
        </v-card-text>
        <v-progress-linear
          v-if="isLoading"
          color="blue-grey"
          indeterminate
          height="25"
        >
          <strong> {{ $t('views.global.please-wait') }}</strong>
        </v-progress-linear>
        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            @click="dialog = false"
          >
            {{ $t('views.imageGroup.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </material-card>
</template>

<script>
import axios from 'axios'
import { datapoints } from '@/utils/enum'
import NavigateMapToDatapoint from '@/mixins/NavigateMapToDatapoint'

export default {
  name: 'ImageViewer',
  mixins: [NavigateMapToDatapoint],
  props: {
    actions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      markers: [],
      map: null,
      images: [],
      imageDatapointIds: new Set(),
      dialog: false,
      isLoading: true,
      src: '',
      height: 350,
    }
  },

  computed: {
    filteredImages() {
      return this.images.filter((image) =>
        this.visibleDatapointIds.has(image._id),
      )
    },

    markerDatapoints() {
      return this.markers.flatMap((marker) => marker.points)
    },

    visibleDatapointIds() {
      return new Set(this.markerDatapoints.map((datapoint) => datapoint._id))
    },

    imageDatapoints() {
      return this.markerDatapoints.filter(
        (datapoint) => datapoint.type === datapoints.IMAGE,
      )
    },
  },

  watch: {
    async imageDatapoints(imageDatapoints) {
      const newImageDatapoints = []
      for (const imageDatapoint of imageDatapoints) {
        if (!this.imageDatapointIds.has(imageDatapoint._id)) {
          newImageDatapoints.push(imageDatapoint)
          this.imageDatapointIds.add(imageDatapoint._id)
        }
      }

      for (const newImageDatapoint of newImageDatapoints) {
        try {
          const { data } = await axios.get(
            `/datapoints/${newImageDatapoint._id}/thumbnail`,
            {
              responseType: 'blob',
            },
          )
          newImageDatapoint.src = URL.createObjectURL(data)
          this.images.push(newImageDatapoint)
        } catch (error) {
          console.error(error)
        }
      }
    },
  },

  methods: {
    async open(image) {
      this.isLoading = true
      this.height = 350
      this.src = image.src

      this.dialog = true

      await axios
        .get(`/datapoints/${image?._id}/image`, {
          responseType: 'blob',
        })
        .then((result) => {
          this.height = 700
          this.src = URL.createObjectURL(result.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

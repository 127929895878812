// Lib imports
import router from '@/router'
import axios from 'axios'
import dayjs from 'dayjs'
import store from '../store/index'

// Sets the default url used by all of this axios instance's requests
axios.defaults.baseURL =
  process.env.VUE_APP_BASE_URL || 'https://apimega.ipave.ca/v1'
axios.defaults.headers.get['Accept'] = 'application/json'
axios.defaults.withCredentials = true

axios.interceptors.request.use((request) => {
  request.headers['X-Date'] = dayjs().format()
  return request
})

axios.interceptors.response.use(null, (error) => {
  if (error?.response?.status === 401) {
    const fullPath = router.currentRoute.fullPath
    const path = router.currentRoute.path
    if (path !== '/') {
      router.replace(`/?redirectUrl=${fullPath}`, async () => {
        await store.cache.clear()
        await store.dispatch('logout')
        store.commit('showError', error)
      })
    }
  }
  store.commit('showError', error)
  return Promise.reject(error)
})

<template>
  <v-container
    fluid
    py-0
  >
    <v-row align="center">
      <v-col>
        <div class="d-flex align-center">
          <v-btn
            v-if="showBack"
            icon
            class="v-btn-titlebar"
            @click="goBack()"
          >
            <v-icon class="left"> $fas fa-arrow-left </v-icon>
          </v-btn>
          <h3
            v-if="showTitle"
            class="my-0"
          >
            {{ $t(title) }}
          </h3>
          <v-btn
            v-if="showCreate"
            class="mx-2"
            fab
            small
            color="primary"
            elevation="0"
            @click="$emit('create')"
          >
            <v-icon> $fas fa-plus </v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col style="maxwidth: 500px">
        <v-select
          v-if="showSelector"
          :items="items"
          :item-value="'_id'"
          :label="$t(label)"
          :value="selectedItem"
          prepend-icon="$fas fa-industry"
          class="custom"
          hide-details
          @change="onChange"
        >
          <template
            slot="item"
            slot-scope="data"
          >
            {{ plantIdentifier(data.item) }}
          </template>
          <template
            slot="selection"
            slot-scope="data"
          >
            {{ plantIdentifier(data.item) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'views.production-technician.plant',
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    showCreate: {
      type: Boolean,
      default: false,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    showSelector: {
      type: Boolean,
      default: true,
    },
    selectedItem: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    title() {
      if (typeof this.$route.meta.name === 'function') {
        return this.$route.meta.name(this.$route)
      }
      return this.$route.meta.name ? this.$route.meta.name : ''
    },
  },
  methods: {
    onChange(item) {
      this.$emit('factory-change', item)
    },
    goBack() {
      return this.$router.go(-1)
    },
    plantIdentifier(item) {
      return (
        item.supplierNumber +
        (item.supplierName ? ' - ' + item.supplierName : '')
      )
    },
  },
}
</script>

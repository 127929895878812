<template>
  <v-wait for="isFetchingWeather">
    <core-spinner slot="waiting" />
    <div
      v-if="showWeather"
      class="weather-panel"
      @click="toggleForecast"
    >
      <v-row>
        <v-col cols="7">
          <div class="weather-panel-city">
            <v-badge
              :value="showPrecipitationWarning"
              :color="precipitationWarningColor"
            >
              {{ location }}
            </v-badge>
            <br />
            <small>
              {{ date }}
            </small>
          </div>
        </v-col>
        <v-col
          cols="5"
          class="text-right"
        >
          <div class="temperature">
            <v-icon class="temperature-icon">{{
              getConditionIcon(condition)
            }}</v-icon>
            <span>{{ current }}°C</span>
            <br />
            <small>{{ min }}°C/{{ max }}°C</small>
          </div>
        </v-col>
        <v-col>
          <div class="d-flex">
            <v-icon class="forecast-icon mr-1"
              >$fas fa-cloud-showers-heavy</v-icon
            >
            <span>{{ $t('core.drawer.weather.precipitation') }}</span>
            <span class="ml-auto text-right"
              >{{ precipitation }} {{ precipitationUnit }}</span
            >
          </div>
          <div class="d-flex mt-1">
            <v-icon class="forecast-icon mr-1">$fas fa-tint</v-icon>
            <span>{{ $t('core.drawer.weather.humidity') }}</span>
            <span class="ml-auto text-right">{{ humidity }} %</span>
          </div>
          <div class="d-flex mt-1">
            <v-icon class="forecast-icon mr-1">$fas fa-wind</v-icon>
            <span>{{ $t('core.drawer.weather.wind') }}</span>
            <span class="ml-auto text-right">{{ wind }} km/h</span>
          </div>
        </v-col>
      </v-row>
      <v-expand-transition>
        <ul
          v-show="showForecast"
          class="list-inline row forecast"
        >
          <li
            v-for="forecastElement in forecast"
            :key="forecastElement.time"
            class="text-center col-3"
          >
            <div>{{ forecastElement.time }}</div>
            <div>
              <v-icon class="forecast-icon">{{
                getConditionIcon(forecastElement.condition)
              }}</v-icon>
            </div>
            <div>
              <small>{{ forecastElement.temp }}°C</small>
            </div>
          </li>
        </ul>
      </v-expand-transition>
    </div>
  </v-wait>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import dayjs from 'dayjs'
import { geoJsonToMarker } from '@/utils/map'

export default {
  name: 'WeatherWidget',
  data() {
    return {
      mode: '',
      forecastState: false,
      weatherLocation: null,
    }
  },

  computed: {
    ...mapState(['selectedDate', 'isClientDashboard']),
    ...mapState('weather', {
      weatherIsLoaded: 'loaded',
    }),
    ...mapState('weather', [
      'condition',
      'current',
      'date',
      'enabled',
      'forecast',
      'humidity',
      'location',
      'max',
      'min',
      'precipitation',
      'wind',
    ]),
    precipitationWarningColor() {
      let color = 'warning'

      if (this.precipitation >= 75) color = 'error'

      return color
    },
    precipitationUnit() {
      return this.mode === 'forecast' ? '%' : 'mm'
    },
    showForecast() {
      return this.showWeather && this.mode === 'forecast' && this.forecastState
    },
    showPrecipitationWarning() {
      return this.showWeather && this.precipitation >= 40
    },
    showWeather() {
      return this.weatherIsLoaded && this.enabled
    },
  },

  created() {
    this.$root.$on('weatherLocationUpdated', this.weatherListener)
  },

  destroyed() {
    this.$root.$off('weatherLocationUpdated', this.weatherListener)
  },

  methods: {
    ...mapActions('weather', {
      getCurrentWeather: 'getCurrent',
      getHistoryWeather: 'getHistory',
    }),
    ...mapMutations('weather', ['setWeatherWidgetState']),
    async weatherListener(location) {
      if (!location) {
        this.setWeatherWidgetState(false)
        return
      }

      const latLngCoordinates = geoJsonToMarker(location)
      await this.getWeather(latLngCoordinates)
    },

    async getWeather(weatherLocation) {
      this.$wait.start('isFetchingWeather')
      const options = {
        location: weatherLocation,
        isClientDashboard: this.isClientDashboard,
        uuid: this.$route.query.uuid,
        token: this.$route.query.token,
      }

      if (
        this.selectedDate &&
        dayjs(this.selectedDate).isSameOrAfter(dayjs(), 'day')
      ) {
        await this.getCurrentWeather(options)
        this.setWeatherWidgetState(true)
        this.mode = 'forecast'
      } else if (
        this.selectedDate &&
        dayjs(this.selectedDate).isBefore(dayjs(), 'day')
      ) {
        options.date = this.selectedDate
        await this.getHistoryWeather(options)
        this.setWeatherWidgetState(true)
        this.mode = 'history'
      } else {
        this.setWeatherWidgetState(false)
      }
      this.$wait.end('isFetchingWeather')
    },
    toggleForecast() {
      this.forecastState = !this.forecastState
    },
    getConditionIcon(conditionCode) {
      let condition

      switch (conditionCode) {
        case 1000:
          condition = '$fas fa-sun'
          break
        case 1003:
        case 1006:
          condition = '$fas fa-cloud-sun'
          break
        case 1009:
        case 1087:
          condition = '$fas fa-cloud'
          break
        case 1030:
        case 1063:
        case 1072:
        case 1135:
        case 1147:
        case 1150:
        case 1153:
        case 1168:
        case 1171:
        case 1180:
        case 1183:
        case 1198:
        case 1273:
          condition = '$fas fa-cloud-rain'
          break
        case 1186:
        case 1189:
        case 1240:
          condition = '$fas fa-cloud-showers-heavy'
          break
        case 1114:
        case 1192:
        case 1195:
        case 1201:
        case 1243:
        case 1246:
        case 1276:
          condition = '$fas fa-cloud-showers-heavy'
          break
        case 1117:
        case 1066:
        case 1069:
        case 1204:
        case 1207:
        case 1210:
        case 1213:
        case 1216:
        case 1219:
        case 1222:
        case 1225:
        case 1237:
        case 1249:
        case 1252:
        case 1255:
        case 1258:
        case 1261:
        case 1264:
        case 1279:
        case 1282:
          condition = '$fas fa-snowflake'
          break
      }

      return condition
    },
  },
}
</script>

<template>
  <v-wait for="isSiteForemanUpdating">
    <core-spinner slot="waiting" />
    <title-bar :show-back="back">
      <ProjectSelector
        class="col-5 mr-2"
        @project-change="mixin_onProjectChange"
      />
      <MultiDatePicker class="col-3 py-0" />
    </title-bar>
    <div class="grid-container">
      <MapComponent
        class="qc-map"
        :center="mixin_qualityControlCenter"
        :datapoints="mixin_qualityControlDatapointsForSelectedDates"
        @expand="expandQualityControl"
      />
      <IriMap
        class="iri-map"
        :map-bindings="roadComfortMapBindings"
        @expand="expandRoadComfort"
      >
        <template slot="map-content">
          <iri-run :runs="mixin_iriRunGeoForSelectedDates" />
          <image-cluster :images="mixin_imagesForSelectedDates" />
        </template>
      </IriMap>
      <BarChart
        class="barChart"
        :title="$t('views.dashboard.graph.title')"
        :subtitle="$t('views.dashboard.graph.subtitle')"
        :datapoints="mixin_compactionsForSelectedDates"
      />
      <LinesScatterChart
        :id="'linesScatterChart'"
        class="scatterChart"
        :title="$t('views.dashboard.line-scatter-chart.title')"
        :line-chart-data="mixin_iriLineChartData"
        :scatter-chart-data="mixin_compactionsForSelectedDates"
      />
      <gauge-chart
        class="gauge1"
        :kpi="aggregateProductionKpi"
        :title="$t('views.dashboard.gauge.foundation')"
        :gauge-identifier="'gaugeIQCSG'"
      />
      <gauge-chart
        class="gauge2"
        :kpi="asphaltProductionKpi"
        :title="$t('views.dashboard.gauge.asphalt')"
        :gauge-identifier="'gaugeIQCE'"
      />
      <gauge-chart
        class="gauge3"
        :kpi="kpiScore('IQTTBMTC', 'total')"
        :title="$t('views.dashboard.gauge.siteControl')"
        :gauge-identifier="'gaugeIQTBMCC'"
      />
      <gauge-chart
        class="gauge4"
        :kpi="workScoreKpi"
        :title="$t('views.dashboard.gauge.workQuality')"
        :gauge-identifier="'gaugeIQDDM'"
      />
    </div>
  </v-wait>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import GaugeChart from '../Charts/Gauge.vue'
import MapComponent from '../Charts/Maps/Widget'
import BarChart from '../Charts/Bar.vue'
import LinesScatterChart from '../Charts/Lines/Scatter'
import TitleBar from '@/components/core/TitleBar'
import IriMap from '@/components/DashViews/Charts/Maps/IriMap.vue'
import IriRun from '@/components/core/map/IriRun.vue'
import ImageCluster from '@/components/core/map/ImageCluster.vue'
import ProjectSelector from '../../core/ProjectSelector.vue'
import MultiDatePicker from '@/components/core/MultiDatePicker.vue'

import * as MapUtils from '@/utils/map'
import { mergeKpis } from '@/utils/kpi'
import { datapoints } from '@/utils/enum'
import projectsListMixin from '@/mixins/projectsListMixin'
import compactionsMixin from '@/mixins/compactionsMixin'
import siteForemanMixin from '@/mixins/siteForemanMixin'

export default {
  name: 'SiteForemanDashboard',
  components: {
    BarChart,
    GaugeChart,
    ImageCluster,
    IriMap,
    IriRun,
    LinesScatterChart,
    MapComponent,
    TitleBar,
    ProjectSelector,
    MultiDatePicker,
  },

  mixins: [siteForemanMixin, projectsListMixin, compactionsMixin],

  data() {
    return {
      roadComfortTypes: [datapoints.IMAGE, datapoints.IRI],
      details: false,
      superIntendent: true,
    }
  },

  computed: {
    ...mapState(['datapoints']),
    ...mapGetters(['kpiScore', 'lastProdKpiByNames']),

    back() {
      return Boolean(this.$route.query.showBack) || false
    },

    computedDatapoints() {
      return this.mixin_qualityControlDatapoints
    },

    roadComfortCenter() {
      return MapUtils.datapointsCenter(this?.roadComfortPoints || [])
    },
    roadComfortPoints() {
      if (!this.datapoints.length || !this.selectedDates?.size) {
        return []
      }

      return this.datapoints.filter(
        (datapoint) =>
          datapoint.location &&
          this.roadComfortTypes.includes(datapoint.type) &&
          this.selectedDates.has(datapoint.date),
      )
    },
    aggregateProductionKpi() {
      return mergeKpis(this.lastProdKpiByNames(['IQDUG']))
    },
    asphaltProductionKpi() {
      return mergeKpis(this.lastProdKpiByNames(['IQDUM']))
    },
    workScoreKpi() {
      return mergeKpis(this.lastProdKpiByNames(['IQDDMP', 'IQDDGP']))
    },
  },

  watch: {
    mixin_qualityControlDatapointsForSelectedDates(newPoints) {
      if (this.selectedDates.size) {
        // selectedDates is sorted, we set first one so weather is for latest of selectedDates
        this.setSelectedDate(this.selectedDates.values().next().value)
      }

      this.$root.$emit('weatherLocationUpdated', newPoints?.[0]?.location)
    },
  },

  methods: {
    ...mapMutations(['setSelectedDate']),

    expandQualityControl() {
      const path = this.$router.currentRoute.path + '/quality-control'
      const query = { ...this.$router.currentRoute.query, showBack: true }
      this.$router.push({ path, query })
    },

    expandRoadComfort() {
      const path = this.$router.currentRoute.path + '/road-comfort'
      const query = { ...this.$router.currentRoute.query, showBack: true }
      this.$router.push({ path, query })
    },
  },
}
</script>

<style scoped>
.qc-map {
  grid-column: 1 / span 6;
  grid-row: 1 / span 5;
}

.iri-map {
  grid-column: 7 / span 6;
  grid-row: 1 / span 5;
}

.barChart {
  grid-column: 1 / span 4;
  grid-row: 6 / span 5;
}

.scatterChart {
  grid-column: 5 / span 8;
  grid-row: 6 / span 5;
}

.gauge1 {
  grid-column: 1 / span 3;
  grid-row: 11 / span 2;
}

.gauge2 {
  grid-column: 4 / span 3;
  grid-row: 11 / span 2;
}

.gauge3 {
  grid-column: 7 / span 3;
  grid-row: 11 / span 2;
}

.gauge4 {
  grid-column: 10 / span 3;
  grid-row: 11 / span 2;
}
</style>

<template>
  <core-card v-resize="onResize">
    <core-callout :title="title" />
    <div
      :id="legendId"
      class="legend"
    />
    <div
      :id="id"
      class="no-line-chart no-path-chart dashed-chart chart"
    />
  </core-card>
</template>
<script>
// This component was made not knowing what the naming scheme would be for the data.
import * as d3 from 'd3'
import crossfilter from 'crossfilter2'
import * as dc from 'dc'
import resize from 'vue-resize-directive'
import _ from 'lodash'

export default {
  name: 'LinesScatterChart',
  directives: {
    resize,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    lineChartData: {
      type: Array,
      required: true,
    },
    scatterChartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartContainer: null,
      crossfilter: crossfilter(),
      lineChartsDim: null,
      lineChartsGroups: [],
      lineChartsColors: d3.schemeCategory10,
      scatterChartsDim: null,
      scatterChartsGroups: [],
      scatterChartsColors: [
        '#FFBD21',
        '#33CC99',
        '#05A57D',
        '#FF9400',
        '#FF6666',
      ],
      farthestLoc: 0,
      closestLoc: 0,
      farthestIRI: 0,
      closestIRI: 0,
      farthestComp: 0,
      closestComp: 0,
      chartMargins: { top: 10, right: 45, bottom: 45, left: 45 },
      plotSize: 14,
      mobileWidth: 645,
      height: 0,
      ready: false,
    }
  },
  computed: {
    lineDataGroupedByDate() {
      return this.groupByDate(this.lineChartData)
    },
    watchedData() {
      return this.scatterChartData.concat(this.lineChartData)
    },
    legendId() {
      return `${this.id}-legend`
    },
  },

  watch: {
    watchedData() {
      if (!this.chartContainer) return // Make sure to not try to update data when chart isn't even there yet

      this.updateData()
      this.buildChart()
      this.finalizeRender()
    },
  },
  mounted() {
    this.height = d3.select(`#${this.id}`).node().getBoundingClientRect().height

    this.$nextTick(() => {
      this.generateChart()
      this.updateData()
      this.buildChart()
      this.finalizeRender()
    })
  },
  methods: {
    groupByDate(data) {
      const result = _.groupBy(data, 'date')
      return result
    },
    generateChart() {
      this.chartContainer = new dc.CompositeChart(`#${this.id}`)
    },

    updateData() {
      const crossfilterline = crossfilter(this.lineChartData)
      const crossfilterscatter = crossfilter(this.scatterChartData)

      this.scatterChartDimension = crossfilterscatter.dimension((d) => {
        return [d.station.replace('+', ''), d.compaction]
      })

      this.lineChartDimension = crossfilterline.dimension((d) => {
        return d.station.replace('+', '')
      })

      // Setting lineChartYAxis
      const lineYAxisValues = this.lineChartData.map(
        (_datapoint) => _datapoint.IRI,
      )
      if (lineYAxisValues.length > 0) {
        this.farthestIRI = _.max(lineYAxisValues) + 3
        this.closestIRI = Math.max(_.min(lineYAxisValues) - 3, 0)
      } else {
        this.farthestIRI = 12
        this.closestIRI = 0
      }

      // Setting scatterChartYAxis
      const scatterYAxisValues = this.scatterChartData.map(
        (_datapoint) => _datapoint.compaction,
      )
      if (scatterYAxisValues.length > 0) {
        this.farthestComp = Math.min(_.max(scatterYAxisValues) + 10, 100)
        this.closestComp = _.min(scatterYAxisValues) - 10
      } else {
        this.farthestComp = 100
        this.closestComp = 75
      }

      // Setting X Axis
      const locationValues = [
        ...this.lineChartData.map((_datapoint) =>
          parseInt(_datapoint.station.replace('+', '')),
        ),
        ...this.scatterChartData.map((_datapoint) =>
          parseInt(_datapoint.station.replace('+', '')),
        ),
      ]
      if (locationValues.length > 0) {
        this.farthestLoc = _.max(locationValues) + 100
        this.closestLoc = Math.max(_.min(locationValues) - 100, 0) // dont allow a negative station value
      } else {
        this.farthestLoc = 1000
        this.closestLoc = 0
      }
    },

    buildChart() {
      // Build scatterCharts
      const scatterCharts = []
      const groupedScatterData = _.groupBy(this.scatterChartData, 'group')
      const groups = Object.keys(groupedScatterData)
      groups.forEach((group, index) => {
        const data = groupedScatterData[group]
        const scatterFilter = crossfilter(data)
        const scatterDimension = scatterFilter.dimension((d) => {
          return [d.station.replace('+', ''), d.compaction]
        })

        const groupScatterChart = new dc.ScatterPlot(this.chartContainer)
          .dimension(scatterDimension)
          .group(
            scatterDimension.group().reduceSum((d) => {
              return d.compaction
            }),
          )
          .colors(this.scatterChartsColors[index])
          .symbolSize(this.plotSize)
          .clipPadding(10)

        groupScatterChart.legendName = group
        scatterCharts.push(groupScatterChart)
      })

      // Build lineCharts
      const lineChartsValues = Object.values(this.lineDataGroupedByDate)
      const lineCharts = []
      for (let i = 0; i < lineChartsValues.length; i++) {
        this.crossfilter = crossfilter(lineChartsValues[i])
        this.lineChartDimension = this.crossfilter.dimension((d) => {
          return d.station.replace('+', '')
        })
        const lineChart = new dc.LineChart(this.chartContainer)
          .dimension(this.lineChartDimension)
          .renderDataPoints({
            radius: this.plotSize / 2.25,
            fillOpacity: 1.0,
            strokeOpacity: 1.0,
            stroke: this.lineChartsColors[i % this.lineChartsColors.length],
            strokeWidth: 4.0,
          })
          .colors(this.lineChartsColors[i])
          .group(
            this.lineChartDimension.group().reduceSum((d) => {
              return d.IRI
            }),
            'Dots',
          )
          .useRightYAxis(true)

        const dates = Object.keys(this.lineDataGroupedByDate)
        lineChart.legendName = dates[i]
        lineCharts.push(lineChart)
      }

      this.chartContainer.xAxis().tickFormat((t) => {
        return this.setXAxisTickFormat(t)
      })
      this.chartContainer.rightYAxis().ticks(5)
      this.chartContainer.yAxis().ticks(5)

      this.chartContainer
        .height(this.height)
        .x(d3.scaleLinear().domain([this.closestLoc, this.farthestLoc]))
        .rightY(d3.scaleLinear().domain([this.closestIRI, this.farthestIRI]))
        .y(d3.scaleLinear().domain([this.closestComp, this.farthestComp]))
        .margins(this.chartMargins)
        .xAxisLabel(this.$t('views.dashboard.line-scatter-chart.x-axis-label'))
        .yAxisLabel(this.$t('views.dashboard.line-scatter-chart.y-axis-label'))
        .rightYAxisLabel(
          this.$t('views.dashboard.line-scatter-chart.right-y-axis-label'),
        )
        .renderHorizontalGridLines(true)
        .renderVerticalGridLines(true)
        .compose(scatterCharts.concat(lineCharts))
        .legend(
          new dc.HtmlLegend()
            .container(`#${this.legendId}`)
            .legendText((d) => d.chart.legendName)
            .horizontal(true)
            .highlightSelected(true),
        )
        .brushOn(false)
        .render()
    },

    finalizeRender() {
      this.chartContainer
        .svg()
        .selectAll('.chart-body circle')
        .style('stroke-width', '4px')
      this.chartContainer
        .svg()
        .selectAll('.chart-body circle')
        .style('fill', 'white')
      this.chartContainer
        .svg()
        .selectAll('.chart-body path.line')
        .style('stroke-width', '3px')

      this.chartContainer.svg().selectAll('.chart-body').attr('clip-path', null)

      if (window.innerWidth <= this.mobileWidth) {
        this.chartContainer
          .selectAll('g.x text')
          .attr('transform', 'translate(-10,10) rotate(315)')
      }

      this.ready = true
    },
    onResize() {
      if (
        this.farthestLoc &&
        this.farthestIRI &&
        this.farthestComp &&
        this.ready
      ) {
        this.chartContainer.render()
        this.finalizeRender()
      }
    },
    setXAxisTickFormat(t) {
      return t
        .toString()
        .padStart(4, '0')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '+')
    },
  },
}
</script>

import { defaults } from 'lodash'

export const initialWeatherState = {
  mode: 'current',
  loaded: false,
  enabled: false,
  date: '',
  location: '',
  condition: 0,
  current: 0,
  min: 0,
  max: 0,
  humidity: 0,
  wind: 0,
  precipitation: 0,
  windCat: 0,
  forecast: [],
  position: null,
  time: null,
}

export default defaults({}, initialWeatherState)

import i18n from '@/i18n'

export function formatServerError(err) {
  if (err.response) {
    const { data, status } = err.response
    const isClientError = status.toString().startsWith('4') && data.message

    if (
      isClientError ||
      (process.env.NODE_ENV !== 'development' && data.stack)
    ) {
      return i18n.te(`api-error.${data.message}`)
        ? i18n.t(`api-error.${data.message}`)
        : data.message
    } else if (
      process.env.NODE_ENV === 'development' &&
      data.message &&
      data.stack
    ) {
      return `[${status}] ${data.stack}`
    } else {
      return data
    }
  } else if (err instanceof Error) {
    return err.message
  } else {
    return err
  }
}

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 280 140"
    style="
      font-size: 13px;
      font-family:
        Open Sans,
        Helvetica,
        Arial,
        sans-serif;
    "
    class="gauge-chart-svg"
  >
    <g transform="translate(140,140)">
      <path
        fill="#F6F6F6"
        d="M-138,-1.6900125828233476e-14A138,138,0,0,1,-131.24579924873117,-42.644345223742754L-99.86093421099112,-32.446784409369485A105,105,0,0,0,-105,-1.2858791391047208e-14Z"
      />
      <path
        fill="#F6F6F6"
        d="M-131.24579924873117,-42.644345223742754A138,138,0,0,1,-111.64434522374273,-81.1143648163613L-84.94678440936947,-61.71745149070969A105,105,0,0,0,-99.86093421099112,-32.446784409369485Z"
      />
      <path
        fill="#F6F6F6"
        d="M-111.64434522374273,-81.1143648163613A138,138,0,0,1,-81.11436481636127,-111.64434522374275L-61.71745149070967,-84.94678440936949A105,105,0,0,0,-84.94678440936947,-61.71745149070969Z"
      />
      <path
        fill="#F6F6F6"
        d="M-81.11436481636127,-111.64434522374275A138,138,0,0,1,-42.64434522374273,-131.2457992487312L-32.44678440936947,-99.86093421099113A105,105,0,0,0,-61.71745149070967,-84.94678440936949Z"
      />
      <path
        fill="#F6F6F6"
        d="M-42.64434522374273,-131.2457992487312A138,138,0,0,1,8.450062914116738e-15,-138L6.429395695523604e-15,-105A105,105,0,0,0,-32.44678440936947,-99.86093421099113Z"
      />
      <path
        fill="#F6F6F6"
        d="M8.450062914116738e-15,-138A138,138,0,0,1,42.64434522374278,-131.24579924873117L32.446784409369506,-99.86093421099112A105,105,0,0,0,6.429395695523604e-15,-105Z"
      />
      <path
        fill="#F6F6F6"
        d="M42.64434522374278,-131.24579924873117A138,138,0,0,1,81.11436481636133,-111.64434522374272L61.717451490709706,-84.94678440936946A105,105,0,0,0,32.446784409369506,-99.86093421099112Z"
      />
      <path
        fill="#F6F6F6"
        d="M81.11436481636133,-111.64434522374272A138,138,0,0,1,111.64434522374275,-81.11436481636129L84.94678440936949,-61.71745149070968A105,105,0,0,0,61.717451490709706,-84.94678440936946Z"
      />
      <path
        fill="#F6F6F6"
        d="M111.64434522374275,-81.11436481636129A138,138,0,0,1,131.24579924873117,-42.64434522374274L99.86093421099112,-32.44678440936948A105,105,0,0,0,84.94678440936949,-61.71745149070968Z"
      />
      <path
        fill="#F6F6F6"
        d="M131.24579924873117,-42.64434522374274A138,138,0,0,1,138,0L105,0A105,105,0,0,0,99.86093421099112,-32.44678440936948Z"
      />
    </g>
    <g transform="translate(140,140)">
      <path
        fill="#FF6666"
        d="M-129.99710335725186,-0.8678241319410652A130,130,0,0,1,-123.90276465230743,-39.34596436122665L-109.63655756724376,-34.71059268875204A115,115,0,0,0,-114.99672552414708,-0.8678241319410513Z"
      />
      <path
        fill="#FF6666"
        d="M-123.3664198425105,-40.99666395258809A130,130,0,0,1,-105.67996006187174,-75.708295723264L-93.54439947284473,-66.8912948541501A115,115,0,0,0,-109.10021275744683,-36.36129228011345Z"
      />
      <path
        fill="#FF9400"
        d="M-104.6597716091948,-77.112464665002A130,130,0,0,1,-77.11246466500198,-104.65977160919482L-68.29546379588805,-92.52421102016783A115,115,0,0,0,-92.52421102016781,-68.29546379588807Z"
      />
      <path
        fill="#FF9400"
        d="M-75.70829572326399,-105.67996006187175A130,130,0,0,1,-40.996663952588065,-123.3664198425105L-36.36129228011345,-109.10021275744683A115,115,0,0,0,-66.89129485415009,-93.54439947284474Z"
      />
      <path
        fill="#FFBD21"
        d="M-39.34596436122662,-123.90276465230743A130,130,0,0,1,-0.8678241319410414,-129.99710335725186L-0.8678241319410557,-114.99672552414708A115,115,0,0,0,-34.71059268875199,-109.63655756724377Z"
      />
      <path
        fill="#FFBD21"
        d="M0.8678241319410572,-129.99710335725186A130,130,0,0,1,39.34596436122666,-123.90276465230741L34.71059268875203,-109.63655756724376A115,115,0,0,0,0.8678241319410698,-114.99672552414708Z"
      />
      <path
        fill="#33CC99"
        d="M40.99666395258811,-123.36641984251048A130,130,0,0,1,75.70829572326402,-105.67996006187172L66.8912948541501,-93.54439947284473A115,115,0,0,0,36.36129228011349,-109.10021275744681Z"
      />
      <path
        fill="#33CC99"
        d="M77.11246466500202,-104.65977160919479A130,130,0,0,1,104.6597716091948,-77.112464665002L92.5242110201678,-68.29546379588808A115,115,0,0,0,68.29546379588811,-92.52421102016778Z"
      />
      <path
        fill="#05A57D"
        d="M105.67996006187175,-75.70829572326399A130,130,0,0,1,123.3664198425105,-40.99666395258808L109.10021275744683,-36.36129228011346A115,115,0,0,0,93.54439947284476,-66.89129485415008Z"
      />
      <path
        fill="#05A57D"
        d="M123.90276465230743,-39.34596436122662A130,130,0,0,1,129.99710335725186,-0.8678241319410595L114.99672552414708,-0.8678241319410595A115,115,0,0,0,109.63655756724377,-34.710592688751994Z"
      />
    </g>
    <g transform="translate(140,140)">
      <text
        fill="#939394"
        text-anchor="middle"
        transform="translate(-91, -5.572142936120458e-15)"
      >
        0
      </text>
      <text
        fill="#939394"
        text-anchor="middle"
        transform="translate(-73.62054648812023, -53.48845795861506)"
      >
        20
      </text>
      <text
        fill="#939394"
        text-anchor="middle"
        transform="translate(-28.120546488120212, -86.54614298285897)"
      >
        40
      </text>
      <text
        fill="#939394"
        text-anchor="middle"
        transform="translate(28.120546488120212, -86.54614298285897)"
      >
        60
      </text>
      <text
        fill="#939394"
        text-anchor="middle"
        transform="translate(73.62054648812023, -53.48845795861506)"
      >
        80
      </text>
      <text
        fill="#939394"
        text-anchor="middle"
        transform="translate(91, -5.572142936120458e-15)"
      >
        100
      </text>
    </g>
    <g>
      <circle
        r="17.5"
        text-anchor="middle"
        fill="#EFEFEF"
        transform="translate(140,115)"
      />
      <g transform="translate(140,115)">
        <path
          d="M6.25,0L0,-35L-6.25,0L0,5"
          fill="#4A4A4A"
          :transform="`rotate(${scale(score)})`"
        />
      </g>
      <circle
        r="1.5"
        text-anchor="middle"
        fill="#EFEFEF"
        transform="translate(140,115)"
      />
    </g>
  </svg>
</template>
<script>
import { scaleLinear } from 'd3-scale'
export default {
  props: {
    score: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      scale: scaleLinear()
        // Possible score values
        .domain([0, 100])
        // Degrees of needle rotation
        .range([-105, 105]),
    }
  },
}
</script>

<template>
  <core-base-app>
    <v-wait
      for="isUpdatingClientDashboard"
      class="grow d-flex flex-column flex-nowrap fill-height"
    >
      <core-spinner slot="waiting" />
      <title-bar>
        <ProjectSelector class="col-auto mr-2" />
        <DateSelector class="col-3" />
      </title-bar>
      <div class="grid-container">
        <MapComponent
          ref="mapComponent"
          class="map"
          :center="center"
          :datapoints="points"
          :remove-outside-visible-bounds="false"
          :show-expand="false"
        />
        <TableDatapointComponent
          ref="datapointsTable"
          class="table"
          :items="points"
        />
      </div>
    </v-wait>
  </core-base-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import * as enums from '@/utils/enum'
import * as MapUtils from '@/utils/map'
import MapComponent from '../Charts/Maps/Widget.vue'
import TableDatapointComponent from '../Charts/Tables/Datapoint.vue'
import TitleBar from '@/components/core/TitleBar.vue'
import ProjectSelector from '@/components/core/ProjectSelector.vue'
import DateSelector from '@/components/core/DateSelector.vue'

export default {
  name: 'ClientDashboard',
  components: {
    MapComponent,
    TableDatapointComponent,
    TitleBar,
    ProjectSelector,
    DateSelector,
  },
  props: {
    uuid: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pollingInterval: null,
      intervalDuration: 5 * 60 * 1000, // 5 mins
      types: [
        enums.datapoints.SITE_AGGREGATE_SAMPLE_TEST,
        enums.datapoints.SITE_AGGREGATE_TEST,
        enums.datapoints.SITE_AGGREGATE_STAB_TEST,
        enums.datapoints.SITE_AGGREGATE_SECTION,
        enums.datapoints.SITE_ASPHALT_SAMPLE_TEST,
        enums.datapoints.SITE_ASPHALT_THERMOGRAPHY_LOT,
        enums.datapoints.SITE_ASPHALT_SPREAD_RATE,
        enums.datapoints.SITE_ASPHALT_SECTION,
        enums.datapoints.SITE_ASPHALT_TEMPERATURE,
        enums.datapoints.SITE_ASPHALT_COMPACTION_MTQ,
        enums.datapoints.SITE_ASPHALT_COMPACTION_CONTRACTOR,
        enums.datapoints.IMAGE,
      ],
    }
  },
  computed: {
    ...mapState(['datapoints']),

    center() {
      return MapUtils.datapointsCenter(this?.points || [])
    },
    points() {
      return this.datapoints.filter(
        (datapoint) =>
          this.types.includes(datapoint.type) && datapoint.location,
      )
    },
  },

  watch: {
    points(newPoints) {
      this.$root.$emit('weatherLocationUpdated', newPoints?.[0]?.location)
    },
  },

  async created() {
    this.$wait.start('isUpdatingClientDashboard')
    await this.getDatapoints()
    this.startPollingInterval()
    this.$wait.end('isUpdatingClientDashboard')
  },

  beforeDestroy() {
    clearInterval(this.pollingInterval)
  },

  methods: {
    ...mapActions(['updateClientDashboard']),

    async getDatapoints() {
      await this.updateClientDashboard({
        uuid: this.uuid,
        token: this.token,
      })
    },

    startPollingInterval() {
      this.pollingInterval = setInterval(async () => {
        if (document.hasFocus()) {
          await this.getDatapoints()
        }
      }, this.intervalDuration)
    },
  },
}
</script>

<style scoped>
.project {
  grid-column: 6 / span 4;
}

.date-selector {
  grid-column: 10 / span 3;
}

.map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 12;
}

.table {
  grid-row: 7 / span 6;
  grid-column: 1 / span 12;
}
</style>

<template>
  <v-wait for="isFetchingCalibrations">
    <core-spinner slot="waiting" />
    <title-bar :show-back="true" />
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <ListTable
            :title="$t('views.iri-management.calibration-list-title')"
            :description="
              $t('views.iri-management.calibration-list-description')
            "
            :headers="[
              {
                text: $t(
                  'views.iri-management.calibration-list-header.calibration',
                ),
                value: 'iriOffset',
                sortable: true,
              },
              {
                text: $t('views.iri-management.calibration-list-header.device'),
                value: 'device',
                sortable: true,
              },
              {
                text: $t(
                  'views.iri-management.calibration-list-header.vehicle',
                ),
                value: 'vehicle',
                sortable: true,
              },
              {
                text: $t('views.iri-management.calibration-list-header.date'),
                value: 'date',
                sortable: true,
              },
              {
                text: $t(
                  'views.iri-management.calibration-list-header.enabled',
                ),
                value: 'enabled',
                sortable: false,
                checkbox: true,
                onChange: updateDisabledCalibration,
              },
            ]"
            :items="calibrationItems"
            :hidden="[]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-wait>
</template>

<script>
import ListTable from '../../Charts/Tables/List'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import TitleBar from '@/components/core/TitleBar'

export default {
  name: 'CalibrationsDashboard',
  components: {
    ListTable,
    TitleBar,
  },

  computed: {
    ...mapState('iri', ['calibrations']),
    ...mapGetters('iri', ['device', 'vehicle']),

    calibrationItems() {
      return this.calibrations.map((calibration) => {
        const device = this.device(calibration.device)
        const vehicle = this.vehicle(calibration.vehicle)

        return {
          id: calibration._id,
          date: { value: new Date(calibration.date).toLocaleString('fr-CA') },
          iriOffset: { value: calibration.iriOffset },
          device: { value: `${device.externalIdentifier} - ${device.type}` },
          vehicle: {
            value: `${vehicle.make} ${vehicle.model} - ${vehicle.licencePlate}`,
          },
          enabled: { value: !calibration.disabled },
        }
      })
    },
  },

  async created() {
    this.setCalibrations([])
    this.$wait.start('isFetchingCalibrations')
    await this.getCalibrations()
    this.$wait.end('isFetchingCalibrations')
  },

  methods: {
    ...mapActions('iri', ['getCalibrations', 'updateCalibration']),
    ...mapMutations('iri', ['setCalibrations']),

    updateDisabledCalibration(id, enabled) {
      this.updateCalibration({ id, disabled: !enabled })
    },
  },
}
</script>

<style scoped></style>

<template>
  <v-card
    :options="options"
    color="warning"
    type="Map"
  >
    <l-map
      ref="mMap"
      :zoom="mapMixin_zoom"
      :center="center"
      :options="mapMixin_options"
      style="min-height: 400px; z-index: 1"
      @ready="mapMixin_onReady(mapRef)"
      @dblclick="onDoubleClick"
    >
      <l-tile-layer
        :url="mapMixin_url"
        :attribution="mapMixin_attribution"
      />
      <l-marker
        :lat-lng="marker"
        :options="{ draggable: true, autoPan: true }"
        @dragend="(event) => updateMarker(event)"
      >
        <l-icon
          :icon-anchor="[13, 41]"
          :tooltip-anchor="[15, -30]"
          icon-url="/img/marker-icon.png"
          icon-retina-url="/img/marker-icon-2x.png"
          shadow-url="/img/marker-shadow.png"
        />
      </l-marker>
    </l-map>
  </v-card>
</template>

<script>
import { LIcon, LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import mapMixin from '@/mixins/mapMixin'

export default {
  name: 'LocationPicker',
  components: {
    LIcon,
    LMap,
    LMarker,
    LTileLayer,
  },
  mixins: [mapMixin],

  props: {
    marker: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      options: {
        lineSmooth: this.$chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: 50,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    }
  },

  computed: {
    center() {
      return this.marker
    },

    mapRef() {
      return this.$refs.mMap
    },
  },

  methods: {
    onSearch(position) {
      const location = [position.location.y, position.location.x]
      this.$emit('markerUpdated', location)
    },

    onDoubleClick(position) {
      const location = [position.latlng.lat, position.latlng.lng]
      this.$emit('markerUpdated', location)
    },

    updateMarker(event) {
      const location = [event.target._latlng.lat, event.target._latlng.lng]
      this.$emit('markerUpdated', location)
    },
  },
}
</script>

<template>
  <v-card
    outlined
    class="fill-height v-card-dense overflow-auto"
  >
    <v-card-text class="d-flex flex-column justify-space-between">
      <div class="callout pl-2">
        <div class="text-h6">
          {{ title }}
        </div>
      </div>
      <ul
        class="overview-list"
        role="list"
      >
        <li
          v-for="item in data.counts"
          :key="item.name"
        >
          <span class="name">{{ item.name }}</span>
          <span class="page">
            {{ item.value }}
          </span>
        </li>
      </ul>
      <div>
        {{ data.recentlyActive.name }}
        <v-progress-linear
          :color="color(recentlyActive, false)"
          :value="recentlyActive[1]"
          height="32"
        >
          <strong>{{ text(recentlyActive) }}</strong>
        </v-progress-linear>
      </div>
      <div>
        {{ data.compliance.name }}
        <v-progress-linear
          :color="color(compliance, true)"
          :value="compliance[1]"
          height="32"
        >
          <strong>{{ text(compliance) }}</strong>
        </v-progress-linear>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TDOverview',
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    recentlyActive() {
      const fraction = `${this.data.recentlyActive.dividend}/${this.data.recentlyActive.divisor}`
      const percent =
        this.data.recentlyActive.divisor === 0
          ? 0
          : Math.round(
              (this.data.recentlyActive.dividend /
                this.data.recentlyActive.divisor) *
                100,
            )
      return [fraction, percent]
    },
    compliance() {
      const fraction = `${this.data.compliance.dividend}/${this.data.compliance.divisor}`
      const percent =
        this.data.compliance.divisor === 0
          ? 0
          : Math.round(
              (this.data.compliance.dividend / this.data.compliance.divisor) *
                100,
            )
      return [fraction, percent]
    },
  },

  methods: {
    color(array, reversed) {
      const percent = array[1]

      if (reversed) {
        if (percent > 95) return 'success'
        if (percent > 90) return '#FFBD21'
        return '#FF6666'
      } else {
        if (percent < 33) return '#FF6666'
        if (percent < 66) return '#FFBD21'
        return 'success'
      }
    },

    text(array) {
      const [fraction, percent] = array
      return `${fraction} (${percent}%)`
    },
  },
}
</script>

<style scoped>
.v-progress-linear {
  color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}
</style>

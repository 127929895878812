class FormDataBuilder {
  static createFormDataBuilder() {
    return new FormDataBuilder()
  }

  /**
   * @private
   */
  constructor() {
    this._formData = new FormData()
  }

  /**
   * Append a value to the form data
   * @param {string} key
   * @param {*} value
   */
  addField(key, value) {
    this._formData.set(key, value)
    return this
  }

  /**
   * Append each object property as a form data field
   * @param {Object<string: string|number|array>} data
   */
  addFields(data) {
    for (let [key, value] of Object.entries(data)) {
      this._formData.set(key, value)
    }
    return this
  }

  /**
   * Add one or more file
   * @param {string} field
   * @param {[File]|[Blob]} files
   */
  addFiles(field, files) {
    for (let file of files) {
      this._formData.append(field, file)
    }
    return this
  }

  /**
   * Get the FormData
   */
  build() {
    return this._formData
  }
}

export default FormDataBuilder

import axios from 'axios'
import Vue from 'vue'
import modules from '../../modules/index'
import router from '@/router'
import { pick } from 'lodash'

export default {
  async updateUserList({ commit }, options) {
    const { data: users } = await axios.get('/users', { params: options })
    commit('updateUserList', users)
  },

  async getUser({ commit }, userId) {
    const { data: user } = await axios.get('/users/' + userId)

    commit('setUser', user)
  },

  async createUser(_, user) {
    const toUpdate = pick(
      user,
      'name',
      'email',
      'password',
      'roles',
      'confirmPassword',
      'expiryDate',
    )

    const { data } = await axios.post('/users/', toUpdate)

    if (data) {
      Vue.$router.push({ path: '/administration/users/' })
    }
  },

  async updateUser({ state }) {
    const user = pick(state.user, 'name', 'email', 'roles', 'expiryDate')

    await axios.patch(`/users/${state.userId}`, user)
  },

  async toggleUserDisabled({ state }) {
    const user = pick(state.user, 'disabled')

    await axios.patch(`/users/${state.userId}`, user)
  },

  async setLanguage({ commit }, language) {
    const user = {
      languagePreference: language,
    }
    const res = await axios.patch('/users/me', user)
    commit('user', res.data, { root: true })
    commit('updateLanguage', language, { root: true })
  },

  async activateUser(_, userId) {
    await axios.post('/users/activate-user', {
      userId,
    })
  },

  async getUserOrganizations({ rootState }) {
    if (!rootState.user.id || !rootState.user.roles.length) {
      return []
    }
    const { data } = await axios.get('/users/organization')
    return data
  },

  async setUserOrganization({ commit, rootGetters }, organizationId) {
    await axios.post('/users/organization', { organizationId })

    const routeData = router.resolve({
      path: rootGetters.landingPage,
    })

    window.location.href = routeData.href

    // Flush all state
    sessionStorage.clear()
    commit('clearRootState', null, { root: true })
    Object.keys(modules).forEach((module) => {
      commit(`${module}/clearState`, null, { root: true })
    })

    Vue.nextTick(() => {
      router.go()
    })
  },
}

import * as dc from 'dc'
import 'dc/dist/style/dc.css'

dc.config.defaultColors([
  '#23171b',
  '#4a58dd',
  '#2f9df5',
  '#27d7c4',
  '#4df884',
  '#95fb51',
  '#dedd32',
  '#ffa423',
  '#f65f18',
  '#ba2208',
  '#900c00',
])

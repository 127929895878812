export default {
  methods: {
    mixin_formatWeather(item) {
      if (item.weather && item.weather.current) {
        return `${item.weather.current.current}°C`
      }
      return '-'
    },
  },
}

import { toMonthYearDay } from '@/utils/date'
import { chain } from 'lodash'

export default {
  contain10mSection: (state) => {
    const result = (state.segments || []).some(
      (segment) => segment.iriSections10m?.length > 0,
    )

    return result
  },

  device: (state) => (id) => {
    return state.devices.find((d) => d._id === id)
  },

  deviceVehicleDescription: (_, getters) => (deviceId, vehicleId) => {
    if (!deviceId || !vehicleId) return

    const device = getters.device(deviceId)
    const vehicle = getters.vehicle(vehicleId)

    if (device && vehicle) {
      return `${device.externalIdentifier} - ${vehicle.make} ${vehicle.model} - ${vehicle.licencePlate}`
    }
  },

  deviceVehiclePairs: (state, getters) => {
    return chain(state.calibrations)
      .orderBy('date', 'desc') // reverse sort to get the latest calibration
      .uniqBy((_c) => _c.device + _c.vehicle)
      .map((_c) => {
        const name = getters.deviceVehicleDescription(_c.device, _c.vehicle)
        return {
          text: `${name} (${toMonthYearDay(_c.date)})`,
          value: _c._id,
        }
      })
      .value()
  },

  vehicle: (state) => (id) => {
    return state.vehicles.find((v) => v._id === id)
  },
}

<template>
  <v-card
    outlined
    class="fill-height v-card-dense"
  >
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CoreCard',
}
</script>

<template>
  <v-wait for="isFetchingProduction">
    <core-spinner slot="waiting" />
    <FactorySelector
      :show-create="hasAccessToKpis"
      :show-back="true"
      :show-selector="false"
      :style="{ margin: '5px 0' }"
      @create="kpiDialog = true"
    />
    <div class="grid-container production-dashboard-layout">
      <VariationChart
        id="variationChart"
        class="variation1"
        :data="materialSampleProperties"
        :title="$t('views.dashboard.variation-chart.title')"
        translation-path="enums.aggregateProperties"
        @rowclick="onAggregatePropertyRowClick($event)"
      >
        <v-select
          :items="mixFilteredMaterialsList"
          :item-text="'grade'"
          :item-value="'_id'"
          :value="selectedMaterialId"
          single-line
          hide-details
          class="variation-selector"
          @change="onMaterialChange"
        />
      </VariationChart>

      <LineBoundaryChart
        id="RawMaterialsPropertyChart"
        class="boundary"
        :title="$t('views.dashboard.boundary-chart.title')"
        :subtitle="
          selectedMaterialPropertyId
            ? $t('enums.aggregateProperties.' + selectedMaterialPropertyId)
            : $t('enums.sampleProperties.undefined')
        "
        :boundary-data="materialSamplePropertyTimeline"
        :upper-h-r="specifications[6] - specifications[3]"
        :upper-a-r="specifications[5] - specifications[3]"
        :upper-l-r="specifications[4] - specifications[3]"
        :zero-r="0"
        :lower-l-r="specifications[2] - specifications[3]"
        :lower-a-r="specifications[1] - specifications[3]"
        :lower-h-r="specifications[0] - specifications[3]"
      />

      <GaugeChart
        class="gauge1"
        :kpi="kpiScore(kpiTypes.IQDUM)"
        :title="$t('views.dashboard.gauge.siteImpact')"
        :gauge-identifier="'gaugeF'"
      />

      <GaugeChart
        class="gauge2"
        :kpi="kpiScore(kpiTypes.IQTBMPE)"
        :title="$t('views.dashboard.gauge.asphalt')"
        :gauge-identifier="'gaugeE'"
      />

      <GaugeChart
        class="gauge3"
        :kpi="materialQualityKpi"
        :title="$t('views.dashboard.gauge.rawMaterials')"
        :gauge-identifier="'gaugeCC'"
      />

      <GaugeChart
        class="gauge4"
        :kpi="kpiScore(kpiTypes.IQDDMP)"
        :title="$t('views.dashboard.gauge.decisional')"
        :gauge-identifier="'gaugeQT'"
      />
    </div>
    <v-dialog
      v-if="hasAccessToKpis"
      v-model="kpiDialog"
      scrollable
      max-width="750px"
    >
      <v-card>
        <TableKpiComponent
          class="kpiTable"
          :items="materialFilteredKpis"
        />
      </v-card>
    </v-dialog>
  </v-wait>
</template>
<script>
import productionWeatherMixin from '@/mixins/productionWeatherMixin'
import { mapState, mapGetters, mapMutations } from 'vuex'
import GaugeChart from '../Charts/Gauge.vue'
import LineBoundaryChart from '../Charts/Lines/Boundary.vue'
import TableKpiComponent from '../Charts/Tables/Kpi.vue'
import VariationChart from '../Charts/Variation.vue'
import FactorySelector from './FactorySelector.vue'
import { productTypes, kpiTypes } from '@/utils/enum'
import { mergeKpis } from '@/utils/kpi'

export default {
  components: {
    GaugeChart,
    LineBoundaryChart,
    TableKpiComponent,
    VariationChart,
    FactorySelector,
  },

  mixins: [productionWeatherMixin],

  provide: {
    type: productTypes.MATERIAL,
  },

  props: {
    material: {
      type: String,
      default: '',
    },
    mixDesign: {
      type: String,
      default: '',
    },
    plant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      kpiDialog: false,
      kpiTypes,
    }
  },
  computed: {
    ...mapState('production', [
      'selectedPlantId',
      'selectedMixId',
      'selectedMaterialId',
      'selectedMaterialPropertyId',
    ]),
    ...mapGetters('production', [
      'materialFilteredKpis',
      'materialSampleProperties',
      'materialSamplePropertyTimeline',
      'mixFilteredMaterialsList',
      'selectedMaterial',
      'materialFilteredKpis',
    ]),
    ...mapGetters(['hasAccessToKpis']),

    ...mapGetters({
      kpiScore: 'production/kpiScore',
    }),
    materialQualityKpi() {
      return mergeKpis(
        this.materialFilteredKpis.filter((k) => k.name === kpiTypes.IQGQMAT),
      )
    },
    specifications() {
      return this.selectedMaterial &&
        this.selectedMaterial.specifications &&
        this.selectedMaterialPropertyId
        ? this.selectedMaterial.specifications[this.selectedMaterialPropertyId]
        : [-3, -2, -1, 0, 1, 2, 3]
    },
    showGraphics() {
      return this.selectedMaterial !== undefined
    },
  },

  watch: {
    async material() {
      await this.$store.dispatch('production/changeSelectedMaterial', {
        material: this.material,
      })
    },
  },

  async created() {
    this.$wait.start('isFetchingProduction')

    if (!this.selectedPlantId) {
      await this.$store.dispatch('production/fetchPlants')
      this.$store.commit('production/selectPlant', this.plant)

      await this.$store.dispatch(
        'production/fetchProductionTechnicianDashboard',
        { plantId: this.selectedPlantId },
      )
      await this.$store.dispatch('production/changeSelectedMixDesign', {
        mixDesignId: this.mixDesign,
      })
      await this.$store.dispatch('production/changeSelectedMaterial', {
        material: this.material,
      })
    }

    this.$wait.end('isFetchingProduction')
  },
  methods: {
    ...mapMutations('production', ['selectMaterialSampleProperty']),
    onMaterialChange(item) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { plant: this.plant, mixDesign: this.mixDesign, material: item },
      })
    },
    onAggregatePropertyRowClick(data) {
      this.selectMaterialSampleProperty(data.id)
    },
  },
}
</script>

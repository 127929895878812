import axios from 'axios'
import multipartDataUtil from '@/utils/multipartDataUtil'
import { pick } from 'lodash'

export default {
  async getBenchmarks({ commit }, { location, radius } = {}) {
    let params = {}

    if (location) {
      params.location = location
    }

    if (radius) {
      params.radius = radius
    }

    const { data } = await axios.get('/iri/benchmarks', { params })
    commit('setCalibrations', data)
  },

  async getCalibrations({ commit }, params) {
    const { data } = await axios.get('/iri/calibrations', { params })
    commit('setCalibrations', data.calibrations)
    commit('updateDevices', data.devices)
    commit('updateVehicles', data.vehicles)
  },

  async getMeasurements({ commit }, params) {
    if (!params || !params?.idproject) return

    const { data } = await axios.get('/dashboards/iri-measurement', {
      params: {
        project: params.idproject,
      },
    })

    commit('images/setImages', [], { root: true })
    commit('setMeasurements', data.segments)
  },

  async getMeasurement({ commit }, params) {
    if (!params || !params?.measurementId) return

    const { data } = await axios.get('/dashboards/iri-measurement-details', {
      params: {
        measurement: params.measurementId,
      },
    })

    commit('images/setImages', data.images, { root: true })
    commit('setSegment', data.segments[0])
  },

  async uploadCalibration({ commit }, payload) {
    const { data } = await axios.post(
      '/iri/calibrations',
      multipartDataUtil
        .createFormDataBuilder()
        .addFields(pick(payload, ['type', 'vehicle', 'device']))
        .addFiles('iri', payload.files)
        .build(),
    )

    commit('addToCalibrations', data)

    return data
  },

  async uploadBenchmark(_, payload) {
    const { data } = await axios.post(
      '/iri/benchmarks',
      multipartDataUtil
        .createFormDataBuilder()
        .addFields(pick(payload, ['type', 'isReversed', 'isPublic']))
        .addFiles('iri', payload.files)
        .build(),
    )
    return data
  },

  async uploadIriData({ rootState }, payload) {
    const { startLocation } = payload

    const formData = multipartDataUtil
      .createFormDataBuilder()
      .addField('projectId', rootState.idproject)
      .addFields(
        pick(payload, [
          'type',
          'vehicle',
          'device',
          'isReference',
          'isReversed',
          'layer',
          'roadCategory',
          'roadWidth',
          'shoulderWidth',
          'bikeLaneWidth',
          'importMacrotexture',
          'lane',
          'direction',
          'sectionStart',
        ]),
      )
      .addFiles('iri', payload.files)
      .build()

    formData.set('data', JSON.stringify({ startLocation }))

    const { data } = await axios.post('/iri/measurements', formData)
    return data
  },

  async previewIriFile({ commit }, payload) {
    const formData = multipartDataUtil
      .createFormDataBuilder()
      .addField('type', payload.type)
      .addFiles('iri', payload.files)
      .build()

    const { data } = await axios.post('/iri/measurements/preview', formData)

    commit('setMeasurements', [data])
    return data
  },

  async updateCalibration(_, { id, ...payload }) {
    await axios.patch(`/iri/calibrations/${id}`, payload)
  },

  async getDevices({ commit }) {
    const { data } = await axios.get('/iri/devices')
    commit('updateDevices', data)
  },

  async createDevice(_, device) {
    const { data } = await axios.post('/iri/devices', device)
    return data
  },

  async patchMeasurement({ commit }, { id, payload }) {
    try {
      const { data } = await axios.patch(`/iri/measurements/${id}`, payload)
      if (!data) {
        throw new Error('No data returned')
      }
      commit('setSegment', data)
    } catch (error) {
      console.error(error)
    }
  },

  async getVehicles({ commit }) {
    const { data } = await axios.get('/iri/vehicles')
    commit('updateVehicles', data)
  },

  async createVehicle(_, vehicle) {
    const { data } = await axios.post('/iri/vehicles', vehicle)
    return data
  },

  async getRoadroidMeasurementList({ state, commit }) {
    if (state.roadroidMeasurementsList?.length > 0) {
      return
    }

    const { data } = await axios.get('/roadroid-import')
    commit('setRoadroidMeasurementsList', data)
  },

  async importRoadroidMeasurements(
    { state, rootState },
    { importHistoryIds, payload },
  ) {
    const ids = new Set(importHistoryIds)
    const projectId = rootState.idproject
    const projectType = rootState.project?.type
    const roadroidMeasurementIds = state.roadroidMeasurementsList
      .filter((measurement) => ids.has(measurement.ImportHistoryId))
      .map((measurement) => measurement.MeasurementId)

    if (!projectId) {
      throw new Error(
        'Invalid arguments for importing Roadroid measurement(s) from API',
      )
    }

    const { data } = await axios.post(`/roadroid-import/process`, {
      ...payload,
      projectId,
      projectType,
      roadroidMeasurementIds,
    })

    return data
  },

  async importRoadroidCalibration(
    { state, commit },
    { importHistoryIds, payload },
  ) {
    const ids = new Set(importHistoryIds)
    const roadroidMeasurementIds = state.roadroidMeasurementsList
      .filter((measurement) => ids.has(measurement.ImportHistoryId))
      .map((measurement) => measurement.MeasurementId)

    const { data } = await axios.post(`/roadroid-import/calibration`, {
      ...payload,
      roadroidMeasurementIds,
    })

    commit('addToCalibrations', data)

    return data
  },

  async previewRoadroidMeasurements({ state, commit }, importHistoryIds) {
    const ids = new Set(importHistoryIds)
    const roadroidMeasurementIds = state.roadroidMeasurementsList
      .filter((measurement) => ids.has(measurement.ImportHistoryId))
      .map((measurement) => measurement.MeasurementId)

    const { data } = await axios.post('/roadroid-import/preview', {
      roadroidMeasurementIds,
    })

    commit('setMeasurements', [data])

    return data
  },

  async importImagesForMeasurement(_, measurementId) {
    const { data } = await axios.post(`/roadroid-import/image/${measurementId}`)

    return data
  },
}

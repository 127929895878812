<template>
  <div class="trend-chip">
    <v-icon
      v-if="useTrend"
      small
      :color="trendColor"
    >
      {{ trendIcon }}
    </v-icon>

    <v-icon
      v-if="useRisk"
      small
      :color="riskColor"
    >
      {{ riskIcon }}
    </v-icon>

    <slot />
    <v-chip
      v-if="timeSince"
      class="trend-chip ml-1"
      label
    >
      <v-icon x-small> $fas fa-clock </v-icon>
      <span class="ml-1">{{ timeSince }}</span>
    </v-chip>
  </div>
</template>

<script>
import { trend as trendEnum, risk as riskEnum } from '@/utils/enum'

export default {
  name: 'TrendChip',
  props: {
    date: {
      type: Date,
      required: false,
      default: undefined,
    },
    risk: {
      type: Number,
      default: undefined,
    },
    trend: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    timeSince() {
      if (this.date && this.$dayjs(this.date).isValid()) {
        return this.$dayjs(this.date).fromNow(true)
      } else {
        return undefined
      }
    },
    useTrend() {
      return this.trend !== undefined
    },
    trendIcon() {
      switch (this.trend) {
        case trendEnum.UPWARD:
          return '$fas fa-arrow-trend-up'
        case trendEnum.CONSTANT:
          return '$fas fa-arrow-right-long'
        case trendEnum.DOWNWARD:
          return '$fas fa-arrow-trend-down'
        default:
          return '$fas fa-minus'
      }
    },
    trendColor() {
      switch (this.trend) {
        case trendEnum.UPWARD:
          return 'success'
        case trendEnum.CONSTANT:
          return 'warning'
        case trendEnum.DOWNWARD:
          return 'error'
        default:
          return 'secondary'
      }
    },
    useRisk() {
      return this.risk !== undefined
    },
    riskIcon() {
      switch (this.risk) {
        case riskEnum.LR:
          return '$fas fa-circle-check'
        case riskEnum.AR:
          return '$fas fa-triangle-exclamation'
        case riskEnum.HR:
          return '$fas fa-circle-exclamation'
        default:
          return '$fas fa-minus'
      }
    },
    riskColor() {
      switch (this.risk) {
        case riskEnum.LR:
          return 'success'
        case riskEnum.AR:
          return 'warning'
        case riskEnum.HR:
          return 'error'
        default:
          return 'secondary'
      }
    },
  },
}
</script>

<template>
  <div class="d-flex justify-center align-center">
    <v-btn-toggle
      v-model="mapSwitch"
      mandatory
      @change="onChange"
    >
      <v-btn>
        <v-icon>{{ '$fas fa-address-card' }}</v-icon>
      </v-btn>

      <v-btn>
        <v-icon>{{ '$fas fa-map' }}</v-icon>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>
<script>
export default {
  name: 'MapSwitch',
  data() {
    return {
      mapSwitch: parseInt(localStorage.getItem('mapView')),
    }
  },

  methods: {
    onChange() {
      localStorage.setItem('mapView', this.mapSwitch)
      this.$emit('map-switch-toggle', this.mapSwitch)
    },
  },
}
</script>

<template>
  <v-card
    outlined
    class="pa-0 fill-height"
  >
    <l-map
      ref="iriMap"
      class="leaflet-map fill-height"
      :zoom="mapMixin_zoom"
      :center="center"
      :options="mapMixin_options"
      @dblclick="onDoubleClick"
    >
      <l-tile-layer
        :url="mapMixin_url"
        :attribution="mapMixin_attribution"
        :options="mapMixin_tileOptions"
      />
      <l-marker
        :lat-lng="marker"
        :options="{ draggable: true, autoPan: true }"
        @dragend="(event) => updateMarker(event)"
      >
        <l-icon
          :icon-anchor="[13, 41]"
          :tooltip-anchor="[15, -30]"
          icon-url="/img/marker-icon.png"
          icon-retina-url="/img/marker-icon-2x.png"
          shadow-url="/img/marker-shadow.png"
        />
        <l-tooltip>{{ $t('enums.headers.sectionStart') }}</l-tooltip>
      </l-marker>
      <l-geo-json
        ref="geoJsonLayer"
        :geojson="iriFeatures"
        :options-style="iriStyle"
      />
    </l-map>
  </v-card>
</template>

<script>
import {
  LMap,
  LIcon,
  LMarker,
  LTileLayer,
  LTooltip,
  LGeoJson,
} from 'vue2-leaflet'
import mapMixin from '@/mixins/mapMixin'
import { mapMutations } from 'vuex'
import { defaultGeolocation } from '@/utils/constants'
import * as enums from '@/utils/enum'
import { geoJsonToMarker } from '@/utils/map'
import { flatten } from 'lodash'

export default {
  name: 'MeasurementsMap',
  components: {
    LMap,
    LGeoJson,
    LIcon,
    LMarker,
    LTileLayer,
    LTooltip,
  },

  mixins: [mapMixin],

  props: {
    iriRuns: {
      type: Array,
      required: false,
      default: () => [],
    },
    startProjectLocation: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    center() {
      return this.marker || defaultGeolocation
    },

    marker() {
      let marker = defaultGeolocation

      if (this.iriRuns?.length >= 1) {
        marker = geoJsonToMarker(this.iriRuns[0].startLocation)
      } else if (this.startProjectLocation) {
        marker = this.startProjectLocation
      }

      return marker
    },

    mapRef() {
      return this.$refs.iriMap
    },

    iriFeatures() {
      return flatten(
        this.iriRuns.map((run) =>
          run.iriSections.map((iri) => ({
            type: 'Feature',
            properties: {
              risk: iri.RUni,
            },
            geometry: {
              type: 'LineString',
              coordinates: [
                iri.startLocation.coordinates,
                iri.endLocation.coordinates,
              ],
            },
          })),
        ),
      )
    },
  },

  methods: {
    ...mapMutations(['showDanger', 'showSuccess']),
    iriStyle(feature) {
      return {
        color: this.iriColor(feature.properties.risk),
        opacity: 1,
      }
    },

    iriColor(risk) {
      return risk === enums.risk.LR
        ? '#05A57D'
        : risk === enums.risk.AR
          ? '#FFBD21'
          : risk === enums.risk.HR
            ? '#FF6666'
            : '#0090FE'
    },

    onSearch(position) {
      const location = [position.location.y, position.location.x]
      this.$emit('markerUpdated', location)
    },

    onDoubleClick(position) {
      const location = [position.latlng.lat, position.latlng.lng]
      this.$emit('markerUpdated', location)
    },

    updateMarker(event) {
      const location = [event.target._latlng.lat, event.target._latlng.lng]
      this.$emit('markerUpdated', location)
    },
  },
}
</script>

<template>
  <v-wait for="isPreparingReport">
    <core-spinner slot="waiting" />
    <title-bar :show-back="true">
      <ProjectSelector
        disable-project
        class="col-5"
      />
    </title-bar>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <material-card outlined>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :value="report && $t(`enums.reportTypes.${report.type}`)"
                      :label="$t('views.reports.report-type')"
                      readonly
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :value="
                        report && $t(`enums.reportSources.${report.source}`)
                      "
                      :label="$t('views.reports.source')"
                      readonly
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :value="report && report.impact"
                      :label="$t('views.reports.impact')"
                      readonly
                    />
                  </v-col>
                  <v-col
                    v-if="report && report.nature"
                    cols="12"
                  >
                    <v-text-field
                      :label="$t('views.reports.nature')"
                      :value="
                        report && $t(`enums.reportNatures.${report.nature}`)
                      "
                      readonly
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :label="$t('views.reports.author')"
                      :value="report && report.author"
                      readonly
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :label="$t('views.reports.created-at')"
                      :value="
                        report &&
                        $dayjs(report.createdAt).format('YYYY-MM-DD H:mm')
                      "
                      readonly
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :label="$t('views.reports.updated-at')"
                      :value="
                        report &&
                        $dayjs(report.updatedAt).format('YYYY-MM-DD H:mm')
                      "
                      readonly
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-for="image in images"
                :key="image._id"
                cols="12"
                sm="6"
                md="4"
                xl="2"
              >
                <v-card class="thumbnail-card">
                  <v-img
                    :src="image.src"
                    class="white--text align-end"
                    height="250"
                    width="250"
                  >
                    <v-card-title>
                      <div class="text-truncate">
                        {{ image.originalFilename }}
                      </div>
                    </v-card-title>
                  </v-img>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      icon
                      @click="$refs.imageDialog.show(image)"
                    >
                      <v-icon>$fas fa-expand-alt</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <ResizableImageDialog ref="imageDialog" />
          </material-card>
        </v-col>

        <v-col cols="12">
          <material-card outlined>
            <v-form ref="reportForm">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-if="isReportInProgress"
                    v-model="selectedState"
                    :items="reportStates"
                    :label="$t('views.reports.report-state')"
                    class="select-no-border"
                    :menu-props="{
                      nudgeBottom: 1000,
                    }"
                  />
                </v-col>
                <v-col cols="12">
                  <material-card
                    v-for="(version, index) of descriptionVersions"
                    :key="index"
                    outlined
                    class="my-2"
                  >
                    <!-- Use class binding to dynamically set styling -->
                    <div :class="{ callout: index === 0 }">
                      <div class="subtitle-1 ml-2">
                        {{ version.user.name }}
                      </div>
                      <div class="text-caption text--disabled ml-2">
                        {{
                          $dayjs(version.timestamp).format('YYYY-MM-DD H:mm')
                        }}
                      </div>
                    </div>
                    <div class="body-1">
                      {{ version.text }}
                    </div>
                  </material-card>
                </v-col>
              </v-row>
              <v-row
                v-if="!isReportInProgress"
                align="center"
                justify="center"
              >
                <v-btn
                  :loading="isLoading"
                  color="primary"
                  @click="setInProgress"
                >
                  {{ $t('views.reports.markInProgress') }}
                </v-btn>
              </v-row>
              <v-row v-if="isReportInProgress">
                <v-col cols="12">
                  <v-textarea
                    v-model="description"
                    :label="$t('views.reports.description')"
                    filled
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-btn
                    :loading="isLoading"
                    color="primary"
                    @click="update"
                  >
                    {{ $t('views.reports.confirm') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-wait>
</template>

<script>
import TitleBar from '@/components/core/TitleBar'
import { mapActions, mapGetters } from 'vuex'
import { reportStates } from '@/utils/enum'
import { toSelectItems } from '@/utils/i18n'
import axios from 'axios'
import ResizableImageDialog from '@/components/DashViews/Image/ResizableImageDialog'
import ProjectSelector from '../../core/ProjectSelector.vue'

export default {
  name: 'EditReport',
  components: {
    TitleBar,
    ResizableImageDialog,
    ProjectSelector,
  },
  props: {
    reportId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    selectedState: null,
    description: '',
    isLoading: false,
    images: [],
    historyDialog: false,
  }),

  computed: {
    ...mapGetters({ getReportById: 'reports/getById' }),

    report() {
      return this.getReportById(this.reportId)
    },
    reportStates() {
      return toSelectItems(reportStates, 'enums.reportStates')
    },
    descriptionVersions() {
      return (this.report && this.report.description) || []
    },
    isReportInProgress() {
      return this.selectedState !== 'created'
    },
  },

  async created() {
    this.$wait.start('isPreparingReport')
    await this.fetchReport(this.reportId)
    this.selectedState = this.report && this.report.state
    await this.prepareImages()
    this.$wait.end('isPreparingReport')
  },

  methods: {
    ...mapActions({
      fetchReport: 'reports/fetchById',
      updateReport: 'reports/update',
    }),

    async update() {
      if (!this.$refs.reportForm.validate()) return

      this.isLoading = true
      try {
        await this.updateReport({
          _id: this.report._id,
          state: this.selectedState,
          description: this.description,
        })
        this.description = ''
      } finally {
        this.isLoading = false
      }
    },

    async setInProgress() {
      this.isLoading = true

      try {
        await this.updateReport({
          _id: this.report._id,
          state: 'processing',
        })
        this.selectedState = 'processing'
      } finally {
        this.isLoading = false
      }
    },

    async prepareImages() {
      const images = [...this.report.pictures.map((_p) => _p.image)]
      if (this.report.memoPicture) {
        images.push(this.report.memoPicture)
      }

      await Promise.all(
        images.map(async (_image) => {
          if (_image.src) {
            return
          }
          try {
            const { data } = await axios.get(
              `/images/thumbnail/${_image._id}`,
              { responseType: 'blob' },
            )
            _image.src = URL.createObjectURL(data)
          } catch (err) {
            console.warn(err)
          }
        }),
      )

      this.images = images
    },
  },
}
</script>

<template>
  <div>
    <title-bar :show-back="true" />
    <v-container fluid>
      <v-row>
        <v-col sm12>
          <material-card outlined>
            <v-form ref="reportForm">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="selectedType"
                    :items="reportTypes"
                    :label="$t('views.reports.report-type')"
                    :rules="[
                      (v) => !!v || $t('views.reports.report-type-error'),
                    ]"
                    class="select-no-border"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="selectedProject"
                    :items="projects"
                    :label="$t('core.toolbar.project-selection.label')"
                    :rules="[(v) => !!v || $t('views.reports.project-error')]"
                    item-text="title"
                    item-value="_id"
                    class="select-no-border"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="selectedSource"
                    :items="reportSources"
                    :label="$t('views.reports.source')"
                    :rules="[(v) => !!v || $t('views.reports.source-error')]"
                    class="select-no-border"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="author"
                    :label="$t('views.reports.author')"
                    :rules="[(v) => !!v || $t('views.reports.author-error')]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="impact"
                    :items="impactLevels"
                    :label="$t('views.reports.impact')"
                    :rules="[(v) => !!v || $t('views.reports.impact-error')]"
                    class="select-no-border"
                  />
                </v-col>
                <v-col
                  v-if="selectedType === reportTypesEnum.externalReport"
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="nature"
                    :items="reportNatures"
                    :label="$t('views.reports.nature')"
                    :rules="[(v) => !!v || $t('views.reports.nature-error')]"
                    class="select-no-border"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="description"
                    :label="$t('views.reports.description')"
                    filled
                  />
                </v-col>
                <v-col
                  v-if="selectedType === reportTypesEnum.externalReport"
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="memoPicture"
                    :accept="'.jpg,.jpeg,.tiff'"
                    :label="$t('views.reports.memo-picture')"
                    :clearable="false"
                    :rules="[
                      (v) =>
                        selectedType !== reportTypesEnum.externalReport ||
                        !!v ||
                        $t('views.reports.memo-picture-error'),
                    ]"
                    small-chips
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="pictures"
                    :accept="'.jpg,.jpeg,.tiff'"
                    :label="$t('views.reports.pictures')"
                    :clearable="false"
                    :rules="[
                      (v) =>
                        !v ||
                        (!!v && v.length <= 10) ||
                        $t('views.reports.pictures-error'),
                    ]"
                    multiple
                    counter
                    small-chips
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="memoPicture"
                justify="start"
              >
                <v-col cols="12">
                  <div class="subtitle-1 ml-2">
                    {{ $t('views.reports.memo-picture') }}
                  </div>
                </v-col>
                <v-col>
                  <v-img
                    :src="memoPicturePreview"
                    class="picture-preview"
                    width="200"
                    contain
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="pictures.length > 0"
                justify="start"
              >
                <v-col cols="12">
                  <div class="subtitle-1 ml-2">
                    {{ $t('views.reports.pictures') }}
                  </div>
                </v-col>
                <v-col class="picture-review-container">
                  <v-img
                    v-for="preview in picturePreviews"
                    :key="preview.key"
                    :src="preview.src"
                    class="picture-preview"
                    width="200"
                    contain
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-btn
                    :loading="isLoading"
                    color="primary"
                    @click="create"
                    >{{ $t('views.reports.confirm') }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </material-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TitleBar from '@/components/core/TitleBar'
import {
  reportTypes,
  reportSources,
  impactLevels,
  reportNatures,
} from '@/utils/enum'
import { toSelectItems } from '@/utils/i18n'
import { mapActions, mapMutations, mapState } from 'vuex'
import projectsListMixin from '@/mixins/projectsListMixin'

export default {
  name: 'CreateReport',
  components: {
    TitleBar,
  },
  mixins: [projectsListMixin],

  data: () => ({
    reportTypesEnum: reportTypes,
    selectedType: null,
    selectedProject: null,
    selectedSource: null,
    author: '',
    memoPicture: null,
    pictures: [],
    impact: 3,
    description: '',
    isLoading: false,
    nature: null,
  }),

  computed: {
    ...mapState(['projects', 'user', 'idproject']),

    reportTypes() {
      return toSelectItems(reportTypes, 'enums.reportTypes')
    },
    reportSources() {
      return toSelectItems(reportSources, 'enums.reportSources')
    },
    impactLevels() {
      return toSelectItems(impactLevels, 'enums.impactLevels')
    },
    reportNatures() {
      return toSelectItems(reportNatures, 'enums.reportNatures')
    },
    memoPicturePreview() {
      return URL.createObjectURL(this.memoPicture)
    },
    picturePreviews() {
      return this.pictures.map((_p) => ({
        key: _p.filename,
        src: URL.createObjectURL(_p),
      }))
    },
  },

  mounted() {
    this.author = this.user.name
    this.selectedProject = this.idproject
  },

  methods: {
    ...mapActions({ createReport: 'reports/create' }),
    ...mapMutations(['showError']),

    async create() {
      if (!this.$refs.reportForm.validate()) return
      this.isLoading = true
      try {
        const reportBody = {
          type: this.selectedType,
          project: this.selectedProject,
          source: this.selectedSource,
          author: this.author,
          memoPicture: this.memoPicture,
          pictures: this.pictures,
          impact: this.impact,
          description: this.description,
        }

        if (this.selectedType === reportTypes.externalReport) {
          reportBody.nature = this.nature
        }

        const report = await this.createReport(reportBody)
        await this.$router.replace({ path: `/dashboard/reports/${report._id}` })
      } catch (e) {
        this.showError(this.$t('views.reports.create-error'))
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
.picture-review-container {
  display: inline-block;
}
.picture-preview {
  display: inline-block;
  margin-right: 0.5rem;
  border-radius: 5px;
}
</style>

<template>
  <div>
    <l-geo-json
      v-if="!!section"
      :geojson="section"
      :options-style="sectionStyle"
    />
  </div>
</template>

<script>
import { LGeoJson } from 'vue2-leaflet'
import { riskToHexColor } from '@/utils/map'

export default {
  name: 'SectionHighlight',

  components: {
    LGeoJson,
  },

  props: {
    section: {
      type: Object,
      required: false,
      default: null,
    },
  },

  methods: {
    sectionStyle(feature) {
      return {
        weight: 15,
        fillOpacity: 0.2,
        opacity: 0.6,
        color: riskToHexColor(feature.properties.risk),
      }
    },
  },
}
</script>

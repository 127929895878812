import { defaults } from 'lodash'

export const initialUserManagementState = {
  user: {
    roles: [],
    id: '',
  },
  users: [],
  page: 1,
  totalPages: 1,
  totalResults: 10,
}

export default defaults({}, initialUserManagementState)

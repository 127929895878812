var render = function render(){var _vm=this,_c=_vm._self._c;return _c('material-card',{staticClass:"fill-height",attrs:{"outlined":""}},[_c('div',{staticClass:"callout"},[_c('div',{staticClass:"subtitle-1 ml-2"},[_vm._v(" "+_vm._s(_vm.title || _vm.$t('views.charts.tableList.title'))+" "),(_vm.hidden.length > 0)?_c('v-btn',{staticClass:"right",attrs:{"label":"","icon":""},on:{"click":_vm.expand}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("$fas fa-expand-alt")])],1):_vm._e()],1),_c('div',{staticClass:"text-caption text--disabled ml-2"},[_vm._v(" "+_vm._s(_vm.description || _vm.$t('views.charts.tableList.description'))+" ")])]),_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items-per-page":10,"items":_vm.items,"footer-props":{
      showFirstLastPage: true,
      firstIcon: '$fas fa-arrow-left',
      lastIcon: '$fas fa-arrow-right',
      prevIcon: '$fas fa-minus',
      nextIcon: '$fas fa-plus',
    }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',_vm._l((_vm.computedHeaders),function(header,index){return _c('td',{key:index},[(header.checkbox)?[_c('v-checkbox',{staticClass:"px-4",on:{"change":function($event){header.onChange &&
                  header.onChange.call(
                    this,
                    item.id,
                    item[header.value].value,
                  )}},model:{value:(item[header.value].value),callback:function ($$v) {_vm.$set(item[header.value], "value", $$v)},expression:"item[header.value].value"}})]:(header.slot === true)?[_vm._t(header.value,null,{"item":item})]:(item[header.value] !== undefined)?[(item[header.value].icon)?_c('v-icon',{attrs:{"color":item[header.value].icon.color}},[_vm._v("$"+_vm._s(item[header.value].icon.value))]):_vm._e(),_vm._v(" "+_vm._s(item[header.value].value)+" ")]:_vm._e()],2)}),0)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
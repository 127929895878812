import Vue from 'vue'
import resetState from '@/utils/resetState'
import { initialWeatherState } from './state'

export default {
  setCurrent: (state, weather) => {
    state.loaded = true
    state.enabled = true
    state.location = weather.location
    state.condition = weather.current.condition
    state.current = weather.current.current
    state.min = weather.current.min
    state.max = weather.current.max
    state.humidity = weather.current.humidity
    state.wind = weather.current.wind
    state.precipitation = weather.current.precipitation
    state.date = weather.date
    state.time = weather.time
    state.position = weather.position
    Vue.set(state, 'forecast', weather.forecast)
  },
  clearState(state) {
    resetState(state, initialWeatherState)
  },
  setWeatherWidgetState(state, enabled) {
    Vue.set(state, 'enabled', enabled)
  },
}

<template>
  <material-card
    outlined
    class="fill-height"
  >
    <core-callout
      :title="$t('views.charts.tableDataPoint.title')"
      :subtitle="$t('views.charts.tableList.description')"
    ></core-callout>
    <v-data-table
      class="v-table-pointer"
      :headers="segmentHeaders"
      :items="segments"
      item-key="_id"
      fixed-header
      style="overflow: auto"
      height="100%"
      hide-default-footer
      disable-pagination
      @click:row="onRowClick"
      @mouseover:row="(event, { item }) => selectSection(item)"
      @mouseleave:row="(event, { item }) => unselectSection(item)"
    >
      <template #headerCell="{ header }">
        <span
          class="subheading font-weight-light text-success text--darken-3"
          v-text="header.text"
        />
      </template>
      <template #[`item.name`]="{ item }">
        {{ item.name || '-' }}
      </template>
      <template #[`item.imageCount`]="{ item }">
        <div class="d-flex justify-space-around align-center">
          <div
            class="image-count"
            :class="{
              'background-success': item.imageCount,
              'background-danger': !item.imageCount,
            }"
          >
            {{ item.imageCount || 0 }}
          </div>
          <v-btn
            icon
            color="primary"
            @click.stop="goToIriImageUpload(item)"
          >
            <v-icon>$fas fa-file-circle-plus</v-icon>
          </v-btn>
        </div>
      </template>
      <template #[`item.date`]="{ item }">
        {{ $helpers.date.formatDatetime(item.date) }}
      </template>
      <template #[`item.roadCategory`]="{ item }">
        {{ $t('enums.roadCategories.' + item.roadCategory) }}
      </template>
      <template #[`item.layer`]="{ item }">
        {{ $t('enums.layers.' + item.layer) }}
      </template>
      <template #[`item.mode`]>
        {{ $t('enums.projectTypes.' + projectType) }}
      </template>
      <template #[`item.deviceVehicle`]="{ item }">
        {{
          getDeviceVehicleDescription(item) ||
          $t('views.iri-management.not-available')
        }}
      </template>
      <template #[`item.type`]="{ item }">
        {{ getType(item) || $t('views.iri-management.not-available') }}
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import * as enums from '@/utils/enum'
import { mapGetters } from 'vuex'

export default {
  name: 'RunDetailTable',
  props: {
    segments: {
      type: Array,
      required: true,
    },
    projectType: {
      type: String,
      default: enums.projectTypes.construction,
    },
  },

  data() {
    return {
      contractSegmentHeaders: [
        { text: this.$t('enums.headers.name'), value: 'name', sortable: true },
        {
          text: this.$t('enums.headers.images'),
          value: 'imageCount',
          sortable: true,
          align: 'center',
        },
        { text: this.$t('enums.headers.date'), value: 'date', sortable: true },
        {
          text: this.$t('enums.headers.layer'),
          value: 'layer',
          sortable: true,
        },
        { text: this.$t('enums.headers.mode'), value: 'mode', sortable: true },
        {
          text: this.$t('views.iri-management.upload-form.device-vehicle-pair'),
          value: 'deviceVehicle',
          sortable: true,
        },
        { text: this.$t('enums.headers.type'), value: 'type', sortable: true },
      ],
      maintenanceSegmentHeaders: [
        { text: this.$t('enums.headers.name'), value: 'name', sortable: true },
        {
          text: this.$t('enums.headers.images'),
          value: 'imageCount',
          sortable: true,
        },
        { text: this.$t('enums.headers.date'), value: 'date', sortable: true },
        {
          text: this.$t('enums.headers.roadCategory'),
          value: 'roadCategory',
          sortable: true,
        },
        { text: this.$t('enums.headers.mode'), value: 'mode', sortable: true },
        {
          text: this.$t('views.iri-management.upload-form.device-vehicle-pair'),
          value: 'deviceVehicle',
          sortable: true,
        },
        { text: this.$t('enums.headers.type'), value: 'type', sortable: true },
      ],
    }
  },
  computed: {
    ...mapGetters('iri', ['device', 'vehicle']),

    segmentHeaders() {
      return this.projectType === enums.projectTypes.construction
        ? this.contractSegmentHeaders
        : this.maintenanceSegmentHeaders
    },
  },
  methods: {
    onRowClick(segment) {
      this.$emit('row:click', segment)
    },
    selectSection(item) {
      this.$emit('section-selected', item)
    },
    unselectSection() {
      this.$emit('section-selected', undefined)
    },
    getDeviceVehicleDescription(deviceId, vehicleId) {
      const device = this.device(deviceId)
      const vehicle = this.vehicle(vehicleId)

      if (device && vehicle) {
        return `${device.externalIdentifier} - ${vehicle.make} ${vehicle.model} - ${vehicle.licencePlate}`
      }
    },
    getType(datapoint) {
      let type

      if (datapoint.importerFileType) {
        type = this.$t(`enums.iri-file-types.${datapoint.importerFileType}`)

        if (datapoint.deviceClass) {
          type +=
            ' (' + this.$t(`enums.device-type.${datapoint.deviceClass}`) + ')'
        }
      }

      return type
    },

    goToIriImageUpload(segment) {
      const path = `/dashboard/iri-images/${segment._id}`
      this.$router.push({ path })
    },
  },
}
</script>

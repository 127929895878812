var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-wait',{attrs:{"for":"isFetchingCalibrations"}},[_c('core-spinner',{attrs:{"slot":"waiting"},slot:"waiting"}),_c('title-bar',{attrs:{"show-back":true}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ListTable',{attrs:{"title":_vm.$t('views.iri-management.calibration-list-title'),"description":_vm.$t('views.iri-management.calibration-list-description'),"headers":[
            {
              text: _vm.$t(
                'views.iri-management.calibration-list-header.calibration',
              ),
              value: 'iriOffset',
              sortable: true,
            },
            {
              text: _vm.$t('views.iri-management.calibration-list-header.device'),
              value: 'device',
              sortable: true,
            },
            {
              text: _vm.$t(
                'views.iri-management.calibration-list-header.vehicle',
              ),
              value: 'vehicle',
              sortable: true,
            },
            {
              text: _vm.$t('views.iri-management.calibration-list-header.date'),
              value: 'date',
              sortable: true,
            },
            {
              text: _vm.$t(
                'views.iri-management.calibration-list-header.enabled',
              ),
              value: 'enabled',
              sortable: false,
              checkbox: true,
              onChange: _vm.updateDisabledCalibration,
            },
          ],"items":_vm.calibrationItems,"hidden":[]}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { risk, productTypes } from '@/utils/enum'
import i18n from '@/i18n'

export function notificationsFromProductionKpis(kpis, userId) {
  return kpis
    .filter(
      (kpi) =>
        kpi.risk >= risk.AR &&
        i18n.te(`views.notifications.RBAI.${kpi.name}.${kpi.state}`),
    )
    .map((kpi) => ({
      name: kpi.name,
      risk: kpi.risk,
      id: kpi.metadata.product + kpi.name,
      metadata: kpi.metadata,
      read: kpi.readBy?.includes(userId) || false,
      sticky: !Array.isArray(kpi.readBy),
      date: kpi.date,
      text: i18n.t(`views.notifications.RBAI.${kpi.name}.${kpi.state}`),
      solution: i18n.te(
        `views.notifications.solutions.${kpi.name}.${kpi.state}`,
      )
        ? i18n.t(`views.notifications.solutions.${kpi.name}.${kpi.state}`)
        : '',
    }))
}

export function notificationsFromKpis(kpis, userId) {
  return kpis
    .filter(
      (kpi) =>
        kpi.risk >= risk.AR &&
        i18n.te(`views.notifications.RBAI.${kpi.name}.${kpi.state}`),
    )
    .map((kpi) => ({
      name: kpi.name,
      risk: kpi.risk,
      id: kpi._id,
      read: kpi?.readBy?.includes(userId) || false,
      date: kpi.date,
      text: i18n.t(`views.notifications.RBAI.${kpi.name}.${kpi.state}`),
      solution: i18n.te(
        `views.notifications.solutions.${kpi.name}.${kpi.state}`,
      )
        ? i18n.t(`views.notifications.solutions.${kpi.name}.${kpi.state}`)
        : '',
    }))
}

export function productNotificationsFromKpis(kpis, userId, filter) {
  if (filter?.type === productTypes.ASPHALT) {
    kpis = kpis.filter((kpi) => kpi?.metadata?.mixDesign === filter.mixDesign)
  } else if (filter?.type === productTypes.AGGREGATE) {
    kpis = kpis.filter(
      (kpi) =>
        kpi?.metadata?.specification === filter.specification &&
        kpi?.metadata?.stockpile === filter.stockpile,
    )
  }

  return notificationsFromProductionKpis(kpis, userId)
}

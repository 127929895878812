<template>
  <v-card
    outlined
    class="fill-height v-card-dense expandable-card"
    :class="{ 'expanded-card': isExpanded }"
  >
    <v-btn
      x-small
      depressed
      outlined
      color="grey"
      class="expand-button pa-4"
      @click="expandCard"
    >
      <v-icon> {{ icon }} </v-icon>
    </v-btn>
    <v-card-text>
      <slot :is-expanded="isExpanded" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
      isExpanded: false,
    }
  },

  computed: {
    icon() {
      return this.isExpanded ? '$fas fa-compress-alt' : '$fas fa-expand-alt'
    },
  },

  beforeDestroy() {
    this.setIsCardExpanded(false)
  },

  methods: {
    ...mapMutations(['setIsCardExpanded']),

    expandCard() {
      this.isExpanded = !this.isExpanded
      this.setIsCardExpanded(this.isExpanded)
    },
  },
}
</script>

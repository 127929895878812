<template>
  <material-card
    outlined
    class="fill-height"
  >
    <v-card-title>
      <div class="callout">
        <div class="subtitle-1 ml-2">
          {{ $t('views.charts.notificationTable.title') }}
        </div>
        <div class="text-caption text--disabled ml-2">
          {{ $t('views.charts.notificationTable.description') }}
        </div>
      </div>
      <v-spacer />

      <v-text-field
        v-model="search"
        :label="$t('views.projects.search')"
        append-icon="$fas fa-search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      class="row-pointer fill-width"
      :headers="headers"
      sort-by="date"
      :sort-desc="true"
      :items="notifications"
      :items-per-page="-1"
      :search="search"
      hide-default-footer
      fixed-header
      style="overflow: auto"
      height="100%"
      @click:row="readNotification"
    >
      <template #item.risk="{ item: notification }">
        <RingChart
          :id="'icon' + notification.id"
          :inner-radius="23"
          :outer-radius="25"
          :percentile="100"
          :yellow-min="notification.risk == 3 ? 101 : 99"
          :green-min="120"
          :no-background-color="true"
          class="my-2"
        >
          <v-icon class="white--text"> $far fa-bell </v-icon>
        </RingChart>
      </template>
      <template #item.date="{ item: notification }">
        <div :class="{ unread: !notification.read }">
          {{ notification.date ? formatDate(notification.date) : '-' }}
        </div>
      </template>
      <template #item.text="{ item: notification }">
        <div :class="{ unread: !notification.read }">
          {{ notification.text }}
        </div>
        <div v-if="notification.solution">
          {{ notification.solution }}
        </div>
      </template>
      <template #item.read="{ item: notification }">
        <v-icon :color="notificationColor(notification)">
          {{ notificationIcon(notification) }}
        </v-icon>
      </template>
    </v-data-table>
  </material-card>
</template>
<script>
import RingChart from '../Ring.vue'
import { kpiTypes } from '@/utils/enum'
import { toMonthYearDay } from '@/utils/date'

export default {
  name: 'NotificationTable',
  components: {
    RingChart,
  },
  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('views.notifications.headers.risk'),
          value: 'risk',
          align: 'center',
        },
        {
          text: this.$t('views.notifications.headers.date'),
          value: 'date',
          align: 'center',
        },
        {
          text: this.$t('views.notifications.headers.description'),
          value: 'text',
          sortable: false,
        },
        {
          text: this.$t('views.notifications.headers.read'),
          value: 'read',
          align: 'center',
        },
      ],
      formatDate: toMonthYearDay,
      search: '',
    }
  },

  methods: {
    notificationColor(notification) {
      if (notification.read) {
        return 'gray'
      }
      return 'primary'
    },

    notificationIcon(notification) {
      if (notification.name === kpiTypes.RMEMO) {
        return '$fas fa-link'
      }
      if (notification.read) {
        return '$fas fa-envelope-open'
      }
      return '$fas fa-envelope'
    },

    readNotification(notification) {
      if (notification.name === kpiTypes.RMEMO) {
        const routeData = this.$router.resolve({
          path: '/dashboard/reports',
          query: { project: notification.project },
        })
        window.open(routeData.href, '_blank')
        notification.read = true
      } else if (
        !notification.read &&
        notification?.metadata?.product &&
        !notification.sticky
      ) {
        this.$store.dispatch('readProductionNotification', notification)
        notification.read = true
      } else if (!notification.read && !notification.sticky) {
        this.$store.dispatch('readNotification', notification.id)
        notification.read = true
      }
    },
  },
}
</script>
<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
.unread {
  font-weight: bold;
}
</style>

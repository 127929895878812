import { render, staticRenderFns } from "./SiteForemanDetails.vue?vue&type=template&id=5e8f5dba&scoped=true"
import script from "./SiteForemanDetails.vue?vue&type=script&lang=js"
export * from "./SiteForemanDetails.vue?vue&type=script&lang=js"
import style0 from "./SiteForemanDetails.vue?vue&type=style&index=0&id=5e8f5dba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e8f5dba",
  null
  
)

export default component.exports
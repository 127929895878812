export const set = (property) => (state, payload) => (state[property] = payload)

export const toggle = (property) => (state) =>
  (state[property] = !state[property])

export const isJson = function (text) {
  return !!/^[\],:{}\s]*$/.test(
    text
      .replace(/\\["\\/bfnrtu]/g, '@')
      .replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
        ']',
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
  )
}

<template>
  <div>
    <title-bar>
      <DatePicker
        class="col-auto"
        @month-year-change="onMonthYearChange"
      />
      <MapSwitch
        class="col-auto"
        @map-switch-toggle="onMapSwitchToggle"
      />
    </title-bar>

    <core-progress :in-progress="$wait.is('isUpdatingDashboard')" />

    <v-container
      v-if="mapView === 1"
      fluid
      class="grid-container"
    >
      <IriMap
        class="map"
        :map-bindings="mapBindings"
      >
        <template #map-content>
          <TechnicianMarkers
            :markers="markersForSelectedDate"
            @marker-click="reroute"
          />
        </template>
      </IriMap>
    </v-container>

    <v-container
      v-else
      fluid
    >
      <v-row>
        <v-col
          v-for="(data, index) in dataForSelectedDate"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <material-card
            class="fill-height"
            @click="reroute(data.project._id, data.user._id)"
          >
            <div class="callout pl-2 d-flex flex-column fill-height">
              <div class="text-h6">{{ data.user.name }}</div>
              <div class="font-weight-light">
                {{ fullProjectTitle(data.project) }}
              </div>
              <div class="font-weight-light">
                {{
                  data.user.roles
                    ? translateRoles(data.user.roles, 'enums.roles')
                    : translateRoles(['siteTechnician'], 'enums.roles')
                }}
              </div>
              <v-spacer />
              <RingChart
                :id="`${data.user.initials}${index}-chart`"
                :inner-radius="23"
                :outer-radius="25"
                :percentile="percentile(data)"
                :no-background-color="true"
                class="mt-2"
              >
                {{ data.user.initials }}
              </RingChart>
              <v-btn
                v-if="canAccessTBMClient"
                icon
                style="position: absolute; right: 16px; bottom: 16px"
                @click.stop.prevent="
                  onClientDashboardClick(data.project._id, data.user._id)
                "
              >
                <v-icon color="primary">$fas fa-chalkboard-user</v-icon>
              </v-btn>
              <v-btn
                v-if="canAccessTBMClient"
                icon
                style="position: absolute; right: 64px; bottom: 16px"
                @click.stop="
                  onClientDashboardCopy(data.project._id, data.user._id)
                "
              >
                <v-icon color="primary">$fas fa-copy</v-icon>
              </v-btn>
            </div>
          </material-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import RingChart from './Charts/Ring.vue'
import TitleBar from '../core/TitleBar.vue'
import IriMap from './Charts/Maps/IriMap.vue'
import TechnicianMarkers from './TechnicianMarkers.vue'
import DatePicker from '../core/DatePicker.vue'
import MapSwitch from './MapSwitch.vue'

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { toSelectItems } from '@/utils/i18n'
import { roles } from '@/utils/enum'
import { defaultMapBindings, geoJsonToMarker } from '@/utils/map'
import { latLngBounds } from 'leaflet'
import { clamp } from 'lodash'

export default {
  name: 'MainDashboard',
  components: {
    RingChart,
    TitleBar,
    IriMap,
    TechnicianMarkers,
    DatePicker,
    MapSwitch,
  },

  data() {
    return {
      mapView: parseInt(localStorage.getItem('mapView')),
      mapBindings: defaultMapBindings(),
    }
  },

  computed: {
    ...mapState('userManagement', ['users']),
    ...mapState(['selectedDate', 'projects', 'dataList']),
    ...mapGetters(['userHasRole']),
    canAccessTBMClient() {
      return this.userHasRole(roles.PROJECT_ADMINISTRATOR)
    },
    dataForSelectedDate() {
      return this.dataList
        .filter((d) => d.date === this.selectedDate && d.project && d.user)
        .map((d) => ({
          ...d,
          project: this.projects.find((project) => project._id === d.project),
        }))
    },

    markersForSelectedDate() {
      return this.dataForSelectedDate
        .map((d, i) => ({
          ...d,
          id: i,
          latLng: this.getProjectLocation(d.project),
          score: clamp(this.percentile(d), -1, 100),
        }))
        .filter((d) => d.latLng)
    },
  },

  watch: {
    markersForSelectedDate() {
      if (this.markersForSelectedDate.length === 0) {
        return
      }

      const bounds = latLngBounds()
      const markerCoordinates = this.markersForSelectedDate.map((m) => m.latLng)

      for (const coord of markerCoordinates) {
        bounds.extend(coord)
      }

      this.mapBindings.bounds = bounds.pad(0.1)
    },
  },
  async created() {
    this.$wait.start('isUpdatingDashboard')
    await this.updateProjectList()
    this.$wait.end('isUpdatingDashboard')
  },
  methods: {
    ...mapActions([
      'getProject',
      'getClientToken',
      'updateProjectList',
      'updatePMDashboard',
    ]),
    ...mapMutations(['showSuccess']),
    reroute(project, user) {
      this.getProject(project)
      this.$router.push({
        path: '/dashboard/site-control-technician',
        query: {
          project,
          user,
          showBack: true,
        },
      })
    },
    async onClientDashboardClick(project, user) {
      const clientToken = await this.getClientToken({
        project,
        date: this.selectedDate,
        user,
      })

      let routeData = this.$router.resolve({
        path: '/client',
        query: { uuid: clientToken.uuid, token: clientToken.token },
      })
      window.open(routeData.href, '_blank')
    },
    async onClientDashboardCopy(project, user) {
      const clientToken = await this.getClientToken({
        project,
        date: this.selectedDate,
        user,
      })

      const routeData = this.$router.resolve({
        path: '/client',
        query: { uuid: clientToken.uuid, token: clientToken.token },
      })
      const link = new URL(routeData.href, window.location.origin).href
      navigator.clipboard.writeText(link)
      this.showSuccess(this.$t('views.project-manager.copySuccess'))
    },
    translateRoles(roles, rolesEnum) {
      return toSelectItems(roles, rolesEnum).map((role) => role.text)[0]
    },

    percentile(data) {
      if (!data || data.weight === 0) {
        return -1
      }

      return Math.round((data.score / data.weight) * 100)
    },

    fullProjectTitle(project) {
      return project.client
        ? `${project.title} - ${project.client}`
        : project.title
    },

    async onMonthYearChange(monthYearString) {
      await this.updatePMDashboard(monthYearString)
    },

    onMapSwitchToggle() {
      if (this.mapView === 1) {
        this.mapView = 0
      } else {
        this.mapView = 1
      }
    },

    getProjectLocation(project) {
      if (project.startMarker) {
        return geoJsonToMarker(project.startMarker)
      } else if (project.backupLocation) {
        return geoJsonToMarker(project.backupLocation)
      } else {
        return null
      }
    },
  },
}
</script>

<style scoped>
.map {
  grid-row: 1 / span 12;
  grid-column: 1 / span 12;
}

.callout {
  border: 1px solid #fff;
  border-left-color: grey;
  border-left-width: 4px;
  border-radius: 3px;
}

.callout .success {
  border-left-color: #00a97a;
}

.callout .warning {
  border-left-color: #f8bd22;
}

.callout .danger {
  border-left-color: #e61b1e;
}
</style>

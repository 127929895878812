<template>
  <div>
    <core-spinner v-if="$wait.waiting('isFetching*')" />
    <div v-else>
      <title-bar>
        <ProjectSelector
          class="col-5"
          @project-change="mixin_onProjectChange"
        />
      </title-bar>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <material-card
              outlined
              class="fill-height"
            >
              <v-row>
                <v-col cols="12">
                  <v-card-title>
                    <div class="callout">
                      <div class="subtitle-1 ml-2">
                        {{ $t('views.reports.list-title') }}
                        <span>
                          <v-btn
                            color="primary"
                            fab
                            x-small
                            @click="
                              $router.push({
                                path: `/dashboard/reports/create`,
                              })
                            "
                          >
                            <v-icon>$fas fa-plus</v-icon>
                          </v-btn>
                        </span>
                      </div>
                    </div>
                    <v-spacer />

                    <v-text-field
                      v-model="search"
                      :label="$t('views.projects.search')"
                      append-icon="$fas fa-search"
                      single-line
                      hide-details
                    />
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="reportTableData"
                    :item-class="itemClass"
                    :items-per-page="10"
                    :footer-props="footerProps"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :search="search"
                    multi-sort
                    class="row-pointer"
                    @click:row="viewReport"
                  >
                    <template #item.risk="{ item }">
                      <trend-chip :risk="getRisk(item.risk)" />
                    </template>
                    <template #item.createdAt="{ item }">
                      {{ item.createdAt }}
                    </template>
                    <template #item.lastUpdatedBy="{ item }">
                      <trend-chip :date="new Date(item.updatedAt)">
                        {{ item.lastUpdatedBy }}
                      </trend-chip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </material-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/core/TitleBar'
import TrendChip from '@/components/DashViews/Charts/TrendChip.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import * as enums from '@/utils/enum'
import { formatDate } from '@/utils/date'
import projectsListMixin from '@/mixins/projectsListMixin'
import ProjectSelector from '../../core/ProjectSelector.vue'

export default {
  name: 'ReportListDashboard',
  components: {
    TitleBar,
    TrendChip,
    ProjectSelector,
  },

  mixins: [projectsListMixin],

  data() {
    return {
      sortBy: ['read', 'risk'],
      sortDesc: [false, false],
      footerProps: {
        showFirstLastPage: true,
        firstIcon: '$fas fa-arrow-left',
        lastIcon: '$fas fa-arrow-right',
        prevIcon: '$fas fa-minus',
        nextIcon: '$fas fa-plus',
      },
      search: '',
    }
  },

  computed: {
    ...mapState({ reports: 'reports/items' }),
    ...mapGetters(['projectById', 'project', 'userId']),
    ...mapGetters({ getReports: 'reports/getAll' }),

    headers() {
      return [
        { text: this.$t('enums.headers.reportType'), value: 'type' },
        { text: this.$t('enums.headers.createdAt'), value: 'createdAt' },
        {
          text: this.$t('enums.headers.lastUpdatedBy'),
          value: 'lastUpdatedBy',
        },
        { text: this.$t('enums.headers.reportSource'), value: 'source' },
        { text: this.$t('enums.headers.reportAuthor'), value: 'author' },
        { text: this.$t('enums.headers.read'), value: 'read' },
        { text: this.$t('enums.headers.impact'), value: 'impact' },
        { text: this.$t('enums.headers.risk'), value: 'risk' },
      ]
    },

    reportTableData() {
      if (!this.getReports || !this.project) {
        return []
      }

      const reportsFilteredByCurrentProject = this.getReports.filter(
        (report) => report.project === this.project._id,
      )

      return reportsFilteredByCurrentProject.map((report) => ({
        ...report,
        user: report.user.name,
        type: this.$t(`enums.reportTypes.${report.type}`),
        source: this.$t(`enums.reportSources.${report.source}`),
        createdAt: this.formatDate(report.createdAt, '-'),
        read: report.readBy?.includes(this.userId)
          ? this.$t('enums.headers.readYes')
          : this.$t('enums.headers.readNo'),
        impact: this.$t(`enums.impactLevels.${report.impact}`),
      }))
    },
  },

  async created() {
    this.$wait.start('isFetchingReports')
    await this.fetchReports()
    this.$wait.end('isFetchingReports')
  },

  methods: {
    ...mapActions('reports', ['fetchReports', 'markAsRead']),
    formatDate,

    viewReport(report) {
      // Mark report as read by current user
      const hasUserReadReport = report.readBy?.includes(this.userId)
      if (!hasUserReadReport) {
        this.markAsRead({
          _id: report._id,
        })
      }

      this.$router.push({ path: `/dashboard/reports/${report._id}` })
    },

    getRisk(risk) {
      // Make sure risk is a number between 5 & 100
      if (typeof risk !== 'number' || risk < 5 || risk > 100) {
        return enums.risk.NA
      } else {
        if (risk >= 60) return enums.risk.LR
        else if (risk >= 40) return enums.risk.AR
        else if (risk >= 5) return enums.risk.HR
      }
    },

    itemClass(item) {
      return item.read === this.$t('enums.headers.readYes') ? 'reportRead' : ''
    },
  },
}
</script>

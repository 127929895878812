import { mapActions, mapState } from 'vuex'
import { iriRunToFeatureCollection } from '@/utils/datapoint'
import { datapoints } from '@/utils/enum'
import * as MapUtils from '@/utils/map'
import { formatDate, formatDateAsIs } from '@/utils/date'
import { geoJSON } from 'leaflet'
import { flatten, groupBy, first, meanBy } from 'lodash'

export default {
  data() {
    return {
      qualityControlTypes: [
        datapoints.SITE_ASPHALT_THERMOGRAPHY_LOT,
        datapoints.SITE_ASPHALT_SPREAD_RATE,
        datapoints.SITE_ASPHALT_TEMPERATURE,
        datapoints.SITE_ASPHALT_COMPACTION_MTQ,
        datapoints.SITE_ASPHALT_COMPACTION_CONTRACTOR,
        datapoints.SITE_AGGREGATE_SAMPLE_TEST,
        datapoints.SITE_AGGREGATE_TEST,
      ],
      roadComfortMapBindings: MapUtils.defaultMapBindings(),
    }
  },

  computed: {
    ...mapState(['datapoints', 'idproject', 'selectedDates']),
    ...mapState('iri', { iriRuns: 'segments' }),
    ...mapState('images', {
      images: 'items',
    }),
    ...mapState(['userId']),

    mixin_qualityControlCenter() {
      return MapUtils.datapointsCenter(
        this?.mixin_qualityControlDatapoints || [],
      )
    },

    mixin_qualityControlDatapoints() {
      if (this.datapoints) {
        return this.datapoints.filter(
          (point) =>
            point.location && this.qualityControlTypes.includes(point.type),
        )
      }
      return []
    },

    mixin_qualityControlDatapointsForSelectedDates() {
      if (!this.selectedDates?.size) {
        return []
      }
      return this.mixin_qualityControlDatapoints.filter((point) => {
        return this.selectedDates.has(point.date)
      })
    },

    mixin_iriRunForSelectedDates() {
      if (this.superIntendent) {
        return this.iriRuns.filter((run) => {
          return this.selectedDates.has(formatDate(run.date))
        })
      } else {
        return this.iriRuns || []
      }
    },

    mixin_imagesForSelectedDates() {
      if (this.superIntendent) {
        return this.images.filter((image) => {
          return this.selectedDates.has(formatDate(image.datapoint.date))
        })
      } else {
        return this.images || []
      }
    },

    mixin_iriRunGeoForSelectedDates() {
      return iriRunToFeatureCollection(this.mixin_iriRunForSelectedDates)
    },

    mixin_iriBounds() {
      return geoJSON(this.mixin_iriRunGeoForSelectedDates).getBounds()
    },

    mixin_iriLineChartData() {
      const mappedValues = flatten(
        this.mixin_iriRunForSelectedDates.map((run) => {
          return run.iriSections.map((iri) => ({
            date: formatDateAsIs(new Date(run.date)),
            station: iri.endStation,
            stationInt: iri.endStationInt,
            IRI: iri.iri,
          }))
        }),
      ).sort((a, b) => (a.stationInt > b.stationInt ? -1 : 1))

      // group datapoints with the same location together
      const groupedData = groupBy(mappedValues, (_d) => _d.station)
      const filteredData = []
      for (const datapoints of Object.values(groupedData)) {
        filteredData.push({
          ...first(datapoints),
          IRI: meanBy(datapoints, (_d) => _d.IRI),
        })
      }
      return filteredData
    },
  },

  watch: {
    async idproject() {
      this.$wait.start('isSiteForemanUpdating')
      await this.updateDashboard()
      this.$wait.end('isSiteForemanUpdating')
    },
    mixin_iriBounds: function (newBounds) {
      if (newBounds && newBounds.isValid()) {
        this.$set(this.roadComfortMapBindings, 'bounds', newBounds)
      }
    },
  },

  async created() {
    this.$wait.start('isSiteForemanUpdating')
    await this.updateDashboard()
    this.$wait.end('isSiteForemanUpdating')
  },

  methods: {
    ...mapActions(['updateSiteForemanDashboard']),

    async updateDashboard() {
      if (this.idproject) {
        await this.updateSiteForemanDashboard({
          params: {
            project: this.idproject,
            details: this.details,
            user: this.userId,
          },
          superIntendent: this.superIntendent,
        })
      }
    },
  },
}

<template>
  <div class="fill-height">
    <core-progress :in-progress="$wait.is('loading')" />
    <div class="grid-container">
      <div class="map">
        <ImageMap
          :project-id="idproject"
          :images="imageMarkers"
          :style="{ borderRadius: '10px' }"
        />
      </div>

      <div class="form">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <ImageInput
                  :rules="rules.images"
                  @onChange="generateMarkers"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="selectedLayer"
                  :items="layers"
                  :label="$t('enums.headers.layer')"
                  :rules="[rules.required]"
                  class="select-no-border pa-0"
                />
              </v-col>
              <v-col cols="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="picker"
                      v-bind="attrs"
                      label="Date"
                      prepend-icon=""
                      readonly
                      :rules="[rules.required]"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="picker"
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-btn
          color="success"
          class="mt-4"
          block
          :disabled="saveDisabled"
          @click="saveImages"
        >
          {{ $t('views.user-management.update-form.save') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { roadLayers } from '@/utils/enum'
import ImageMap from './ImageMap.vue'
import ImageInput from '../Forms/ImageInput.vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { rules } from '@/utils/rules'
import { toSelectItems } from '@/utils/i18n'
import imageUploadMixin from '@/mixins/imageUploadMixin'

export default {
  components: {
    ImageMap,
    ImageInput,
  },

  mixins: [imageUploadMixin],

  data() {
    return {
      selectedLayer: '',
      menu: false,
      valid: false,
      rules,
      picker: null,
      imageMarkers: [],
      uploadedFiles: [],
      imagesDate: null,
    }
  },

  computed: {
    ...mapState(['idproject']),

    layers() {
      return toSelectItems(roadLayers, 'enums.layers')
    },
  },

  methods: {
    ...mapActions('images', ['uploadImages']),
    ...mapMutations(['showWarning', 'showError']),

    async generateMarkers(files) {
      this.imageMarkers = []

      this.uploadedFiles = await this.filterImagesWithLocation(files)

      for (const file of this.uploadedFiles) {
        const { name } = file.file
        const { latitude, longitude } = file.metadata

        const imageMarker = {
          name,
          date: this.imagesDate,
          latitude,
          longitude,
          thumbnail: URL.createObjectURL(file.file),
        }

        this.imageMarkers.push(imageMarker)
      }

      this.picker = this.imagesDate
    },

    createMultipartForm() {
      const imageLocations = {}
      const formData = new FormData()

      for (const image of this.uploadedFiles) {
        imageLocations[image.file.name] = [
          image.metadata.longitude,
          image.metadata.latitude,
        ]
      }

      formData.set(
        'data',
        JSON.stringify({
          date: this.picker,
          layer: this.selectedLayer,
          projectId: this.idproject,
          imageLocations,
        }),
      )

      for (const image of this.uploadedFiles) {
        formData.append('images', image.file)
      }

      return formData
    },
  },
}
</script>
<style>
.map {
  grid-row: 1 / span 12;
  grid-column: 1 / span 8;
}

.form {
  grid-row: 1 / span 12;
  grid-column: 9 / span 4;
}
</style>

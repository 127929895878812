<template>
  <material-card
    outlined
    class="fill-height"
  >
    <div>
      <div class="subtitle-1 ml-2">
        {{ $t('views.datapointGroup.title') }}
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :sort-by="['date', 'product', 'layer']"
      :sort-desc="[true, false, false]"
      class="v-table-pointer"
      multi-sort
      fixed-header
      style="overflow: auto"
      height="100%"
      hide-default-footer
      show-expand
      single-expand
      :items-per-page="-1"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      @item-expanded="(event) => $emit('item-expanded', event)"
    >
      <template #item.maxThermo="{ item: point }">
        <TrendChip :risk="point.thermo" />
      </template>
      <template #expanded-item="{ item }">
        <td
          :colspan="12"
          class="py-4"
        >
          <v-data-table
            dense
            :headers="nestedHeaders"
            :items="item.datapoints"
            hide-default-footer
            :items-per-page="-1"
            @click:row="navigateToDataPoint"
          >
            <template #item.thermo="{ item: point }">
              <TrendChip :risk="point.thermo" />
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import _ from 'lodash'
import NavigateMapToDatapoint from '@/mixins/NavigateMapToDatapoint'
import TrendChip from '../TrendChip.vue'
import * as enums from '@/utils/enum'

export default {
  name: 'DatapointGroup',
  components: {
    TrendChip,
  },
  mixins: [NavigateMapToDatapoint],
  props: {
    datapoints: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      headers: [
        { text: this.$t('enums.headers.date'), value: 'date' },
        {
          text: `${this.$t('enums.headers.mixType')} / ${this.$t(
            'enums.headers.aggregateGrade',
          )}`,
          value: 'product',
        },
        { text: this.$t('enums.headers.layer'), value: 'layer' },
        {
          text: this.$t('enums.headers.avgCompaction') + ' (%)',
          value: 'avgCompaction',
        },
        {
          text: this.$t('enums.headers.avgSpreadRateThickness'),
          value: 'avgThickness',
        },
        {
          text: this.$t('enums.headers.avgTemperatureTruck'),
          value: 'avgTemperature',
        },
        {
          text: this.$t('enums.headers.maxThermo'),
          value: 'maxThermo',
        },
      ],
      nestedHeaders: [
        { text: this.$t('enums.headers.date'), value: 'date' },
        {
          text: `${this.$t('enums.headers.mixType')} / ${this.$t(
            'enums.headers.aggregateGrade',
          )}`,
          value: 'product',
        },
        { text: this.$t('enums.headers.layer'), value: 'layer' },
        {
          text: this.$t('enums.headers.compaction') + ' (%)',
          value: 'compaction',
        },
        {
          text: this.$t('enums.headers.spreadRateThickness'),
          value: 'thickness',
        },
        {
          text: this.$t('enums.headers.temperatureTruck'),
          value: 'temperature',
        },
        { text: this.$t('enums.headers.thermography'), value: 'thermo' },
      ],
      map: null,
      markers: [],
      asphaltTypes: [
        enums.datapoints.SITE_ASPHALT_THERMOGRAPHY_LOT,
        enums.datapoints.SITE_ASPHALT_SPREAD_RATE,
        enums.datapoints.SITE_ASPHALT_TEMPERATURE,
        enums.datapoints.SITE_ASPHALT_COMPACTION_MTQ,
        enums.datapoints.SITE_ASPHALT_COMPACTION_CONTRACTOR,
      ],
      aggregateTypes: [
        enums.datapoints.SITE_AGGREGATE_SAMPLE_TEST,
        enums.datapoints.SITE_AGGREGATE_TEST,
      ],
    }
  },

  computed: {
    items() {
      const formattedDatapoints = this.datapoints.map((datapoint) => {
        const product = datapoint.mixType || datapoint.aggregateGrade
        const layer = datapoint.layer
          ? this.$t(`enums.layers.${datapoint.layer}`)
          : ''
        const group = [datapoint.date, product, layer].join(',')

        return {
          ...datapoint,
          product,
          layer,
          group,
          compaction:
            Number.parseFloat(datapoint.compaction?.$numberDecimal) || '',
          thickness: Number.parseFloat(datapoint.requiredThickness) || '',
          temperature:
            Number.parseFloat(datapoint.temperatureTruck?.$numberDecimal) || '',
          thermo: datapoint.kpis?.RThermoFrequence || 0,
        }
      })

      const groupedDatapoints = _.groupBy(formattedDatapoints, 'group')
      const groups = []

      for (const group in groupedDatapoints) {
        const datapoints = groupedDatapoints[group]

        const date = datapoints[0].date
        const product = datapoints[0].product
        const layer = datapoints[0].layer
        const maxThermo = Math.max(...datapoints.map((d) => d.thermo))

        // Calculate averages for relevant group properties
        const properties = ['compaction', 'thickness', 'temperature']
        const averages = []

        for (let i = 0; i < properties.length; i++) {
          const property = properties[i]
          const values = datapoints
            .filter((point) => Number.isFinite(point[property]))
            .map((point) => point[property])

          if (values.length) {
            const sum = values.reduce((a, b) => a + b, 0)
            const avg = _.round(sum / values.length, 2)
            averages.push(avg)
          } else {
            averages.push(null)
          }
        }

        const [avgCompaction, avgThickness, avgTemperature] = averages

        groups.push({
          date,
          product,
          layer,
          avgCompaction,
          avgThickness,
          avgTemperature,
          maxThermo,
          datapoints,
          id: datapoints[0]._id,
        })
      }

      return groups
    },
  },
  methods: {
    getRiskColor(risk) {
      switch (risk) {
        case 1:
          return '#05A57D'
        case 2:
          return '#FFBD21'
        case 3:
          return '#FF6666'
        default:
          return '#000'
      }
    },
  },
}
</script>

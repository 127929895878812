import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import icons from './icon'
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fr },
    current: localStorage.getItem('language') || navigator.language.slice(0, 2),
  },
  icons: icons,
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#0090FE',
        success: '#05A57D',
        warning: '#FFBD21',
        error: '#FF6666',
      },
      dark: {
        primary: '#0090FE',
        success: '#05A57D',
        warning: '#FFBD21',
        error: '#FF6666',
      },
    },
  },
  breakpoint: {
    mobileBreakpoint: 1440,
  },
})

<template>
  <v-dialog
    v-if="datapointExists"
    v-model="show"
    width="80%"
  >
    <v-tabs
      v-model="tab"
      fixed-tabs
    >
      <v-tab href="#tab-1">{{ $t('views.datapointPopup.detailsTab') }}</v-tab>
      <v-tab href="#tab-2">JSON</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-img
            v-if="hasImage"
            :class="isLoading ? 'blurry' : ''"
            :aspect-ratio="16 / 9"
            :height="height"
            :src="imageSrc"
            class="grey darken-4"
            contain
          >
            <v-btn
              v-if="!isLoaded && !isLoading"
              outlined
              class="right"
              @click="open(dataPoint)"
            >
              <v-icon>$fas fa-expand-alt</v-icon>
            </v-btn>
          </v-img>
          <v-progress-linear
            v-if="isLoading"
            color="blue-grey"
            indeterminate
            height="25"
          >
            <strong> {{ $t('sviews.global.please-wait') }}</strong>
          </v-progress-linear>
          <v-card-text>
            <v-slider
              v-if="isLoaded"
              v-model="height"
              :label="$t('views.image-upload.size')"
              :thumb-color="'#e2632c'"
              class="align-self-stretch"
              min="350"
              max="1350"
              step="1"
              thumb-label="always"
            />
            <v-simple-table>
              <thead>
                <tr>
                  <th>{{ $t(`enums.headers.type`) }}</th>
                  <th>{{ $t(`enums.headers.layer`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ $t(`enums.type.${dataPoint.type}`) }}
                  </td>
                  <td>
                    {{ $t(`enums.layers.${dataPoint.layer}`) }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-simple-table>
              <thead>
                <tr>
                  <th
                    v-for="(_, key) in specificData"
                    :key="'key-' + key"
                  >
                    {{ $t(`enums.headers.${key}`) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <template v-for="(value, key) in specificData">
                    <td
                      v-if="
                        key === 'compaction' || key === 'requiredCompaction'
                      "
                      :key="'value-' + key"
                    >
                      {{ $t('core.unit.percent', { n: value.$numberDecimal }) }}
                    </td>
                    <td
                      v-else-if="typeof value === 'object'"
                      :key="'value-' + key"
                    >
                      <span
                        v-for="(v, k) in value"
                        :key="k"
                      >
                        {{ v }}
                      </span>
                    </td>
                    <td
                      v-else-if="key === 'side'"
                      :key="'value-' + key"
                    >
                      {{ $t('enums.side.' + value) }}
                    </td>
                    <td
                      v-else
                      :key="'value-' + key"
                    >
                      {{ value }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
            <v-simple-table>
              <thead>
                <tr>
                  <th>{{ $t(`enums.headers.data`) }}</th>
                  <th>{{ $t(`enums.headers.type`) }}</th>
                  <th>{{ $t(`enums.headers.coordinates`) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ dataPoint.data || '-' }}
                  </td>
                  <td v-if="dataPoint.location">
                    {{ dataPoint.location.type || '-' }}
                  </td>
                  <td v-if="dataPoint.location">
                    {{ dataPoint.location.coordinates[1] || '-' }},
                    {{ dataPoint.location.coordinates[0] || '-' }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-simple-table>
              <thead>
                <tr>
                  <th
                    v-for="(value, key) in dateKeys"
                    :key="key"
                  >
                    {{ $t(`enums.headers.${value}`) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    v-for="(value, key) in datesData"
                    :key="key"
                  >
                    {{ value }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              @click="show = false"
              >{{ $t('views.datapointPopup.closeButton') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-card flat>
          <v-card-text>
            <json-viewer :value="dataPoint" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              @click="show = false"
            >
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-dialog>
</template>
<script>
import JsonViewer from 'vue-json-viewer'
import axios from 'axios'
import _ from 'lodash'
import purgeObject from '../../../utils/purgeObject'

export default {
  name: 'DataPointPopUp',
  components: { JsonViewer },
  props: {
    popUPtitle: {
      type: String,
      default: '',
    },
    dataPoint: {
      type: Object,
      required: true,
    },
    dataFilterOut: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      imageSrc: '',
      isLoading: false,
      isLoaded: false,
      model: false,
      height: 350,
      dateKeys: ['date'],
      toRemoveKeys: [
        '_id',
        'user',
        'externalIdentifier',
        'location',
        'source',
        'createdAt',
        'date',
        'updatedAt',
        'type',
        'typeUI',
        'data',
        'layer',
        'mixDesignCodes',
        'aggregateSource',
      ],
    }
  },
  computed: {
    datapointExists() {
      return !_.isEmpty(this.dataPoint)
    },
    datesData() {
      let result = {}
      for (const key of Object.keys(this.dataPoint).filter((x) =>
        this.dateKeys.includes(x),
      )) {
        if (
          this.dataFilterOut.indexOf(key) === -1 &&
          this.dataPoint[key] !== null
        ) {
          result[key] = this.$helpers.date.formatDateAsIs(this.dataPoint[key])
        }
      }
      return result
    },
    specificData() {
      let result = {}

      const filteredDatapointKeys = Object.keys(this.dataPoint).filter(
        (key) => !this.toRemoveKeys.includes(key),
      )
      for (const key of filteredDatapointKeys) {
        if (
          this.dataFilterOut.indexOf(key) === -1 &&
          this.dataPoint[key] !== null
        ) {
          result[key] = this.dataPoint[key]
        }
      }
      result = purgeObject(result)
      return result
    },
    source: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    hasImage() {
      return this.dataPoint.type === 'images'
    },
  },
  watch: {
    show() {
      this.imageSrc = ''
      this.height = 350
      this.isLoaded = false

      if (this.dataPoint.type === 'images' && this.show) {
        this.$http
          .request({
            url: `/datapoints/${this.dataPoint._id}/thumbnail`,
            responseType: 'blob',
          })
          .then((blob) => {
            this.imageSrc = URL.createObjectURL(blob.data)
          })
          .catch((error) => console.error(error))
      }
    },
  },
  methods: {
    async open(value) {
      this.isLoading = true

      await axios
        .get(`/datapoints/${value._id}/image`, {
          responseType: 'blob',
        })
        .then((result) => {
          this.height = 700
          this.imageSrc = URL.createObjectURL(result.data)
          this.isLoading = false
          this.isLoaded = true
        })
    },
  },
}
</script>
<style scoped>
img {
  display: block;
  margin: auto;
}

.v-card > .v-card__progress + :not(.v-btn):not(.v-chip),
.v-card > :first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
</style>

<template>
  <v-select
    v-model="projectFilters"
    :label="$t('views.projectFilterSelector.buttonText')"
    hide-details
    class="custom"
    multiple
    :items="projectTypes.concat(projectStatuses)"
    :menu-props="{ maxHeight: 'auto' }"
    prepend-inner-icon="$fas fa-filter"
  >
    <template #selection="{ item, index }">
      <v-chip
        v-if="index < 2"
        small
      >
        <span>{{ item.text }}</span>
      </v-chip>
      <span
        v-if="index === 2"
        class="text-caption"
      >
        (+{{ projectFilters.length - 2 }}
        {{
          $tc('views.projectFilterSelector.others', projectFilters.length - 2)
        }})
      </span>
    </template>
  </v-select>
</template>

<script>
import { projectTypes, projectStatuses } from '../../../utils/enum'

export default {
  inject: ['dashboard'],

  computed: {
    projectFilters: {
      get() {
        return this.$store.state.projectFilters
      },

      set(filters) {
        this.$store.state.projectFilters = filters
      },
    },

    projectTypes() {
      let types = []
      switch (this.dashboard) {
        case 'contractor':
          types = [projectTypes.construction, projectTypes.production]
          break
        case 'supervisor':
          types = [
            projectTypes.construction,
            projectTypes.research,
            projectTypes.maintenance,
          ]
          break
      }

      return types.map((type) => ({
        text: this.$t(`enums.projectTypes.${type}`),
        value: type,
      }))
    },

    projectStatuses() {
      return Object.values(projectStatuses).map((status) => ({
        text: this.$t(`enums.projectStatuses.${status}`),
        value: status,
      }))
    },
  },

  mounted() {
    if (!this.projectFilters.length) {
      this.projectFilters = [...this.projectTypes, ...this.projectStatuses].map(
        (filter) => filter.value,
      )
    }
  },
}
</script>

// https://vuex.vuejs.org/en/getters.html
import { defaultGeolocation } from '@/utils/constants'
import { roles } from '@/utils/enum'
import { geoJsonToMarker } from '@/utils/map'
import dayjs from 'dayjs'
import _ from 'lodash'

export default {
  isLoggedIn: (state) => state.isLoggedIn,
  idproject: (state) => state.idproject,
  selectedDate: (state) => state.selectedDate,
  kpiScore: (state) => (name, version, date, user) => {
    let kpi

    if (version === 'daily') {
      kpi = state.kpi.find(
        (k) =>
          k.type === name &&
          k.version === 'daily' &&
          k.date === date &&
          k.user === user,
      )
    } else {
      kpi = state.kpi.find((k) => k.type === name && k.version === 'total')
    }

    return kpi
  },
  dateFiltered: (state, getters) => {
    return (getters.selectedDate ? state.datapoints || [] : []).filter(
      (d) => d.date === getters.selectedDate,
    )
  },
  lastSiteProductionKpis: (state, getters) => {
    let datapoints = getters.dateFiltered

    if (!datapoints.length && state.datapoints.length) {
      datapoints = state.datapoints
    }

    if (!datapoints.length) return []

    const filteredAggr = _.uniqBy(
      datapoints.filter((d) => d.aggregateSource && d.aggregateGrade),
      (d) => d.aggregateGrade + d.aggregateSource,
    ).map((d) => ({
      specificationGrade: d.aggregateGrade,
      supplierNumber: d.aggregateSource,
    }))
    const filteredAsph = _.reduce(
      datapoints.filter((d) => d.mixDesignCodes?.length),
      (acc, d) => {
        d.mixDesignCodes.forEach((code) => {
          if (!acc.includes(code)) acc.push(code)
        })
        return acc
      },
      [],
    )

    return _.uniqBy(
      state.kpiProduction.filter((k) => {
        return (
          dayjs(k.date).isSameOrBefore(state.selectedDate, 'day') &&
          (filteredAggr.some(
            (agg) =>
              agg.supplierNumber === k.metadata.supplierNumber &&
              agg.specificationGrade === k.metadata.specificationGrade,
          ) ||
            filteredAsph.includes(k.metadata.mixDesignCode))
        )
      }),
      (k) => {
        return (
          k.metadata.mixDesignCode +
          k.metadata.specificationGrade +
          k.metadata.supplierNumber +
          k.name
        )
      },
    )
  },
  lastProdKpiByNames: (state, getters) => (names) => {
    return getters.lastSiteProductionKpis.filter((k) => names.includes(k.name))
  },
  userId: (state) => {
    return state.user?.id
  },
  notificationCount: (getters) => {
    return getters.notifications.filter((n) => n.read !== true).length
  },
  notifications: (state) => {
    return state.notifications || []
  },
  project: (state) => state.project,
  projects: (state) => state.projects,
  projectLocation: (state) => {
    const currentLocation = state.app?.localisation
    const currentProject = state.projects?.find(
      (p) => p._id === state.idproject,
    )

    if (currentProject?.startMarker) {
      return geoJsonToMarker(currentProject.startMarker)
    } else if (currentProject?.backupLocation) {
      return geoJsonToMarker(currentProject.backupLocation)
    } else if (currentLocation) {
      return currentLocation
    }
    return defaultGeolocation
  },
  projectById: (state) => (projectId) =>
    state.projects.find((_p) => _p._id === projectId),
  userName: (state) => state.user?.name || '',
  appLanguage: (state) => state.language,
  user: (state) => state.user,
  userHasRole: (state) => (role) => (state.user.roles || []).includes(role),
  hasAccessToKpis: (state, getters) => getters.userHasRole('superAdmin'),
  landingPage: (state, getters) => {
    if (getters.userHasRole(roles.TECHNICAL_MANAGER)) {
      return '/dashboard/technical-manager'
    }
    if (getters.userHasRole(roles.GENERAL_MANAGER_CONTRACTOR)) {
      return '/dashboard/general-manager-contractor'
    }
    if (getters.userHasRole(roles.PROJECT_MANAGER)) {
      return '/dashboard/project-manager'
    }
    if (getters.userHasRole(roles.SITE_FOREMAN)) {
      return '/dashboard/site-foreman'
    }
    if (getters.userHasRole(roles.SITE_SUPERINTENDENT)) {
      return '/dashboard/site-superintendent'
    }
    if (getters.userHasRole(roles.CONTRACTOR)) {
      return '/dashboard/contractor'
    }
    if (getters.userHasRole(roles.PRODUCTION_FOREMAN)) {
      return '/dashboard/production-foreman'
    }
    if (getters.userHasRole(roles.ASPHALT_FOREMAN)) {
      return '/dashboard/asphalt-foreman'
    }
    if (getters.userHasRole(roles.AGGREGATE_FOREMAN)) {
      return '/dashboard/aggregate-foreman'
    }
    if (getters.userHasRole(roles.SITE_TECHNICIAN)) {
      return '/dashboard/site-control-technician'
    }
    if (getters.userHasRole(roles.PRODUCTION_TECHNICIAN_ASPHALT)) {
      return '/dashboard/production-technician-asphalt'
    }
    if (getters.userHasRole(roles.PRODUCTION_TECHNICIAN_AGGREGATE)) {
      return '/dashboard/production-technician-aggregate'
    }
    if (getters.userHasRole(roles.ROLLING_COMFORT)) {
      return '/dashboard/calibration-management'
    }
    if (getters.userHasRole(roles.SYSTEM_ADMINISTRATOR)) {
      return '/administration/users'
    }
    if (getters.userHasRole(roles.PROJECT_ADMINISTRATOR)) {
      return '/administration/projects'
    }
    if (getters.userHasRole(roles.SUPER_ADMIN)) {
      return '/administration/users'
    }
    if (getters.userHasRole(roles.USER)) {
      return '/preferences'
    }
  },
  isCardExpanded: (state) => state.isCardExpanded,
}

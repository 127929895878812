import { defaultGeolocation } from '@/utils/constants'
import { defaults } from 'lodash'

export const initialAppState = {
  localisationWatcherId: null,
  localisation: defaultGeolocation,
  isUserLocated: false,
  drawer: null,
  color: 'general',
}

export default defaults({}, initialAppState)

<template>
  <Production-Foreman
    foreman-type="aggregate"
    :supplier="supplier"
    :specification="specification"
    :stockpile="stockpile"
  />
</template>
<script>
import ProductionForeman from './ProductionForeman.vue'

export default {
  components: {
    ProductionForeman,
  },
  props: {
    supplier: {
      type: String,
      default: '',
    },
    specification: {
      type: String,
      default: '',
    },
    stockpile: {
      type: String,
      default: '',
    },
  },
}
</script>

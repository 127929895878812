<template>
  <v-container
    class="login-form"
    fill-height
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
        offset-md="4"
      >
        <v-card outlined>
          <v-card-title class="justify-center">
            <v-toolbar-title>
              <img
                src="/img/logo-daway.png"
                height="92px"
              />
            </v-toolbar-title>
          </v-card-title>
          <v-card-text>
            <v-form ref="newPasswordForm">
              <v-text-field
                v-model.trim="newPassword"
                :append-icon="
                  isPasswordVisible ? '$fas fa-eye' : '$fas fa-eye-slash'
                "
                :rules="rules.password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :label="$t('forms.labels.password')"
                @click:append="togglePasswordVisibility"
              />
              <v-text-field
                v-model.trim="newConfirmedPassword"
                :rules="confirmedPasswordRules"
                :type="isPasswordVisible ? 'text' : 'password'"
                :label="$t('forms.labels.confirmPassword')"
              />
            </v-form>
          </v-card-text>
          <v-divider class="mt-5" />
          <v-card-actions>
            <v-btn
              block
              justify-center
              color="primary"
              text
              @click="confirmNewPassword"
            >
              <v-wait for="isUpdatingPassword">
                <core-spinner slot="waiting" />
                {{ $t('views.passwordReset.confirm') }}
              </v-wait>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { rules } from '../utils/rules'

export default {
  props: {
    token: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isPasswordVisible: false,
      newPassword: '',
      newConfirmedPassword: '',
      rules,
    }
  },

  computed: {
    confirmedPasswordRules() {
      return [
        (value) => !!value || this.$t('forms.validation.required'),
        (value) =>
          value.trim() === this.newPassword.trim() ||
          this.$t('forms.validation.confirmedPassword'),
      ]
    },
  },

  methods: {
    ...mapActions(['modifyPassword']),
    ...mapMutations(['showError', 'showSuccess']),

    async confirmNewPassword() {
      this.$wait.start('isUpdatingPassword')

      if (this.$refs.newPasswordForm.validate()) {
        try {
          await this.modifyPassword({
            token: this.token,
            password: this.newPassword,
            path: this.$route.path,
          })
          this.$router.replace('/')
          this.showSuccess(this.$t('views.passwordReset.passwordSuccess'))
        } catch (err) {
          this.showError(this.$t('api-error.genericError'))
        }
      }

      this.$wait.end('isUpdatingPassword')
    },

    togglePasswordVisibility() {
      return (this.isPasswordVisible = !this.isPasswordVisible)
    },
  },
}
</script>

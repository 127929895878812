<template>
  <material-card outlined>
    <div class="callout">
      <div class="subtitle-2 ml-2">
        <b>{{ $t('views.charts.tableKpi.title') }}</b>
      </div>
      <div class="text-caption text--disabled ml-2">
        {{ $t('views.charts.tableKpi.description') }}
      </div>
    </div>

    <div>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :items-per-page="30"
        hide-default-footer
      >
        <template #headerCell="{ header }">
          <span
            class="subheading font-weight-light text-success text--darken-3"
            v-text="header.text"
          />
        </template>
        <template #[`item.risk`]="{ item }">
          {{ getKpiRisk(item) }}
        </template>
        <template #[`item.score`]="{ item }">
          {{ getKpiScore(item) }}
        </template>
        <template #[`item.result`]="{ item }">
          {{ getKpiResult(item) }}
        </template>
        <template #[`item.state`]="{ item }">
          {{ getKpiState(item) }}
        </template>
      </v-data-table>
    </div>
  </material-card>
</template>

<script>
export default {
  name: 'TableList',
  props: {
    items: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t('views.charts.tableKpi.index'), value: 'name' },
        { text: this.$t('views.charts.tableKpi.score'), value: 'score' },
        { text: this.$t('views.charts.tableKpi.result'), value: 'result' },
        { text: this.$t('views.charts.tableKpi.process'), value: 'state' },
        { text: this.$t('views.charts.tableKpi.risk'), value: 'risk' },
      ],
    }
  },
  methods: {
    getKpiRisk(kpi) {
      switch (kpi.risk) {
        case 0:
          return '-'
        case 1:
          return this.$t('views.charts.tableKpi.lowRisk')
        case 2:
          return this.$t('views.charts.tableKpi.averageRisk')
        case 3:
          return this.$t('views.charts.tableKpi.highRisk')
        default:
          return '-'
      }
    },
    getKpiState(kpi) {
      switch (kpi.state) {
        case 0:
          return '-'
        default:
          return kpi.state
      }
    },
    getKpiScore(kpi) {
      if (Number.isFinite(kpi.score)) {
        return kpi.score
      }
      return '-'
    },

    getKpiResult(kpi) {
      if (Number.isFinite(kpi.result)) {
        // Round to 3 decimal places only if necessary
        return Math.round((kpi.result + Number.EPSILON) * 1000) / 1000
      }
      return '-'
    },
  },
}
</script>

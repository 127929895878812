<template>
  <fieldset class="px-3 flex-grow-1">
    <legend>{{ $t(`enums.defects.${defect}`) }}</legend>

    <v-radio-group
      v-model="severity"
      class="mt-0"
      :label="$t('views.inventory.severity') + ':'"
      :rules="severityRule"
      row
      dense
    >
      <v-radio
        v-for="n in 5"
        :key="n"
        :label="n.toString()"
        :value="n"
        :color="severityColors[n - 1]"
      />
    </v-radio-group>

    <v-radio-group
      v-model="roadSide"
      class="mt-0"
      :label="$t('views.inventory.roadSide')"
      :rules="roadSideRule"
      row
      dense
    >
      <v-radio
        :label="$t('enums.side.0')"
        value="left"
      />
      <v-radio
        :label="$t('enums.side.1')"
        value="right"
      />
    </v-radio-group>

    <label>{{ $t('views.inventory.roadSideDistance') }}</label>
    <v-range-slider
      v-model="roadSideDistance"
      :max="maxRoadSideDistance"
      min="0"
      dense
      thumb-label
    />

    <label>{{ $t('views.inventory.vehicleDistance') }}</label>
    <v-range-slider
      v-model="vehicleDistance"
      max="10"
      min="0"
      dense
      thumb-label
    />

    <v-text-field
      v-if="hasQuantity"
      v-model="quantity"
      class="px-0"
      :label="$t('views.inventory.quantity')"
      type="number"
      :rules="[quantityRule]"
    />
  </fieldset>
</template>

<script>
import { mapState } from 'vuex'

import { rules } from '@/utils/rules'
import { defectTypes } from '@/utils/enum'
import { isValidNumber } from '@/utils/math'
import { defaultWidths, defaultLengths } from '@/utils/constants'

export default {
  name: 'DefectsFieldset',

  props: {
    defect: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      severity: null,
      roadSide: null,
      quantity: null,
      roadSideDistance: [0, defaultWidths.road],
      vehicleDistance: [0, defaultLengths.segment],
      maxVehicleDistance: defaultLengths.segment,
      severityColors: ['#05a57d', '#33cc99', '#ffbd21', '#ff9400', '#ff6666'],

      roadSideRule: rules.inventory.roadSide,
      severityRule: rules.inventory.severity,

      defectsWithQuantity: [
        defectTypes.CORNER_BREAKS,
        defectTypes.EDGE_BREAKS,
        defectTypes.POTHOLES,
      ],
    }
  },

  computed: {
    ...mapState('iri', ['segment']),
    ...mapState('images', ['currentImage']),

    maxRoadSideDistance() {
      return (
        this.currentImage?.roadWidth ||
        this.segment?.roadWidth ||
        defaultWidths.road
      )
    },

    hasQuantity() {
      return this.defectsWithQuantity.includes(this.defect)
    },
  },

  watch: {
    maxRoadSideDistance() {
      this.roadSideDistance = [0, this.maxRoadSideDistance]
    },
  },

  methods: {
    collectData() {
      const result = {
        defectId: this.defect,
        data: {
          severity: this.severity,
          roadSide: this.roadSide,
          roadSideDistance: this.roadSideDistance,
          vehicleDistance: this.vehicleDistance,
        },
      }

      if (this.hasQuantity) {
        result.data.quantity = this.quantity
      }

      return result
    },

    setValues(data) {
      this.quantity = data.quantity
      this.severity = data.severity
      this.roadSide = data.roadSide
      this.roadSideDistance = data.roadSideDistance
      this.vehicleDistance = data.vehicleDistance
    },

    quantityRule(value) {
      if (!isValidNumber(value, 1, 100, 0)) {
        return this.$t('forms.validation.inventory.quantity')
      }
      return true
    },
  },
}
</script>

<template>
  <v-autocomplete
    v-model="projectId"
    :items="projects"
    :label="$t('core.toolbar.project-selection.label')"
    :disabled="isProjectDisabled"
    prepend-icon="$fas fa-layer-group"
    :item-text="fullProjectTitle"
    item-value="_id"
    :menu-props="{ maxHeight: 600 }"
    auto-select-first
    hide-details
    class="custom"
    @change="onChange"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    disableProject: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      projectId: null,
    }
  },

  computed: {
    ...mapState(['idproject', 'projects', 'isClientDashboard']),

    isProjectDisabled() {
      return this.isClientDashboard || this.disableProject
    },
  },

  watch: {
    projectId(newId, oldId) {
      if (newId !== oldId && newId !== null) {
        this.changeProject(newId)
      }
    },
  },

  mounted() {
    this.projectId = this.idproject
  },

  methods: {
    ...mapActions(['changeProject']),

    fullProjectTitle(project) {
      return project.client
        ? `${project.title} - ${project.client}`
        : project.title
    },

    onChange(project) {
      this.$emit('project-change', project)
    },
  },
}
</script>

<template>
  <v-form>
    <v-autocomplete
      :items="availableAssets"
      :value="selectedAssets"
      class="custom dense-select"
      item-text="text"
      item-value="name"
      chips
      multiple
      hide-details
      :menu-props="{ maxHeight: 600 }"
      @change="onSelectedAssetsChange"
    >
      <template #selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
        >
          <v-avatar tile>
            <v-img :src="data.item.src" />
          </v-avatar>
          {{ itemName(data.item.name) }}
        </v-chip>
      </template>
      <template #item="data">
        <v-list-item-avatar
          v-if="data.item.src"
          tile
          style="border: none; margin-right: 16px"
        >
          <img :src="data.item.src" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ itemName(data.item.name) }}</v-list-item-title>
          <v-list-item-subtitle>{{ data.item.groupText }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ data.item.text }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-form>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import { assetTypes } from '@/utils/enum'

export default {
  data() {
    return {
      dangerSigns: [],
      indicationSigns: [],
      prescriptionSigns: [],
      roadMarkings: [],
      guardRails: [],
      lampPosts: [],
      signs: new Set([
        assetTypes.DANGER_SIGNS,
        assetTypes.INDICATION_SIGNS,
        assetTypes.PRESCRIPTION_SIGNS,
      ]),
    }
  },

  computed: {
    ...mapState('inventory', ['selectedAssets']),
    ...mapState(['language']),

    availableAssets() {
      const result = [
        {
          name: 'other',
          text: this.$t('enums.assets.other'),
        },
        { divider: true },
      ]

      for (const assetType of Object.values(assetTypes)) {
        result.push({ header: this.$t(`enums.assets.${assetType}`) })
        result.push(...this[assetType])
        result.push({ divider: true })
      }

      return result
    },
  },

  async created() {
    try {
      const list = await this.fetchAssetsList()
      const groupToAssetsMap = {}

      for (const [assetGroup, assetGroupItems] of Object.entries(list)) {
        this[assetGroup] = []
        groupToAssetsMap[assetGroup] = new Set()

        for (const [key, value] of Object.entries(assetGroupItems)) {
          const item = {
            name: key,
            text: value[this.language] + ` (${key})`,
            group: assetGroup,
            groupText: this.$t(`enums.assets.${assetGroup}`),
          }

          if (this.signs.has(assetGroup)) {
            item.src = `https://roadsigns.daway.ai/img/roadsigns/${key}.gif`
          } else if (assetGroup === assetTypes.ROAD_MARKINGS) {
            item.src = `https://roadsigns.daway.ai/img/roadsigns/${key}.JPG`
          }

          this[assetGroup].push(item)
          groupToAssetsMap[assetGroup].add(key)
        }
      }
      this.setGroupToAssetsMap(groupToAssetsMap)
    } catch (error) {
      this.showError(this.$t('views.inventory.assetsListError'))
    }
  },

  methods: {
    ...mapMutations('inventory', ['setSelectedAssets', 'setGroupToAssetsMap']),
    ...mapMutations(['showError']),
    ...mapActions('inventory', ['fetchAssetsList']),

    onSelectedAssetsChange(selectedAssets) {
      this.setSelectedAssets(selectedAssets)
    },

    remove(item) {
      const index = this.selectedAssets.indexOf(item.name)

      if (index >= 0) {
        this.setSelectedAssets(
          this.selectedAssets.filter((_, i) => i !== index),
        )
      }
    },

    itemName(name) {
      if (name === 'other') {
        return this.$t('enums.assets.other')
      }

      return name
    },
  },
}
</script>

<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>$fas fa-download</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
      >
        <v-btn
          text
          @click="downloadCsv(item)"
        >
          {{ item }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import { groupBy } from 'lodash'

import { arrayToCsv } from '@/utils/csv'
import { defaultWidths } from '@/utils/constants'

export default {
  name: 'AssetsDownload',

  props: {
    segment: {
      type: Object,
      required: true,
    },

    orderedImages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      items: ['10m', '100m'],
      maxAssetsCount: 0,
      maxAssetsCount100m: 0,

      assetHeaders: [
        'type',
        'referenceNo',
        'condition',
        'roadSide',
        'isOffRoad',
      ],

      headers: [
        {
          text: this.$t('enums.headers.roadName'),
          value: 'name',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.lat'),
          value: 'endLatitude',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.lon'),
          value: 'endLongitude',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.roadWidth'),
          value: 'roadWidth',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.shoulderWidth'),
          value: 'shoulderWidth',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.bikeLaneWidth'),
          value: 'bikeLaneWidth',
          align: 'left',
          sortable: false,
        },
      ],

      sectionWidths: {},

      imagesGroupedByStation: {},
      imagesGroupedByStation100m: {},
    }
  },

  computed: {
    ...mapState('inventory', ['assets']),

    csvHeaders() {
      return this.generateHeaders(this.maxAssetsCount)
    },

    csvHeaders100m() {
      return this.generateHeaders(this.maxAssetsCount100m)
    },
  },

  methods: {
    downloadCsv(item) {
      const csvContent = this.toCsv(item)

      const link = document.createElement('a')
      link.setAttribute('href', csvContent)
      link.setAttribute('download', `${this.segment.name}_${item}.csv`)
      link.click()
      link.remove()
    },

    toCsv(item) {
      const orderedAssets = this.assetsOrderedByStation()
      const CSVBody = []

      const hundredMeterSections = {}

      for (let i = 0; i < this.segment.iriSections10m.length; i++) {
        const iriSection = this.segment.iriSections10m[i]

        const { startStation, endStation, endStationInt, endLocation, iri } =
          iriSection

        const { name } = this.segment
        const { roadWidth, shoulderWidth, bikeLaneWidth } =
          this.sectionWidths[startStation]

        const key = Math.ceil(endStationInt / 100) * 100

        if (!this.imagesGroupedByStation100m[key]) {
          this.imagesGroupedByStation100m[key] = []
        }

        this.imagesGroupedByStation100m[key].push(
          ...this.imagesGroupedByStation[startStation],
        )

        const section = {
          name,
          startStation,
          endStation,
          endStationInt,
          endLatitude: endLocation.coordinates[1],
          endLongitude: endLocation.coordinates[0],
          iri,
          roadWidth,
          shoulderWidth,
          bikeLaneWidth,
        }

        if (!hundredMeterSections[key]) {
          hundredMeterSections[key] = { ...section, assets: [] }
        }

        if (orderedAssets[startStation]) {
          section.roadWidth = roadWidth
          section.shoulderWidth = shoulderWidth
          section.bikeLaneWidth = bikeLaneWidth

          section.assets = orderedAssets[startStation]
          hundredMeterSections[key].assets.push(...section.assets)
          this.appendAssetsToSection(section, section.assets)
        }

        CSVBody.push(section)
      }

      this.hundredMeterSections = hundredMeterSections

      if (item === '10m') {
        return (
          'data:text/csv;charset=utf-8,' +
          encodeURI(arrayToCsv(this.csvHeaders, CSVBody))
        )
      }

      return this.toCsv100m()
    },

    toCsv100m() {
      const CSVBody = []
      const entries = Object.entries(this.hundredMeterSections)

      for (let i = 0; i < entries.length; i++) {
        const iriSection = this.segment.iriSections[i]
        const [key, section] = entries[i]
        const assets = section.assets

        const { startStation, endStation, endStationInt, endLocation, iri } =
          iriSection

        const { name } = this.segment

        const newSection = {
          name,
          startStation,
          endStation,
          endStationInt,
          endLatitude: endLocation.coordinates[1],
          endLongitude: endLocation.coordinates[0],
          iri,
        }

        const { roadWidth, shoulderWidth, bikeLaneWidth } =
          this.calculateWidths(this.imagesGroupedByStation100m[key])

        newSection.roadWidth = roadWidth
        newSection.shoulderWidth = shoulderWidth
        newSection.bikeLaneWidth = bikeLaneWidth

        this.appendAssetsToSection(newSection, assets)

        if (assets.length > this.maxAssetsCount100m) {
          this.maxAssetsCount100m = assets.length
        }

        CSVBody.push(newSection)
      }

      return (
        'data:text/csv;charset=utf-8,' +
        encodeURI(arrayToCsv(this.csvHeaders100m, CSVBody))
      )
    },

    assetsOrderedByStation() {
      const result = {}
      const imagesGroupedByStation = groupBy(this.orderedImages, 'startStation')
      this.imagesGroupedByStation = imagesGroupedByStation

      for (const [station, images] of Object.entries(imagesGroupedByStation)) {
        const stationAssets = []
        this.sectionWidths[station] = this.calculateWidths(images)

        for (const image of images) {
          const imageAssets = this.assets[image._id]?.assets
          if (imageAssets) {
            for (const [assetType, assetBody] of Object.entries(imageAssets)) {
              stationAssets.push({ ...assetBody, type: assetType, image })
            }
          }
        }

        if (stationAssets.length > 0) {
          result[station] = stationAssets
          this.maxAssetsCount = Math.max(
            this.maxAssetsCount,
            stationAssets.length,
          )
        } else {
          result[station] = undefined
        }
      }

      return result
    },

    generateHeaders(maxAssetsCount) {
      const headers = [...this.headers]

      for (let i = 0; i < maxAssetsCount; i++) {
        const index = i + 1

        for (const assetHeader of this.assetHeaders) {
          const text = `${this.$t(`views.inventory.${assetHeader}`)} ${index}`
          const value = assetHeader + index

          headers.push({
            text,
            value,
            align: 'left',
            sortable: false,
          })
        }
      }

      return headers
    },

    appendAssetsToSection(section, assets) {
      for (let i = 0; i < assets.length; i++) {
        const index = i + 1
        const asset = assets[i]

        for (const property of this.assetHeaders) {
          const key = property + index
          let value

          if (property === 'condition') {
            value = this.$t(`enums.roadAssetConditions.${asset[property]}`)
          } else if (property === 'isOffRoad') {
            value = asset[property]
              ? this.$t('views.inventory.offRoad')
              : this.$t('views.inventory.onRoad')
          } else if (property === 'roadSide') {
            value = this.$t(`enums.side.${asset[property]}`)
          } else {
            value = asset[property]
          }

          section[key] = value
        }
      }
    },

    calculateWidths(images) {
      const roadWidths = []
      const shoulderWidths = []
      const bikeLaneWidths = []
      let addedSegementWidths = false

      for (const image of images) {
        const { roadWidth, shoulderWidth, bikeLaneWidth } = image

        if (roadWidth) {
          roadWidths.push(roadWidth)
          shoulderWidths.push(shoulderWidth)
          bikeLaneWidths.push(bikeLaneWidth)
        } else if (addedSegementWidths === false) {
          if (this.segment?.roadWidth !== undefined) {
            roadWidths.push(this.segment.roadWidth)
            shoulderWidths.push(this.segment.shoulderWidth)
            bikeLaneWidths.push(this.segment.bikeLaneWidth)
          } else {
            roadWidths.push(defaultWidths.road)
            shoulderWidths.push(defaultWidths.shoulder)
            bikeLaneWidths.push(defaultWidths.bikeLane)
          }
          addedSegementWidths = true
        }
      }

      if (roadWidths.length === 0) {
        return {
          roadWidth: `${this.segment.roadWidth}m`,
          shoulderWidth: `${this.segment.shoulderWidth}m`,
          bikeLaneWidth: `${this.segment.bikeLaneWidth}m`,
        }
      }

      const minRoadWidth = Math.min(...roadWidths)
      const minShoulderWidth = Math.min(...shoulderWidths)
      const minBikeLaneWidth = Math.min(...bikeLaneWidths)
      const maxRoadWidth = Math.max(...roadWidths)
      const maxShoulderWidth = Math.max(...shoulderWidths)
      const maxBikeLaneWidth = Math.max(...bikeLaneWidths)

      const roadWidth =
        minRoadWidth === maxRoadWidth
          ? `${minRoadWidth}m`
          : `${minRoadWidth} - ${maxRoadWidth}m`
      const shoulderWidth =
        minShoulderWidth === maxShoulderWidth
          ? `${minShoulderWidth}m`
          : `${minShoulderWidth} - ${maxShoulderWidth}m`
      const bikeLaneWidth =
        minBikeLaneWidth === maxBikeLaneWidth
          ? `${minBikeLaneWidth}m`
          : `${minBikeLaneWidth} - ${maxBikeLaneWidth}m`

      return {
        roadWidth,
        shoulderWidth,
        bikeLaneWidth,
      }
    },
  },
}
</script>

import { round } from 'lodash'

export function toRad(degree) {
  return (degree * Math.PI) / 180
}

export function isValidNumber(value, min, max, decimals) {
  if (!value) return false
  const number = Number(value)
  const isNumber = !isNaN(number)
  const isGreaterThanMin = number >= min
  const isLessThanMax = number <= max
  const rounded = round(number, decimals)
  const isRounded = number === rounded

  return isNumber && isGreaterThanMin && isLessThanMax && isRounded
}

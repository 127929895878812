<template>
  <!-- wrapper div to ensure that titlebar styles are applied correctly -->
  <div>
    <v-menu
      v-model="datePicker"
      :nudge-left="50"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      right
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="selectedDate"
          :label="$t('core.titlebar.datePickerLabel')"
          hide-details
          prepend-inner-icon="$far fa-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="selectedDate"
        :picker-date.sync="pickerDate"
        :locale="$t('core.titlebar.datePickerLocale')"
        :allowed-dates="allowedDates"
        @input="datePicker = false"
        @change="changeDate"
      />
    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { toMonthYear } from '@/utils/date'

export default {
  props: {
    list: {
      type: String,
      default: 'projectManager',
    },
  },

  data() {
    return {
      datePicker: false,
      pickerDate: null,
    }
  },

  computed: {
    ...mapState(['contractorDatePickerList', 'projectManagerDatePickerList']),

    selectedDate: {
      get() {
        return this.$store.state.selectedDate
      },

      set(date) {
        this.$store.state.selectedDate = date
      },
    },
  },

  watch: {
    selectedDate(newDate) {
      this.pickerDate = toMonthYear(newDate)
    },

    pickerDate(newMonthYear) {
      this.$emit('month-year-change', newMonthYear)
    },
  },

  mounted() {
    const queryDate = this.$router.currentRoute?.query?.date

    if (queryDate) {
      this.selectedDate = this.$router.currentRoute.query.date
    }

    this.pickerDate = toMonthYear(this.selectedDate)
  },

  methods: {
    allowedDates(date) {
      switch (this.list) {
        case 'contractor':
          return this.contractorDatePickerList.includes(date)
        case 'projectManager':
          return this.projectManagerDatePickerList.includes(date)
        default:
          return this.projectManagerDatePickerList.includes(date)
      }
    },

    changeDate(date) {
      const currentDateQuery = this.$router.currentRoute?.query?.date

      if (!currentDateQuery || currentDateQuery !== date) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { ...this.$router.currentRoute.query, date },
        })
      }
    },
  },
}
</script>

<template>
  <v-wait for="isFetchingDatapoints">
    <core-spinner slot="waiting" />
    <title-bar
      :show-back="back"
      :show-create="hasAccessToKpis"
      @create="kpiDialog = true"
    >
      <ProjectSelector
        :disable-project="isProjectManager"
        class="col-auto mr-2"
        @project-change="mixin_onProjectChange"
      />
      <DateSelector class="col-3" />
    </title-bar>
    <div class="grid-container">
      <MapComponent
        class="map"
        :center="mixin_center"
        :datapoints="mixin_nonSectionDatapoints"
        :remove-outside-visible-bounds="false"
        :show-user-position="true"
        :section-datapoints="mixin_sectionDatapoints"
        @expand="expand"
      />
      <gauge-chart
        class="gauge1"
        :kpi="kpiScore('IQTTBMTC', 'daily', selectedDate, user || userId)"
        :title="$t('views.site-technician.charts.received')"
        :gauge-identifier="'gaugeIQTTBMTC'"
      />
      <gauge-chart
        class="gauge2"
        :kpi="fieldDecisionalKpi"
        :title="$t('views.site-technician.charts.decisional')"
        :gauge-identifier="'gaugeIQDDM'"
      />
      <BarChart
        class="barChart"
        :title="$t('views.dashboard.graph.title')"
        :subtitle="$t('views.dashboard.graph.subtitle')"
        :datapoints="mixin_compactions"
      />
      <TableDatapointComponent
        ref="tableListDatapoints"
        class="table"
        :items="mixin_nonSectionDatapoints.concat(mixin_sectionDatapoints)"
      />
      <v-dialog
        v-if="hasAccessToKpis"
        v-model="kpiDialog"
        scrollable
        max-width="750px"
      >
        <TableKpiComponent :items="userKpis" />
      </v-dialog>
    </div>
  </v-wait>
</template>

<script>
import GaugeChart from '../Charts/Gauge.vue'
import MapComponent from '../Charts/Maps/Widget.vue'
import TableDatapointComponent from '../Charts/Tables/Datapoint.vue'
import TableKpiComponent from '../Charts/Tables/Kpi.vue'
import BarChart from '../Charts/Bar.vue'
import TitleBar from '@/components/core/TitleBar'
import ProjectSelector from '../../core/ProjectSelector.vue'
import DateSelector from '../../core/DateSelector.vue'

import { mapState, mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import { mergeKpis } from '@/utils/kpi'
import projectsListMixin from '@/mixins/projectsListMixin'
import siteTechMixin from '@/mixins/siteTechMixin'
import compactionsMixin from '@/mixins/compactionsMixin'

export default {
  name: 'SiteTechnician',
  components: {
    BarChart,
    GaugeChart,
    MapComponent,
    TableDatapointComponent,
    TableKpiComponent,
    TitleBar,
    ProjectSelector,
    DateSelector,
  },
  mixins: [projectsListMixin, siteTechMixin, compactionsMixin],
  props: {
    project: {
      type: String,
      default: '',
    },
    user: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      kpiDialog: false,
    }
  },
  computed: {
    ...mapState(['kpi', 'idproject', 'selectedDate']),
    ...mapGetters([
      'kpiScore',
      'userId',
      'hasAccessToKpis',
      'fieldDecisionalKpi',
      'lastProdKpiByNames',
    ]),
    back() {
      return Boolean(this.$route.query.showBack) || false
    },
    computedDatapoints() {
      return this.mixin_datapointsForDate
    },
    fieldDecisionalKpi() {
      return mergeKpis(this.lastProdKpiByNames(['IQDUG', 'IQDUM']))
    },
    userKpis() {
      return _.chain(this.kpi || [])
        .filter((k) => k.date === this.selectedDate && k.user === this.user)
        .sortBy(['version', 'type'])
        .value()
    },
  },
  watch: {
    mixin_nonSectionDatapoints(newPoints) {
      this.$root.$emit('weatherLocationUpdated', newPoints?.[0]?.location)
    },
  },
  async created() {
    this.$wait.start('isFetchingDatapoints')
    await Promise.all([this.getDatapoints(), this.getCurrentPosition()])
    this.startWatchingPosition()
    this.$wait.end('isFetchingDatapoints')
  },
  beforeDestroy() {
    this.stopWatchingPosition()
  },
  methods: {
    ...mapActions('app', [
      'getCurrentPosition',
      'startWatchingPosition',
      'stopWatchingPosition',
    ]),

    expand() {
      const newPath = `${this.$route.path}/details?project=${
        this.idproject
      }&user=${this.user || this.userId}`
      this.$router.push(newPath)
    },
  },
}
</script>

<style scoped>
.map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 10;
}

.gauge1 {
  grid-row: 1 / span 3;
  grid-column: 11 / span 2;
}

.gauge2 {
  grid-row: 4 / span 3;
  grid-column: 11 / span 2;
}

.barChart {
  grid-row: 7 / span 6;
  grid-column: 1 / span 6;
}

.table {
  grid-row: 7 / span 6;
  grid-column: 7 / span 6;
}
</style>

import { set, toggle } from '@/utils/vuex'
import resetState from '@/utils/resetState'
import { initialAppState } from './state'

export default {
  setColor: set('color'),
  setDrawer: set('drawer'),
  setLocalisation: set('localisation'),
  setUserLocated: set('isUserLocated'),
  setLocalisationWatcherId: set('localisationWatcherId'),
  toggleDrawer: toggle('drawer'),
  clearState(state) {
    resetState(state, initialAppState)
  },
}

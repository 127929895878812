<template>
  <div class="fill-height overflow-hidden d-flex flex-column">
    <core-progress :in-progress="$wait.is('deleting')" />

    <v-card-title>
      <v-text-field
        v-model="search"
        :label="$t('views.projects.search')"
        append-icon="$fas fa-search"
        single-line
        hide-details
      />
      <v-spacer />
      <v-switch
        v-model="isRestore"
        :label="$t('views.image-upload.restore-btn')"
      />
      <v-spacer />
      <v-btn
        :color="buttonColor"
        :disabled="selected.length === 0"
        @click="dialog = true"
      >
        {{ buttonText }}
      </v-btn>
    </v-card-title>

    <div class="table-container">
      <v-data-table
        v-model="selected"
        :items="listItems"
        :headers="headers"
        :search="search"
        item-key="_id"
        class="d-flex flex-column fill-height"
        hide-default-footer
        sort-by="originalFilename"
        show-select
        :items-per-page="-1"
        fixed-header
        style="overflow: auto"
        dense
      />
    </div>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ dialogTitle }}
        </v-card-title>

        <v-divider />

        <v-card-text class="text-subtitle-1">
          {{ dialogText }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{ $t('views.image-upload.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            :color="buttonColor"
            @click="accept"
          >
            {{ $t('views.image-upload.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      headers: [
        {
          text: 'File Name',
          value: 'originalFilename',
        },
      ],
      dialog: false,
      selected: [],
      search: '',
      isRestore: false,
    }
  },

  computed: {
    ...mapState(['idproject']),
    ...mapState('images', ['items']),

    dialogTitle() {
      return this.isRestore
        ? this.$t('views.image-upload.restore')
        : this.$t('views.image-upload.delete')
    },

    dialogText() {
      return this.isRestore
        ? this.$t('views.image-upload.restore-message', {
            count: this.selected.length,
          })
        : this.$t('views.image-upload.delete-message', {
            count: this.selected.length,
          })
    },

    buttonColor() {
      return this.isRestore ? 'success' : 'danger'
    },

    buttonText() {
      return this.isRestore
        ? this.$t('views.image-upload.restore-btn')
        : this.$t('views.image-upload.delete-btn')
    },

    deletableItems() {
      return this.items.filter((item) => !item.deleted)
    },

    restorableItems() {
      return this.items.filter((item) => item.deleted)
    },

    listItems() {
      return this.isRestore ? this.restorableItems : this.deletableItems
    },
  },

  watch: {
    idproject: {
      async handler() {
        this.$wait.start('loading')
        await this.getAllImages(this.idproject)
        this.$wait.end('loading')
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('images', ['getAllImages', 'deleteImages', 'restoreImages']),

    accept() {
      if (this.isRestore) {
        this.restoreItems()
      } else {
        this.deleteItems()
      }
    },

    async deleteItems() {
      const selectedIds = this.selected.map((item) => item._id)

      this.$wait.start('deleting')
      await this.deleteImages(selectedIds)
      this.selected = []
      this.dialog = false
      this.$wait.end('deleting')
    },

    async restoreItems() {
      const selectedIds = this.selected.map((item) => item._id)

      this.$wait.start('deleting')
      await this.restoreImages(selectedIds)
      this.selected = []
      this.dialog = false
      this.$wait.end('deleting')
    },
  },
}
</script>

<style>
.images-table {
  display: flex;
  flex-direction: column;
}
.table-container {
  flex: 1 1 auto;
  max-height: 90%;
}
</style>

<template>
  <v-wait for="isSiteForemanUpdating">
    <core-spinner slot="waiting" />
    <title-bar :show-back="true">
      <ProjectSelector
        disable-project
        class="col-6"
      />
    </title-bar>
    <div class="grid-container">
      <MapComponent
        class="map"
        :center="mixin_qualityControlCenter"
        :datapoints="mapDatapoints"
        :show-expand="false"
      />
      <TableDatapointComponent
        class="table"
        :datapoints="mixin_qualityControlDatapoints"
        @item-expanded="itemExpanded"
      />
    </div>
  </v-wait>
</template>

<script>
import TableDatapointComponent from '@/components/DashViews/Charts/Tables/DatapointGroup.vue'
import MapComponent from '@/components/DashViews/Charts/Maps/Widget.vue'
import TitleBar from '@/components/core/TitleBar'
import ProjectSelector from '../../core/ProjectSelector.vue'

import siteForemanMixin from '@/mixins/siteForemanMixin'
import projectsListMixin from '@/mixins/projectsListMixin'

export default {
  name: 'SiteForemanDetailDashboard',
  components: {
    TableDatapointComponent,
    MapComponent,
    TitleBar,
    ProjectSelector,
  },

  mixins: [siteForemanMixin, projectsListMixin],

  data() {
    return {
      details: true,
      filteredDatapoints: [],
    }
  },

  computed: {
    mapDatapoints() {
      if (this.filteredDatapoints.length) {
        return this.filteredDatapoints
      }
      return this.mixin_qualityControlDatapoints
    },
  },

  methods: {
    itemExpanded(event) {
      const group = event.item
      const isExpanded = event.value

      if (isExpanded) {
        const datapointIds = group.datapoints.map((d) => d._id)
        this.filteredDatapoints = this.mixin_qualityControlDatapoints.filter(
          (d) => datapointIds.includes(d._id),
        )
      } else {
        this.filteredDatapoints = []
      }
    },
  },
}
</script>

<style scoped>
.map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 12;
}
.table {
  grid-row: 7 / span 6;
  grid-column: 1 / span 12;
}
</style>

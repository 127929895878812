<template>
  <div class="titlebar">
    <div class="title">
      <v-btn
        v-if="showBack"
        icon
        class="v-btn-titlebar"
        @click="goBack()"
      >
        <v-icon class="left"> $fas fa-arrow-left </v-icon>
      </v-btn>
      <h3
        v-if="showTitle"
        class="my-0"
      >
        {{ $t(title) }}
      </h3>
      <h3
        v-else-if="customTitle"
        class="my-0"
      >
        {{ customTitle }}
      </h3>
      <v-btn
        v-if="showCreate"
        class="mx-2"
        fab
        small
        color="primary"
        elevation="0"
        @click="$emit('create')"
      >
        <v-icon small> $fas fa-plus </v-icon>
      </v-btn>
    </div>

    <div class="row justify-end ma-0">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    showBack: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    customTitle: {
      type: String,
      default: null,
    },
    showCreate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    title() {
      if (this.customTitle) {
        return this.customTitle
      } else if (typeof this.$route.meta.name === 'function') {
        return this.$route.meta.name(this.$route)
      } else {
        return this.$route.meta.name ? this.$route.meta.name : ''
      }
    },
  },

  methods: {
    goBack() {
      return this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.titlebar {
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 12px;
}

.title {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
</style>

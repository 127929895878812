<template>
  <v-wait for="isUpdatingDashboard">
    <core-spinner slot="waiting" />
    <TitleBar>
      <FilterSelector class="col-6 mr-2" />
      <DatePicker
        list="contractor"
        class="col-3 py-0"
      />
    </TitleBar>
    <v-container fluid>
      <v-row>
        <v-col
          v-for="(card, index) in filteredContractorKpisCards"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <material-card
            class="fill-height"
            @click="reroute(card.project._id, card.date)"
          >
            <div class="callout pl-2 d-flex flex-column fill-height">
              <div class="text-h6">
                {{ projectTitle(card.project) }}
              </div>

              <div class="font-weight-light">
                {{ projectDates(card.project) }}
              </div>
              <div class="font-weight-light">
                {{ projectStatus(card.project) }}
              </div>

              <v-spacer />

              <div class="ring-chart-container">
                <RingChart
                  v-for="(kpiName, subIndex) in kpiNames"
                  :id="`ringChart${kpiName}${index}${subIndex}`"
                  :key="`${kpiName}${index}${subIndex}`"
                  :no-background-color="true"
                  :percentile="calculatePercentile(card.kpis[kpiName])"
                  class="d-flex flex-column align-center"
                >
                  <template #title>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-badge
                            dot
                            inline
                            :value="isKpiNew(card.kpis[kpiName])"
                          >
                            {{ $t(`enums.shortKpi.${kpiName}`) }}
                          </v-badge>
                        </span>
                      </template>
                      <span>{{ $t(`enums.kpi.${kpiName}`) }}</span>
                    </v-tooltip>
                  </template>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kpiValue(card.kpis[kpiName]) }}
                      </span>
                    </template>
                    <span>{{ kpiTooltipValue(card.kpis[kpiName]) }}</span>
                  </v-tooltip>
                </RingChart>

                <RingChart
                  :id="`environmentChart${index}`"
                  :percentile="-1"
                  :no-background-color="true"
                  class="d-flex flex-column align-center"
                >
                  <template #title>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          >{{ $t('enums.shortKpi.IPECHP') }}</span
                        >
                      </template>
                      <span>{{ $t('enums.kpi.IPECHP') }}</span>
                    </v-tooltip>
                  </template>
                  <v-icon color="white"> $fas fa-leaf </v-icon>
                </RingChart>
              </div>
            </div>
          </material-card>
        </v-col>
      </v-row>
    </v-container>
  </v-wait>
</template>

<script>
import TitleBar from '../../../core/TitleBar.vue'
import RingChart from '../../Charts/Ring.vue'
import FilterSelector from '@/components/DashViews/ProjectManagers/FilterSelector.vue'
import DatePicker from '../../../core/DatePicker.vue'

import { mapActions, mapMutations, mapState } from 'vuex'
import { formatDateAsIs } from '@/utils/date'
import { riskToPercentile } from '@/utils/constants'
import { projectStatuses, kpiTypes } from '@/utils/enum'
import { round, uniq } from 'lodash'

export default {
  components: {
    TitleBar,
    RingChart,
    FilterSelector,
    DatePicker,
  },

  provide: {
    dashboard: 'contractor',
  },

  data() {
    return {
      kpiNames: [
        kpiTypes.RDELAIS,
        kpiTypes.RMEMO,
        kpiTypes.IQTRAVAUX,
        kpiTypes.IQTBMCPE,
      ],
      formatDateAsIs,
      riskKpis: [kpiTypes.RDELAIS, kpiTypes.RMEMO],
    }
  },

  computed: {
    ...mapState(['projectFilters', 'selectedDate', 'contractorKpis']),

    contractorKpisGroupedByCard() {
      // Group kpis by project, specifying dates & kpis for said project
      const kpisGroupedByProject = {}
      for (const contractorKpi of this.contractorKpis) {
        const projectId = contractorKpi.project._id

        if (!kpisGroupedByProject[projectId]) {
          kpisGroupedByProject[projectId] = {
            project: contractorKpi.project,
            dates: new Set(),
            kpis: [],
          }
        }

        kpisGroupedByProject[projectId].kpis.push(contractorKpi)
        kpisGroupedByProject[projectId].dates.add(contractorKpi.date)
      }

      // Use grouped data to construct array of kpis grouped by date & project to iterate through for view
      const result = []

      for (const group in kpisGroupedByProject) {
        kpisGroupedByProject[group].kpis.sort((a, b) => a.date - b.date)

        for (const date of kpisGroupedByProject[group].dates) {
          const current = {
            date,
            project: kpisGroupedByProject[group].project,
            kpis: {},
          }

          for (const kpiName of this.kpiNames) {
            // Find returns most recent because we sorted the kpis by date earlier
            const found = kpisGroupedByProject[group].kpis.find(
              (kpi) =>
                kpi.type === kpiName &&
                this.$dayjs(current.date).isSameOrAfter(kpi.date, 'day'),
            )

            if (found) {
              current.kpis[kpiName] = found
            }
          }
          result.push(current)
        }
      }
      return result
    },

    uniqueDates() {
      return uniq(this.contractorKpisGroupedByCard.map((kpi) => kpi.date))
    },

    filteredContractorKpisCards() {
      return this.contractorKpisGroupedByCard.filter(
        (card) =>
          card.date === this.selectedDate &&
          this.projectFilters.includes(card.project.type) &&
          this.projectFilters.includes(card.project.status),
      )
    },
  },

  watch: {
    uniqueDates(newDates) {
      this.addToContractorDatePickerList(newDates)
    },
  },

  async created() {
    this.$wait.start('isUpdatingDashboard')
    await this.updateProjectList()
    await this.updateContractorDashboard()
    this.$wait.end('isUpdatingDashboard')
  },

  methods: {
    ...mapActions(['updateContractorDashboard', 'updateProjectList']),

    ...mapMutations(['addToContractorDatePickerList']),

    reroute(project) {
      this.$router.push({
        path: '/dashboard/site-superintendent',
        query: {
          project,
          showBack: true,
        },
      })
    },

    calculatePercentile(kpi) {
      if (!kpi) {
        return -1
      }

      if (this.riskKpis.includes(kpi.type)) {
        return riskToPercentile(kpi.risk)
      }

      return this.formattedResult(kpi)
    },

    projectTitle(project) {
      if (!project) {
        return ''
      }

      if (project.client) {
        return `${project.title} - ${project.client}`
      }

      return project.title
    },

    projectDates(project) {
      const startDate = project?.startDate
        ? formatDateAsIs(project.startDate)
        : this.$t('views.contractorDashboard.notAvailable')
      const endDate = project?.endDate
        ? formatDateAsIs(project.endDate)
        : this.$t('views.contractorDashboard.notAvailable')

      return `${startDate} - ${endDate}`
    },

    projectStatus(project) {
      let status
      if (!project || !project.status) {
        status = projectStatuses.notAvailable
      } else {
        status = project.status
      }

      return this.$t(`enums.projectStatuses.${status}`)
    },

    kpiValue(kpi) {
      if (!kpi) {
        return this.$t('views.contractorDashboard.notAvailable')
      }

      return this.$t(`enums.shortRisk.${kpi.risk}`)
    },

    kpiTooltipValue(kpi) {
      if (!kpi) {
        return this.$t('views.contractorDashboard.notAvailableLong')
      }

      if (this.riskKpis.includes(kpi.type)) {
        return this.$t(`enums.risk.${kpi.risk}`)
      }

      return `${this.formattedResult(kpi)}%`
    },

    isKpiNew(kpi) {
      return kpi?.date === this.selectedDate
    },

    formattedResult(kpi) {
      if (kpi.result) {
        return round(kpi.result * 100)
      }

      return kpi.score
    },
  },
}
</script>

<style scoped>
.ring-chart-container {
  display: flex;
  margin-top: 0.75rem;
}

.ring-chart-container > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
</style>

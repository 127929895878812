<template>
  <v-wait for="isFetchingDashboard">
    <TitleBar />
    <core-spinner slot="waiting" />
    <div class="grid-container">
      <SupplierList
        :items="mixedSuppliers"
        :selected-item="selectedMixedSupplierId"
        class="supplier-list"
        @onSupplierSelected="onMixedSupplierChange"
      />
      <MixAggregateList
        :items="mixedMaterials"
        :selected-item="selectedMaterial"
        :selected-item-type="selectedMaterialType"
        class="mixed-mix-aggregate-list"
        @onItemSelected="onSelectedMaterialChange"
      />
      <VariationChart
        id="variation-chart"
        class="variation-chart"
        :data="mixedSampleProperties"
        :title="$t('views.dashboard.variation-chart.title')"
        :translation-path="variationTranslationPath"
        @rowclick="onPropertyRowClick($event)"
      />
      <LineBoundaryChart
        id="RawMaterialsPropertyChart"
        :title="$t('views.dashboard.boundary-chart.title')"
        :subtitle="$t(mixedpropertyName)"
        :boundary-data="mixedpropertyTimeline"
        :upper-h-r="mixedSpecifications[6] - mixedSpecifications[3]"
        :upper-a-r="mixedSpecifications[5] - mixedSpecifications[3]"
        :upper-l-r="mixedSpecifications[4] - mixedSpecifications[3]"
        :zero-r="0"
        :lower-l-r="mixedSpecifications[2] - mixedSpecifications[3]"
        :lower-a-r="mixedSpecifications[1] - mixedSpecifications[3]"
        :lower-h-r="mixedSpecifications[0] - mixedSpecifications[3]"
        class="boundary-chart"
      />
    </div>
  </v-wait>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { references as referenceTypes } from '@/utils/enum'
import { defaultSpecificationRange } from '@/utils/constants'
import MixAggregateList from './MixAggregateList.vue'
import SupplierList from './SupplierList.vue'
import TitleBar from '@/components/core/TitleBar.vue'
import VariationChart from '@/components/DashViews/Charts/Variation.vue'
import LineBoundaryChart from '@/components/DashViews/Charts/Lines/Boundary.vue'
import productionWeatherMixin from '@/mixins/productionWeatherMixin'

export default {
  components: {
    LineBoundaryChart,
    MixAggregateList,
    SupplierList,
    TitleBar,
    VariationChart,
  },
  mixins: [productionWeatherMixin],
  props: {
    mixDesign: {
      type: String,
      default: '',
    },
    supplier: {
      type: String,
      default: '',
    },
    specification: {
      type: String,
      default: '',
    },
    stockpile: {
      type: String,
      default: '',
    },
    foremanType: {
      type: String,
      default: 'superintendent',
    },
  },

  computed: {
    ...mapState('production', [
      'aggregates',
      'asphaltSamples',
      'mixDesigns',
      'mixedSuppliers',
      'selectedAggregateSpecification',
      'selectedAggregateSampleProperty',
      'selectedAggregateStockpile',
      'selectedMixedSupplierId',
      'selectedMixId',
      'selectedSamplePropertyId',
    ]),
    ...mapGetters('production', [
      'aggregateSampleProperties',
      'aggregateSamplePropertyTimeline',
      'aggregateTableList',
      'asphaltSampleProperties',
      'asphaltSamplePropertyTimeline',
      'mixDesignTableList',
      'selectedAggregate',
      'selectedMixDesign',
      'selectedMixedSupplier',
    ]),
    mixedMaterials() {
      if (this.selectedSupplierType === referenceTypes.SUPPLIER) {
        return this.aggregateTableList
      } else if (this.selectedSupplierType === referenceTypes.PLANT) {
        return this.mixDesignTableList
      } else {
        return []
      }
    },
    selectedMaterial() {
      if (this.selectedSupplierType === referenceTypes.SUPPLIER) {
        return (
          this.selectedAggregateSpecification +
          '-' +
          this.selectedAggregateStockpile
        )
      } else if (this.selectedSupplierType === referenceTypes.PLANT) {
        return this.selectedMixId
      } else {
        return ''
      }
    },
    selectedSupplierType() {
      if (this.selectedMixedSupplier) {
        return this.selectedMixedSupplier.type
      } else {
        return undefined
      }
    },
    selectedMaterialType() {
      if (this.selectedSupplierType === referenceTypes.SUPPLIER) {
        return referenceTypes.LAB_SPEC_MATERIAL
      } else if (this.selectedSupplierType === referenceTypes.PLANT) {
        return referenceTypes.LAB_MIX_DESIGN
      } else {
        return ''
      }
    },
    mixedSpecifications() {
      if (this.selectedSupplierType === referenceTypes.SUPPLIER) {
        return this.selectedAggregate &&
          this.selectedAggregate.specifications &&
          this.selectedAggregateSampleProperty
          ? this.selectedAggregate.specifications[
              this.selectedAggregateSampleProperty
            ]
          : defaultSpecificationRange
      } else if (this.selectedSupplierType === referenceTypes.PLANT) {
        return (
          this.selectedMixDesign?.specifications[
            this.selectedSamplePropertyId
          ] || defaultSpecificationRange
        )
      } else {
        return defaultSpecificationRange
      }
    },
    mixedpropertyTimeline() {
      if (this.selectedSupplierType === referenceTypes.SUPPLIER) {
        return this.aggregateSamplePropertyTimeline
      } else if (this.selectedSupplierType === referenceTypes.PLANT) {
        return this.asphaltSamplePropertyTimeline
      } else {
        return []
      }
    },
    mixedpropertyName() {
      if (
        this.selectedSupplierType === referenceTypes.SUPPLIER &&
        this.selectedAggregateSampleProperty
      ) {
        return (
          'enums.aggregateProperties.' + this.selectedAggregateSampleProperty
        )
      } else if (
        this.selectedSupplierType === referenceTypes.PLANT &&
        this.selectedSamplePropertyId
      ) {
        return 'enums.sampleProperties.' + this.selectedSamplePropertyId
      } else {
        return 'enums.sampleProperties.undefined'
      }
    },
    mixedSampleProperties() {
      if (this.selectedSupplierType === referenceTypes.SUPPLIER) {
        return this.aggregateSampleProperties
      } else if (this.selectedSupplierType === referenceTypes.PLANT) {
        return this.asphaltSampleProperties
      } else {
        return []
      }
    },
    variationTranslationPath() {
      if (this.selectedSupplierType === referenceTypes.SUPPLIER) {
        return 'enums.aggregateProperties'
      } else {
        return 'enums.sampleProperties'
      }
    },
  },

  watch: {
    async supplier() {
      await this.initializeSuppliers()
    },
    async mixDesign() {
      if (this.selectedMixedSupplier.type === referenceTypes.PLANT) {
        await this.changeMixDesign()
      }
    },
    async specification() {
      if (this.selectedMixedSupplier.type === referenceTypes.SUPPLIER) {
        await this.changeAggregate()
      }
    },
    async stockpile() {
      if (this.selectedMixedSupplier.type === referenceTypes.SUPPLIER) {
        await this.changeAggregate()
      }
    },
  },

  async created() {
    this.$wait.start('isFetchingDashboard')

    await this.initializeSuppliers()

    this.$wait.end('isFetchingDashboard')
  },

  methods: {
    ...mapMutations('production', [
      'selectAggregateSampleProperty',
      'selectAsphaltSampleProperty',
    ]),
    async initializeSuppliers() {
      if (!this.mixedSuppliers.length > 0) {
        await this.$store.dispatch(
          'production/fetchProductionForemanDashboard',
          this.foremanType,
        )
      }
      if (this.mixedSuppliers.length > 0) {
        await this.$store.commit(
          'production/selectMixedSupplier',
          this.supplier ||
            this.selectedMixedSupplierId ||
            this.mixedSuppliers[0]._id,
        )

        this.setProductionWeather(this.selectedMixedSupplier)

        if (this.selectedMixedSupplier.type === referenceTypes.SUPPLIER) {
          await this.loadAggregates()
          await this.changeAggregate()
        } else if (this.selectedMixedSupplier.type === referenceTypes.PLANT) {
          await this.loadMixDesigns()
          await this.changeMixDesign()
        }
      }
    },
    async changeAggregate() {
      if (this.aggregates.length) {
        if (this.specification && this.stockpile) {
          await this.$store.dispatch('production/changeSelectedAggregate', {
            specification: this.specification,
            stockpile: this.stockpile,
          })
        } else if (this.aggregates.length > 0) {
          await this.$store.dispatch('production/changeSelectedAggregate', {
            specification: this.aggregates[0].specification,
            stockpile: this.aggregates[0].stockpile,
          })
        }
      }
    },
    async changeMixDesign() {
      if (this.mixDesigns.length) {
        if (this.mixDesign) {
          await this.$store.dispatch('production/changeSelectedMixDesign', {
            mixDesignId: this.mixDesign,
          })
        } else if (this.asphaltSamples.length > 0) {
          await this.$store.dispatch('production/changeSelectedMixDesign', {
            mixDesignId: this.asphaltSamples[0].mixDesign,
          })
        }
      }
    },
    async loadAggregates() {
      await this.$store.dispatch(
        'production/fetchAggregateProductionTechnicianDashboard',
        {
          supplierId: this.selectedMixedSupplierId,
          includeJobsites: true,
        },
      )
    },
    async loadMixDesigns() {
      await this.$store.dispatch(
        'production/fetchProductionTechnicianDashboard',
        {
          plantId: this.selectedMixedSupplierId,
          includeJobsites: true,
        },
      )
    },
    onMixedSupplierChange(item) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { supplier: item },
      })
    },
    onSelectedMaterialChange(item) {
      if (this.selectedMixedSupplier.type === referenceTypes.SUPPLIER) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            supplier: this.supplier,
            specification: item.specification,
            stockpile: item.stockpile,
          },
        })
      } else if (this.selectedMixedSupplier.type === referenceTypes.PLANT) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { supplier: this.supplier, mixDesign: item.id },
        })
      }
    },
    onPropertyRowClick(data) {
      if (this.selectedMixedSupplier.type === referenceTypes.SUPPLIER) {
        this.selectAggregateSampleProperty(data.id)
      } else if (this.selectedMixedSupplier.type === referenceTypes.PLANT) {
        if (this.selectedMixDesign.specifications[data.id]) {
          this.selectAsphaltSampleProperty(data.id)
        }
      }
    },
  },
}
</script>
<style scoped>
.supplier-list {
  grid-row: 1 / span 5;
  grid-column: 1 / span 6;
}
.mixed-mix-aggregate-list {
  grid-row: 6 / span 7;
  grid-column: 1 / span 6;
}
.variation-chart {
  grid-row: 1 / span 6;
  grid-column: 7 / span 6;
}
.boundary-chart {
  grid-row: 7 / span 6;
  grid-column: 7 / span 6;
}
</style>

import { markerToGeoJson } from '@/utils/map'
import { mapMutations, mapActions } from 'vuex'
import { toEnum } from '@/utils/enum'

const STATUS = toEnum({
  SUPPLIER: 'supplier',
  LOCATION: 'location',
})

export default {
  data() {
    return {
      status: null,
      options: {
        enableHighAccuracy: false,
        maximumAge: 1200,
        timeout: 10000,
      },
    }
  },
  methods: {
    ...mapActions({
      getSupplierCurrentWeather: 'weather/getSupplierCurrent',
    }),
    ...mapMutations({
      setCurrentWeather: 'weather/setCurrent',
    }),
    setProductionWeather(supplier) {
      if (supplier?.supplierAddressPostalCode) {
        this.status = STATUS.SUPPLIER
        this.getSupplierCurrentWeather(supplier)
          .then((weatherData) => {
            if (weatherData && this.status === STATUS.SUPPLIER) {
              this.setCurrentWeather(weatherData)
            }
          })
          .catch(() => {
            if (this.status === STATUS.SUPPLIER) {
              this.$root.$emit('weatherLocationUpdated', null)
            }
          })
      } else {
        this.status = STATUS.LOCATION
        this.setWeatherByLocation()
      }
    },
    setWeatherByLocation() {
      const success = (position) => {
        const crd = position.coords
        const location = markerToGeoJson([crd.latitude, crd.longitude])
        if (location && this.status === STATUS.LOCATION) {
          this.$root.$emit('weatherLocationUpdated', location)
        }
      }

      const error = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
        if (this.status === STATUS.LOCATION) {
          this.$root.$emit('weatherLocationUpdated', null)
        }
      }

      navigator.geolocation.getCurrentPosition(success, error, this.options)
    },
  },
}

<template>
  <v-app-bar
    app
    absolute
    align="right"
  >
    <v-btn
      id="square-right"
      :class="$store.state.app.drawer ? 'hide' : 'mt-2 ml-4'"
      icon
      @click="onClickBtn"
      ><v-icon>$fas fa-bars</v-icon></v-btn
    >
    <v-spacer />
    <v-toolbar-items>
      <v-select
        v-if="organizations.length >= 2"
        v-model="selectedOrganization"
        :items="organizations"
        item-text="name"
        item-value="_id"
        flat
        dense
        solo
        return-object
        hide-details
        @change="setSelectedOrganization"
      />
      <v-btn
        v-if="!isClientDashboard"
        v-ripple
        icon
        to="/dashboard/notifications"
      >
        <v-badge
          :content="notificationCount"
          :value="notificationCount"
          color="red"
          overlap
        >
          <v-icon color>$far fa-bell</v-icon>
        </v-badge>
      </v-btn>
      <v-select
        v-model="$root.$i18n.locale"
        :items="langs"
        flat
        dense
        solo
        hide-details
        @change="setLocale"
      >
      </v-select>
      <v-btn
        v-ripple
        icon
        @click="logout"
      >
        <v-icon>$fas fa-power-off</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'CoreToolbar',
  data: () => ({
    notifications: [],
    title: undefined,
    responsive: false,
    langs: ['en', 'fr'],
    organizations: [],
    selectedOrganization: null,
  }),
  computed: {
    ...mapState(['isClientDashboard', 'user']),
    ...mapGetters(['userName', 'notificationCount', 'landingPage']),
  },
  mounted() {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.initializeOrganizationSelector()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations('app', ['setDrawer']),
    ...mapActions({
      setLanguage: 'userManagement/setLanguage',
      getUserOrgs: 'userManagement/getUserOrganizations',
      setUserOrg: 'userManagement/setUserOrganization',
    }),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onResponsiveInverted() {
      this.responsive = window.innerWidth < 991
    },
    async logout() {
      this.$store.cache.clear()
      await this.$store.dispatch('logout')
      this.$router.push('/')
    },
    async setLocale(value) {
      if (!this.isClientDashboard) {
        await this.setLanguage(value)
      } else {
        await this.$store.commit('updateLanguage', value)
      }
    },

    async initializeOrganizationSelector() {
      const data = (await this.getUserOrgs()) || []

      if (data.length < 2) {
        return
      }

      this.organizations = data

      const found = this.organizations.find(
        (org) => org.id === this.user.selectedOrganization,
      )
      this.selectedOrganization = found || this.organizations[0]
    },

    async setSelectedOrganization(value) {
      await this.setUserOrg(value.id)
    },
  },
}
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}

#core-toolbar > .v-toolbar__content {
  padding-top: 0;
  padding-bottom: 0;
}
</style>

<template>
  <div>
    <core-progress :in-progress="$wait.is('loading')" />
    <TitleBar :show-back="true" />
    <div class="grid-container">
      <div class="iri-map">
        <IriMap
          :iri-runs="iriRuns"
          :images="images"
        />
      </div>
      <material-card
        outlined
        class="fill-height iri-form"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="segmentName"
                  class="pa-0"
                  :label="$t('views.iri-management.upload-form.segment')"
                  disabled
                />
              </v-col>

              <v-col cols="12">
                <ImageInput
                  :rules="rules"
                  class="pa-0"
                  @onChange="generateMarkers"
                />
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="selectedLayer"
                  :items="layers"
                  :label="$t('enums.headers.layer')"
                  class="select-no-border pa-0"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-btn
          color="success"
          class="mt-4"
          :disabled="saveDisabled"
          @click="saveImages"
        >
          {{ $t('views.user-management.update-form.save') }}
        </v-btn>
      </material-card>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/core/TitleBar.vue'
import ImageInput from '@/components/DashViews/Forms/ImageInput.vue'
import IriMap from '../../Charts/Maps/IriMap.vue'

import { mapState, mapActions, mapMutations } from 'vuex'
import { rules } from '@/utils/rules'
import { roadLayers } from '@/utils/enum'
import { toSelectItems } from '@/utils/i18n'
import imageUploadMixin from '@/mixins/imageUploadMixin'

export default {
  name: 'IRIImages',
  components: {
    TitleBar,
    ImageInput,
    IriMap,
  },

  mixins: [imageUploadMixin],

  props: {
    measurementId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      rules: rules.iriImages,
      imageMarkers: [],
      selectedLayer: roadLayers.rolling,
    }
  },

  computed: {
    ...mapState('iri', ['segment']),
    ...mapState('images', ['items']),

    layers() {
      return toSelectItems(roadLayers, 'enums.layers')
    },

    segmentName() {
      return this.segment?.name || ''
    },

    iriRuns() {
      if (this.segment) {
        return [this.segment]
      }
      return []
    },

    images() {
      return [...this.imageMarkers, ...this.items]
    },
  },

  async created() {
    this.$wait.start('loading')
    await this.getMeasurement({
      measurementId: this.measurementId,
    })
    this.$wait.end('loading')
  },

  methods: {
    ...mapActions('iri', ['getMeasurement']),
    ...mapActions('images', ['uploadImages']),
    ...mapMutations(['showError']),

    async generateMarkers(files) {
      this.imageMarkers = []

      this.uploadedImages = await this.filterImagesWithLocation(files)

      for (const file of this.uploadedImages) {
        const { name } = file.file
        const { latitude, longitude } = file.metadata

        const imageMarker = {
          _id: name,
          datapoint: {
            location: {
              coordinates: [longitude, latitude],
            },
          },
        }

        this.imageMarkers.push(imageMarker)
      }
    },

    createMultipartForm() {
      const imageLocations = {}
      const formData = new FormData()

      for (const image of this.uploadedImages) {
        imageLocations[image.file.name] = [
          image.metadata.longitude,
          image.metadata.latitude,
        ]
      }

      formData.set(
        'data',
        JSON.stringify({
          date: this.segment.date,
          layer: this.selectedLayer,
          projectId: this.segment.project,
          imageLocations,
          iriMeasurement: this.segment._id,
        }),
      )

      for (const image of this.uploadedImages) {
        formData.append('images', image.file)
      }

      return formData
    },
  },
}
</script>
<style>
.iri-map {
  grid-row: 1 / span 12;
  grid-column: 1 / span 8;
}

.iri-form {
  grid-row: 1 / span 12;
  grid-column: 9 / span 4;
}
</style>

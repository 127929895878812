import Vue from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import en from 'dayjs/locale/en'
import fr from 'dayjs/locale/fr'

const localizedRelativeTime = {
  en: {
    future: 'in %s',
    past: '%s ago',
    s: '1 m',
    m: '1 m',
    mm: '%d m',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 M',
    MM: '%d M',
    y: '1 y',
    yy: '%d y',
  },
  fr: {
    future: 'dans %s',
    past: 'il y a %s',
    s: '1 m',
    m: '1 m',
    mm: '%d m',
    h: '1 h',
    hh: '%d h',
    d: '1 j',
    dd: '%d j',
    M: '1 M',
    MM: '%d M',
    y: '1 a',
    yy: '%d a',
  },
}

dayjs.locale(en)
dayjs.locale(fr)
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(timezone)
dayjs.extend(utc)

dayjs.updateLocale('en', {
  relativeTime: localizedRelativeTime.en,
})
dayjs.updateLocale('fr', {
  relativeTime: localizedRelativeTime.fr,
})

dayjs.locale(localStorage.getItem('language') || navigator.language.slice(0, 2))

Vue.prototype.$dayjs = dayjs

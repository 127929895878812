<template>
  <core-card>
    <v-card-title class="pa-0">
      <div class="callout">
        <div class="subtitle-1 ml-2">
          {{ $t('views.charts.tableDataPoint.title') }}
        </div>
        <div class="text-caption text--disabled ml-2">
          {{ $t('views.charts.tableDataPoint.description') }}
        </div>
      </div>
      <v-spacer />
      <v-text-field
        v-model="search"
        :label="$t('views.projects.search')"
        append-icon="$fas fa-search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      class="v-table-pointer"
      :headers="headers"
      :items="computedItems"
      :items-per-page="-1"
      height="100%"
      fixed-header
      :search="search"
      hide-default-footer
      style="overflow: auto"
      @click:row="navigateToDataPoint"
    >
      <template #headerCell="{ header }">
        <span
          class="subheading font-weight-light text-success text--darken-3"
          v-text="header.text"
        />
      </template>
      <template #[`item.station`]="{ item }">
        <v-icon
          v-if="item.icon"
          :color="item.icon.color"
          >${{ item.icon.value }}</v-icon
        >
        {{ item.station }}
      </template>
    </v-data-table>
  </core-card>
</template>

<script>
import NavigateMapToDatapoint from '@/mixins/NavigateMapToDatapoint'
import * as enums from '@/utils/enum'

export default {
  name: 'TableList',
  mixins: [NavigateMapToDatapoint],
  props: {
    items: {
      type: [Array],
      required: true,
    },
  },

  data() {
    return {
      map: undefined,
      sectionTypes: [
        enums.datapoints.SITE_AGGREGATE_SECTION,
        enums.datapoints.SITE_ASPHALT_SECTION,
      ],
      search: '',
    }
  },

  computed: {
    headers() {
      return [
        {
          sortable: true,
          text: this.$t('views.charts.tableDataPoint.station'),
          value: 'station',
        },
        { sortable: true, text: 'Type', value: 'typeUI' },
        {
          sortable: false,
          text: this.$t('views.charts.tableDataPoint.data'),
          value: 'data',
        },
      ]
    },

    computedItems() {
      this.items.forEach((element, index) => {
        element.station = element.station || `Point ${index + 1}`
        element.data = this.getPointData(element)
        element.typeUI = this.getElementTypeUI(element)
        element.icon = {
          color: this.getPointRisk(element.risk),
          value: 'fas fa-map-marker-alt',
        }
      })

      return [...this.items].sort(this.dataPointSorter)
    },
  },
  methods: {
    dataPointSorter(a, b) {
      if (a['station'] && b['station']) {
        return a['station'].localeCompare(b['station'])
      } else {
        return 0
      }
    },

    getPointRisk(risk) {
      switch (risk) {
        case enums.risk.HR:
          return '#FF6666'
        case enums.risk.AR:
          return '#FFBD21'
        case enums.risk.LR:
          return '#05A57D'
        default:
          return '#999999'
      }
    },

    getPointData(point) {
      switch (point.type) {
        case enums.datapoints.SITE_ASPHALT_SAMPLE_TEST:
        case enums.datapoints.SITE_AGGREGATE_SAMPLE_TEST:
          return point.sampleWeight
            ? Number(point.sampleWeight.$numberDecimal).toFixed(0) + ' T'
            : '-'
        case enums.datapoints.SITE_AGGREGATE_TEST:
        case enums.datapoints.SITE_AGGREGATE_STAB_TEST:
        case enums.datapoints.SITE_ASPHALT_COMPACTION_MTQ:
        case enums.datapoints.SITE_ASPHALT_COMPACTION_CONTRACTOR:
          return point.compaction
            ? this.$t('core.unit.percent', {
                n: Number(point.compaction.$numberDecimal).toFixed(1),
              })
            : '-'
        case enums.datapoints.SITE_ASPHALT_SPREAD_RATE:
          return (
            `${this.getPropName('spreadRate')}: ` +
            (point.spreadRate
              ? Number(point.spreadRate.$numberDecimal).toFixed(1) + ' Kg/m²'
              : '-') +
            ` | ${this.getPropName('spreadRateLength')}: ` +
            (point.spreadRateLength
              ? Number(point.spreadRateLength.$numberDecimal).toFixed(1) + ' m'
              : '-') +
            ` | ${this.getPropName('spreadRateWidth')}: ` +
            (point.spreadRateWidth
              ? Number(point.spreadRateWidth.$numberDecimal).toFixed(1) + ' m'
              : '-')
          )
        case enums.datapoints.SITE_ASPHALT_TEMPERATURE:
          return (
            `${this.getPropName('mix')}: ` +
            (point.temperatureTruck
              ? Number(point.temperatureTruck.$numberDecimal).toFixed(1)
              : '-') +
            `° | ${this.getPropName('surface')}: ` +
            (point.temperatureSurface
              ? Number(point.temperatureSurface.$numberDecimal).toFixed(1)
              : '-') +
            '°'
          )
        case enums.datapoints.SITE_ASPHALT_THERMOGRAPHY_LOT:
          return (
            `${this.getPropName('min')}: ` +
            (point.thermographyMinimumTemp
              ? Number(point.thermographyMinimumTemp.$numberDecimal).toFixed(1)
              : '-') +
            ` | ${this.getPropName('max')}: ` +
            (point.thermographyMaximumTemp
              ? Number(point.thermographyMaximumTemp.$numberDecimal).toFixed(1)
              : '-') +
            ` | ${this.getPropName('segregation')}: ` +
            (point.thermographyIsLTS
              ? this.getPropName('yes')
              : this.getPropName('no'))
          )
        default:
          return '-'
      }
    },

    getPropName(key) {
      return this.$t(`views.charts.tableDataPoint.${key}`)
    },

    getElementTypeUI(element) {
      if (this.sectionTypes.includes(element.type)) {
        const append = element.isStart
          ? this.$t('views.project.startTooltip')
          : this.$t('views.project.endTooltip')

        return this.$t(`enums.type.${element.type}`) + ' - ' + append
      }
      return this.$t(`enums.type.${element.type}`)
    },
  },
}
</script>

<template>
  <div>
    <title-bar />
    <div class="grid-container">
      <div class="buttons">
        <v-select
          v-model="dataSource"
          class="custom"
          :items="dataSources"
          hide-details
          :label="$t('views.minds.dataSource')"
          dense
        />
        <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              :label="$t('views.minds.startDate')"
              hide-details
              prepend-inner-icon="$far fa-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="startDate"
            :max="max"
            :locale="$t('core.titlebar.datePickerLocale')"
            @input="datePicker = false"
          />
        </v-menu>

        <v-btn
          :disabled="disabled"
          color="success"
          :loading="loading"
          @click="onClick"
        >
          {{ $t('views.minds.send') }}
        </v-btn>
      </div>

      <v-card
        class="table"
        elevation="1"
      >
        <v-card-title>
          Data
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="$fas fa-search"
            :label="$t('core.tables.search')"
            single-line
            hide-details
          />
        </v-card-title>

        <v-data-table
          fixed-header
          :loading="loading"
          :loading-text="$t('core.tables.loading')"
          :headers="headers"
          :items="data"
          :search="search"
          style="overflow: auto"
          height="100%"
          hide-default-footer
          disable-pagination
        />
      </v-card>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/core/TitleBar.vue'

import { mapActions } from 'vuex'

export default {
  components: {
    TitleBar,
  },

  data() {
    return {
      headers: [],
      data: [],
      loading: false,
      search: '',
      dataSource: null,
      datePicker: false,
      startDate: null,
      max: new Date().toISOString().split('T')[0],
    }
  },

  computed: {
    disabled() {
      return this.loading || !this.dataSource || !this.startDate
    },

    dataSources() {
      return [
        { text: this.$t('views.minds.dataSources.drum'), value: 'drum' },
        {
          text: this.$t('views.minds.dataSources.tower'),
          value: 'batch/tower',
        },
        {
          text: this.$t('views.minds.dataSources.feeder'),
          value: 'batch/feeder',
        },
        { text: this.$t('views.minds.dataSources.tickets'), value: 'tickets' },
      ]
    },
  },

  methods: {
    ...mapActions(['getMindsData']),

    async onClick() {
      this.loading = true

      try {
        const result = await this.getMindsData({
          dataSource: this.dataSource,
          startDate: this.startDate,
        })

        this.headers = this.generateHeaders(result)
        this.data = result
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    generateHeaders(data) {
      if (!data.length) return []

      return Object.keys(data[0]).map((key) => ({
        text: key,
        value: key,
      }))
    },
  },
}
</script>

<style>
.buttons {
  grid-row: 1 / span 1;
  grid-column: 1 / span 12;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.table {
  grid-row: 2 / span 11;
  grid-column: 1 / span 12;
}
</style>

<template>
  <div>
    <p class="copyright">
      {{ $t('core.copyright') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'CopyrightNotice',
}
</script>

<template>
  <div>
    <l-geo-json
      :geojson="runs"
      :options-style="pathStyle"
      @click="iriPathFeatureClick"
    />
  </div>
</template>

<script>
import { LGeoJson } from 'vue2-leaflet'
import { riskToHexColor } from '@/utils/map'

export default {
  name: 'IriRun',

  components: {
    LGeoJson,
  },

  props: {
    runs: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  methods: {
    iriPathFeatureClick(event) {
      if (event?.layer?.feature?.properties?._id)
        this.$emit('path:click', event.layer.feature.properties)
    },

    pathStyle(feature) {
      return {
        color: riskToHexColor(feature.properties.risk),
        opacity: 1,
        weight: 4,
      }
    },
  },
}
</script>

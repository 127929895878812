<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    outlined
    class="pa-0 fill-height"
  >
    <l-map
      ref="mapWidget"
      class="leaflet-map"
      :zoom.sync="mapBindings.zoom"
      :bounds.sync="mapBindings.bounds"
      :center.sync="mapBindings.center"
      :options="mapOptions"
    >
      <l-control-layers position="bottomright" />
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :options="tileOptions"
        layer-type="base"
      />
      <l-control v-if="hasExpandListener">
        <v-btn
          text
          class="toolbar-items"
          @click="expand"
        >
          <v-icon>$fas fa-expand-alt</v-icon>
        </v-btn>
      </l-control>
      <l-marker
        v-if="isUserMarkerVisible"
        :lat-lng="localisation"
      >
        <l-icon>
          <v-icon color="primary"> $fas fa-location-arrow </v-icon>
        </l-icon>
      </l-marker>
      <template v-if="starts.length > 0">
        <l-marker
          v-for="(element, index) in starts"
          :key="'start-icon-' + index"
          :lat-lng="[element[0], element[1]]"
        >
          <l-icon :icon-anchor="[4, 24]">
            <v-icon color="black"> $fas fa-flag </v-icon>
          </l-icon>
        </l-marker>
      </template>
      <slot name="map-content" />
    </l-map>
  </v-card>
</template>

<script>
import { defaultMapBindings } from '@/utils/map'
import { mapState } from 'vuex'
import {
  LControl,
  LControlLayers,
  LIcon,
  LMap,
  LMarker,
  LTileLayer,
} from 'vue2-leaflet'

export default {
  name: 'IriMap',
  components: {
    LControl,
    LControlLayers,
    LIcon,
    LMap,
    LMarker,
    LTileLayer,
  },
  model: {
    prop: 'mapBindings',
  },
  props: {
    mapBindings: {
      type: Object,
      default: () => defaultMapBindings(),
    },
    starts: {
      type: Array,
      default: () => [],
      required: false,
    },
    showUserPosition: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tileProviders: [
        {
          name: 'Flat',
          visible: true,
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'Aerial',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        },
      ],
      tileOptions: {
        maxZoom: 20,
        maxNativeZoom: 18,
      },
      mapOptions: {
        zoomSnap: 0.5,
      },
    }
  },

  computed: {
    ...mapState('app', ['isUserLocated', 'localisation']),

    isUserMarkerVisible() {
      return this.showUserPosition && this.isUserLocated
    },

    hasExpandListener() {
      return this.$listeners && this.$listeners.expand
    },
  },

  methods: {
    expand() {
      this.$emit('expand')
    },
  },
}
</script>

import axios from 'axios'
import dayjs from 'dayjs'
import { isValidPostalcode } from '@/utils/address'
import { geoJsonToMarker, isValidPoint } from '@/utils/map'

export default {
  async getCurrent({ state, commit }, options) {
    if (
      state.loaded &&
      isSameTime(state.time) &&
      isSamePosition(state.position, options.location)
    ) {
      return
    }

    const url = options.isClientDashboard
      ? '/passwordless/weather'
      : '/weather/current'

    const params = {
      lat: options.location[0],
      long: options.location[1],
    }

    // Client dashboard weather requires more params for passwordless auth
    if (options.isClientDashboard && options.uuid && options.token) {
      params.uuid = options.uuid
      params.token = options.token
    }

    const { data } = await axios.get(`${url}`, { params })
    if (!data) return

    data.date = dayjs().format('YYYY-MM-DD')
    data.time = dayjs()
    data.position = options.location

    commit('setCurrent', data)
  },

  async getProjectCurrent(_, project) {
    const { startMarker, endMarker, backupLocation } = project
    let location
    if (isValidPoint(startMarker)) {
      location = geoJsonToMarker(startMarker)
    } else if (isValidPoint(endMarker)) {
      location = geoJsonToMarker(endMarker)
    } else if (isValidPoint(backupLocation)) {
      location = geoJsonToMarker(backupLocation)
    } else {
      return null
    }

    if (location) {
      const params = {
        lat: location[0],
        long: location[1],
      }

      const { data } = await axios.get('/weather/current', { params })
      if (!data) return null

      data.date = dayjs().format('YYYY-MM-DD')
      data.time = dayjs()
      data.position = location

      return data
    }

    return null
  },

  async getSupplierCurrent(_, supplier) {
    const { supplierAddressPostalCode } = supplier

    if (!isValidPostalcode(supplierAddressPostalCode)) return null

    const { data } = await axios.get('/weather/current', {
      params: {
        postalCode: supplierAddressPostalCode,
      },
    })
    if (!data) return null

    data.date = dayjs().format('YYYY-MM-DD')
    data.time = dayjs()

    return data
  },

  async getHistory({ state, commit }, options) {
    if (
      state.loaded &&
      isSameDate(options.date, state.date) &&
      isSamePosition(state.position, options.location)
    ) {
      return
    }

    const url = options.isClientDashboard
      ? '/passwordless/weather/history'
      : '/weather/history'

    const params = {
      lat: options.location[0],
      long: options.location[1],
      date: options.date,
    }

    // Client dashboard weather requires more params for passwordless auth
    if (options.isClientDashboard && options.uuid && options.token) {
      params.uuid = options.uuid
      params.token = options.token
    }

    const { data } = await axios.get(`${url}`, { params })
    if (!data) return

    data.date = dayjs(options.date).format('YYYY-MM-DD')
    data.position = options.location

    commit('setCurrent', data)
  },
}

function isSamePosition(previousPosition, newPosition) {
  if (!previousPosition || !newPosition) {
    return true
  }

  const hundredMeterPrecisionDecimal = 3

  return (
    previousPosition[0].toFixed(hundredMeterPrecisionDecimal) ===
      newPosition[0].toFixed(hundredMeterPrecisionDecimal) &&
    previousPosition[1].toFixed(hundredMeterPrecisionDecimal) ===
      newPosition[1].toFixed(hundredMeterPrecisionDecimal)
  )
}

function isSameTime(previousTime) {
  if (!previousTime) {
    return false
  }

  return dayjs().isSame(previousTime, 'hour')
}

function isSameDate(previousDate, newDate) {
  if (!previousDate) {
    return false
  }

  return dayjs(newDate).isSame(previousDate, 'day')
}

import _ from 'lodash'
import { defaultGeolocation } from './constants'
import { risk as RISK } from './enum'
import { latLng, latLngBounds } from 'leaflet'

export function defaultMapBindings() {
  return {
    zoom: 10.5,
    center: latLng(45.502461520719805, -73.47677380937172),
    bounds: latLngBounds(
      // South West corner
      latLng(45.368224142002205, -74.07155013555744),
      // North East corner
      latLng(45.636379593203564, -72.88199748318597),
    ),
  }
}

export function toCenter(points) {
  const locations = points
    .filter(isDatapointLocationValid)
    .map((p) => p.location.coordinates)

  if (locations.length === 0) {
    return defaultGeolocation
  } else {
    // Reverse Geo Json order from long, lat to LatLong, as expected by Leaflet
    const centerX = _.mean(locations.map((p) => p[1]))
    const centerY = _.mean(locations.map((p) => p[0]))

    return [centerX, centerY]
  }
}

export function datapointsCenter(datapoints) {
  if (_.isEmpty(datapoints)) {
    return defaultGeolocation
  }

  const datapointCoordinates = datapoints
    .filter(isDatapointLocationValid)
    .map((dp) => geoJsonToMarker(dp.location))

  const centerLatLngObj = latLngBounds(datapointCoordinates).getCenter()
  return [centerLatLngObj.lat, centerLatLngObj.lng]
}

export function markerToGeoJson(marker) {
  return {
    type: 'Point',
    coordinates: [marker[1], marker[0]],
  }
}

export function geoJsonToMarker(geoJsonPoint) {
  return [geoJsonPoint.coordinates[1], geoJsonPoint.coordinates[0]]
}

export function isValidPoint(geoJsonPoint) {
  return (
    geoJsonPoint?.coordinates?.length === 2 &&
    Number.isFinite(geoJsonPoint?.coordinates[0]) &&
    Number.isFinite(geoJsonPoint?.coordinates[1])
  )
}

function isDatapointLocationValid(datapoint) {
  const coords = _.get(datapoint, 'location.coordinates')

  return (
    coords &&
    Array.isArray(coords) &&
    coords.length === 2 &&
    Number.isFinite(coords[0]) &&
    Number.isFinite(coords[1])
  )
}

export function riskToHexColor(risk) {
  return risk === RISK.LR
    ? '#05A57D'
    : risk === RISK.AR
      ? '#FFBD21'
      : risk === RISK.HR
        ? '#FF6666'
        : '#0090FE'
}

import * as enums from '@/utils/enum'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['selectedDates']),

    mixin_compactions() {
      const aggregateTypes = [
        enums.datapoints.SITE_AGGREGATE_TEST,
        enums.datapoints.SITE_AGGREGATE_STAB_TEST,
      ]
      const asphaltTypes = [
        enums.datapoints.SITE_ASPHALT_COMPACTION_MTQ,
        enums.datapoints.SITE_ASPHALT_COMPACTION_CONTRACTOR,
      ]

      const validTypes = aggregateTypes.concat(...asphaltTypes)

      const filteredDatapoints = this.computedDatapoints.filter(
        (point) =>
          validTypes.includes(point.type) &&
          point.station &&
          point.compaction?.$numberDecimal &&
          point.date,
      )
      const mappedDatapoints = filteredDatapoints.map((point) => {
        const result = {
          station: point.station,
          compaction: parseFloat(point.compaction.$numberDecimal),
          date: point.date,
        }

        if (aggregateTypes.includes(point.type)) {
          result.group = `${point.aggregateGrade} ${this.$t(
            'views.dashboard.graph.aggregate',
          )}`
        } else if (asphaltTypes.includes(point.type)) {
          result.group =
            this.$t(`enums.layers.${point.layer}`) +
            ` ${this.$t('views.dashboard.graph.asphalt')}`
        }

        return result
      })

      return mappedDatapoints
    },

    mixin_compactionsForSelectedDates() {
      if (!this.selectedDates?.size) {
        return []
      }

      return this.mixin_compactions.filter((compaction) =>
        this.selectedDates.has(compaction.date),
      )
    },
  },
}

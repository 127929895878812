import '../plugins/readMdb'

/**
 * Read a file as an array buffer
 * @param file
 * @returns {Promise<unknown>}
 * @private
 */
async function readAsBuffer(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.readAsArrayBuffer(file)
  })
}

export async function romdasPreProcessing(file) {
  let iriData = []
  let mpdData = []
  let gpsData = []
  let headerDate = new Date()
  let name = new Date()
  let isReversed = false

  let buffer = await readAsBuffer(file)
  // eslint-disable-next-line no-undef
  const reader = readMdb(buffer)

  const tableNames = reader.getTableNames()
  const gpsDataTableName = tableNames.find((n) => n.includes('GPS_Processed'))
  const iriDataTableName = tableNames.find((n) => n.includes('Profiler_IRI'))
  const mpdDataTableName = tableNames.find((n) => n.includes('Profiler_MPD'))
  const headerTableName = tableNames.find((n) => n.includes('Survey_Header'))

  if (iriDataTableName) {
    iriData = reader
      .getTable(iriDataTableName)
      .getData()
      .filter((iri) => iri['CHAINAGE'] >= 0)
  }
  if (mpdDataTableName) {
    mpdData = reader
      .getTable(mpdDataTableName)
      .getData()
      .filter((mpd) => mpd['CHAINAGE'] >= 0)
  }
  if (gpsDataTableName) {
    gpsData = reader
      .getTable(gpsDataTableName)
      .getData()
      .sort((a, b) => a['CHAINAGE'] - b['CHAINAGE'])
  }
  if (headerTableName) {
    name = reader.getTable(headerTableName).getData()[0]['SURVEY_FILE']
    headerDate = new Date(
      reader.getTable(headerTableName).getData()[0]['SURVEY_DATE'],
    )
    isReversed = reader.getTable(headerTableName).getData()[0]['OTHERSIDE']
  }

  return { iriData, mpdData, gpsData, headerDate, name, isReversed }
}

<template>
  <v-wait for="isFetchingDatapoints">
    <core-spinner slot="waiting" />
    <title-bar :show-back="true">
      <ProjectSelector
        :disable-project="isProjectManager"
        class="col-auto mr-2"
        @project-change="mixin_onProjectChange"
      />
      <DateSelector class="col-3" />
    </title-bar>
    <div class="grid-container">
      <MapComponent
        class="map"
        :center="mixin_center"
        :datapoints="mapDatapoints"
        :show-expand="false"
        :section-datapoints="mixin_sectionDatapoints"
      />
      <TableDatapointComponent
        class="table"
        :datapoints="mixin_combinedDatapoints"
        @item-expanded="itemExpanded"
      />
      <ImageGroup
        class="image-group"
        :actions="false"
      />
    </div>
  </v-wait>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MapComponent from '../Charts/Maps/Widget.vue'
import TableDatapointComponent from '../Charts/Tables/DatapointGroup.vue'
import ImageGroup from '@/components/DashViews/Charts/Tables/ImageGroup.vue'
import TitleBar from '@/components/core/TitleBar'
import projectsListMixin from '@/mixins/projectsListMixin'
import siteTechMixin from '@/mixins/siteTechMixin'
import ProjectSelector from '../../core/ProjectSelector.vue'
import DateSelector from '../../core/DateSelector.vue'

export default {
  name: 'SiteTechnicianDetails',
  components: {
    MapComponent,
    TableDatapointComponent,
    ImageGroup,
    TitleBar,
    ProjectSelector,
    DateSelector,
  },
  mixins: [projectsListMixin, siteTechMixin],
  props: {
    user: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      filteredDatapoints: [],
    }
  },

  computed: {
    ...mapState(['kpi', 'idproject']),

    mapDatapoints() {
      if (this.filteredDatapoints.length) {
        return this.filteredDatapoints
      }
      return this.mixin_nonSectionDatapoints
    },
  },

  watch: {
    mixin_nonSectionDatapoints(newPoints) {
      this.$root.$emit('weatherLocationUpdated', newPoints?.[0]?.location)
    },
  },
  async created() {
    this.$wait.start('isFetchingDatapoints')
    await Promise.all([this.getDatapoints(), this.getCurrentPosition()])
    this.$wait.end('isFetchingDatapoints')
  },
  methods: {
    ...mapActions('app', ['getCurrentPosition']),

    itemExpanded(event) {
      const group = event.item
      const isExpanded = event.value

      if (isExpanded) {
        const datapointIds = group.datapoints.map((d) => d._id)
        this.filteredDatapoints = this.mixin_nonSectionDatapoints.filter((d) =>
          datapointIds.includes(d._id),
        )
      } else {
        this.filteredDatapoints = []
      }
    },
  },
}
</script>
<style scoped>
.map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 9;
}

.table {
  grid-row: 7 / span 6;
  grid-column: 1 / span 9;
}

.image-group {
  grid-row: 1 / span 12;
  grid-column: 10 / span 3;
}
</style>

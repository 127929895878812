// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import axios from 'axios'

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import vuetify from './plugins/vuetify'
import utils from './plugins/utils'
import wait from './plugins/vueWait'

import '@/styles/index.scss'

// LEAFLET
import 'leaflet/dist/leaflet.css'
// END LEAFLET

Vue.prototype.$http = axios
Vue.$router = router
Vue.prototype.$helpers = utils

// Sync store with router
sync(store, router)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  wait,
  render: (h) => h(App),
}).$mount('#app')

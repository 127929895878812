import _ from 'lodash'
import * as enums from '@/utils/enum'

export function mergeKpis(kpis = []) {
  if (!kpis.length) return undefined
  else {
    return {
      trend: _.max(kpis.map((k) => k.trend)),
      risk: _.max(kpis.map((k) => k.risk)),
      date: _.maxBy(kpis.map((k) => k.date)),
      weight: _.sumBy(kpis, 'weight'),
      score: _.sumBy(kpis, 'score'),
    }
  }
}

export function computeRiskFromWeight(kpi) {
  let risk = enums.risk.NA

  if (kpi) {
    if (kpi.weight === 0) {
      return enums.risk.NA
    }

    const result = kpi.score / kpi.weight

    if (result < 0.34) risk = enums.risk.HR
    else if (result < 0.67) risk = enums.risk.AR
    else risk = enums.risk.LR
  }

  return risk
}

<template>
  <v-card
    outlined
    :options="options"
    type="Map"
    class="fill-height"
  >
    <l-map
      ref="mMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="min-height: none; z-index: 1"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-control-layers position="bottomright"></l-control-layers>
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />
      <l-control>
        <router-link
          class="toolbar-items"
          :to="$route.path + '/details'"
        >
          <v-icon>$fas fa-expand-alt</v-icon>
        </router-link>
      </l-control>
      <l-circle
        v-for="(element, index) in shownCircles"
        :key="index"
        :lat-lng="[
          element.location.coordinates[1],
          element.location.coordinates[0],
        ]"
        :fill-color="circleColor(element)"
        :radius="circleRadius(element)"
        :color="circleColor(element)"
        :fill-opacity="0.8"
        :opacity="0.8"
        @click="innerClick(index)"
      />
    </l-map>
    <data-point-pop-up
      v-model="dialog"
      :pop-uptitle="'Data point details'"
      :data-point="clickedDatapoint"
      :data-filter-out="[
        '_id',
        'color',
        'cl_attributes',
        'asset_id',
        'group_id',
        'funccclass_id',
        'rubix_conditionclass_id',
        'sub_conditionclass_id',
        'zz_agency_cl_id',
        'ObjectIdTotalpave',
        'ObjectIdClasse1',
      ]"
      @closePopUp="closeModal"
    />
  </v-card>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LCircle,
  LControl,
  LControlLayers,
} from 'vue2-leaflet'
import DataPointPopUp from '../../PopUps/Datapoint.vue'

export default {
  name: 'MapComponent',
  components: {
    LMap,
    LCircle,
    LControl,
    LControlLayers,
    LTileLayer,
    DataPointPopUp,
  },
  props: {
    datapoints: {
      type: [Array],
      required: true,
    },
    center: {
      type: [Array],
      required: true,
    },
    circleRadius: {
      type: Function,
      required: true,
    },
    circleColor: {
      type: Function,
      required: true,
    },
    circleShow: {
      type: Function,
      required: true,
    },
    markerList: {
      type: [Array],
      required: false,
      default: function () {
        return []
      },
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    description: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      dialog: false,
      clickedDatapoint: {},
      zoom: 13,
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      tileProviders: [
        {
          name: 'Flat',
          visible: true,
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'Aerial',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          // attribution: 'Map data: &copy; <a href="https://server.arcgisonline.com/arcgis/rest/services">ArcGIS REST Services</a>, <a href="https://explor.ai">explor.ai</a> contributors',
        },
      ],
      currentZoom: 11.5,
      mapOptions: {
        zoomSnap: 0.5,
      },
      options: {
        lineSmooth: this.$chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: 50,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    }
  },
  computed: {
    shownCircles: function () {
      window.dispatchEvent(new Event('resize'))

      let circleShow = this.circleShow
      return this.datapoints.filter(function (element) {
        return circleShow(element)
      })
    },
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom
    },
    centerUpdate(center) {
      this.currentCenter = center
    },
    showLongText() {
      this.showParagraph = !this.showParagraph
    },
    innerClick(index) {
      this.clickedDatapoint = this.datapoints[index]
      this.dialog = true
    },
    closeModal() {
      this.dialog = false
    },
  },
}
</script>

<template>
  <v-wait for="isSiteForemanUpdating">
    <core-spinner slot="waiting" />
    <title-bar :show-back="back">
      <ProjectSelector
        class="col-5"
        @project-change="mixin_onProjectChange"
      />
    </title-bar>
    <div class="grid-container">
      <MapComponent
        v-if="mixin_qualityControlDatapoints"
        class="qc-map"
        :center="mixin_qualityControlCenter"
        :datapoints="mixin_qualityControlDatapoints"
        @expand="expandQualityControl"
      />
      <IriMap
        class="iri-map"
        :map-bindings="roadComfortMapBindings"
        @expand="expandRoadComfort"
      >
        <template slot="map-content">
          <iri-run :runs="mixin_iriRunGeoForSelectedDates" />
          <image-cluster :images="mixin_imagesForSelectedDates" />
        </template>
      </IriMap>
      <BarChart
        class="barChart"
        :title="$t('views.dashboard.graph.title')"
        :subtitle="$t('views.dashboard.graph.subtitle')"
        :datapoints="mixin_compactions"
      />
      <LinesScatterChart
        :id="'linesScatterChart'"
        class="scatterChart"
        :title="$t('views.dashboard.line-scatter-chart.title')"
        :line-chart-data="mixin_iriLineChartData"
        :scatter-chart-data="mixin_compactions"
      />
      <gauge-chart
        class="gauge1"
        :kpi="aggregateProductionKpi"
        :title="$t('views.dashboard.gauge.foundation')"
        gauge-identifier="gaugeIQDUG"
      />
      <gauge-chart
        class="gauge2"
        :kpi="asphaltProductionKpi"
        :title="$t('views.dashboard.gauge.asphalt')"
        gauge-identifier="gaugeIQDUM"
      />
      <gauge-chart
        class="gauge3"
        :kpi="siteControlKpi"
        :title="$t('views.dashboard.gauge.siteControl')"
        gauge-identifier="gaugeIQTTBMTC"
      />
      <gauge-chart
        class="gauge4"
        :kpi="workScoreKpi"
        :title="$t('views.dashboard.gauge.workQuality')"
        gauge-identifier="gaugeIQDDM"
      />
    </div>
  </v-wait>
</template>

<script>
import BarChart from '@/components/DashViews/Charts/Bar.vue'
import CompactionsMixin from '@/mixins/compactionsMixin'
import GaugeChart from '@/components/DashViews/Charts/Gauge.vue'
import IriMap from '@/components/DashViews/Charts/Maps/IriMap.vue'
import IriRun from '@/components/core/map/IriRun.vue'
import ImageCluster from '@/components/core/map/ImageCluster.vue'
import LinesScatterChart from '@/components/DashViews/Charts/Lines/Scatter.vue'
import MapComponent from '@/components/DashViews/Charts/Maps/Widget.vue'
import ProjectsListMixin from '@/mixins/projectsListMixin'
import SiteForemanMixin from '@/mixins/siteForemanMixin'
import TitleBar from '@/components/core/TitleBar.vue'
import ProjectSelector from '../../core/ProjectSelector.vue'

import { kpiTypes } from '@/utils/enum'
import { mergeKpis } from '@/utils/kpi'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SiteForemanDashboard',
  components: {
    BarChart,
    GaugeChart,
    ImageCluster,
    IriMap,
    IriRun,
    LinesScatterChart,
    MapComponent,
    TitleBar,
    ProjectSelector,
  },

  mixins: [CompactionsMixin, ProjectsListMixin, SiteForemanMixin],

  data() {
    return {
      details: false,
      superIntendent: false,
    }
  },

  computed: {
    ...mapState(['datapoints']),
    ...mapGetters(['kpiScore', 'lastProdKpiByNames']),

    back() {
      return Boolean(this.$route.query.showBack) || false
    },
    computedDatapoints() {
      return this.mixin_qualityControlDatapoints
    },
    aggregateProductionKpi() {
      return mergeKpis(this.lastProdKpiByNames([kpiTypes.IQDUG]))
    },
    asphaltProductionKpi() {
      return mergeKpis(this.lastProdKpiByNames([kpiTypes.IQDUM]))
    },
    siteControlKpi() {
      return this.kpiScore(kpiTypes.IQTTBMTC, 'total')
    },
    workScoreKpi() {
      return mergeKpis(
        this.lastProdKpiByNames([kpiTypes.IQDDMP, kpiTypes.IQDDGP]),
      )
    },
  },

  watch: {
    mixin_qualityControlDatapoints(newPoints) {
      this.$root.$emit('weatherLocationUpdated', newPoints?.[0]?.location)
    },
  },

  methods: {
    expandQualityControl() {
      const path = this.$router.currentRoute.path + '/quality-control'
      const query = { ...this.$router.currentRoute.query, showBack: true }
      this.$router.push({ path, query })
    },

    expandRoadComfort() {
      const path = this.$router.currentRoute.path + '/road-comfort'
      const query = { ...this.$router.currentRoute.query, showBack: true }
      this.$router.push({ path, query })
    },
  },
}
</script>

<style scoped>
.qc-map {
  grid-column: 1 / span 6;
  grid-row: 1 / span 5;
}

.iri-map {
  grid-column: 7 / span 6;
  grid-row: 1 / span 5;
}

.barChart {
  grid-column: 1 / span 4;
  grid-row: 6 / span 5;
}

.scatterChart {
  grid-column: 5 / span 8;
  grid-row: 6 / span 5;
}

.gauge1 {
  grid-column: 1 / span 3;
  grid-row: 11 / span 2;
}

.gauge2 {
  grid-column: 4 / span 3;
  grid-row: 11 / span 2;
}

.gauge3 {
  grid-column: 7 / span 3;
  grid-row: 11 / span 2;
}

.gauge4 {
  grid-column: 10 / span 3;
  grid-row: 11 / span 2;
}
</style>

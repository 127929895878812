<template>
  <v-wait for="loadingDashboard">
    <core-progress :in-progress="$wait.is('loadingMeasurement')" />
    <core-spinner slot="waiting" />
    <title-bar
      :show-create="true"
      :show-back="back"
      @create="onCreate()"
    >
      <ProjectSelector
        class="col-5"
        @project-change="mixin_onProjectChange"
      />
    </title-bar>
    <div class="grid-container">
      <IriMap
        v-model="mapBindings"
        class="map"
        :selected-section="selectedSection"
        @path:click="viewDetails"
      >
        <template #map-content>
          <iri-run :runs="iriPathGeo" />
          <section-highlight :section="selectedSectionGeo" />
        </template>
      </IriMap>
      <iri-list-table
        class="table"
        :segments="segments"
        :project-type="project?.type"
        @row:click="viewDetails"
        @section-selected="sectionSelected"
      />
    </div>
  </v-wait>
</template>

<script>
import IriMap from '@/components/DashViews/Charts/Maps/IriMap.vue'
import IriRun from '@/components/core/map/IriRun.vue'
import IriListTable from './IriListTable.vue'
import SectionHighlight from '@/components/core/map/SectionHighlight.vue'
import TitleBar from '@/components/core/TitleBar'
import ProjectSelector from '@/components/core/ProjectSelector'
import projectsListMixin from '@/mixins/projectsListMixin'

import { iriPathToFeatureCollection } from '@/utils/datapoint'
import { defaultMapBindings } from '@/utils/map'
import { mapActions, mapMutations, mapState } from 'vuex'
import { geoJSON } from 'leaflet'

export default {
  name: 'IriMeasurementListView',
  components: {
    IriMap,
    IriRun,
    IriListTable,
    SectionHighlight,
    TitleBar,
    ProjectSelector,
  },

  mixins: [projectsListMixin],

  data() {
    return {
      selectedSection: undefined,
      mapBindings: defaultMapBindings(),
    }
  },

  computed: {
    ...mapState('iri', ['segments']),
    ...mapState('images', {
      images: 'items',
    }),
    ...mapState(['project', 'idproject']),
    back() {
      return Boolean(this.$route.query.showBack) || false
    },
    iriPathGeo() {
      return iriPathToFeatureCollection(this.segments)
    },
    selectedSectionGeo() {
      if (!this.selectedSection) {
        return null
      } else {
        return {
          type: 'Feature',
          properties: {
            risk: this.selectedSection.RUni,
          },
          geometry: this.selectedSection.pathSimplified,
        }
      }
    },
  },

  watch: {
    async idproject() {
      this.$wait.start('loadingMeasurement')
      await this.updateData()
      this.$wait.end('loadingMeasurement')
    },
  },

  async created() {
    this.$wait.start('loadingDashboard')
    this.setCalibrations([])
    await this.getCalibrations({ disabled: false })
    await Promise.all([
      this.getVehicles(),
      this.getDevices(),
      this.updateData(),
    ])
    this.$wait.end('loadingDashboard')
  },

  methods: {
    ...mapActions('iri', [
      'getCalibrations',
      'getDevices',
      'getVehicles',
      'getMeasurements',
    ]),
    ...mapMutations('iri', ['setCalibrations']),
    ...mapActions(['updateDashboard', 'updateDateList']),

    async updateData() {
      await this.getMeasurements({
        idproject: this.idproject,
      })
      this.setBounds()
    },

    setBounds() {
      const bounds = geoJSON(this.iriPathGeo).getBounds()

      if (bounds && bounds.isValid()) {
        this.mapBindings.bounds = bounds
      }
    },

    onCreate() {
      const path = '/dashboard/iri-measurements-management/create'
      const query = this.$router.currentRoute.query
      this.$router.push({ path, query })
    },

    sectionSelected(item) {
      this.selectedSection = item
    },

    viewDetails(iriMeasurement) {
      this.$router.push({
        path: `/dashboard/iri-measurements/${iriMeasurement._id}`,
      })
    },
  },
}
</script>

<style scoped>
.map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 12;
}
.table {
  grid-row: 7 / span 6;
  grid-column: 1 / span 12;
}
</style>

<template>
  <material-card
    v-resize="onResize"
    outlined
    class="fill-height d-flex"
  >
    <core-callout :title="title">
      <slot />
    </core-callout>
    <div
      :id="id"
      class="graph-container"
    >
      <svg
        v-if="initialized"
        :id="svgId"
        :viewBox="[0, 0, width ? width : 0, height]"
        class="graph-svg"
      >
        <g>
          <g
            v-for="(datum, rowIndex) in stackedData"
            :key="svgId + '-row-' + rowIndex"
            :fill="color(rowIndex)"
          >
            <rect
              v-for="(d, index) in datum"
              :key="svgId + '-row-' + rowIndex + '-col-' + index"
              :x="d[1] > 0 ? xScale(d[0]) : xScale(d[1])"
              :y="yScale(index)"
              :width="Math.abs(xScale(d[0]) - xScale(d[1]))"
              :height="yScale.bandwidth()"
              :name="formattedData[index].name"
              cursor="pointer"
              @click="rowClick(formattedData[index].name)"
            />
          </g>
        </g>
        <g>
          <g
            :id="`${id}-xAxis`"
            class="xAxis"
            transform="translate(0, 15)"
          />
          <g
            :id="`${id}-yAxis`"
            :transform="`translate(${xScale(0)}, 0)`"
            class="yAxis"
          />
        </g>
      </svg>
    </div>
  </material-card>
</template>
<script>
import * as d3 from 'd3'
import resize from 'vue-resize-directive'

export default {
  name: 'VariationChart',
  directives: {
    resize,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    data: {
      type: [Array],
      required: true,
    },
    translationPath: {
      type: String,
      default: 'enums.sampleProperties',
    },
  },
  data: function () {
    return {
      initialized: false,
      chartContainer: null,
      graphFontSize: '0.4rem',
      isCreated: false,
      margin: { top: 16, right: 16, bottom: 16, left: 16 },
      yAxisScalePadding: 130,
      rowPadding: 0.5,
      rowClass: 'variationChartRow',
      subgroups: ['prepost', 'start', 'lr', 'ar', 'hr', 'post'],
      width: null,
      height: null,
      xAxisGridOpacity: 0.15,
      xAxisValuesPaddingTop: 10,
      xScale: null,
      yScale: null,
    }
  },
  computed: {
    formattedData() {
      return this.data.map((prop) => {
        prop.value = prop.value || 1
        let element = {
          id: prop.id,
          value: prop.value,
        }
        let sym = prop.value > 0 ? 1 : -1
        element.prepost = prop.value > 0 ? -100 : 100
        element.start = prop.value > 0 ? 100 : -100
        element.lr =
          Math.abs(prop.value) >= 33 ? 33 * sym : Math.abs(prop.value) * sym
        element.ar =
          Math.abs(prop.value) >= 66
            ? 33 * sym
            : Math.abs(prop.value) > 33
              ? (Math.abs(prop.value) % 33) * sym
              : 0
        element.hr =
          Math.abs(prop.value) > 66 ? (Math.abs(prop.value) % 66) * sym : 0
        element.post = Math.max(100 - Math.abs(prop.value), 0) * sym

        if (this.$te(`${this.translationPath}.${prop.name}`)) {
          element.name = this.$t(`${this.translationPath}.${prop.name}`)
        } else {
          element.name = prop.name
        }

        return element
      })
    },
    stackedData() {
      return d3.stack().keys(this.subgroups)(this.formattedData)
    },
    color() {
      return d3
        .scaleOrdinal()
        .domain(this.subgroups)
        .range([
          '#F5F5F5',
          '#F5F5F5',
          '#34D1BF',
          '#FFBD21',
          '#FF6666',
          '#F5F5F5',
        ])
    },
    svgId() {
      return `${this.id}-svg`
    },
    svg() {
      return d3.select(`#${this.svgId}`)
    },
  },
  watch: {
    data() {
      this.onDataChange()
    },
  },
  methods: {
    renderAxes() {
      // x Axis
      d3.select(`#${this.id}-xAxis`)
        .call(
          d3
            .axisBottom(this.xScale)
            .ticks(8)
            .tickSizeInner(this.height - this.margin.bottom - 20)
            .tickPadding(this.xAxisValuesPaddingTop),
        )
        .call((g) => g.select('.domain').remove())
      this.svg.selectAll('.tick line').style('opacity', this.xAxisGridOpacity)

      // y Axis
      d3.select(`#${this.id}-yAxis`)
        .call(
          d3
            .axisLeft(this.yScale)
            .tickFormat((i) => this.formattedData[i].name, true)
            .tickSize(0)
            .tickPadding((this.width - this.yAxisScalePadding) / 2),
        )
        .call((g) => g.select('.domain').style('color', '#DFDFDF'))
      this.isCreated = true
    },
    calcSize() {
      this.width = d3.select(`#${this.id}`).node().getBoundingClientRect().width
      this.height = d3
        .select(`#${this.id}`)
        .node()
        .getBoundingClientRect().height

      this.xScale = d3
        .scaleLinear()
        .domain([-100, 100])
        .rangeRound([this.yAxisScalePadding, this.width - this.margin.right])

      this.yScale = d3
        .scaleBand()
        .domain(d3.range(this.formattedData.length))
        .rangeRound([this.margin.top, this.height - this.margin.bottom])
        .padding(this.rowPadding)

      this.initialized = true
    },
    onResize() {
      this.calcSize()
      setTimeout(this.renderAxes, 0)
    },
    onDataChange() {
      if (this.isCreated) this.onResize()
    },
    rowClick(name) {
      // Find object in with the same name property in this.formattedData to access its data
      const propertyData = this.formattedData.find((data) => data.name === name)
      this.$emit('rowclick', propertyData)
    },
  },
}
</script>
<style scoped>
.xAxis {
  stroke-dasharray: 3 2;
  font-size: 0.6rem;
}
.yAxis {
  color: #9b9b9b;
  font-size: 0.6rem;
}
</style>

import { defaults } from 'lodash'

export const initialInventoryState = {
  assets: {},
  defects: {},
  selectedAssets: [],
  groupToAssetsMap: null,
}

export default defaults({}, initialInventoryState)

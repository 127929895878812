<template>
  <v-wait for="isFetchingDashboard">
    <core-spinner slot="waiting" />
    <FactorySelector
      :items="plants"
      :selected-item="selectedPlantId"
      :show-create="hasAccessToKpis"
      @factory-change="onFactoryChange"
      @create="kpiDialog = true"
    />
    <div class="grid-container production-dashboard-layout">
      <VariationChart
        id="variationChart1"
        class="variation1"
        :data="asphaltSampleProperties"
        :title="$t('views.dashboard.variation-chart.title')"
        @rowclick="onAsphaltSampleRowClick($event)"
      >
        <v-select
          :items="mixDesigns"
          :item-text="'mixDesignCode'"
          :item-value="'_id'"
          :value="selectedMixId"
          single-line
          dense
          hide-details
          class="variation-selector"
          @change="onMixDesignChange"
        />
      </VariationChart>

      <LineBoundaryChart
        id="AsphaltPropertyChart"
        class="boundary"
        :title="$t('views.dashboard.boundary-chart.title')"
        :subtitle="
          selectedSamplePropertyId
            ? $t('enums.sampleProperties.' + selectedSamplePropertyId)
            : $t('enums.sampleProperties.undefined')
        "
        :boundary-data="asphaltSamplePropertyTimeline"
        :upper-h-r="specifications[6] - specifications[3]"
        :upper-a-r="specifications[5] - specifications[3]"
        :upper-l-r="specifications[4] - specifications[3]"
        :zero-r="0"
        :lower-l-r="specifications[2] - specifications[3]"
        :lower-a-r="specifications[1] - specifications[3]"
        :lower-h-r="specifications[0] - specifications[3]"
      />

      <VariationChart
        v-if="showMaterialProperty"
        id="variationChart2"
        class="variation2"
        :data="selectedMixDesign.materialProperties"
        :title="$t('views.dashboard.variation-chart.title')"
        translation-path="enums.mixDesignMaterialProperties"
        @rowclick="onMaterialRowClick($event)"
      />

      <GaugeChart
        class="gauge1"
        :kpi="kpiScore($helpers.enums.kpiTypes.IQDDMP)"
        :title="$t('views.dashboard.gauge.siteImpact')"
        :gauge-identifier="'gaugeF'"
      />

      <GaugeChart
        class="gauge2"
        :kpi="kpiScore($helpers.enums.kpiTypes.IQTBMPE)"
        :title="$t('views.dashboard.gauge.asphalt')"
        :gauge-identifier="'gaugeE'"
      />

      <GaugeChart
        class="gauge3"
        :kpi="materialQualityKpi"
        :title="$t('views.dashboard.gauge.rawMaterials')"
        :gauge-identifier="'gaugeCC'"
      />

      <GaugeChart
        class="gauge4"
        :kpi="kpiScore($helpers.enums.kpiTypes.IQDUM)"
        :title="$t('views.dashboard.gauge.decisional')"
        :gauge-identifier="'gaugeQT'"
      />
      <v-dialog
        v-if="hasAccessToKpis"
        v-model="kpiDialog"
        scrollable
        max-width="750px"
      >
        <v-card>
          <TableKpiComponent :items="mixFilteredAsphaltKpis" />
        </v-card>
      </v-dialog>
    </div>
  </v-wait>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import VariationChart from '../Charts/Variation.vue'
import LineBoundaryChart from '../Charts/Lines/Boundary.vue'
import GaugeChart from '../Charts/Gauge.vue'
import FactorySelector from './FactorySelector.vue'
import TableKpiComponent from '@/components/DashViews/Charts/Tables/Kpi.vue'
import { mergeKpis } from '@/utils/kpi'
import productionWeatherMixin from '@/mixins/productionWeatherMixin'
import { productTypes, kpiTypes } from '@/utils/enum'

export default {
  components: {
    GaugeChart,
    FactorySelector,
    LineBoundaryChart,
    TableKpiComponent,
    VariationChart,
  },

  mixins: [productionWeatherMixin],

  provide: { type: productTypes.ASPHALT },

  props: {
    mixDesign: {
      type: String,
      default: '',
    },
    plant: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      kpiDialog: false,
    }
  },

  computed: {
    ...mapState('production', [
      'asphaltSamples',
      'mixDesigns',
      'plants',
      'selectedMixId',
      'selectedPlantId',
      'selectedSamplePropertyId',
    ]),
    ...mapGetters('production', [
      'asphaltSampleProperties',
      'asphaltSamplePropertyTimeline',
      'kpiScore',
      'mixFilteredAsphaltKpis',
      'mixFilteredMaterials',
      'mixFilteredMaterialKpis',
      'selectedMixDesign',
      'selectedPlant',
    ]),
    ...mapGetters(['hasAccessToKpis']),
    materialQualityKpi() {
      return mergeKpis(
        this.mixFilteredMaterialKpis.filter((k) => k.name === kpiTypes.IQGQMAT),
      )
    },
    specifications() {
      return (
        this.selectedMixDesign?.specifications[
          this.selectedSamplePropertyId
        ] || [-10, -7.5, -2.5, 0, 2.5, 7.5, 10]
      )
    },
    showMaterialProperty() {
      return (this.selectedMixDesign?.materialProperties?.length || 0) > 0
    },
  },

  watch: {
    async plant() {
      this.$store.commit(
        'production/selectPlant',
        this.plant || this.plants[0]._id,
      )

      this.setProductionWeather(this.selectedPlant)

      await this.$store.dispatch(
        'production/fetchProductionTechnicianDashboard',
        { plantId: this.plant || this.plants[0]._id },
      )

      if (this.mixDesigns.length > 0) {
        this.$store.dispatch('production/changeSelectedMixDesign', {
          mixDesignId: this.asphaltSamples[0].mixDesign,
        })
      }
    },
    async mixDesign() {
      this.$store.dispatch('production/changeSelectedMixDesign', {
        mixDesignId: this.mixDesign,
      })
    },
  },

  async created() {
    this.$wait.start('isFetchingDashboard')

    if (!this.plants.length > 0) {
      await this.$store.dispatch('production/fetchPlants')
    }

    if (this.plants.length > 0) {
      this.$store.commit(
        'production/selectPlant',
        this.plant || this.selectedPlantId || this.plants[0]._id,
      )
    }

    this.setProductionWeather(this.selectedPlant)

    if (!this.mixDesigns.length) {
      await this.$store.dispatch(
        'production/fetchProductionTechnicianDashboard',
        { plantId: this.selectedPlantId },
      )
    }

    if (this.mixDesigns.length) {
      if (this.mixDesign) {
        await this.$store.dispatch('production/changeSelectedMixDesign', {
          mixDesignId: this.mixDesign,
        })
      } else if (this.selectedMixId) {
        await this.$store.dispatch('production/changeSelectedMixDesign', {
          mixDesignId: this.selectedMixId,
        })
      } else if (this.asphaltSamples.length > 0) {
        await this.$store.dispatch('production/changeSelectedMixDesign', {
          mixDesignId: this.asphaltSamples[0].mixDesign,
        })
      }
    }

    this.setProductionWeather(this.selectedPlant)

    this.$wait.end('isFetchingDashboard')
  },

  methods: {
    ...mapMutations('production', ['selectAsphaltSampleProperty']),
    onAsphaltSampleRowClick(data) {
      if (this.selectedMixDesign.specifications[data.id]) {
        this.selectAsphaltSampleProperty(data.id)
      }
    },
    onMaterialRowClick(data) {
      if (
        data.id &&
        this.mixFilteredMaterials.map((m) => m._id).includes(data.id)
      ) {
        this.$store.dispatch('production/changeSelectedMaterial', {
          material: data.id,
        })
        this.$router.push({
          path: 'raw-materials',
          query: {
            plant: this.selectedPlantId,
            mixDesign: this.selectedMixId,
            material: data.id,
          },
        })
      }
    },
    onMixDesignChange(item) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { plant: this.selectedPlantId, mixDesign: item },
      })
    },
    onFactoryChange(item) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { plant: item },
      })
    },
  },
}
</script>

<template>
  <v-container
    class="login-form align-end"
    fill-height
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
        offset-md="4"
      >
        <v-card outlined>
          <v-card-title class="justify-center">
            <v-toolbar-title>
              <img
                src="/img/logo-daway.png"
                height="92px"
              />
            </v-toolbar-title>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="loginForm"
              data-form-type="login"
            >
              <v-text-field
                ref="username"
                v-model.trim="username"
                prepend-icon="$fas fa-user"
                :label="$t('forms.labels.email')"
                :rules="rules.email"
                required
                data-form-type="username"
              />
              <v-text-field
                ref="password"
                v-model.trim="password"
                :append-icon="
                  isPasswordVisible ? '$fas fa-eye' : '$fas fa-eye-slash'
                "
                :rules="rules.password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :label="$t('forms.labels.password')"
                prepend-icon="$fas fa-lock"
                data-form-type="password"
                counter
                required
                @click:append="togglePasswordVisibility"
                @keydown.enter="login"
              />
            </v-form>
          </v-card-text>
          <div class="text-center">
            <v-dialog
              v-model="dialogOpen"
              width="500"
            >
              <template #activator="{ on, attrs }">
                <a
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('views.loginForm.forgotPassword') }}
                </a>
              </template>
              <v-card>
                <v-card-title>
                  {{ $t('views.loginForm.forgottenPassword.title') }}
                </v-card-title>
                <v-card-text>
                  {{ $t('views.loginForm.forgottenPassword.text') }}
                </v-card-text>
                <v-form ref="passwordResetForm">
                  <v-text-field
                    v-model.trim="resetEmail"
                    type="email"
                    :label="$t('forms.labels.email')"
                    :rules="rules.email"
                  />
                </v-form>
                <v-divider />
                <v-card-actions>
                  <v-btn
                    color="error"
                    text
                    :disabled="cancelDisabled"
                    @click="dialogOpen = false"
                  >
                    {{ $t('views.loginForm.forgottenPassword.cancel') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="success"
                    text
                    @click="sendPasswordReset"
                  >
                    <v-wait for="isSendingResetLink">
                      <core-spinner slot="waiting" />
                      {{ $t('views.loginForm.forgottenPassword.confirm') }}
                    </v-wait>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <v-divider class="mt-5" />
          <v-card-actions>
            <v-btn
              block
              justify-center
              class="v-btn-primary"
              @click="login"
            >
              {{ $t('views.loginForm.login') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <Copyright class="mx-auto" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { rules } from '../utils/rules'

import Copyright from '@/components/core/Copyright.vue'

export default {
  components: {
    Copyright,
  },

  data: function () {
    return {
      username: '',
      password: '',
      showPassword: false,
      dialogOpen: false,
      resetEmail: '',
      cancelDisabled: false,
      isPasswordVisible: false,
      rules,
    }
  },
  computed: {
    ...mapGetters(['landingPage']),
  },

  methods: {
    ...mapActions(['forgotPassword']),
    ...mapMutations(['showSuccess', 'showError']),

    async login() {
      if (this.$refs.loginForm.validate()) {
        const username = this.username
        const password = this.password
        const res = await this.$store.dispatch('login', { username, password })
        if (res) {
          const redirectUrl = this.$route?.query?.redirectUrl
          if (redirectUrl) {
            return this.$router.replace(redirectUrl)
          }
          return this.$router.replace(this.landingPage)
        }
      }
    },

    async sendPasswordReset() {
      this.cancelDisabled = true
      this.$wait.start('isSendingResetLink')

      if (this.$refs.passwordResetForm.validate()) {
        try {
          await this.forgotPassword(this.resetEmail)
          this.dialogOpen = false
          this.showSuccess(this.$t('views.loginForm.resetLinkSuccess'))
          this.$refs.passwordResetForm.reset()
        } catch (err) {
          this.showError(this.$t('api-error.genericError'))
        }
      }

      this.cancelDisabled = false
      this.$wait.end('isSendingResetLink')
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible
    },
  },
}
</script>

import * as enums from './enum'
import { toShortDate, formatDateAsIs } from './date'

export function assemble(datapoints = []) {
  datapoints.forEach((d) => {
    d.date = d.date
      ? formatDateAsIs(new Date(d.date))
      : toShortDate(new Date(d.createdAt))
  })
}

export function computeRUni(IRI, layer, roadCategory, projectType) {
  let risk = enums.risk.NA

  if (!projectType || !layer || !Number.isFinite(IRI)) return risk

  if (projectType === enums.projectTypes.construction) {
    if (
      layer === enums.roadLayers.leveling ||
      layer === enums.roadLayers.correction ||
      layer === enums.roadLayers.coldReprocessing
    ) {
      if (IRI > 2.5) risk = enums.risk.HR
      else if (IRI >= 1.5) risk = enums.risk.AR
      else risk = enums.risk.LR
    } else if (layer === enums.roadLayers.base) {
      if (IRI > 2) risk = enums.risk.HR
      else if (IRI >= 1.2) risk = enums.risk.AR
      else risk = enums.risk.LR
    } else if (layer === enums.roadLayers.intermediate) {
      if (IRI > 1.8) risk = enums.risk.HR
      else if (IRI >= 1) risk = enums.risk.AR
      else risk = enums.risk.LR
    } else if (layer === enums.roadLayers.rolling) {
      if (IRI > 1.7) risk = enums.risk.HR
      else if (IRI >= 1) risk = enums.risk.AR
      else risk = enums.risk.LR
    }
  } else if (projectType === enums.projectTypes.maintenance) {
    if (roadCategory === enums.roadCategories.urban) {
      if (IRI > 6.1) risk = enums.risk.HR
      else if (IRI > 2.0) risk = enums.risk.AR
      else risk = enums.risk.LR
    } else if (roadCategory === enums.roadCategories.highway) {
      if (IRI > 6.5) risk = enums.risk.HR
      else if (IRI > 2.2) risk = enums.risk.AR
      else risk = enums.risk.LR
    } else if (roadCategory === enums.roadCategories.national) {
      if (IRI > 4) risk = enums.risk.HR
      else if (IRI > 2.5) risk = enums.risk.AR
      else risk = enums.risk.LR
    } else if (roadCategory === enums.roadCategories.country) {
      if (IRI > 4.5) risk = enums.risk.HR
      else if (IRI > 3) risk = enums.risk.AR
      else risk = enums.risk.LR
    } else if (roadCategory === enums.roadCategories.collector) {
      if (IRI > 5) risk = enums.risk.HR
      else if (IRI > 3.5) risk = enums.risk.AR
      else risk = enums.risk.LR
    }
  }

  return risk
}

export function fillUsers(users = []) {
  users.forEach((u) => {
    u.initials = u.name
      .match(/(^\S\S?|\s\S)?/g)
      .map((v) => v.trim())
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toLocaleUpperCase()
  })
}

export function iriRunToFeatureCollection(runs) {
  return (
    runs?.map((run) => ({
      type: 'FeatureCollection',
      features: run.iriSections?.map((iri) => ({
        type: 'Feature',
        properties: {
          risk: iri.RUni,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            iri.startLocation.coordinates,
            iri.endLocation.coordinates,
          ],
        },
      })),
    })) || []
  )
}

export function iriPathToFeatureCollection(runs) {
  return runs?.length > 0
    ? [
        {
          type: 'FeatureCollection',
          features: runs.map((run) => ({
            type: 'Feature',
            properties: {
              risk: run.RUni,
            },
            geometry: run.pathSimplified,
          })),
        },
      ]
    : []
}

export function iriBenchmarkPathToFeatureCollection(benchmarks) {
  return benchmarks?.length > 0
    ? [
        {
          type: 'FeatureCollection',
          features: benchmarks.map((benchmark) => ({
            type: 'Feature',
            properties: {
              risk: enums.risk.NA,
            },
            geometry: benchmark.path,
          })),
        },
      ]
    : []
}

export function stationToInt(station) {
  return parseInt(station.replace('+', ''))
}

export default {
  methods: {
    sortByKpiTrendAndDate(items, index, isDesc) {
      let sortableItems, unsortableItems
      const property = index[0]

      // Sort kpis by trend then date, making sure that items with no trend are always at the bottom
      if (property === 'kpi') {
        sortableItems = items.filter((item) => item[property].trend > 0)
        unsortableItems = items.filter((item) => item[property].trend === 0)
        const sorted = sortableItems.sort((a, b) => {
          const sortOrder = !isDesc[0] ? 1 : -1

          if (a[property].trend < b[property].trend) {
            return -1 * sortOrder
          } else if (a[property].trend > b[property].trend) {
            return 1 * sortOrder
          } else {
            if (a[property].date < b[property].date) {
              return 1
            } else if (a[property].date > b[property].date) {
              return -1
            } else {
              return 0
            }
          }
        })

        return sorted.concat(unsortableItems)
      }

      return this.defaultSort(items, property, isDesc[0])
    },

    sortByKpiRiskAndDate(items, index, isDesc) {
      let sortableItems, unsortableItems
      const property = index[0]

      if (property === 'kpi') {
        sortableItems = items.filter((item) => item[property].risk > 0)
        unsortableItems = items.filter((item) => item[property].risk === 0)
        const sorted = sortableItems.sort((a, b) => {
          const sortOrder = !isDesc[0] ? 1 : -1

          if (a[property].risk < b[property].risk) {
            return -1 * sortOrder
          } else if (a[property].risk > b[property].risk) {
            return 1 * sortOrder
          } else {
            if (a[property].date < b[property].date) {
              return 1
            } else if (a[property].date > b[property].date) {
              return -1
            } else {
              return 0
            }
          }
        })

        return sorted.concat(unsortableItems)
      }

      return this.defaultSort(items, property, isDesc[0])
    },

    defaultSort(items, index, isDesc) {
      return items.sort((a, b) => {
        if (!isDesc) {
          return a[index] > b[index] ? 1 : -1
        }
        return a[index] > b[index] ? -1 : 1
      })
    },
  },
}

<template>
  <v-wait for="isUpdatingGMContractor">
    <core-spinner slot="waiting" />
    <TitleBar />
    <div class="grid-container">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dataTableData"
        :search="search"
        item-key="id"
        hide-default-footer
        fixed-header
        height="100%"
        :custom-sort="customSort"
        disable-pagination
        class="dataTable v-card v-card__text v-sheet--outlined row-pointer"
        @click:row="handleRowClick"
      >
        <template #top>
          <v-card-title class="pa-0 pb-4">
            <v-select
              v-model="value"
              :label="$t('views.generalManagerContractor.headers.type')"
              hide-details
              class="custom"
              multiple
              :items="dataTypes"
              :menu-props="{ maxHeight: 'auto' }"
              prepend-inner-icon="$fas fa-filter"
            />
            <v-spacer />
            <v-text-field
              v-model="search"
              :label="$t('views.generalManagerContractor.search')"
              single-line
              hide-details
              clearable
              clear-icon="$fas fa-xmark"
              prepend-inner-icon="$fas fa-search"
            />
          </v-card-title>
        </template>
        <template #[`item.dataType`]="{ item }">
          {{ $t(`views.generalManagerContractor.types.${item.dataType}`) }}
        </template>
        <template #[`item.trend`]="{ item }">
          <TrendChip :trend="item.trend" />
        </template>
        <template #[`item.combinedIndices`]="{ item }">
          <TrendChip :risk="item.combinedIndices" />
        </template>
      </v-data-table>

      <QualityChart
        id="quality1"
        class="quality1"
        :title="$t('views.generalManagerContractor.charts.productionQuality')"
        :subtitle="qualityChartSubtitle"
        :kpis="productionKpis"
      />

      <QualityChart
        id="quality2"
        class="quality2"
        :title="$t('views.generalManagerContractor.charts.siteQuality')"
        :subtitle="qualityChartSubtitle"
        :kpis="siteKpis"
      />

      <GaugeChart
        :kpi="globalBusinessKpi"
        :title="$t('views.generalManagerContractor.charts.globalBusiness')"
        :gauge-identifier="'gauge1'"
        class="gauge1"
      />
      <GaugeChart
        :kpi="globalProductionKpi"
        :title="$t('views.generalManagerContractor.charts.globalProduction')"
        :gauge-identifier="'gauge2'"
        class="gauge2"
      />
      <GaugeChart
        :kpi="globalWorkKpi"
        :title="$t('views.generalManagerContractor.charts.globalWork')"
        :gauge-identifier="'gauge3'"
        class="gauge3"
      />
    </div>
  </v-wait>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'
import { kpiTypes } from '@/utils/enum'

import TitleBar from '@/components/core/TitleBar.vue'
import TrendChip from '@/components/DashViews/Charts/TrendChip.vue'
import GaugeChart from '@/components/DashViews/Charts/Gauge.vue'
import QualityChart from '@/components/DashViews/Charts/QualityChart.vue'

export default {
  components: {
    TitleBar,
    TrendChip,
    GaugeChart,
    QualityChart,
  },

  data() {
    return {
      selected: [],
      search: '',
      value: ['project', 'plant', 'supplier'],
      dataTypes: [
        {
          text: this.$t('views.generalManagerContractor.types.project'),
          value: 'project',
        },
        {
          text: this.$t('views.generalManagerContractor.types.plant'),
          value: 'plant',
        },
        {
          text: this.$t('views.generalManagerContractor.types.supplier'),
          value: 'supplier',
        },
      ],
      headers: [
        {
          text: this.$t('views.generalManagerContractor.headers.title'),
          align: 'start',
          value: 'title',
        },
        {
          text: this.$t('views.generalManagerContractor.headers.type'),
          value: 'dataType',
        },
        {
          text: this.$t('views.generalManagerContractor.headers.location'),
          value: 'location',
        },
        {
          text: this.$t(
            'views.generalManagerContractor.headers.combinedIndices',
          ),
          value: 'combinedIndices',
        },
        {
          text: this.$t('views.generalManagerContractor.headers.trend'),
          value: 'trend',
          align: 'center',
        },
        {
          text: this.$t('views.generalManagerContractor.headers.latestUpdate'),
          value: 'latestUpdate',
          align: 'center',
        },
        {
          text: this.$t('views.generalManagerContractor.headers.dueDate'),
          value: 'dueDate',
          align: 'center',
        },
      ],
    }
  },

  computed: {
    ...mapState(['generalManagerContractorData']),

    formattedData() {
      return this.generalManagerContractorData?.productionSites?.map((d) => ({
        id: d._id,
        title: d.title || d.supplierName || 'N/D',
        dataType: d.siteType,
        latestUpdate: d.date ? d.date : '-',
        location: d.supplierAddressCity,
        productionKpis: d.kpis
          .filter((kpi) =>
            [
              kpiTypes.IQDUG,
              kpiTypes.IQDUM,
              kpiTypes.IQEQMEL,
              kpiTypes.IQGQMAT,
              kpiTypes.RCRPIP,
              kpiTypes.IQTBMCPE,
              kpiTypes.IQTBMCC,
            ].includes(kpi.name),
          )
          .map((kpi) => ({
            ...kpi,
            result: this.formatResult(kpi.result),
          })),
        siteKpis: d.kpis
          .filter((kpi) =>
            [
              kpiTypes.IQTBMPE,
              kpiTypes.IQTBMPG,
              kpiTypes.IQTTBMTC,
              kpiTypes.IQTBMCRP,
              kpiTypes.IRLITIGE,
              kpiTypes.IQTRAVAUX,
            ].includes(kpi.name),
          )
          .map((kpi) => ({
            ...kpi,
            result: this.formatResult(kpi.result),
          })),
        combinedIndices: d.combinedKpis?.risk,
        trend: d.combinedKpis?.trend,
        date: d.combinedKpis?.date,
        dueDate: d.endDate ? dayjs(d.endDate).format('YYYY-MM-DD') : '-',
        weatherLocation: d.startMarker || d.backupLocation || null,
      }))
    },

    dataTableData() {
      return this.formattedData?.filter((data) =>
        this.value.includes(data.dataType),
      )
    },

    productionKpis() {
      if (!this.selected.length || !this.selected[0].productionKpis) {
        return []
      }
      return this.selected[0].productionKpis
    },

    siteKpis() {
      if (!this.selected.length || !this.selected[0].siteKpis) {
        return []
      }
      return this.selected[0].siteKpis
    },

    qualityChartSubtitle() {
      if (!this.selected.length || !this.selected[0].title) {
        return ''
      }
      return this.selected[0].title
    },

    globalBusinessKpi() {
      return this.roundedGlobalKpi(kpiTypes.IQGLOBALBUSINESS)
    },

    globalProductionKpi() {
      return this.roundedGlobalKpi(kpiTypes.IQGLOBALPROD)
    },

    globalWorkKpi() {
      return this.roundedGlobalKpi(kpiTypes.IQGLOBALTRAVAUX)
    },
  },

  async created() {
    this.$wait.start('isUpdatingGMContractor')
    await this.updateGMContractorDashboard()
    this.$wait.end('isUpdatingGMContractor')
  },

  methods: {
    ...mapActions(['updateGMContractorDashboard']),

    defaultSort(items, index, isDesc) {
      return items.sort((a, b) => {
        if (!isDesc) {
          return a[index] > b[index] ? 1 : -1
        }
        return a[index] > b[index] ? -1 : 1
      })
    },

    customSort(items, index, isDesc) {
      // Special sorting function to place all items with certain properties === 0 at the end, no matter if ascending or descending
      // E.g. trend === 0 means it's N/A, in which case it should be at the bottom of the sort every time
      const specialIndices = ['combinedIndices', 'trend']

      let sortableItems, unsortableItems

      if (specialIndices.includes(index[0])) {
        sortableItems = items.filter((item) => item[index[0]] > 0)
        unsortableItems = items.filter((item) => item[index[0]] === 0)
        return this.defaultSort(sortableItems, index[0], isDesc[0]).concat(
          unsortableItems,
        )
      }

      return this.defaultSort(items, index[0], isDesc[0])
    },

    handleRowClick(item) {
      if (!this.selected.length || this.selected[0].id !== item.id) {
        this.selected = [item]
        this.$root.$emit(
          'weatherLocationUpdated',
          this.selected[0].weatherLocation,
        )
      }
    },

    formatResult(result) {
      if (result === 0) {
        return 1
      }

      return result * 100
    },

    roundedGlobalKpi(kpiType) {
      const kpi = this.generalManagerContractorData?.organizationKpis?.[kpiType]
      if (kpi && Number.isFinite(kpi.score)) {
        kpi.score = Math.round(kpi.score)
        return kpi
      }
      return null
    },
  },
}
</script>

<style scoped>
.dataTable {
  grid-row: 1 / span 10;
  grid-column: 1 / span 7;
  display: flex;
  flex-direction: column;
}

.quality1 {
  grid-row: 1 / span 5;
  grid-column: 8 / span 5;
}

.quality2 {
  grid-row: 6 / span 5;
  grid-column: 8 / span 5;
}

.gauge1 {
  grid-row: 11 / span 3;
  grid-column: 1 / span 4;
}

.gauge2 {
  grid-row: 11 / span 3;
  grid-column: 5 / span 4;
}

.gauge3 {
  grid-row: 11 / span 3;
  grid-column: 9 / span 4;
}
</style>

<template>
  <material-card
    id="boundary-card"
    v-resize="onResize"
    outlined
    class="fill-height"
  >
    <div class="callout">
      <div class="subtitle-1 ml-2">
        {{ title }}
      </div>
      <div class="text-caption text--disabled ml-2">
        {{ subtitle }}
      </div>
      <div class="text-caption text--disabled ml-2">
        <a
          v-if="isTechnicalDirector"
          @click="popUpClick()"
        >
          {{ $t('views.widget.learnMore') }}
        </a>
      </div>
    </div>
    <div
      :id="id"
      ref="boundaryGraph"
      class="graph-container"
    >
      <svg
        :viewBox="viewBox"
        class="graph-svg"
      >
        <g id="gridlines">
          <g
            id="xAxisGrid"
            :transform="`translate(0, ${height - padding})`"
            class="grid"
          />
          <g
            id="yAxisGrid"
            :transform="`translate(${paddingLeft}, 0)`"
            class="grid"
          />
        </g>
        <g id="axes">
          <g
            id="xAxis"
            :transform="`translate(0, ${height - padding})`"
            class="axis"
          />
          <g
            id="yAxis"
            :transform="`translate(${paddingLeft}, 0)`"
            class="axis"
          />
        </g>
        <g id="bounds">
          <line
            v-for="(boundValue, boundName) in bounds"
            :key="`${boundName}Upper`"
            :class="boundName"
            :x1="paddingLeft"
            :y1="yScale(boundValue[0])"
            :x2="innerWidth + paddingLeft"
            :y2="yScale(boundValue[0])"
          >
            <title>{{ `${boundValue[0]} ` }}</title>
          </line>
          <line
            v-for="(boundValue, boundName) in bounds"
            :key="`${boundName}Lower`"
            :class="boundName"
            :x1="paddingLeft"
            :y1="yScale(boundValue[1])"
            :x2="innerWidth + paddingLeft"
            :y2="yScale(boundValue[1])"
          >
            <title>{{ `${boundValue[1]} ` }}</title>
          </line>
        </g>
        <path
          :d="line"
          class="path"
        />
        <g id="datapoints">
          <circle
            v-for="(d, index) in dataset"
            :key="index"
            :cx="xScale(d[0])"
            :cy="yScale(d[1])"
            :r="14 / 2.25"
            class="datapoint"
          >
            <title>{{ `${d[0]}: ${d[1]} ` }}</title>
          </circle>
        </g>
      </svg>
    </div>
    <v-dialog
      v-model="dialog"
      content-class="fill-height"
    >
      <TDProductionDialogTable />
    </v-dialog>
  </material-card>
</template>

<script>
import * as d3 from 'd3'
import resize from 'vue-resize-directive'
import { mapGetters } from 'vuex'
import { roles } from '@/utils/enum'
import TDProductionDialogTable from '../../TechnicalDirector/TDProductionDialogTable.vue'

export default {
  name: 'LineBoundaryChart',
  directives: {
    resize,
  },
  components: {
    TDProductionDialogTable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    boundaryData: {
      type: Array,
      required: true,
    },
    upperHR: {
      type: Number,
      required: true,
    },
    upperAR: {
      type: Number,
      required: true,
    },
    upperLR: {
      type: Number,
      required: true,
    },
    zeroR: {
      type: Number,
      required: true,
    },
    lowerLR: {
      type: Number,
      required: true,
    },
    lowerAR: {
      type: Number,
      required: true,
    },
    lowerHR: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      height: 0,
      width: 0,
      padding: 30,
      paddingLeft: 60,
      dialog: false,
    }
  },
  computed: {
    ...mapGetters(['userHasRole']),

    isTechnicalDirector() {
      return this.userHasRole(roles.TECHNICAL_MANAGER)
    },

    bounds() {
      return {
        highRiskBounds: [this.lowerHR, this.upperHR],
        avgRiskBounds: [this.lowerAR, this.upperAR],
        lowRiskBounds: [this.lowerLR, this.upperLR],
      }
    },
    dataset() {
      return this.boundaryData.map((v, i) => {
        return [i + 1, v]
      })
    },
    domainMin() {
      const values = this.dataset.map((d) => d[1])
      return Math.min(...values, this.lowerHR) - this.margin
    },
    domainMax() {
      const values = this.dataset.map((d) => d[1])
      return Math.max(...values, this.upperHR) + this.margin
    },
    innerWidth() {
      return this.width - this.padding - this.paddingLeft
    },
    innerHeight() {
      return this.height - this.padding * 2
    },
    xScale() {
      return d3
        .scaleLinear()
        .domain([0, this.dataset.length + 1])
        .range([this.paddingLeft, this.width - this.padding])
    },
    yScale() {
      return d3
        .scaleLinear()
        .domain([this.domainMin, this.domainMax])
        .range([this.height - this.padding, this.padding])
    },
    path() {
      return d3
        .line()
        .x((d) => this.xScale(d[0]))
        .y((d) => this.yScale(d[1]))
        .curve(d3.curveMonotoneX)
    },
    line() {
      return this.path(this.dataset)
    },
    viewBox() {
      return `0, 0, ${this.width ? this.width : 0}, ${this.height}`
    },
    margin() {
      return (this.upperHR - this.lowerHR) / 7
    },
  },
  watch: {
    upperHR() {
      this.renderAxes()
      this.renderGridlines()
    },
  },
  mounted() {
    this.width = this.$refs.boundaryGraph.clientWidth
    this.height = this.$refs.boundaryGraph.clientHeight

    this.renderAxes()
    this.renderGridlines()
  },
  methods: {
    onResize() {
      this.width = this.$refs.boundaryGraph.clientWidth
      this.height = this.$refs.boundaryGraph.clientHeight
      this.renderAxes()
      this.renderGridlines()
    },
    renderAxes() {
      // x Axis
      d3.select('#xAxis')
        .call(d3.axisBottom(this.xScale))
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())

      // y Axis
      d3.select('#yAxis')
        .call(d3.axisLeft(this.yScale))
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
    },
    renderGridlines() {
      // Vertical grid lines
      d3.select('#xAxisGrid').call(
        d3.axisBottom(this.xScale).tickSize(-this.innerHeight).tickFormat(''),
      )

      // Horizontal grid lines
      d3.select('#yAxisGrid').call(
        d3.axisLeft(this.yScale).tickSize(-this.innerWidth).tickFormat(''),
      )
    },
    popUpClick() {
      this.dialog = true
    },
  },
}
</script>
<style scoped>
h3 {
  text-align: center;
}

span {
  font-size: 0.85rem;
}
</style>

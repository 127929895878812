import { parse } from 'exifr'
import dayjs from 'dayjs'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      erroredFilesCount: 0,
      filenameErrorsCount: 0,
    }
  },

  computed: {
    ...mapState('images', ['items']),

    existingImageFilenames() {
      if (this.items) {
        return new Set(
          this.items.map((item) =>
            item.name ? item.name : item.originalFilename,
          ),
        )
      }
      return new Set()
    },

    saveDisabled() {
      return this.erroredFilesCount > 0 || this.filenameErrorsCount > 0
    },
  },

  methods: {
    filterImageFiles(files) {
      const imageFileTypes = new Set(['image/png', 'image/jpeg'])

      return files.filter((file) => {
        const fileType = file.type
        return imageFileTypes.has(fileType)
      })
    },

    async filterImagesWithLocation(files) {
      const imageFiles = this.filterImageFiles(files)
      const promises = imageFiles.map((file) => {
        return parse(file).catch((error) => {
          console.error(`Error parsing ${file}:`, error)
          return { error: true }
        })
      })

      this.$wait.start('loading')
      const results = await Promise.allSettled(promises)
      this.$wait.end('loading')

      this.erroredFilesCount = 0
      this.filenameErrorsCount = 0
      const filesWithLocationData = []
      const duplicateFilenames = []

      results.forEach((result, index) => {
        if (this.existingImageFilenames.has(files[index].name)) {
          this.filenameErrorsCount++
          duplicateFilenames.push(files[index].name)
        } else if (
          result.status === 'fulfilled' &&
          result.value &&
          result.value.latitude &&
          result.value.longitude
        ) {
          if (!this.imagesDate && result.value.DateTimeOriginal) {
            this.imagesDate = dayjs(result.value.DateTimeOriginal).format(
              'YYYY-MM-DD',
            )
          }

          filesWithLocationData.push({
            file: files[index],
            metadata: result.value,
          })
        } else {
          this.erroredFilesCount++
        }
      })

      if (this.erroredFilesCount || this.filenameErrorsCount) {
        const sum = this.erroredFilesCount + this.filenameErrorsCount
        this.showError(
          this.$t('views.image-upload.readError', {
            count: sum,
            filenames: duplicateFilenames.length
              ? duplicateFilenames.join(', ')
              : this.$t('views.image-upload.notApplicable'),
          }),
        )
      }

      return filesWithLocationData
    },

    async saveImages() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.$wait.start('loading')

      try {
        const multipartForm = this.createMultipartForm()
        await this.uploadImages(multipartForm)
        window.location.reload()
      } catch (error) {
        this.showError(this.$t('views.image-upload.saveError'))
      } finally {
        this.$wait.end('loading')
      }
    },
  },
}

import i18n from '../i18n'

/**
 * Convert an enum object to items for a selection list.
 * @param {Object.<string, string>} enumObject Enum object
 * @param {string} i18nPath Path to the enum translations (ex: "enums.layers")
 * @returns {{text: string, value: string }[]}
 */
export function toSelectItems(enumObject, i18nPath) {
  return Object.values(enumObject).map((val) => ({
    value: val,
    text: i18n.t(`${i18nPath}.${val}`),
  }))
}

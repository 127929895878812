import * as date from '@/utils/date'
import * as enums from '@/utils/enum'
import * as format from '@/utils/format'
import * as kpi from '@/utils/kpi'
import * as map from '@/utils/map'

export default {
  date,
  enums,
  format,
  kpi,
  map,
}

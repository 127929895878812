<template>
  <v-select
    v-model="selectedGranularity"
    :items="items"
    :label="$t('core.titlebar.granularitySelector')"
    class="custom"
    hide-details
  />
</template>

<script>
import { IRIGranularities } from '@/utils/enum'
import { mapState, mapMutations } from 'vuex'
import { toSelectItems } from '@/utils/i18n'

export default {
  computed: {
    ...mapState('iri', ['granularity']),

    items() {
      return toSelectItems(IRIGranularities, 'enums.IRIGranularities')
    },

    selectedGranularity: {
      get() {
        return this.granularity
      },
      set(granularity) {
        this.setGranularity(granularity)
      },
    },
  },

  methods: {
    ...mapMutations('iri', ['setGranularity']),
  },
}
</script>

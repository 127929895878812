import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

export function faLibrairyToVuetifyIconProps() {
  const iconSet = {}

  for (let style in library.definitions) {
    for (let icon in library.definitions[style]) {
      const iconName = style + ' fa-' + icon
      iconSet[iconName] = {
        component: 'font-awesome-icon',
        props: {
          icon: [style, icon],
        },
      }
    }
  }

  return iconSet
}

Vue.component('FontAwesomeIcon', FontAwesomeIcon) // Register component globally
library.add(fas, far, fab) // Include needed icons

export default {
  iconfont: 'faSvg',
  values: {
    ...faLibrairyToVuetifyIconProps(),
  },
}

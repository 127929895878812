export function toExpand(e) {
  var col = e.target.closest('[class^=col]')
  col.classList = 'col-12 v-expanded'

  var row = col.parentNode
  var children = Array.from(row.children)
  children.splice(Array.prototype.indexOf.call(row.children, col), 1)
  children = children.filter((x) => !x.classList.contains('v-expanded'))

  for (let element of children) {
    if (window.innerWidth > 1264) {
      element.classList = 'col-' + 12 / children.length
    } else {
      element.classList = 'col-12'
    }
  }

  const btn = e.target.closest('button')

  btn.removeEventListener('click', toExpand)
  btn.addEventListener('click', toCompress)
}

export function toCompress(e) {
  var col = e.target.closest('[class^=col]')
  col.classList.remove('v-expanded')

  var row = col.parentNode
  var children = Array.from(row.children)
  children = children.filter((x) => !x.classList.contains('v-expanded'))

  for (let element of children) {
    element.classList.add('col-lg-' + 12 / children.length)
  }

  const btn = e.target.closest('button')

  btn.removeEventListener('click', toCompress)
  btn.addEventListener('click', toExpand)
}

<template>
  <v-expansion-panels flat>
    <v-expansion-panel>
      <v-expansion-panel-header
        class="px-0"
        @click="panel = !panel"
        >{{ $t('views.inventory.sectionDetails') }}</v-expansion-panel-header
      >
      <v-expansion-panel-content>
        <v-form ref="form">
          <v-text-field
            v-model="roadWidth"
            class="px-0"
            :label="$t('enums.headers.roadWidth')"
            type="number"
            :rules="[roadWidthRule]"
            hide-details="auto"
          />
          <v-text-field
            v-model="shoulderWidth"
            class="px-0"
            :label="$t('enums.headers.shoulderWidth')"
            type="number"
            :rules="[shoulderWidthRule]"
            hide-details="auto"
          />
          <v-text-field
            v-model="bikeLaneWidth"
            class="px-0"
            :label="$t('enums.headers.bikeLaneWidth')"
            type="number"
            :rules="[shoulderWidthRule]"
            hide-details="auto"
          />

          <v-btn
            :block="isCardExpanded"
            :disabled="disabled"
            :loading="loading"
            elevation="1"
            color="success"
            class="mr-6 mt-3"
            @click="save"
            >{{ $t('views.inventory.save') }}</v-btn
          >
          <v-btn
            :block="isCardExpanded"
            :disabled="disabled"
            :loading="loading"
            elevation="1"
            color="success"
            class="mr-6 mt-3"
            @click="saveAndApply"
            >{{ $t('views.inventory.saveAndApply') }}</v-btn
          >
          <v-btn
            :block="isCardExpanded"
            :disabled="disabled"
            :loading="loading"
            elevation="1"
            color="success"
            class="mr-6 mt-3"
            @click="saveForMeasurement"
            >{{ $t('views.inventory.saveForMeasurement') }}</v-btn
          >
          <v-btn
            :block="isCardExpanded"
            elevation="1"
            color="warning"
            class="mr-6 mt-3"
            @click="reset"
            >{{ $t('views.inventory.reset') }}</v-btn
          >
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import { isValidNumber } from '@/utils/math'

export default {
  data() {
    return {
      panel: false,
      roadWidth: null,
      shoulderWidth: null,
      bikeLaneWidth: null,
      loading: false,
      applyNext: false,
    }
  },

  computed: {
    ...mapGetters(['isCardExpanded']),
    ...mapState('iri', ['segment']),
    ...mapState('images', ['currentImage']),

    currentImageId() {
      return this.currentImage?._id
    },

    disabled() {
      if (this.loading || !this.currentImageId) {
        return true
      }

      const hasEmpty = [
        this.roadWidth,
        this.shoulderWidth,
        this.bikeLaneWidth,
      ].some((value) => !value)

      return hasEmpty
    },
  },

  watch: {
    currentImage(newImage, oldImage) {
      if (newImage && !oldImage) {
        this.init()
      } else if (newImage._id !== oldImage._id) {
        this.init()
      }
    },
  },

  methods: {
    ...mapActions('iri', ['patchMeasurement']),
    ...mapActions('images', ['patchImage']),

    roadWidthRule(value) {
      if (this.panel && !isValidNumber(value, 1, 100, 1)) {
        return this.$t('api-error.iri.roadWidth-error')
      }
      return true
    },

    shoulderWidthRule(value) {
      if (this.panel && !isValidNumber(value, 0, 10, 1)) {
        return this.$t('api-error.iri.shoulderWidth-error')
      }
      return true
    },

    bikeLaneWidthRule(value) {
      if (this.panel && !isValidNumber(value, 0, 10, 1)) {
        return this.$t('api-error.iri.shoulderWidth-error')
      }
      return true
    },

    async save() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true
      await this.patchImage({
        id: this.currentImageId,
        payload: {
          roadWidth: Number(this.roadWidth),
          shoulderWidth: Number(this.shoulderWidth),
          bikeLaneWidth: Number(this.bikeLaneWidth),
        },
      })
      this.loading = false
    },

    async saveAndApply() {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.applyNext = true
      await this.save()
      this.$emit('next')
    },

    async saveForMeasurement() {
      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true

      await Promise.all([
        this.patchImage({
          id: this.currentImageId,
          payload: {
            roadWidth: Number(this.roadWidth),
            shoulderWidth: Number(this.shoulderWidth),
            bikeLaneWidth: Number(this.bikeLaneWidth),
          },
        }),
        this.patchMeasurement({
          id: this.segment._id,
          payload: {
            roadWidth: Number(this.roadWidth),
            shoulderWidth: Number(this.shoulderWidth),
            bikeLaneWidth: Number(this.bikeLaneWidth),
          },
        }),
      ])
      this.loading = false
    },

    reset() {
      this.init()
      this.$refs.form.resetValidation()
    },

    init() {
      if (this.applyNext) {
        this.applyNext = false
        return
      }

      if (this.currentImage?.roadWidth) {
        this.roadWidth = this.currentImage.roadWidth.toString()
        this.shoulderWidth = this.currentImage.shoulderWidth.toString()
        this.bikeLaneWidth = this.currentImage.bikeLaneWidth.toString()
      } else if (this.segment?.roadWidth) {
        this.roadWidth = this.segment.roadWidth.toString()
        this.shoulderWidth = this.segment.shoulderWidth.toString()
        this.bikeLaneWidth = this.segment.bikeLaneWidth.toString()
      } else {
        this.panel = false
        this.roadWidth = null
        this.shoulderWidth = null
        this.bikeLaneWidth = null
      }
    },
  },
}
</script>

<template>
  <v-wait for="isLoadingMap">
    <core-spinner slot="waiting" />
    <v-card
      outlined
      type="Map"
      class="fill-height"
      style="height: 50vh"
    >
      <l-map
        ref="projectMap"
        :zoom="mapMixin_zoom"
        :center="center"
        :options="mapMixin_options"
        @dblclick="onDoubleClick"
        @ready="mapMixin_onReady(mapRef)"
      >
        <l-tile-layer
          :url="mapMixin_url"
          :attribution="mapMixin_attribution"
        />
        <l-marker
          :lat-lng="endMarker"
          draggable
          @dragend="updateEndMarker"
        >
          <l-icon
            :icon-anchor="[13, 41]"
            :tooltip-anchor="[15, -30]"
            icon-url="/img/red-marker-icon.png"
            icon-retina-url="/img/red-marker-icon-2x.png"
            shadow-url="/img/marker-shadow.png"
          />
          <l-tooltip>{{ $t('views.project.endTooltip') }} </l-tooltip>
        </l-marker>
        <l-marker
          :lat-lng="startMarker"
          draggable
          @dragend="updateStartMarker"
        >
          <l-icon
            :icon-anchor="[13, 41]"
            :tooltip-anchor="[15, -30]"
            icon-url="/img/marker-icon.png"
            icon-retina-url="/img/marker-icon-2x.png"
            shadow-url="/img/marker-shadow.png"
          />
          <l-tooltip>{{ $t('views.project.startTooltip') }}</l-tooltip>
        </l-marker>
      </l-map>
    </v-card>
  </v-wait>
</template>

<script>
import { LMap, LTileLayer, LMarker, LTooltip, LIcon } from 'vue2-leaflet'
import mapMixin from '@/mixins/mapMixin'
import { mapMutations, mapState } from 'vuex'
import { defaultGeolocation } from '@/utils/constants'
import { geoJsonToMarker } from '@/utils/map'
import { latLngBounds } from 'leaflet'
import { isEqual } from 'lodash'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon,
  },

  mixins: [mapMixin],

  props: {
    startMarker: {
      type: Array,
      default() {
        return []
      },
    },

    endMarker: {
      type: Array,
      default() {
        return []
      },
    },
  },

  computed: {
    ...mapState(['project']),

    center() {
      const avgLat = (this.startMarker[0] + this.endMarker[0]) / 2
      const avgLng = (this.startMarker[1] + this.endMarker[1]) / 2
      return [avgLat, avgLng]
    },

    mapRef() {
      return this.$refs.projectMap
    },
  },

  mounted() {
    // Adjust zoom to keep both markers in view
    const bounds = latLngBounds([this.startMarker, this.endMarker]).pad(0.1)
    this.mapRef.fitBounds(bounds)
  },

  created() {
    this.$wait.start('isLoadingMap')
    if (
      !isEqual(this.startMarker, defaultGeolocation) &&
      !isEqual(this.endMarker, defaultGeolocation)
    ) {
      this.showSuccess(this.$t('views.project.projectLocationSuccess'))
    } else if (this.project?.backupLocation) {
      this.centerMapOnBackupLocation()
    } else {
      this.centerMapOnUser()
    }
    this.$wait.end('isLoadingMap')
  },

  methods: {
    ...mapMutations(['showDanger', 'showSuccess']),

    updateStartMarker(event) {
      const location = [event.target._latlng.lat, event.target._latlng.lng]
      this.$emit('startMarkerUpdated', location)
    },

    updateEndMarker(event) {
      const location = [event.target._latlng.lat, event.target._latlng.lng]
      this.$emit('endMarkerUpdated', location)
    },

    onDoubleClick(position) {
      const location = [position.latlng.lat, position.latlng.lng]
      this.$emit('startMarkerUpdated', location)
      this.$emit('endMarkerUpdated', location)
    },

    onSearch(position) {
      const location = [position.location.y, position.location.x]
      this.$emit('startMarkerUpdated', location)
      this.$emit('endMarkerUpdated', location)
    },

    centerMapOnUser() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = [position.coords.latitude, position.coords.longitude]
          this.$emit('startMarkerUpdated', location)
          this.$emit('endMarkerUpdated', location)
          this.showSuccess(this.$t('views.project.userLocationSuccess'))
        },
        (error) => {
          this.showDanger(
            `${this.$t('views.project.userLocationError')} ${error.code}: ${
              error.message
            }`,
          )
          this.$emit('startMarkerUpdated', defaultGeolocation)
          this.$emit('endMarkerUpdated', defaultGeolocation)
        },
        { enableHighAccuracy: false, maximumAge: 1200, timeout: 10000 },
      )
    },

    centerMapOnBackupLocation() {
      const location = geoJsonToMarker(this.project.backupLocation)
      this.$emit('startMarkerUpdated', location)
      this.$emit('endMarkerUpdated', location)
      this.showSuccess(this.$t('views.project.backupLocationSuccess'))
    },
  },
}
</script>

<template>
  <v-wait for="loadingDashboard">
    <core-spinner slot="waiting" />
    <title-bar
      :show-back="true"
      :show-title="false"
      :custom-title="segment?.name"
    >
      <GranularitySelector class="col-3" />
    </title-bar>
    <div class="grid-container">
      <iri-map
        v-model="mapBindings"
        class="map"
      >
        <template #map-content>
          <iri-run :runs="iriMixin_iriRunGeo" />
          <section-highlight :section="iriMixin_selectedSectionGeo" />
          <image-cluster
            :images="iriMixin_orderedImages"
            @click:image="imageSelected"
          />
        </template>
      </iri-map>
      <iri-detail-table
        class="table"
        :segment="iriRun"
        :show-image-btn="images.length > 0"
        @section-selected="iriMixin_sectionSelected"
        @click:image="imageSelected"
      />
      <v-dialog
        v-model="open"
        eager
        width="95%"
        content-class="flex"
      >
        <ImageViewer
          ref="imageViewer"
          @close="closeImageViewer"
        />
      </v-dialog>
    </div>
  </v-wait>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import ImageCluster from '@/components/core/map/ImageCluster.vue'
import ImageViewer from '@/components/DashViews/PopUps/ImageViewer'
import IriMap from '@/components/DashViews/Charts/Maps/IriMap'
import IriRun from '@/components/core/map/IriRun.vue'
import IriDetailTable from './IriDetailTable.vue'
import SectionHighlight from '@/components/core/map/SectionHighlight.vue'
import TitleBar from '@/components/core/TitleBar'
import GranularitySelector from '@/components/core/GranularitySelector.vue'

import iriMixin from '@/mixins/iriMixin'
import { IRIGranularities } from '@/utils/enum'
import { defaultMapBindings } from '@/utils/map'

export default {
  name: 'IriMeasurementDetailsView',

  components: {
    ImageCluster,
    ImageViewer,
    IriMap,
    IriDetailTable,
    IriRun,
    SectionHighlight,
    TitleBar,
    GranularitySelector,
  },

  mixins: [iriMixin],

  props: {
    measurementId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedSection: undefined,
      mapBindings: defaultMapBindings(),
      open: false,
    }
  },

  computed: {
    ...mapGetters('iri', ['contain10mSection']),
    ...mapState('iri', ['granularity', 'segment']),
    ...mapState('images', {
      images: 'items',
    }),

    iriRun() {
      return this.segment
        ? {
            ...this.segment,
            iriSections:
              this.granularity === IRIGranularities.TEN_METERS &&
              this.segment.iriSections10m.length > 0
                ? this.segment.iriSections10m
                : this.segment.iriSections,
          }
        : null
    },
  },

  async created() {
    this.$wait.start('loadingDashboard')
    this.setCalibrations([])
    await this.getCalibrations({ disabled: false })
    await Promise.all([
      this.getMeasurement({
        measurementId: this.measurementId,
      }),
      this.getVehicles(),
      this.getDevices(),
    ])
    this.iriMixin_setBounds()
    this.$wait.end('loadingDashboard')
  },

  methods: {
    ...mapActions('iri', [
      'getCalibrations',
      'getDevices',
      'getVehicles',
      'getMeasurement',
    ]),
    ...mapMutations('iri', ['setCalibrations']),

    imageSelected(item) {
      if (item) {
        this.$refs.imageViewer.openMultiple(
          this.iriMixin_orderedImages,
          this.iriMixin_orderedImages.findIndex((i) => i._id === item._id),
        )
        this.open = true
      } else {
        this.$refs.imageViewer.openMultiple(this.iriMixin_orderedImages, 0)
        this.open = true
      }
    },

    closeImageViewer() {
      this.open = false
    },
  },
}
</script>

<style scoped>
.granularity {
  grid-column: 9 / span 4;
}
.map {
  grid-row: 1 / span 6;
  grid-column: 1 / span 12;
}
.table {
  grid-row: 7 / span 6;
  grid-column: 1 / span 12;
}
</style>

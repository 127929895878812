export function supplier(supplier) {
  return (
    supplier.supplierNumber +
    (supplier.supplierName ? ` - ${supplier.supplierName}` : '') +
    (supplier.supplierAddressCity ? ` - ${supplier.supplierAddressCity}` : '')
  )
}

export function supplierName(supplier) {
  const { supplierNumber, supplierName } = supplier
  return supplierNumber + (supplierName ? ` - ${supplierName}` : '')
}

<template>
  <v-card
    outlined
    class="pa-0 fill-height"
  >
    <l-map
      ref="mMap"
      :zoom.sync="zoom"
      :center="center"
      :options="mapOptions"
      class="leaflet-map"
      @update:center="centerUpdate"
      @update:bounds="onMapBoundsUpdate"
    >
      <l-control-layers position="bottomright" />
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :options="tileOptions"
        layer-type="base"
      />
      <l-control v-if="showExpand">
        <v-btn
          text
          class="toolbar-items"
          @click="onExpandClick"
        >
          <v-icon>$fas fa-expand-alt</v-icon>
        </v-btn>
      </l-control>
      <slot />
      <l-marker
        v-if="isUserMarkerVisible"
        :lat-lng="localisation"
      >
        <l-icon>
          <v-icon color="primary"> $fas fa-location-arrow </v-icon>
        </l-icon>
      </l-marker>
      <template v-if="starts.length > 0">
        <l-marker
          v-for="(element, index) in starts"
          :key="'start-icon-' + index"
          :lat-lng="[element[0], element[1]]"
        >
          <l-icon :icon-anchor="[4, 24]">
            <v-icon color="black"> $fas fa-flag </v-icon>
          </l-icon>
        </l-marker>
      </template>
      <template v-if="lines.length > 0">
        <l-polyline
          v-for="(element, index) in lines"
          :key="'calibration-line-' + index"
          :lat-lngs="element"
          color="#0090FE"
        />
      </template>
      <l-marker-cluster
        ref="markerClusterRef"
        :options="clusterOptions"
        @ready="onReady"
      >
        <datapoint-marker
          v-for="(datapoint, index) in combinedDatapoints"
          :key="datapoint._id"
          :datapoint="datapoint"
          :datapoint-index="index"
          @popupActivated="popUpClick(index)"
          @markerClicked="centerAndZoomOnDatapoint(index)"
        />
      </l-marker-cluster>
    </l-map>
    <data-point-pop-up
      v-model="dialog"
      :data-point="clickedDatapoint"
      :data-filter-out="[
        '__v',
        'color',
        'cl_attributes',
        'asset_id',
        'group_id',
        'funccclass_id',
        'rubix_conditionclass_id',
        'sub_conditionclass_id',
        'zz_agency_cl_id',
        'ObjectIdTotalpave',
        'ObjectIdClasse1',
        'icon',
        'datapoints',
      ]"
      @closePopUp="closeModal"
    />
  </v-card>
</template>

<script>
import { divIcon } from 'leaflet'
import { mapState } from 'vuex'
import {
  LControl,
  LControlLayers,
  LIcon,
  LMap,
  LMarker,
  LPolyline,
  LTileLayer,
} from 'vue2-leaflet'
import LMarkerCluster from 'vue2-leaflet-markercluster'
import DataPointPopUp from '../../PopUps/Datapoint.vue'
import DatapointMarker from './DatapointMarker.vue'
import * as enums from '@/utils/enum'
import { geoJsonToMarker } from '@/utils/map'
import { isEmpty, isEqual } from 'lodash'
import { latLngBounds, Marker, MarkerCluster } from 'leaflet'

export default {
  name: 'MapComponent',
  components: {
    LControl,
    LControlLayers,
    LIcon,
    LMap,
    LMarker,
    LPolyline,
    LTileLayer,
    DataPointPopUp,
    LMarkerCluster,
    DatapointMarker,
  },

  props: {
    datapoints: {
      type: [Array],
      required: true,
    },
    sectionDatapoints: {
      type: [Array],
      required: false,
      default: () => [],
    },
    center: {
      type: [Array],
      required: true,
    },
    lines: {
      type: Array,
      default: () => [],
      required: false,
    },
    starts: {
      type: Array,
      default: () => [],
      required: false,
    },
    showExpand: {
      type: Boolean,
      default: true,
    },
    showUserPosition: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      clickedDatapoint: {},
      zoom: 10,
      tileProviders: [
        {
          name: 'Flat',
          visible: true,
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'Aerial',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        },
      ],
      tileOptions: {
        maxZoom: 20,
        maxNativeZoom: 18,
      },
      mapOptions: {
        zoomSnap: 0.5,
      },
      clusterOptions: {
        singleMarkerMode: true,
        iconCreateFunction: this.clusterGroupIcon,
        removeOutsideVisibleBounds: true,
        zoomToBoundsOnClick: true,
        maxClusterRadius: 10,
      },
    }
  },

  computed: {
    ...mapState('app', ['isUserLocated', 'localisation']),

    isUserMarkerVisible() {
      return this.showUserPosition && this.isUserLocated
    },

    combinedDatapoints() {
      return this.datapoints.concat(this.sectionDatapoints)
    },
  },

  watch: {
    combinedDatapoints(newPoints, oldPoints) {
      if (!isEqual(newPoints, oldPoints)) {
        this.fitDatapointBounds(newPoints)
      }
    },
  },

  mounted() {
    this.fitDatapointBounds(this.combinedDatapoints)
  },

  methods: {
    onReady() {
      this.$emit('ready')

      if (this.$refs.mMap) {
        this.$refs.mMap.mapObject.invalidateSize()
      }
    },

    centerUpdate(center) {
      this.currentCenter = center
      this.$emit('center:update', {
        center,
        zoomLevel: this.zoom,
      })
    },

    async onMapBoundsUpdate() {
      if (!this.$refs.mMap) return

      const markers = []

      this.$refs.mMap.mapObject.eachLayer((l) => {
        const isMarker = l instanceof Marker && l.options.point
        const isCluster = l instanceof MarkerCluster

        if (!isMarker && !isCluster) {
          return
        }

        let childMarkers = null
        if (typeof l.getAllChildMarkers === 'function') {
          childMarkers = l.getAllChildMarkers()
        }

        const markerObject = {
          marker: l,
          points: childMarkers
            ? childMarkers.map((_l) => _l.options.point)
            : [l.options.point],
        }

        markers.push(markerObject)
      })

      this.$root.$emit('boundsUpdated', {
        markers,
        map: this.$refs.mMap.mapObject,
      })
    },

    popUpClick(index) {
      this.clickedDatapoint = this.combinedDatapoints[index]
      this.dialog = true
    },

    clusterGroupIcon(cluster) {
      const risk = Math.max(
        ...cluster.getAllChildMarkers().map((p) => p.options?.point?.risk || 0),
      )
      return this.getIconFromRisk(risk, cluster.getChildCount())
    },

    markerIcon(point) {
      return this.getIconFromRisk(point.risk)
    },

    getIconFromRisk(risk, count, externalIdentifier) {
      let className = 'map_marker'

      switch (risk) {
        case enums.risk.AR:
          className += ' map_marker_yellow'
          break
        case enums.risk.HR:
          className += ' map_marker_red'
          break
        case enums.risk.LR:
          className += ' map_marker_green'
          break
        default:
          className += ' map_marker_gray'
          break
      }

      if (count > 1) {
        return divIcon({
          className: className,
          iconSize: [60, 60],
          iconAnchor: [32, 32],
          html: `<b>${count}</b>` || '',
        })
      }

      return divIcon({
        className: className,
        iconSize: [32, 32],
        iconAnchor: [16, 16],
        html: `<span attr-ex="${externalIdentifier}"></span>`,
      })
    },

    closeModal() {
      this.dialog = false
    },

    onExpandClick() {
      this.$emit('expand')
    },

    fitDatapointBounds(datapoints) {
      if (!isEmpty(datapoints)) {
        const shownPointLocations = datapoints.map((point) =>
          geoJsonToMarker(point.location),
        )
        this.sectionDatapoints.forEach((point) => {
          shownPointLocations.push(geoJsonToMarker(point.location))
        })
        const bounds = latLngBounds([shownPointLocations]).pad(0.1)
        this.$refs.mMap.fitBounds(bounds)
      }
    },

    centerAndZoomOnDatapoint(index) {
      const datapoint = this.combinedDatapoints[index]
      const location = geoJsonToMarker(datapoint.location)
      this.$refs.mMap.mapObject.setView(location, Math.max(18, this.zoom))
    },
  },
}
</script>

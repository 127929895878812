<template>
  <l-marker-cluster :options="clusterOptions">
    <l-marker
      v-for="marker in markers"
      :key="marker.id"
      :lat-lng="marker.latLng"
      :icon="markerIcon(marker)"
      @mouseover="openPopup($event)"
      @mouseout="closePopup($event)"
      @click="() => $emit('marker-click', marker.project._id, marker.user._id)"
    >
      <l-popup>
        <ul>
          <li>
            <span class="font-weight-bold"
              >{{ $t('views.project-manager.popup.user') + ':' }}
            </span>
            <span>
              {{ marker.user.name }}
            </span>
          </li>
          <li>
            <span class="font-weight-bold"
              >{{ $t('views.project-manager.popup.score') + ':' }}
            </span>
            <span>
              {{
                marker.score >= 0
                  ? marker.score
                  : $t('views.project-manager.popup.not-available')
              }}
            </span>
          </li>
          <li>
            <span class="font-weight-bold"
              >{{ $t('views.project-manager.popup.project') + ':' }}
            </span>
            <span>
              {{ `${marker.project.title} - ${marker.project.client}` }}
            </span>
          </li>
        </ul>
      </l-popup>
    </l-marker>
  </l-marker-cluster>
</template>

<script>
import LMarkerCluster from 'vue2-leaflet-markercluster'
import { LMarker, LPopup } from 'vue2-leaflet'
import { divIcon } from 'leaflet'
import { colorHexValues, colorMinimum } from '@/utils/constants'

export default {
  name: 'TechnicianMarkers',

  components: {
    LMarker,
    LPopup,
    LMarkerCluster,
  },

  props: {
    markers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      clusterOptions: {
        iconCreateFunction: this.clusterGroupIcon,
        removeOutsideVisibleBounds: true,
        spiderfyOnMaxZoom: true,
        zoomToBoundsOnClick: true,
        maxClusterRadius: 60,
      },
      iconSize: 50,
    }
  },

  methods: {
    clusterGroupIcon(cluster) {
      return divIcon({
        className: 'map_marker map_marker_gray',
        iconSize: [60, 60],
        iconAnchor: [32, 32],
        html: `<b>${cluster.getChildCount()}</b>` || '',
      })
    },

    openPopup(event) {
      const marker = event.target
      marker.openPopup()
    },

    closePopup(event) {
      const marker = event.target
      marker.closePopup()
    },

    markerIcon(marker) {
      const { initials } = marker.user
      const score = marker.score

      const svgHTML = this.createSvgIcon(score, initials)

      return divIcon({
        className: '',
        html: svgHTML,
        iconSize: [this.iconSize, this.iconSize],
      })
    },

    createSvgIcon(score, initials, textColor = 'white', size = this.iconSize) {
      const baseSize = 50
      const scale = size / baseSize
      const outerRingRadius = 22 * scale
      const strokeWidth = 2 * scale
      const gap = 2 * scale
      const radius = outerRingRadius - strokeWidth - gap
      const fontSize = 12 * scale
      const circumference = 2 * Math.PI * outerRingRadius
      const offset = -(circumference * ((100 - score) / 100))

      let borderColor = colorHexValues.red
      let outerRingColor = colorHexValues.lightRed

      if (score >= colorMinimum.green) {
        borderColor = colorHexValues.green
        outerRingColor = colorHexValues.lightGreen
      } else if (score >= colorMinimum.yellow) {
        borderColor = colorHexValues.yellow
        outerRingColor = colorHexValues.lightYellow
      } else if (score < 0) {
        borderColor = colorHexValues.grey
        outerRingColor = colorHexValues.lightGrey
      }

      const svgHTML = `
        <svg width="${size}" height="${size}" viewPort="0 0 ${size} ${size}" version="1.1" xmlns="http://www.w3.org/2000/svg" style="shape-rendering: geometricPrecision;">
          <circle cx="${size / 2}" cy="${size / 2}" r="${outerRingRadius}" fill="transparent" stroke="${outerRingColor}" stroke-width="${strokeWidth}"/>
          <circle cx="${size / 2}" cy="${size / 2}" r="${outerRingRadius}" fill="transparent" stroke="${borderColor}" stroke-width="${strokeWidth}" stroke-linecap="round"
            stroke-dasharray="${circumference}" stroke-dashoffset="${offset}" transform="rotate(-90 ${size / 2} ${size / 2})"/>
          <circle cx="${size / 2}" cy="${size / 2}" r="${radius}" fill="${borderColor}"/>
          <text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" fill="${textColor}" font-size="${fontSize}" dy=".3rem" style="font-size:${fontSize}px;">${initials}</text>
        </svg>
      `

      return svgHTML
    },
  },
}
</script>

<template>
  <v-card
    style="overflow: hidden"
    outlined
  >
    <v-img
      :class="(!selected ? 'blurry' : '') + ' align-center'"
      :src="src"
      class="grey darken-4 flex-grow-1"
      contain
      max-height="95%"
    />
    <div class="d-flex justify-space-between">
      <div>
        <v-btn
          icon
          depressed
          :disabled="previousDisabled"
          class="inline"
          @click="previous"
        >
          <v-icon>$fas fa-caret-left</v-icon>
        </v-btn>
        <span class="mx-4">
          {{ selectedIndex + 1 }} / {{ items.length || 1 }}
        </span>
        <v-btn
          icon
          depressed
          :disabled="nextDisabled"
          class="inline"
          @click="next"
        >
          <v-icon>$fas fa-caret-right</v-icon>
        </v-btn>
        <span class="mx-4">
          Section: {{ selected?.startStation }} -
          {{ selected?.endStation }}
        </span>
      </div>
      <div v-if="isSuperAdmin">
        <v-btn
          icon
          depressed
          :disabled="!selected"
          color="danger"
          @click="dialog = true"
        >
          <v-icon>$fas fa-trash</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('views.image-upload.delete') }}
        </v-card-title>

        <v-divider />

        <v-card-text class="text-subtitle-1">
          {{ $t('views.image-upload.delete-message', { count: 1 }) }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            :disabled="disabled"
            @click="dialog = false"
            >{{ $t('views.image-upload.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="danger"
            class="white--text"
            :disabled="disabled"
            @click="deleteSelected"
          >
            {{ $t('views.inventory.delete') }}
            <v-icon
              dark
              right
              size="20"
            >
              $far fa-trash-can
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { roles } from '@/utils/enum'

export default {
  name: 'ImageViewer',

  data: () => ({
    items: [],
    selected: null,
    selectedIndex: 0,
    src: '',
    dialog: false,
    disabled: false,
  }),

  computed: {
    ...mapGetters(['userHasRole']),

    isSuperAdmin() {
      return this.userHasRole(roles.SUPER_ADMIN)
    },

    nextDisabled() {
      return (
        this.items.length === 0 || this.selectedIndex >= this.items.length - 1
      )
    },
    previousDisabled() {
      return this.items.length === 0 || this.selectedIndex <= 0
    },
  },

  watch: {
    selected() {
      this.setCurrentImage(this.selected)
    },
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },

  methods: {
    ...mapActions('images', ['deleteImage']),
    ...mapMutations('images', ['setCurrentImage']),

    openSingle(image) {
      this.items = []
      this.selectedIndex = 0

      this.selected = image
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${image._id}`
    },

    openMultiple(images, index) {
      if (index >= images.length || index < 0) return
      this.items = images
      this.selectedIndex = index

      this.selected = images[index]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${images[index]._id}`
    },

    next() {
      if (this.nextDisabled) return

      this.selectedIndex++
      this.selected = this.items[this.selectedIndex]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${this.selected._id}`
    },

    previous() {
      if (this.previousDisabled) return

      this.selectedIndex--
      this.selected = this.items[this.selectedIndex]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${this.selected._id}`
    },

    handleKeyDown(event) {
      if (event.key === 'ArrowLeft') {
        this.previous()
      } else if (event.key === 'ArrowRight') {
        this.next()
      }
    },

    async deleteSelected() {
      this.disabled = true
      await this.deleteImage(this.selected._id)

      const newItems = this.items.filter(
        (item) => item._id !== this.selected._id,
      )
      // No more images left
      if (newItems.length === 0) {
        this.selected = null
        this.src = ''
        this.$emit('close')
        this.dialog = false
        return
      }

      // Image was last in the list
      if (this.selectedIndex === this.items.length - 1) {
        this.selectedIndex--
      }

      this.items = newItems
      this.selected = this.items[this.selectedIndex]
      this.src = `${process.env.VUE_APP_BASE_URL}/images/${this.selected._id}`
      this.dialog = false
      this.disabled = false
    },
  },
}
</script>

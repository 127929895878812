<template>
  <v-select
    v-model="selectedDate"
    :items="dates"
    :disabled="isClientDashboard"
    prepend-icon="$far fa-calendar"
    item-text="text"
    item-value="key"
    menu-props="auto"
    hide-details
    label="Date"
    class="custom"
    @change="changeDate"
  />
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['dates', 'isClientDashboard']),

    selectedDate: {
      get() {
        return this.$store.state.selectedDate
      },

      set(date) {
        this.$store.state.selectedDate = date
      },
    },
  },

  mounted() {
    const queryDate = this.$router.currentRoute?.query?.date

    if (queryDate) {
      this.selectedDate = this.$router.currentRoute.query.date
    }
  },

  methods: {
    changeDate(date) {
      const currentDateQuery = this.$router.currentRoute?.query?.date

      if (!currentDateQuery || currentDateQuery !== date) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: { ...this.$router.currentRoute.query, date },
        })
      }
    },
  },
}
</script>

import { defaults } from 'lodash'

export const initialState = {
  plants: [],
  selectedPlantId: '',
  suppliers: [],
  selectedSupplierId: '',
  mixedSuppliers: [],
  selectedMixedSupplierId: '',
  aggregates: [],
  selectedAggregateSpecification: '',
  selectedAggregateStockpile: '',
  selectedAggregateSampleProperty: '',
  aggregateKpis: [],
  asphaltSamples: [],
  asphaltKpis: [],
  siteAsphaltSamples: [],
  selectedSampleId: '',
  mixDesigns: [],
  selectedMixId: '',
  selectedSamplePropertyId: '',
  bitumenSamples: [],
  bitumens: [],
  materials: [],
  materialSamples: [],
  selectedMaterialId: '',
  selectedMaterialSampleId: '',
  selectedMaterialPropertyId: '',
}

export default defaults({}, initialState)

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState(['projects']),
    ...mapGetters({ getUser: 'user' }),
  },

  async created() {
    if (!this.projects.length) {
      this.$wait.start('isUpdatingProjectsList')
      await this.updateProjectList()
      this.$wait.end('isUpdatingProjectsList')
    }

    const queryProject = this.$route.query?.project
    const currentOrganization = this.getUser?.selectedOrganization
    const lastVisited = this.getUser?.lastVisited?.[currentOrganization]
    const projects = new Set(this.projects?.map((project) => project._id))
    const firstProject = projects?.values()?.next()?.value

    if (queryProject && projects.has(queryProject)) {
      this.changeProject(queryProject)
    } else if (lastVisited && projects.has(lastVisited)) {
      const newQuery = {
        ...this.$router.currentRoute.query,
        project: lastVisited,
      }
      this.$router.replace({
        ...this.$router.currentRoute,
        query: newQuery,
      })
      this.changeProject(lastVisited)
    } else if (firstProject) {
      const newQuery = {
        ...this.$router.currentRoute.query,
        project: firstProject,
      }
      this.$router.replace({
        ...this.$router.currentRoute,
        query: newQuery,
      })
      this.changeProject(firstProject)
    } else {
      this.showWarning(this.$t('core.error.projectsListEmpty'))
    }
  },

  methods: {
    ...mapActions(['updateProjectList', 'changeProject']),
    ...mapMutations(['showWarning']),

    mixin_onProjectChange(project) {
      const currentQuery = this.$router.currentRoute?.query
      this.$router.replace({
        path: this.$router.currentRoute.path,
        query: { ...currentQuery, project },
      })
    },
  },
}

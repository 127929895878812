// https://vuex.vuejs.org/en/state.html
import dayjs from 'dayjs'
import { isJson } from '../utils/vuex'

const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export const initialRootState = {
  isLoggedIn: false,
  datapoints: [],
  dataList: [],
  dates: [],
  idproject: '',
  isClientDashboard: false,
  kpi: [],
  kpiProduction: [],
  language: navigator.language.slice(0, 2), // Get language code only,
  notifications: [],
  project: {},
  projects: [],
  selectedDate: dayjs().format('YYYY-MM-DD'),
  selectedDates: new Set(),
  snackColor: 'general',
  snackText: '',
  snackTimeout: 5000,
  snackbarOpened: false,
  tableList: [],
  user: {},
  userId: null,
  users: [],
  monthYearList: [],
  contractorKpis: [],
  projectFilters: [],
  contractorDatePickerList: [],
  projectManagerDatePickerList: [],
}

export default {
  isLoggedIn: false,
  datapoints: localStorage.getItem('datapoints') || [],
  dataList: [],
  dates: localStorage.getItem('dates') || [],
  idproject: sessionStorage.getItem('idproject') || '',
  isClientDashboard: false,
  kpi: localStorage.getItem('kpi') || [],
  kpiProduction: [],
  language: localStorage.getItem('language') || navigator.language.slice(0, 2), // Get language code only,
  notifications: [],
  project: localStorage.getItem('project') || {},
  projects: localStorage.getItem('projects') || [],
  selectedDate: dayjs().format('YYYY-MM-DD'),
  selectedDates: new Set(),
  snackColor: 'general',
  snackText: '',
  snackTimeout: 5000,
  snackbarOpened: false,
  tableList: [],
  user:
    localStorage.getItem('user') && isJson(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user'))
      : {},
  userId: null,
  users: [],
  monthYearList: [],
  projectFilters: [],
  contractorKpis: [],
  contractorDatePickerList: [],
  projectManagerDatePickerList: [],
}

<template>
  <v-dialog
    v-model="dialog"
    width="80%"
  >
    <v-card>
      <v-img
        :class="isLoading ? 'blurry' : ''"
        :src="src"
        class="grey darken-4"
        :height="height"
        contain
      />
      <v-card-text v-if="!isLoading">
        <v-slider
          v-model="height"
          class="align-self-stretch"
          min="350"
          max="1350"
          step="1"
          :label="$t('views.image-upload.size')"
          :thumb-color="'#e2632c'"
          thumb-label="always"
        />
      </v-card-text>
      <v-progress-linear
        v-if="isLoading"
        color="blue-grey"
        indeterminate
        height="25"
      >
        <strong> {{ $t('views.global.please-wait') }}</strong>
      </v-progress-linear>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          @click="dialog = false"
        >
          {{ $t('views.global.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapMutations } from 'vuex'

export default {
  name: 'ResizableImageDialog',
  data: () => ({
    isLoading: false,
    height: 350,
    src: null,
    dialog: false,
  }),

  methods: {
    ...mapMutations(['showWarning']),

    /**
     * @public
     * Display the image
     * @returns {Promise<void>}
     */
    async show(image) {
      this.dialog = true
      this.src = image.src

      if (!image._id) {
        this.height = 700
        this.src = image.thumbnail
        return
      }

      try {
        this.isLoading = true
        this.height = 350
        const { data } = await axios.get(`/images/${image._id}`, {
          responseType: 'blob',
        })
        this.height = 700
        this.src = URL.createObjectURL(data)
      } catch (err) {
        console.warn(err)
        this.showWarning(this.$t('views.image-upload.expandError'))
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

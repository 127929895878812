<template>
  <material-card
    outlined
    class="fill-height"
  >
    <div class="callout">
      <div class="subtitle-1 ml-2">
        {{ title || $t('views.charts.tableList.title') }}
        <v-btn
          v-if="hidden.length > 0"
          label
          class="right"
          icon
          @click="expand"
        >
          <v-icon class="mx-2">$fas fa-expand-alt</v-icon>
        </v-btn>
      </div>
      <div class="text-caption text--disabled ml-2">
        {{ description || $t('views.charts.tableList.description') }}
      </div>
    </div>
    <v-data-table
      :headers="computedHeaders"
      :items-per-page="10"
      :items="items"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: '$fas fa-arrow-left',
        lastIcon: '$fas fa-arrow-right',
        prevIcon: '$fas fa-minus',
        nextIcon: '$fas fa-plus',
      }"
    >
      <template #item="{ item }">
        <tr>
          <td
            v-for="(header, index) in computedHeaders"
            :key="index"
          >
            <template v-if="header.checkbox">
              <v-checkbox
                v-model="item[header.value].value"
                class="px-4"
                @change="
                  header.onChange &&
                    header.onChange.call(
                      this,
                      item.id,
                      item[header.value].value,
                    )
                "
              ></v-checkbox>
            </template>
            <template v-else-if="header.slot === true">
              <slot
                :name="header.value"
                :item="item"
              ></slot>
            </template>
            <template v-else-if="item[header.value] !== undefined">
              <v-icon
                v-if="item[header.value].icon"
                :color="item[header.value].icon.color"
                >${{ item[header.value].icon.value }}</v-icon
              >
              {{ item[header.value].value }}
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </material-card>
</template>
<script>
import { toExpand } from '../../../../utils/cards'

export default {
  name: 'TableList',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    headers: {
      type: [Array],
      required: true,
    },
    hidden: {
      type: Array,
      default() {
        return []
      },
    },
    items: {
      type: [Array],
      required: true,
    },
  },
  data: function () {
    return {
      hiddenHeaders: this.hidden,
    }
  },
  computed: {
    computedHeaders() {
      return this.headers.filter((x) => !this.hiddenHeaders.includes(x.value))
    },
  },
  methods: {
    expand(e) {
      toExpand(e)
      this.hiddenHeaders = []
    },
  },
}
</script>

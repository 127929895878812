<template>
  <core-card>
    <core-callout :title="$t('views.production-foreman.materialList')">
    </core-callout>
    <v-data-table
      :headers="headers"
      :items="items"
      style="overflow: auto"
      height="100%"
      dense
      fixed-header
      single-select
      disable-pagination
      hide-default-footer
      class="row-pointer pa-1"
    >
      <template #headerCell="{ header }">
        <span
          class="subheading font-weight-light text-success text--darken-3"
          v-text="header.text"
        />
      </template>
      <template #item="{ item }">
        <tr
          :class="{ 'grey lighten-2': item.id === selectedItem }"
          @click="onItemSelected(item)"
        >
          <template
            v-if="
              selectedItemType === $helpers.enums.references.LAB_SPEC_MATERIAL
            "
          >
            <td class="font-weight-bold text-start">{{ item.grade }}</td>
            <td>{{ item.stockpile }}</td>
            <td>{{ item.sampleCount }}</td>
            <td>{{ item.jobsiteCount }}</td>
            <td>{{ formattedTestingDelay(item.testingDelay) }}</td>
            <td class="text-end">
              <TrendChip
                v-if="item[$helpers.enums.kpiTypes.IQGQMAT]"
                :risk="item[$helpers.enums.kpiTypes.IQGQMAT].risk"
                :date="new Date(item[$helpers.enums.kpiTypes.IQGQMAT].date)"
              />
            </td>
          </template>
          <template
            v-else-if="
              selectedItemType === $helpers.enums.references.LAB_MIX_DESIGN
            "
          >
            <td class="font-weight-bold text-start">
              {{ item.mixDesignCode }}
            </td>
            <td>{{ item.asphaltType }}</td>
            <td>{{ item.sampleCount }}</td>
            <td>{{ item.jobsiteCount }}</td>
            <td>{{ formattedTestingDelay(item.testingDelay) }}</td>
            <td class="text-end">
              <TrendChip
                v-if="item[$helpers.enums.kpiTypes.IQEQMEL]"
                :risk="item[$helpers.enums.kpiTypes.IQEQMEL].risk"
                :date="new Date(item[$helpers.enums.kpiTypes.IQEQMEL].date)"
              />
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </core-card>
</template>

<script>
import TrendChip from '../Charts/TrendChip.vue'

export default {
  name: 'MixAggregateList',
  components: {
    TrendChip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: String,
      required: true,
    },
    selectedItemType: {
      type: String,
      required: true,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('views.production-foreman.mixAggregateTable.product'),
          sortable: false,
          align: 'start',
        },
        {
          text: this.$t('views.production-foreman.mixAggregateTable.spec'),
          sortable: false,
        },
        {
          text: this.$t('views.production-foreman.mixAggregateTable.test'),
          sortable: false,
        },
        {
          text: this.$t('views.production-foreman.mixAggregateTable.jobsite'),
          sortable: false,
        },
        {
          text: this.$t('views.production-foreman.mixAggregateTable.delay'),
          sortable: false,
        },
        {
          text: this.$t('views.production-foreman.mixAggregateTable.quality'),
          sortable: false,
          align: 'end',
        },
      ]
    },
  },
  methods: {
    onItemSelected(value) {
      if (value.id !== this.selectedItem) {
        this.$emit('onItemSelected', value)
      }
    },
    formattedTestingDelay(delay) {
      if (delay) {
        const roundedDelay = Math.round((delay + Number.EPSILON) * 100) / 100
        return `${roundedDelay} h`
      }
      return '-'
    },
  },
}
</script>

import Vue from 'vue'
import { initialReportsState } from './state'
import resetState from '@/utils/resetState'

export default {
  update(state, reports) {
    Vue.set(state, 'items', reports)
  },

  updateOne(state, report) {
    let reportIndex = state.items.findIndex((_r) => _r._id === report._id)
    if (reportIndex >= 0) {
      Vue.set(state.items, reportIndex, report)
    } else {
      state.items.push(report)
      Vue.set(state, 'items', state.items)
    }
  },
  clearState(state) {
    resetState(state, initialReportsState)
  },
}

import { geoJsonToMarker } from '@/utils/map'

export default {
  methods: {
    async navigateToDataPoint(value, openPopup = false) {
      const cluster = this.markers.find((_cluster) => {
        return _cluster.points.find((_point) => {
          return _point._id === value._id
        })
      })

      let marker = cluster?.marker

      if (cluster?.points?.length > 1) {
        if (marker?.getAllChildMarkers()?.length > 0) {
          marker?.spiderfy()
          marker = marker?.getAllChildMarkers()?.find((m) => {
            return m?.options?.point?._id === value._id
          })
        }
      }

      if (openPopup) marker?.openPopup()

      // center the map on the datapoint
      this.map?.panTo(geoJsonToMarker(value?.location))
    },
  },

  mounted() {
    this.$root.$on('boundsUpdated', ({ markers, map }) => {
      this.map = map
      this.markers = markers
    })
  },
}

<template>
  <expandable-card>
    <template #default="{ isExpanded }">
      <v-card-title v-if="isExpanded">
        {{ $t('views.technicalDirector.titles.supplierList') }}
        <v-spacer />
        <v-text-field
          v-model="search"
          :label="$t('views.technicalDirector.search')"
          single-line
          hide-details
          clearable
          clear-icon="$fas fa-xmark"
          prepend-inner-icon="$fas fa-search"
        />
      </v-card-title>

      <v-data-table
        :custom-sort="sortByKpiTrendAndDate"
        :dense="!isExpanded"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :search="search"
        :sort-desc="true"
        class="row-pointer overflow-auto"
        fixed-header
        height="100%"
        hide-default-footer
        item-key="id"
        show-expand
        single-expand
        sort-by="kpi"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      >
        <template #[`item.formattedName`]="{ item }">
          <span
            class="supplier-link"
            @click="onSupplierClicked(item)"
            >{{ item.formattedName }}</span
          >
        </template>
        <template #[`item.weather`]="{ item }">
          {{ mixin_formatWeather(item) }}
        </template>
        <template #[`item.kpi`]="{ item }">
          <TrendChip
            :trend="item.kpi.trend"
            :date="new Date(item.kpi.date)"
          />
        </template>
        <template #expanded-item="{ item }">
          <td
            :colspan="12"
            class="py-4"
          >
            <v-data-table
              dense
              :headers="productHeaders"
              :items="item.products"
              :hide-default-footer="true"
              :sort-desc="true"
              :sort-by="['sampleCount']"
              disable-pagination
              @click:row="onProductClicked"
            />
          </td>
        </template>
      </v-data-table>
    </template>
  </expandable-card>
</template>

<script>
import TrendChip from '../Charts/TrendChip.vue'
import customSortMixin from '@/mixins/customSortMixin'
import technicalManagerMixin from '@/mixins/technicalManagerMixin'
import ExpandableCard from '@/components/core/ExpandableCard.vue'
import { productTypes, references } from '@/utils/enum'

export default {
  name: 'TDSuppliers',
  components: {
    TrendChip,
    ExpandableCard,
  },
  mixins: [customSortMixin, technicalManagerMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('views.technicalDirector.headers.supplier'),
          value: 'formattedName',
        },
        {
          text: this.$t('views.technicalDirector.headers.location'),
          value: 'supplierAddressCity',
        },
        {
          text: this.$t('views.technicalDirector.headers.weather'),
          value: 'weather',
        },
        {
          text: this.$t('views.technicalDirector.headers.kpi'),
          value: 'kpi',
        },
      ],
      productHeaders: [
        {
          text: this.$t('views.technicalDirector.headers.product'),
          value: 'productName',
        },
        {
          text: this.$t('views.technicalDirector.headers.sampleCount'),
          value: 'sampleCount',
        },
      ],
      search: '',
    }
  },

  methods: {
    onSupplierClicked(supplier) {
      if (supplier.type === references.PLANT) {
        const routeData = this.$router.resolve({
          path: '/dashboard/production-technician-asphalt',
          query: { plant: supplier.id },
        })
        window.open(routeData.href, '_blank')
      } else if (supplier.type === references.SUPPLIER) {
        const routeData = this.$router.resolve({
          path: '/dashboard/production-technician-aggregate',
          query: {
            supplier: supplier.id,
          },
        })
        window.open(routeData.href, '_blank')
      }
    },

    onProductClicked(product) {
      if (product.type === productTypes.ASPHALT) {
        const routeData = this.$router.resolve({
          path: '/dashboard/production-technician-asphalt',
          query: { plant: product.plant, mixDesign: product.mixDesign },
        })
        window.open(routeData.href, '_blank')
      } else if (product.type === productTypes.AGGREGATE) {
        const routeData = this.$router.resolve({
          path: '/dashboard/production-technician-aggregate',
          query: {
            supplier: product.supplier,
            specification: product.specification,
            stockpile: product.stockpile,
          },
        })
        window.open(routeData.href, '_blank')
      }
    },
  },
}
</script>

<style>
.supplier-link {
  text-decoration: underline;
  color: black !important;
}
</style>

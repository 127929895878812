import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'

export default {
  data() {
    return {
      mapMixin_zoom: 16,
      mapMixin_url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      mapMixin_attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapMixin_options: {
        zoomSnap: 0.5,
      },
      mapMixin_tileOptions: {
        maxZoom: 20,
        maxNativeZoom: 18,
      },
    }
  },

  methods: {
    mapMixin_onReady(mapRef) {
      mapRef.mapObject.on('geosearch/showlocation', this.onSearch)
      mapRef.mapObject.doubleClickZoom.disable()
      mapRef.mapObject.addControl(
        new GeoSearchControl({
          style: 'bar',
          provider: new OpenStreetMapProvider(),
          showMarker: false,
          autoClose: true,
          searchLabel: this.$t('views.project.searchLabel'),
        }),
      )
    },
  },
}

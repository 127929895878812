<template>
  <v-wait for="isGettingPosition">
    <core-spinner slot="waiting" />
    <core-progress :in-progress="$wait.is('loadingBenchmarks')" />
    <title-bar :show-back="true" />
    <v-container
      fluid
      grow
      d-flex
    >
      <v-row>
        <v-col cols="12">
          <MapWidget
            class="fill-height"
            :center="calibrationDataPointsCenter"
            :datapoints="[]"
            :show-expand="false"
            :show-user-position="true"
            :lines="calibrationLines"
            :starts="calibrationStarts"
            @center:update="onCenterUpdate"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-wait>
</template>

<script>
import _ from 'lodash'
import MapWidget from '../../Charts/Maps/Widget'
import { mapActions, mapState } from 'vuex'
import TitleBar from '@/components/core/TitleBar'

export default {
  name: 'NearbyBenchmarksDashboard',
  components: {
    MapWidget,
    TitleBar,
  },
  data() {
    return {
      centerLng: 0,
      centerLat: 0,
      radius: 20,
    }
  },
  computed: {
    ...mapState('iri', ['calibrations']),
    ...mapState('app', ['localisation']),

    calibrationDataPoints() {
      const datapoints = []
      for (let c of this.calibrations) {
        for (let datapoint of c.datapoints) {
          datapoint.type = 'device-calibration' // Type displayed in the map datapoint tooltip
          datapoints.push(datapoint)
        }
      }
      return datapoints
    },
    calibrationLines() {
      return (
        this.calibrations?.map((c) =>
          c.path.coordinates.map((co) => [co[1], co[0]]),
        ) || []
      )
    },
    calibrationStarts() {
      return this.calibrationLines?.map((c) => c[0]) || []
    },
    calibrationDataPointsCenter() {
      return [this.centerLat, this.centerLng]
    },
  },

  watch: {
    centerLat() {
      this.updateBenchmarkData()
    },
  },

  async created() {
    this.$wait.start('isGettingPosition')
    this.centerLat = this.localisation[0]
    this.centerLng = this.localisation[1]
    await this.getCurrentPosition()
    this.$wait.end('isGettingPosition')
  },

  methods: {
    ...mapActions({
      getBenchmarkData: 'iri/getBenchmarks',
    }),
    ...mapActions('app', ['getCurrentPosition']),

    onCenterUpdate: _.debounce(function (mapInfo) {
      const { center, zoomLevel } = mapInfo
      this.centerLng = center.lng
      this.centerLat = center.lat

      this.radius =
        (156 * 512 * Math.cos((center.lat * Math.PI) / 180)) /
        Math.pow(2, zoomLevel)
    }, 1500),

    async updateBenchmarkData() {
      this.$wait.start('loadingBenchmarks')
      await this.getBenchmarkData({
        location: [this.centerLng, this.centerLat],
        radius: this.radius,
      })
      this.$wait.end('loadingBenchmarks')
    },
  },
}
</script>

<style scoped></style>

<template>
  <core-card>
    <core-callout :title="$t('views.production-technician.material')">
    </core-callout>
    <v-data-table
      :headers="headers"
      :items="items"
      style="overflow: auto"
      height="100%"
      dense
      fixed-header
      single-select
      disable-pagination
      hide-default-footer
      class="row-pointer pa-1"
    >
      <template #headerCell="{ header }">
        <span
          class="subheading font-weight-light text-success text--darken-3"
          v-text="header.text"
        />
      </template>
      <template #item="{ item }">
        <tr
          :class="{ 'grey lighten-2': item.id === selectedAggregate }"
          @click="onGradeSelected(item)"
        >
          <td>{{ item.grade }}({{ item.stockpile }})</td>
          <td class="text-xs-right">
            <TrendChip
              v-if="item.GQualMat"
              :trend="item.GQualMat.trend"
            />
          </td>
          <td class="text-xs-right">
            <TrendChip
              v-if="item.GVarMatProd"
              :trend="item.GVarMatProd.trend"
            />
          </td>
          <td class="text-xs-right">
            <TrendChip
              v-if="item.GVarMatQua"
              :trend="item.GVarMatQua.trend"
            />
          </td>
          <td class="text-xs-right">
            <TrendChip
              v-if="item.GVarMatRecy"
              :trend="item.GVarMatRecy.trend"
            />
          </td>
          <td class="text-xs-right">
            <TrendChip :risk="computeRisk(item.IQGQMAT)" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </core-card>
</template>

<script>
import TrendChip from '../Charts/TrendChip.vue'
import { computeRiskFromWeight } from '@/utils/kpi'

export default {
  name: 'GradeList',
  components: {
    TrendChip,
  },
  props: {
    selectedAggregate: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    headers() {
      return [
        { text: this.$t('enums.headers.aggregateGrade'), value: 'grade' },
        { text: this.$t('enums.kpi.GQualMat'), value: 'GQualMat' },
        { text: this.$t('enums.kpi.GVarMatProd'), value: 'GVarMatProd' },
        { text: this.$t('enums.kpi.GVarMatQua'), value: 'GVarMatQua' },
        { text: this.$t('enums.kpi.GVarMatRecy'), value: 'GVarMatRecy' },
        { text: this.$t('enums.headers.risk'), value: 'IQGQMAT' },
      ]
    },
  },
  methods: {
    onGradeSelected(value) {
      if (value.id !== this.selectedAggregate) {
        this.$emit('onGradeSelected', value)
      }
    },
    computeRisk: computeRiskFromWeight,
  },
}
</script>

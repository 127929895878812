<template>
  <core-card v-resize="onResize">
    <core-callout
      :title="title"
      :subtitle="subtitle"
    />
    <div class="chart-container">
      <div class="legend">
        <div class="dc-html-legend">
          <div
            v-for="(group, index) in uniqueGroups"
            :key="group + index"
            class="dc-legend-item-horizontal"
          >
            <span
              class="dc-legend-item-color"
              :style="{ backgroundColor: colors[index] }"
            />
            <span
              class="dc-legend-item-label"
              :title="group"
            >
              {{ group }}
            </span>
          </div>
        </div>
      </div>
      <div
        ref="chartScrollableContainer"
        class="chart-horizon-scroll"
      >
        <div
          class="chart"
          :style="chartCss"
        >
          <svg
            :width="width"
            :height="height"
            :viewBox="[0, 0, width, height]"
            class="svg"
          >
            <!-- yAxis -->
            <g
              id="yAxis"
              :transform="`translate(${margin.left}, 0)`"
            />

            <!-- Actual grouped bar chart -->
            <g>
              <rect
                v-for="index in indices"
                :key="`rect-${index}`"
                :x="xScale(stations[index]) + xzScale(groups[index])"
                :y="yScale(compactions[index])"
                :width="xzScale.bandwidth()"
                :height="yScale(minY) - yScale(compactions[index])"
                :fill="zScale(groups[index])"
              >
                <title>{{ rectTitle(index) }}</title>
              </rect>
            </g>

            <!-- xAxis -->
            <g
              id="xAxis"
              :transform="`translate(0, ${height - margin.bottom})`"
            />
          </svg>
        </div>
      </div>
    </div>
  </core-card>
</template>

<script>
import * as d3 from 'd3'
import * as _ from 'lodash'
import resize from 'vue-resize-directive'

export default {
  name: 'BarChart',
  directives: {
    resize,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    datapoints: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      angledLabelBreakpoint: 7,
      margin: {
        top: 30,
        right: 0,
        bottom: 30,
        left: 30,
      },
      width: 640,
      height: 400,
      xPadding: 0.1,
      zPadding: 0.05,
      colors: ['#ffbd21', '#33cc99', '#ff9400', '#05a57d', '#ff6666'],
    }
  },
  computed: {
    stations() {
      return this.datapoints.map((datapoint) => datapoint.station)
    },
    compactions() {
      return this.datapoints.map((datapoint) => datapoint.compaction)
    },
    groups() {
      return this.datapoints.map((datapoint) => datapoint.group)
    },
    uniqueGroups() {
      return _.uniq(this.groups)
    },
    indices() {
      return d3.range(this.datapoints.length)
    },

    xScale() {
      const xRange = [this.margin.left, this.width - this.margin.right]
      const xDomain = [...this.stations].sort()
      return d3.scaleBand(xDomain, xRange).paddingInner(this.xPadding)
    },
    xzScale() {
      return d3
        .scaleBand(this.groups, [0, this.xScale.bandwidth()])
        .padding(this.zPadding)
    },
    yScale() {
      const yRange = [this.height - this.margin.bottom, this.margin.top]
      const yDomain = [this.minY, 100]
      return d3.scaleLinear(yDomain, yRange)
    },
    zScale() {
      return d3.scaleOrdinal(this.groups, this.colors)
    },

    xAxis() {
      return d3.axisBottom(this.xScale).tickSizeOuter(0)
    },
    yAxis() {
      return d3.axisLeft(this.yScale).ticks(this.height / 60)
    },

    minY() {
      const minCompaction = this.compactions.length
        ? Math.min(...this.compactions)
        : 0
      return minCompaction - (minCompaction % 5)
    },
    chartCss() {
      return {
        '--width': this.width + 'px',
      }
    },
  },

  watch: {
    datapoints() {
      this.computeChartWidth()
      this.renderAxes()
    },
  },

  mounted() {
    this.computeChartWidth()
    this.renderAxes()
  },

  methods: {
    computeChartWidth() {
      this.width = Math.max(
        this.$refs.chartScrollableContainer.clientWidth,
        this.margin.right +
          this.margin.left +
          _.uniq(this.stations).length *
            (16 + (this.uniqueGroups.length - 1) * 10) || 0,
      )
      this.height = this.$refs.chartScrollableContainer.clientHeight
    },

    rectTitle(index) {
      const stationString = `${this.$t('views.dashboard.bar-chart.station')}: ${
        this.stations[index]
      }\n`

      const compactionString = `${this.$t(
        'views.dashboard.bar-chart.compaction',
      )}: ${this.compactions[index]}%\n`

      const groupString = `${this.groups[index]}`

      return stationString + compactionString + groupString
    },

    renderYAxis() {
      d3.select('#yAxis')
        .call(this.yAxis)
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick line')
            .attr('x2', this.width - this.margin.left - this.margin.right)
            .attr('stroke-opacity', 0.1),
        )
    },

    renderXAxis() {
      if (this.datapoints.length > this.angledLabelBreakpoint) {
        // Use angled xAxis labels
        this.margin.bottom = 50
        d3.select('#xAxis')
          .call(this.xAxis)
          .selectAll('text')
          .style('text-anchor', 'end')
          .attr('dx', '-.8em')
          .attr('dy', '.15em')
          .attr('transform', 'rotate(-65)')
      } else {
        // Use flat xAxis labels
        d3.select('#xAxis').call(this.xAxis)
      }
    },

    renderAxes() {
      this.renderYAxis()
      this.renderXAxis()
    },

    onResize() {
      this.computeChartWidth()
      this.renderAxes()
    },
  },
}
</script>
<style scoped>
.chart {
  width: var(--width);
}
</style>

<template>
  <v-app>
    <core-drawer />
    <core-toolbar />
    <v-main>
      <slot />
      <core-snackbar />
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'BaseApp',
}
</script>

<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    v-model="inProgress"
    persistent
    width="auto"
    content-class="elevation-0 overflow-hidden"
  >
    <v-progress-circular
      indeterminate
      color="white"
      class="mb-2 justify-center"
      :size="70"
      :width="7"
    ></v-progress-circular>
  </v-dialog>
</template>

<script>
export default {
  name: 'CoreProgress',
  props: {
    inProgress: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
<style scoped>
.core-spinner {
  background-color: transparent;
}
</style>

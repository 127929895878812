<template>
  <material-card
    v-resize="onResize"
    outlined
    class="fill-height d-flex"
  >
    <core-callout
      :title="title"
      :subtitle="subtitle"
    />
    <div
      :id="id"
      class="graph-container"
    >
      <svg
        v-if="initialized"
        :id="svgId"
        :viewBox="[0, 0, width ? width : 0, height]"
        class="graph-svg"
      >
        <g
          v-for="(data, index) in formattedData"
          :key="`${svgId}-group-${index}`"
        >
          <rect
            :x="xScale(0)"
            :y="yScale(index)"
            :width="Math.abs(xScale(data.result) - xScale(0))"
            :height="yScale.bandwidth()"
            :fill="getColor(data.result)"
          >
            <title>{{ getTitleValue(data.result) }}</title>
          </rect>
          <text
            :ref="`text-${index}`"
            :x="getTextProperties(data, index).xPosition"
            :y="getTextProperties(data, index).yPosition"
            :text-anchor="getTextProperties(data, index).textAnchor"
            dominant-baseline="middle"
            :fill="getTextProperties(data, index).fill"
            font-size="0.9em"
            font-weight="bold"
          >
            {{ data.name }}
          </text>
        </g>
        <g>
          <g
            :id="`${id}-xAxis`"
            class="xAxis"
            transform="translate(0, 15)"
          />
        </g>
      </svg>
    </div>
  </material-card>
</template>

<script>
import * as d3 from 'd3'
import resize from 'vue-resize-directive'
import { round } from 'lodash'

export default {
  name: 'VariationChart',
  directives: {
    resize,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    kpis: {
      type: [Array],
      required: true,
    },
  },
  data() {
    return {
      initialized: false,
      isCreated: false,
      margin: { top: 16, right: 16, bottom: 16, left: 16 },
      rowPadding: 0.5,
      width: null,
      height: null,
      xAxisGridOpacity: 0.15,
      xAxisValuesPaddingTop: 10,
      xScale: null,
      yScale: null,
      translationPath: 'views.generalManagerContractor.kpis',
      showText: [],
    }
  },
  computed: {
    formattedData() {
      return this.kpis.map((kpi) => {
        let { result, name } = kpi

        if (this.$te(`${this.translationPath}.${kpi.name}`)) {
          name = this.$t(`${this.translationPath}.${kpi.name}`)
        }

        return { result, name }
      })
    },

    svgId() {
      return `${this.id}-svg`
    },

    svg() {
      return d3.select(`#${this.svgId}`)
    },

    xAxis() {
      return d3
        .axisBottom(this.xScale)
        .ticks(8)
        .tickSizeInner(this.height - this.margin.bottom - 20)
        .tickPadding(this.xAxisValuesPaddingTop)
    },

    textXPosition() {
      return this.width - this.margin.right - 5
    },
  },

  watch: {
    kpis() {
      this.onKpisChange()
    },
  },

  methods: {
    renderAxes() {
      // x Axis
      d3.select(`#${this.id}-xAxis`)
        .call(this.xAxis)
        .call((g) => g.select('.domain').remove())

      this.svg.selectAll('.tick line').style('opacity', this.xAxisGridOpacity)
      this.isCreated = true
    },

    calcSize() {
      this.width = d3.select(`#${this.id}`).node().getBoundingClientRect().width
      this.height = d3
        .select(`#${this.id}`)
        .node()
        .getBoundingClientRect().height

      this.xScale = d3
        .scaleLinear()
        .domain([0, 100])
        .rangeRound([this.margin.left, this.width - this.margin.right])

      this.yScale = d3
        .scaleBand()
        .domain(d3.range(this.formattedData.length))
        .rangeRound([this.margin.top, this.height - this.margin.bottom])
        .padding(this.rowPadding)

      this.initialized = true
    },

    async onResize() {
      this.calcSize()
      await this.$nextTick()
      this.renderAxes()
      this.updateTextVisibility()
    },

    onKpisChange() {
      if (!this.isCreated) {
        return
      }
      this.onResize()
    },

    getColor(value) {
      if (value >= 0 && value < 33) {
        return '#FF6666'
      } else if (value >= 33 && value < 66) {
        return '#FFBD21'
      } else if (value >= 66 && value <= 100) {
        return '#34D1BF'
      }
      return '#F5F5F5'
    },

    isTextWithinBounds(value, index) {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          const rectWidth = Math.abs(this.xScale(value) - this.xScale(0))
          let textWidth = 0

          const textElement = this.$refs[`text-${index}`]

          const textBBox =
            textElement && textElement.length ? textElement[0].getBBox() : null

          textWidth = textBBox ? textBBox.width : 0

          resolve(textWidth + 5 <= rectWidth)
        })
      })
    },

    async updateTextVisibility() {
      this.showText = await Promise.all(
        this.formattedData.map((data, index) =>
          this.isTextWithinBounds(data.result, index),
        ),
      )
    },

    getTitleValue(value) {
      return round(value, 2) + '%'
    },

    getTextProperties(data, index) {
      const showTextWithin = this.showText[index]
      const xPosition = showTextWithin
        ? this.xScale(data.result) - 5
        : this.xScale(data.result) + 5
      const yPosition = this.yScale(index) + this.yScale.bandwidth() / 2
      const textAnchor = showTextWithin ? 'end' : 'start'
      const fill = showTextWithin ? '#FFFFFF' : '#000000'

      return {
        xPosition,
        yPosition,
        textAnchor,
        fill,
      }
    },
  },
}
</script>
<style scoped>
.xAxis {
  stroke-dasharray: 3 2;
  font-size: 0.6rem;
}
</style>

import Vue from 'vue'
import resetState from '@/utils/resetState'
import { initialUserManagementState } from './state'

export default {
  paginate(state, users) {
    state.page = users.page
    state.totalPages = users.totalPages
    state.totalResults = users.totalResults
  },
  updateUserList(state, users) {
    Vue.set(state, 'users', users)
  },
  setUser(state, user) {
    Vue.set(state, 'user', user)
    Vue.set(state, 'userId', user.id)
  },
  setOrganization(state, organization) {
    Vue.set(state, 'organization', organization)
  },
  clearState(state) {
    resetState(state, initialUserManagementState)
  },
}

export const CANADIAN_POSTALCODE_REGEX =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
export const US_ZIPCODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/i

export function isValidPostalcode(postalCode) {
  return (
    CANADIAN_POSTALCODE_REGEX.test(postalCode) ||
    US_ZIPCODE_REGEX.test(postalCode)
  )
}

<template>
  <!-- wrapper div to ensure that titlebar styles are applied correctly -->
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-left="40"
      transition="scale-transition"
      offset-y
      right
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :label="$t('views.construction-foreman.datePicker.label')"
          :value="selectedItemsText()"
          prepend-inner-icon="$far fa-calendar"
          readonly
          hide-details
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="selectedDates"
        :range="isRange"
        :multiple="!isRange"
        :max="max"
        :min="min"
        :allowed-dates="allowedDates"
        no-title
      >
        <v-sheet class="px-4 d-flex flex-column fill-width">
          <v-switch
            inset
            :value="isRange"
            :label="$t('views.construction-foreman.datePicker.rangeToggle')"
            @click="toggleIsRange"
          />
          <v-switch
            inset
            :value="allDatesSelected"
            :label="$t('views.construction-foreman.datePicker.selectAll')"
            @click="toggleSelectAll"
          />
          <v-btn
            text
            color="primary"
            :disabled="selectedDates.length === 0"
            @click="setDatesFilter"
          >
            {{ $t('views.construction-foreman.datePicker.applyFilter') }}
          </v-btn>
        </v-sheet>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data: () => ({
    menu: false,
    selectedDates: [],
    isRange: false,
    min: null,
    max: null,
  }),

  computed: {
    ...mapState(['dates']),

    allDatesSelected() {
      return this.selectedDates.length === this.dates.length
    },
  },

  mounted() {
    this.selectedDates = [this.dates[0]]
    this.min = this.dates[this.dates.length - 1]
    this.max = this.dates[0]
    this.setDatesFilter()
  },

  methods: {
    ...mapMutations(['setSelectedDates']),

    allowedDates(date) {
      return this.dates.includes(date)
    },

    toggleIsRange() {
      if (this.isRange) {
        this.selectedDates = []
      } else if (this.selectedDates.length > 2) {
        // In case dates are not selected in chronological order
        this.selectedDates.sort()
        const first = this.selectedDates[0]
        const last = this.selectedDates[this.selectedDates.length - 1]
        this.selectedDates = [first, last]
      }
      this.isRange = !this.isRange
    },

    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.isRange) {
          this.isRange = false
          this.selectedDates = this.dates.slice()
        } else if (this.allDatesSelected) {
          this.selectedDates = []
        } else {
          this.selectedDates = this.dates.slice()
        }
      })
    },

    setDatesFilter() {
      if (this.isRange) {
        const first = this.selectedDates[0]
        const last = this.selectedDates[this.selectedDates.length - 1]
        const selectedDatesSet = new Set(
          this.dates.filter((date) => {
            return date >= first && date <= last
          }),
        )
        this.setSelectedDates(selectedDatesSet)
      } else {
        const selectedDatesSet = new Set(this.selectedDates)
        this.setSelectedDates(selectedDatesSet)
      }
      this.menu = false
    },

    selectedItemsText() {
      if (this.selectedDates.length === 0) {
        return this.$t('views.construction-foreman.datePicker.noDateSelected')
      }

      if (this.selectedDates.length === 1) {
        return this.selectedDates[0]
      }

      if (this.allDatesSelected) {
        return this.$t('views.construction-foreman.datePicker.allDatesSelected')
      }

      if (this.isRange) {
        const isChronological = this.selectedDates[0] < this.selectedDates[1]
        if (!isChronological) {
          this.selectedDates.reverse()
        }
        return `${this.selectedDates[0]} ~ ${this.selectedDates[1]}`
      }

      return (
        this.selectedDates.length +
        this.$t('views.construction-foreman.datePicker.numDatesSelected')
      )
    },
  },
}
</script>

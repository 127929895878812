<template>
  <div>
    <title-bar :show-back="true" />
    <div class="grid-container">
      <NotificationTable
        class="table"
        :notifications="notifications"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NotificationTable from '../Charts/Tables/Notification'
import TitleBar from '@/components/core/TitleBar'

export default {
  name: 'NotificationsContainer',
  components: {
    NotificationTable,
    TitleBar,
  },
  computed: {
    ...mapGetters(['notifications']),
  },
}
</script>
<style scoped>
.table {
  grid-row: 1 / span 12;
  grid-column: 2 / span 10;
}
</style>

import * as _ from 'lodash'
import {
  asphaltSamplePropertyNames,
  aggregateSamplePropertyNames,
  materialSamplePropertyNames,
  kpiTypes,
} from '@/utils/enum'

export default {
  selectedMixDesign: (state) => {
    return state.mixDesigns.find((aS) => aS._id === state.selectedMixId)
  },
  selectedAggregate: (state) => {
    return state.aggregates.find(
      (aS) =>
        aS.specification === state.selectedAggregateSpecification &&
        aS.stockpile === state.selectedAggregateStockpile,
    )
  },
  selectedPlant: (state) => {
    return state.plants.find((aS) => aS._id === state.selectedPlantId)
  },
  selectedSupplier: (state) => {
    return state.suppliers.find((aS) => aS._id === state.selectedSupplierId)
  },
  selectedSample: (state, getters) => {
    return getters.mixFilteredAsphaltSamples.find(
      (aS) => aS._id === state.selectedSampleId,
    )
  },
  selectedMaterial: (state, getters) => {
    return getters.mixFilteredMaterials.find(
      (m) => m._id === state.selectedMaterialId,
    )
  },
  selectedMaterialSample: (state, getters) => {
    return getters.materialFilteredmaterialSamples.find(
      (m) => m._id === state.selectedMaterialSampleId,
    )
  },
  selectedMixedSupplier: (state) => {
    return state.mixedSuppliers.find(
      (mS) => mS._id === state.selectedMixedSupplierId,
    )
  },
  mixDesignTableList: (state, getters) => {
    return state.mixDesigns.map((a) => ({
      id: a._id,
      mixDesignCode: a.mixDesignCode,
      asphaltType: a.asphaltType,
      jobsiteCount: a.jobsiteCount,
      sampleCount: a.sampleCount,
      testingDelay: a.testingDelay,
      IQEQMEL: getters.mixKpiByMetadata(kpiTypes.IQEQMEL, a._id),
    }))
  },
  mixFilteredAsphaltSamples: (state) => {
    return state.asphaltSamples.filter(
      (aS) => aS.mixDesign === state.selectedMixId,
    )
  },
  aggregateFilteredSamples: (state, getters) => {
    return getters.selectedAggregate?.samples
  },
  mixFilteredAsphaltKpis: (state) => {
    return state.asphaltKpis.filter(
      (aK) => aK.metadata.mixDesign === state.selectedMixId,
    )
  },

  kpiScore: (state, getters) => (name) => {
    const kpi = getters.mixFilteredAsphaltKpis.find(
      (k) => k.metadata.name === name,
    )

    return kpi
  },

  asphaltSamplePropertyTimeline: (state, getters) => {
    return getters.mixFilteredAsphaltSamples
      .filter((aS) => aS.asphaltProperties[state.selectedSamplePropertyId])
      .map(
        (aS) =>
          aS.asphaltProperties[state.selectedSamplePropertyId] -
          getters.selectedMixDesign.specifications[
            state.selectedSamplePropertyId
          ][3],
      )
      .slice(0, 10)
      .reverse()
  },
  availableAsphaltSampleProperties: (state, getters) => {
    let available = []
    const mixDesign = getters.selectedMixDesign

    if (mixDesign) {
      const specifications = Object.keys(mixDesign.specifications)
      available = asphaltSamplePropertyNames
        .toList()
        .filter((property) => specifications.includes(property))
    }

    return available
  },
  asphaltSampleProperties: (state, getters) => {
    const sample = getters.availableAsphaltSampleProperties
      .map((prop) => {
        const lastSample = getters.mixFilteredAsphaltSamples.find(
          (x) => x.asphaltProperties[prop],
        )?.asphaltProperties?.[prop]

        if (
          lastSample &&
          getters.availableAsphaltSampleProperties.includes(prop)
        ) {
          return {
            id: prop,
            name: prop,
            value:
              lastSample >= getters.selectedMixDesign.specifications[prop][3]
                ? _.min([
                    ((lastSample -
                      getters.selectedMixDesign.specifications[prop][3]) /
                      (getters.selectedMixDesign.specifications[prop][6] -
                        getters.selectedMixDesign.specifications[prop][3])) *
                      100,
                    100,
                  ])
                : _.max([
                    ((lastSample -
                      getters.selectedMixDesign.specifications[prop][3]) /
                      (getters.selectedMixDesign.specifications[prop][3] -
                        getters.selectedMixDesign.specifications[prop][0])) *
                      100,
                    -100,
                  ]),
          }
        }
      })
      .filter((prop) => prop)

    return sample
  },

  mixKpiByMetadata: (state) => (name, mixDesignId) => {
    return state.asphaltKpis.find(
      (aK) =>
        aK.metadata.mixDesign === mixDesignId && aK.metadata.name === name,
    )
  },
  mixFilteredMaterials: (state, getters) => {
    return getters.selectedMixDesign?.materials || []
  },
  mixFilteredMaterialsList: (state, getters) => {
    return getters.mixFilteredMaterials.filter((material) => {
      const materialSampleIndex = state.materialSamples.findIndex(
        (sample) =>
          sample.aggregate === material.specification &&
          sample.supplier === material.supplier,
      )
      return materialSampleIndex !== -1
    })
  },
  materialFilteredmaterialSamples: (state, getters) => {
    return getters.selectedMaterial
      ? state.materialSamples.filter(
          (aS) =>
            getters.selectedMaterial.specification === aS.aggregate &&
            getters.selectedMaterial.supplier === aS.supplier,
        )
      : []
  },
  materialFilteredKpis: (state, getters) => {
    return state.asphaltKpis.filter(
      (k) =>
        k.metadata.specification === getters.selectedMaterial?.specification &&
        k.metadata.supplier === getters.selectedMaterial?.supplier,
    )
  },
  mixFilteredMaterialKpis: (state, getters) => {
    return state.asphaltKpis.filter((k) =>
      getters.mixFilteredMaterials?.some(
        (mat) =>
          k.metadata.specification === mat.specification &&
          k.metadata.supplier === mat.supplier,
      ),
    )
  },
  materialKpiScore: (state, getters) => (name) => {
    const kpi = getters.materialFilteredKpis.find((k) => k.type === name)

    return kpi
  },
  availableMaterialSampleProperties: (state, getters) => {
    let available = []
    const material = getters.selectedMaterial

    if (material) {
      const specifications = Object.keys(material.specifications)
      available = materialSamplePropertyNames
        .toList()
        .filter((property) => specifications.includes(property))
    }

    return available
  },
  materialSampleProperties: (state, getters) => {
    const sample = materialSamplePropertyNames
      .toList()
      .map((prop) => {
        const lastSample = getters.materialFilteredmaterialSamples.find(
          (x) => x.aggregateProperties[prop],
        )?.aggregateProperties?.[prop]

        if (
          lastSample &&
          getters.availableMaterialSampleProperties.includes(prop)
        ) {
          return {
            id: prop,
            name: prop,
            value:
              lastSample >= getters.selectedMaterial.specifications[prop][3]
                ? _.min([
                    ((lastSample -
                      getters.selectedMaterial.specifications[prop][3]) /
                      (getters.selectedMaterial.specifications[prop][6] -
                        getters.selectedMaterial.specifications[prop][3])) *
                      100,
                    100,
                  ])
                : _.max([
                    ((lastSample -
                      getters.selectedMaterial.specifications[prop][3]) /
                      (getters.selectedMaterial.specifications[prop][3] -
                        getters.selectedMaterial.specifications[prop][0])) *
                      100,
                    -100,
                  ]),
          }
        }
      })
      .filter((prop) => prop)

    return sample
  },
  materialSamplePropertyTimeline: (state, getters) => {
    return getters.materialFilteredmaterialSamples
      .filter((aS) => aS.aggregateProperties[state.selectedMaterialPropertyId])
      .map(
        (aS) =>
          aS.aggregateProperties[state.selectedMaterialPropertyId] -
          getters.selectedMaterial.specifications[
            state.selectedMaterialPropertyId
          ][3],
      )
      .slice(0, 10)
      .reverse()
  },
  availableAggregateSampleProperties: (state, getters) => {
    let available = []
    const aggregate = getters.selectedAggregate

    if (aggregate) {
      const uniqueAggregateProperties = new Set([
        ...aggregate.samples.flatMap((sample) =>
          Object.keys(sample.aggregateProperties),
        ),
      ])
      const specifications = Object.keys(aggregate.specifications)
      available = aggregateSamplePropertyNames
        .toList()
        .filter(
          (property) =>
            uniqueAggregateProperties.has(property) &&
            specifications.includes(property),
        )
    }

    return available
  },
  aggregateSampleProperties: (state, getters) => {
    const sample = getters.availableAggregateSampleProperties
      .map((prop) => {
        const lastSample = getters.selectedAggregate?.samples?.find(
          (x) => x.aggregateProperties[prop],
        )?.aggregateProperties?.[prop]

        if (lastSample) {
          return {
            id: prop,
            name: prop,
            value:
              lastSample >= getters.selectedAggregate.specifications[prop][3]
                ? _.min([
                    ((lastSample -
                      getters.selectedAggregate.specifications[prop][3]) /
                      (getters.selectedAggregate.specifications[prop][6] -
                        getters.selectedAggregate.specifications[prop][3])) *
                      100,
                    100,
                  ])
                : _.max([
                    ((lastSample -
                      getters.selectedAggregate.specifications[prop][3]) /
                      (getters.selectedAggregate.specifications[prop][3] -
                        getters.selectedAggregate.specifications[prop][0])) *
                      100,
                    -100,
                  ]),
          }
        }
      })
      .filter((prop) => prop)

    return sample
  },
  aggregateFilteredKpis: (state) => {
    return state.aggregateKpis.filter(
      (aK) =>
        aK.metadata.specification === state.selectedAggregateSpecification &&
        aK.metadata.stockpile === state.selectedAggregateStockpile,
    )
  },

  aggregateKpiScore: (state, getters) => (name) => {
    return getters.aggregateFilteredKpis.find((k) => k.metadata.name === name)
  },

  aggregateKpiByMetadata: (state) => (name, spec, stockpile) => {
    return state.aggregateKpis.find(
      (aK) =>
        aK.metadata.specification === spec &&
        aK.metadata.stockpile === stockpile &&
        aK.metadata.name === name,
    )
  },
  aggregateTableList: (state, getters) => {
    return state.aggregates.map((a) => ({
      id: a.specification + '-' + a.stockpile,
      specification: a.specification,
      stockpile: a.stockpile,
      grade: a.specificationGrade,
      jobsiteCount: a.jobsiteCount,
      sampleCount: a.sampleCount,
      testingDelay: a.testingDelay,
      GQualMat: getters.aggregateKpiByMetadata(
        kpiTypes.GQUALMAT,
        a.specification,
        a.stockpile,
      ),
      GVarMatProd: getters.aggregateKpiByMetadata(
        kpiTypes.GVARMATPROD,
        a.specification,
        a.stockpile,
      ),
      GVarMatQua: getters.aggregateKpiByMetadata(
        kpiTypes.GVARMATQUA,
        a.specification,
        a.stockpile,
      ),
      GVarMatRecy: getters.aggregateKpiByMetadata(
        kpiTypes.GVARMATRECY,
        a.specification,
        a.stockpile,
      ),
      IQGQMAT: getters.aggregateKpiByMetadata(
        kpiTypes.IQGQMAT,
        a.specification,
        a.stockpile,
      ),
    }))
  },
  aggregateSamplePropertyTimeline: (state, getters) => {
    return (getters.selectedAggregate?.samples || [])
      .filter(
        (aS) => aS.aggregateProperties[state.selectedAggregateSampleProperty],
      )
      .map(
        (aS) =>
          aS.aggregateProperties[state.selectedAggregateSampleProperty] -
          getters.selectedAggregate.specifications[
            state.selectedAggregateSampleProperty
          ][3],
      )
      .slice(0, 10)
      .reverse()
  },
}

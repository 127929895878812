<template>
  <core-card>
    <core-callout :title="$t('views.production-foreman.supplierList')">
    </core-callout>
    <v-data-table
      :headers="headers"
      :items="items"
      style="overflow: auto"
      height="100%"
      dense
      fixed-header
      single-select
      disable-pagination
      hide-default-footer
      class="row-pointer"
    >
      <template #headerCell="{ header }">
        <span
          class="subheading font-weight-light text-success text--darken-3"
          v-text="header.text"
        />
      </template>
      <template #item="{ item }">
        <tr
          :class="{ 'grey lighten-2': item._id === selectedItem }"
          @click="onSupplierSelected(item)"
        >
          <td class="font-weight-bold">{{ $helpers.format.supplier(item) }}</td>
          <td class="text-end">
            <TrendChip
              v-if="item.kpi"
              :risk="item.kpi.risk"
              :date="new Date(item.kpi.date)"
            />
          </td>
        </tr>
      </template>
    </v-data-table>
  </core-card>
</template>

<script>
import TrendChip from '../Charts/TrendChip.vue'

export default {
  name: 'SupplierList',
  components: {
    TrendChip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItem: {
      type: String,
      required: true,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('enums.headers.supplierCity'),
          value: 'title',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.avgQualityIndex'),
          value: 'qualityIndex',
          sortable: false,
          align: 'end',
        },
      ]
    },
  },
  methods: {
    onSupplierSelected(value) {
      if (value._id !== this.selectedItem) {
        this.$emit('onSupplierSelected', value._id)
      }
    },
  },
}
</script>

<template>
  <div class="fill-height centered-container login-form">
    <div class="text-md-center">
      <h1 class="my-3 headline">
        {{ $t('views.notFound.headline') }}
      </h1>
      <div>
        <v-btn
          color="primary"
          @click="goHome"
        >
          {{ $t('views.notFound.goHome') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['landingPage']),
  },
  methods: {
    goHome() {
      if (this.landingPage) {
        this.$router.replace({ path: this.landingPage })
      } else {
        this.$router.replace({ path: '/' })
      }
    },
  },
}
</script>

<template>
  <div class="callout callout-flex">
    <div class="callout-title-container">
      <div class="callout-title">
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        class="callout-subtitle text--disabled"
      >
        {{ subtitle }}
      </div>
      <div>
        <slot name="extra"></slot>
      </div>
    </div>
    <div class="callout-trend">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CoreCallout',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<template>
  <expandable-card>
    <template #default="{ isExpanded }">
      <v-card-title v-if="isExpanded">
        {{ $t('views.technicalDirector.titles.controlAlerts') }}
        <v-spacer />
        <v-text-field
          v-model="search"
          :label="$t('views.technicalDirector.search')"
          single-line
          hide-details
          clearable
          clear-icon="$fas fa-xmark"
          prepend-inner-icon="$fas fa-search"
        />
      </v-card-title>

      <v-data-table
        :custom-sort="sortByKpiRiskAndDate"
        :dense="!isExpanded"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :search="search"
        :sort-desc="true"
        class="row-pointer overflow-auto"
        fixed-header
        height="100%"
        hide-default-footer
        sort-by="kpi"
        @click:row="(item) => onAlertClicked(item)"
      >
        <template #[`item.kpi`]="{ item }">
          <TrendChip
            :risk="item.kpi.risk"
            :date="item.kpi.date"
          />
        </template>
      </v-data-table>
    </template>
  </expandable-card>
</template>

<script>
import TrendChip from '../Charts/TrendChip.vue'
import customSortMixin from '@/mixins/customSortMixin'
import ExpandableCard from '@/components/core/ExpandableCard.vue'

export default {
  name: 'TDProductionAlerts',
  components: {
    TrendChip,
    ExpandableCard,
  },
  mixins: [customSortMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('views.technicalDirector.headers.project'),
          value: 'project',
        },
        {
          text: this.$t('views.technicalDirector.headers.result'),
          value: 'result',
        },
        {
          text: this.$t('views.technicalDirector.headers.kpi'),
          value: 'kpi',
        },
      ],
      search: '',
    }
  },

  methods: {
    onAlertClicked(item) {
      const routeData = this.$router.resolve({
        path: '/dashboard/site-control-technician',
        query: { project: item.projectId, user: item.user, date: item.date },
      })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>

export function arrayToCsv(headers, table) {
  let csv = ''
  let headerProps = headers.map((header) => header.value)

  csv += headers.map((header) => header.text).join(',')
  csv += '\r\n'
  csv += table
    .map((row) => {
      let line = ''

      line += headerProps
        .map((header) => {
          if (row[header] || row[header] === 0) {
            return row[header]
          }
          return ''
        })
        .join(',')

      return line
    })
    .join('\r\n')

  return csv
}

/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import i18n from '../i18n'
import Meta from 'vue-meta'

// Routes
import paths from './paths'

Vue.use(Router)
Vue.use(Meta)

// Create a new router
const router = new Router({
  base: '/ctl',
  mode: 'history',
  routes: paths,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  // close the snackbar if its visible
  if (store.state.snackbarOpened) {
    store.state.snackbarOpened = false
  }
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)
  if (nearestWithTitle) {
    document.title = i18n.t(nearestWithTitle.meta.title)
  }
  // Destination requires auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      // Check if user has associated session
      try {
        await store.dispatch('authenticate')
        // User info retrieved, proceed to destination
        next()
      } catch (err) {
        console.error(`Route Guard (router/index.js) ${err}`)
        // User info could NOT be retrieved, return to login
        next('/')
      }
      next()
    } else {
      next()
    }
    // Destination does NOT require auth
  } else {
    next()
  }
})

router.afterEach(() => {
  const clientVersionTimestamp = sessionStorage.getItem(
    'clientVersionTimestamp',
  )
  if (!clientVersionTimestamp) {
    sessionStorage.setItem('clientVersionTimestamp', Date.now())
  } else {
    const thirtyMinutes = 30 * 60 * 1000
    if (Date.now() - clientVersionTimestamp >= thirtyMinutes) {
      sessionStorage.setItem('clientVersionTimestamp', Date.now())
      location.reload()
    }
  }
})

export default router

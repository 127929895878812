<template>
  <v-snackbar
    v-model="snackbar"
    light
    multi-line
    vertical
    :timeout="snackTimeout"
    :color="snackColor"
  >
    {{ snackText }}

    <template #action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="closeSnackbar"
      >
        {{ $t('views.upload.close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'CoreSnackbar',
  computed: {
    ...mapState(['snackbarOpened', 'snackText', 'snackColor', 'snackTimeout']),
    snackbar: {
      get() {
        return this.snackbarOpened
      },
      set(value) {
        if (!value) this.closeSnackbar()
      },
    },
    isMultiLine() {
      return typeof this.snackText === 'string' && this.snackText.includes('\n')
    },
  },
  methods: {
    ...mapMutations(['closeSnackbar']),
  },
}
</script>

import resetState from '@/utils/resetState'
import { initialImagesState } from './state'
import { set } from '@/utils/vuex'

export default {
  clear: (state) => (state.items = []),

  deleteImage(state, imageId) {
    state.items = state.items.map((image) => {
      if (image._id === imageId) {
        return { ...image, deleted: true }
      }
      return image
    })
  },

  deleteImages(state, imageIds) {
    const ids = new Set(imageIds)

    state.items = state.items.map((image) => {
      if (ids.has(image._id)) {
        return { ...image, deleted: true }
      }
      return image
    })
  },

  restoreImages(state, imageIds) {
    const ids = new Set(imageIds)

    state.items = state.items.map((image) => {
      if (ids.has(image._id)) {
        return { ...image, deleted: false }
      }
      return image
    })
  },

  append: (state, items) => {
    const currentIds = state.items.map((_i) => _i._id)
    items
      .filter((_t) => !currentIds.includes(_t._id))
      .forEach((_i) => state.items.push(_i))
  },

  removeOne: (state, imageId) => {
    state.items.splice(
      state.items.findIndex((_i) => _i._id === imageId),
      1,
    )
  },

  setImages: set('items'),

  setCurrentImage(state, image) {
    state.currentImage = image
  },

  clearState(state) {
    resetState(state, initialImagesState)
  },
}

<template>
  <l-map
    ref="map"
    :zoom="zoom"
    :center="center"
  >
    <l-tile-layer
      :url="tileLayerUrl"
      :options="tileOptions"
    />
    <l-marker
      v-for="(image, index) in combinedImages"
      :key="index"
      :lat-lng="[image.latitude, image.longitude]"
      @click="onMarkerClick(image)"
    >
      <l-icon
        :icon-size="[iconSize, iconSize]"
        class-name="leaflet-marker-photo"
      >
        <div>
          <img :src="image.thumbnail" />
        </div>
      </l-icon>
    </l-marker>
    <ResizableImageDialog ref="imageDialog" />
  </l-map>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'
import { defaultGeolocation } from '@/utils/constants'
import ResizableImageDialog from '@/components/DashViews/Image/ResizableImageDialog'
import { latLngBounds } from 'leaflet'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    ResizableImageDialog,
  },

  props: {
    images: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      zoom: 16,
      tileOptions: {
        maxZoom: 20,
        maxNativeZoom: 18,
      },
      iconSize: 50,
      tileLayerUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      center: defaultGeolocation,
      projectImages: [],
    }
  },

  computed: {
    ...mapState(['idproject']),

    combinedImages() {
      return [...this.images, ...this.projectImages]
    },
  },

  watch: {
    images() {
      this.setImageLocations(this.combinedImages)
    },
    idproject(newProjectId) {
      this.initProjectImages(newProjectId)
    },
  },

  created() {
    this.initProjectImages(this.idproject)
  },

  methods: {
    ...mapActions('images', ['getProjectImages']),
    ...mapMutations('images', ['setImages']),

    async initProjectImages(projectId) {
      this.projectImages = []
      const images = await this.getProjectImages(projectId)
      const formattedImages = images.map((image) => ({
        _id: image._id,
        name: image.originalFilename,
        latitude: image.datapoint.location.coordinates[1],
        longitude: image.datapoint.location.coordinates[0],
        thumbnail: '',
      }))

      this.projectImages = formattedImages
      this.setImages(formattedImages)
      this.setImageLocations(formattedImages)

      this.projectImages.forEach((formattedImage) => {
        formattedImage.thumbnail = `${process.env.VUE_APP_BASE_URL}/images/thumbnail/${formattedImage._id}`
      })
    },

    setImageLocations(images) {
      if (!images?.length) {
        this.$refs.map.mapObject.setView(defaultGeolocation, 16)
        return
      }

      const imageLocations = images.map((image) => [
        image.latitude,
        image.longitude,
      ])
      const bounds = latLngBounds([imageLocations]).pad(0.2)
      this.$refs.map.fitBounds(bounds)
    },

    onMarkerClick(image) {
      this.$refs.imageDialog.show(image)

      const bounds = latLngBounds([[image.latitude, image.longitude]]).pad(0.2)
      this.$refs.map.fitBounds(bounds)
    },
  },
}
</script>

<template>
  <core-card>
    <core-callout :title="title">
      <trend-chip
        :trend="trend"
        :date="lastUpdate"
      />
    </core-callout>
    <div class="gauge-container-svg overflow-hidden">
      <GaugeChartSVG :score="score" />
      <div :class="['gauge-label', labelClass]">
        {{ gaugeLabel }}
      </div>
    </div>
  </core-card>
</template>

<script>
import { risk as riskEnum, trend as trendEnum } from '@/utils/enum'
import TrendChip from './TrendChip.vue'
import GaugeChartSVG from './GaugeChartSVG.vue'

export default {
  name: 'GaugeChart',
  components: {
    TrendChip,
    GaugeChartSVG,
  },
  props: {
    kpi: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    title: {
      type: String,
      required: true,
    },
  },
  computed: {
    risk() {
      let risk = riskEnum.NA

      if (this.kpi) {
        risk =
          this.kpi.risk || this.score < 33
            ? riskEnum.HR
            : this.score < 66
              ? riskEnum.AR
              : riskEnum.LR
      }

      return risk
    },
    trend() {
      return this.kpi?.trend || trendEnum.NA
    },
    lastUpdate() {
      return (
        (this.kpi?.updatedAt && new Date(this.kpi.updatedAt)) ||
        (this.kpi?.date && new Date(this.kpi.date))
      )
    },
    score() {
      let score = 0

      if (this.kpi) {
        if (this.kpi.weight) {
          score = Math.round((this.kpi.score / this.kpi.weight) * 100)
        } else {
          score = this.kpi.score
        }
      }
      return score
    },
    gaugeLabel() {
      let label = '--'

      if (this.kpi) label = this.score

      return label
    },
    labelClass() {
      let label = {}

      if (this.risk === riskEnum.NA) {
        label['text-secondary'] = true
      } else if (this.score > 66) {
        label['text-success'] = true
      } else if (this.score > 33) {
        label['text-warning'] = true
      } else {
        label['text-danger'] = true
      }

      return label
    },
  },
}
</script>

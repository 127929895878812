import axios from 'axios'
import {
  notificationsFromProductionKpis,
  productNotificationsFromKpis,
} from '@/utils/notifications'
import { productTypes } from '@/utils/enum'

export default {
  async fetchPlants({ commit }) {
    const { data } = await axios.get('/references/plants')

    commit('updatePlants', data)
  },
  async fetchSuppliers({ commit }) {
    const { data } = await axios.get('/references/suppliers')

    commit('updateSuppliers', data)
  },

  async fetchProductionTechnicianDashboard(
    { commit, rootGetters },
    { plantId, includeJobsites = false },
  ) {
    const { data } = await axios.get('/dashboards/production-technician', {
      params: {
        plant: plantId,
        includeJobsites,
      },
    })
    const { asphaltSamples, mixDesigns, kpis, aggregateSamples } = data

    const notifications = notificationsFromProductionKpis(
      kpis,
      rootGetters.userId,
    )

    commit('updateNotifications', notifications, { root: true })
    commit('updateAsphaltSamples', asphaltSamples)
    commit('updateMixDesigns', mixDesigns)
    commit('updateAsphaltKpis', kpis)
    commit('updateMaterialSamples', aggregateSamples)
  },

  async fetchAggregateProductionTechnicianDashboard(
    { commit, rootGetters },
    { supplierId, includeJobsites = false },
  ) {
    const { data } = await axios.get('/dashboards/aggregate-technician', {
      params: {
        supplier: supplierId,
        includeJobsites,
      },
    })

    const { aggregates, kpis } = data
    const notifications = notificationsFromProductionKpis(
      kpis,
      rootGetters.userId,
    )

    commit('updateNotifications', notifications, { root: true })
    commit('updateAggregates', aggregates)
    commit('updateAggregateKpis', kpis)
  },

  async fetchProductionForemanDashboard({ commit }, foremanType) {
    let endpoint = '/dashboards/production-foreman'
    if (foremanType) {
      endpoint += `/${foremanType}`
    }
    const { data } = await axios.get(endpoint)
    const { suppliers } = data

    commit('updateMixedSuppliers', suppliers)
  },

  changeSelectedMixDesign(
    { state, commit, getters, rootGetters },
    { mixDesignId },
  ) {
    commit('selectMixDesign', mixDesignId)
    commit(
      'selectAsphaltSample',
      getters.mixFilteredAsphaltSamples[0]?._id || undefined,
    )
    commit(
      'selectAsphaltSampleProperty',
      getters.availableAsphaltSampleProperties[0],
    )
    const filter = { type: productTypes.ASPHALT, mixDesign: mixDesignId }
    const notifications = productNotificationsFromKpis(
      state.asphaltKpis,
      rootGetters.userId,
      filter,
    )
    commit('updateNotifications', notifications, { root: true })
  },

  async changeSelectedMaterial({ commit, getters }, { material }) {
    commit('selectMaterial', material)

    if (getters.materialFilteredmaterialSamples[0]) {
      commit(
        'selectMaterialSample',
        getters.materialFilteredmaterialSamples[0]._id,
      )
      commit(
        'selectMaterialSampleProperty',
        getters.availableMaterialSampleProperties[0],
      )
    }
  },

  changeSelectedAggregate(
    { state, commit, getters, rootGetters },
    { specification, stockpile },
  ) {
    commit('selectAggregateSpecification', specification)
    commit('selectAggregateStockpile', stockpile)
    commit(
      'selectAggregateSampleProperty',
      getters.availableAggregateSampleProperties[0],
    )
    const filter = { type: productTypes.AGGREGATE, specification, stockpile }
    const notifications = productNotificationsFromKpis(
      state.aggregateKpis,
      rootGetters.userId,
      filter,
    )
    commit('updateNotifications', notifications, { root: true })
  },

  async updateProductionSiteAccess(_, productionSites) {
    await axios.put('/references/access', productionSites)
  },
}

<template>
  <v-wait for="areSourcesUpdating">
    <core-spinner slot="waiting" />
    <v-container fluid>
      <v-row>
        <v-col sm12>
          <material-card outlined>
            <div>
              <div class="subtitle-1 ml-2">
                {{ $t('views.image-upload.title') }}
              </div>
              <div class="text-caption text--disabled ml-2"><br /></div>
            </div>
            <v-row>
              <v-col
                md="6"
                xs="12"
                cols="12"
              >
                <v-file-input
                  v-model="file"
                  :label="$t('views.image-upload.image-input-label')"
                  @change="readFile"
                />
              </v-col>
              <v-col
                md="6"
                xs="12"
                cols="12"
              >
                <v-select
                  v-model="selectedSource"
                  :items="sources"
                  :label="$t('views.image-upload.source-select-label')"
                  class="select-no-border"
                />
              </v-col>
            </v-row>
            <v-row v-if="!!file">
              <v-col
                cols="12"
                md="6"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="picker"
                      v-bind="attrs"
                      label="Date"
                      prepend-icon="$fa fa-calendar"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="picker"
                    @input="menu2 = false"
                  />
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="selectedLayer"
                  :items="[
                    { text: $t('enums.layers.rolling'), value: 'rolling' },
                    {
                      text: $t('enums.layers.correction'),
                      value: 'correction',
                    },
                    {
                      text: $t('enums.layers.intermediate'),
                      value: 'intermediate',
                    },
                    { text: $t('enums.layers.base'), value: 'base' },
                    { text: $t('enums.layers.leveling'), value: 'leveling' },
                    {
                      text: $t('enums.layers.cold-reprocessing'),
                      value: 'cold-reprocessing',
                    },
                    {
                      text: $t('enums.layers.foundations'),
                      value: 'foundations',
                    },
                  ]"
                  :label="$t('enums.headers.layer')"
                  class="select-no-border"
                />
              </v-col>

              <v-col
                cols="12"
                offset-md="4"
                md="8"
              >
                <v-btn-toggle
                  v-model="locationType"
                  class="flex-wrap"
                >
                  <v-btn
                    v-if="geoLocationEnabled"
                    value="current"
                    @click="getCurrentLocation"
                  >
                    {{ $t('views.image-upload.location-types.current') }}
                  </v-btn>
                  <v-btn
                    :disabled="projectBtnDisabled"
                    value="project"
                    @click="getProjectLocation"
                  >
                    {{ $t('views.image-upload.location-types.project') }}
                  </v-btn>
                  <v-btn
                    :disabled="!fileLocation"
                    value="image"
                    @click="useImageLocation"
                  >
                    {{ $t('views.image-upload.location-types.image') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-img
                  :src="fileBlob"
                  height="400"
                />
              </v-col>

              <v-col
                cols="12"
                md="8"
              >
                <LocationPicker
                  :marker="marker"
                  @markerUpdated="markerUpdated($event)"
                />
                <div class="subtitle-2">
                  {{ $t('views.image-upload.location-picker-subtitle') }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  v-if="!!file && !!marker"
                  outlined
                  block
                  @click="sendFile"
                >
                  {{ $t('views.upload.confirmation') }}
                  <v-icon
                    right
                    dark
                    >$fas fa-upload
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </material-card>
        </v-col>
      </v-row>
      <ImageViewer ref="imageViewer" />
      <v-dialog
        v-model="dialog"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            {{ $t('views.global.please-wait') }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-wait>
</template>

<script>
import exifr from 'exifr'
import LocationPicker from './LocationPicker.vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ImageViewer from '@/components/DashViews/Image/Viewer'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { mapWaitingActions } from 'vue-wait'
import { defaultGeolocation } from '@/utils/constants'
import { isEmpty, isEqual } from 'lodash'

dayjs.extend(customParseFormat)

export default {
  name: 'ImageUpload',
  components: {
    ImageViewer,
    LocationPicker,
  },
  data() {
    return {
      file: null,
      fileBlob: null,
      locationType: null,
      fileLocation: null,
      geoLocationEnabled: !!navigator.geolocation,
      userLocation: null,
      err: 'undifined error',
      sources: [],
      selectedSource: null,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu2: false,
      selectedLayer: null,
      dialog: false,
      marker: defaultGeolocation,
    }
  },
  computed: {
    ...mapState(['idproject', 'projects']),
    ...mapGetters(['projectLocation']),
    projectBtnDisabled() {
      return this.projectLocation === defaultGeolocation
    },
  },
  watch: {
    idproject() {
      this.updateSources()
    },

    projects(newVal, oldVal) {
      if (isEmpty(oldVal) && !isEmpty(newVal)) {
        this.updateSources()
      }
    },
  },
  created() {
    this.$store.commit('updateNotifications', [])
    // only update the sources selection list if the projects are loaded in the store
    if (!isEmpty(this.projects)) {
      this.updateSources()
    }
  },
  methods: {
    ...mapActions({
      uploadImage: 'images/uploadImage',
    }),
    ...mapWaitingActions({ getSources: 'areSourcesUpdating' }),
    ...mapMutations(['showSuccess', 'showWarning', 'showError']),

    async readFile(file) {
      this.file = file
      if (this.file) {
        if (!file.type.startsWith('image')) {
          this.showWarning(this.$t(`views.image-upload.file-type-error`))
          this.file = null
          return
        }

        this.fileBlob = URL.createObjectURL(this.file)

        const location = await exifr.gps(this.file)
        this.fileLocation = location
          ? [location.latitude, location.longitude]
          : null
        if (this.fileLocation) {
          this.useImageLocation()
        }

        const output = await exifr.parse(this.file, ['DateTimeOriginal'])
        let date = dayjs(output.DateTimeOriginal, 'YYYY:MM:DD HH:mm:ss')
        this.picker = date.isValid()
          ? date.format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD')
      } else {
        this.fileBlob = null
      }
    },

    async sendFile() {
      if (this.selectedLayer == null) {
        this.showError(this.$t('views.image-upload.layer-select'))
        return
      }

      try {
        this.dialog = true
        await this.uploadImage({
          file: this.file,
          location: [this.marker[1], this.marker[0]],
          sourceId: this.selectedSource,
          projectId: this.idproject,
          date: dayjs(this.picker, 'YYYY-MM-DD').toDate(),
          layer: this.selectedLayer,
        })
        this.dialog = false
        this.showSuccess(this.$t('views.image-upload.data-saved'))
        await this.$refs.imageViewer.refreshImages()
      } catch (err) {
        this.dialog = false
      }

      this.dialog = false
      this.file = null
      this.fileLocation = null
      this.selectedSource = null
    },

    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.userLocation = [
            position.coords.latitude,
            position.coords.longitude,
          ]
          this.setMarker(this.userLocation)
          this.locationType = 'current'
        },
        () =>
          this.showWarning(this.$t('views.image-upload.geolocation-api-error')),
      )
    },

    getProjectLocation() {
      this.setMarker(this.projectLocation)
      this.locationType = 'project'
    },

    useImageLocation() {
      this.setMarker(this.fileLocation)
      this.locationType = 'image'
    },

    markerUpdated(event) {
      this.marker = event
      if (
        !isEqual(event, this.fileLocation) &&
        !isEqual(event, this.userLocation) &&
        !isEqual(event, this.projectLocation)
      ) {
        this.locationType = 'custom'
      }
    },

    setMarker(location) {
      this.marker = location
    },

    updateSources() {
      const defaultImageSource = 'default-image-bucket'

      const project = this.projects.find((_p) => _p._id === this.idproject)
      this.getSources().then((sources) => {
        this.sources = sources
          .filter(
            (_s) =>
              project.sources.includes(_s._id) &&
              _s.type !== defaultImageSource,
          )
          .concat({ origin: 'nexso', type: defaultImageSource, _id: null }) // add the default value
          .map((_s) => ({ text: `${_s.origin} - ${_s.type}`, value: _s._id }))
      })
    },
  },
}
</script>

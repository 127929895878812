// https://vuex.vuejs.org/en/mutations.html
import Vue from 'vue'
import { formatServerError } from '@/utils/apiUtil'
import dayjs from 'dayjs'
import i18n from '../i18n/index'
import { initialRootState } from './state'
import resetState from '@/utils/resetState'

export default {
  user(state, user) {
    state.user = user
    state.userId = user.id
  },
  login(state) {
    state.isLoggedIn = true
  },
  logout(state) {
    state.isLoggedIn = false
    sessionStorage.clear()
  },
  updateLanguage(state, language) {
    localStorage.language = language
    state.language = language
    dayjs.locale(language)
    i18n.locale = language
  },
  setTableList(state, tableList) {
    state.tableList = tableList
  },
  updateProjectList(state, projects) {
    Vue.set(state, 'projects', projects)
  },
  setSelectedDate(state, date) {
    state.selectedDate = date
  },
  updateDateList(state, dates) {
    Vue.set(state, 'dates', dates)
    if (state.dates.length && !state.dates.includes(state.selectedDate)) {
      state.selectedDate = state.dates[0]
    }
  },
  updateDatapointList(state, datapoints) {
    Vue.set(state, 'datapoints', datapoints)
  },
  updateKpiList(state, kpi) {
    Vue.set(state, 'kpi', kpi)
  },
  updateProdKpiList(state, kpi) {
    Vue.set(state, 'kpiProduction', kpi)
  },
  updateNotifications(state, notifications) {
    Vue.set(state, 'notifications', notifications)
  },
  changeProject(state, idproject) {
    Vue.set(
      state,
      'project',
      state.projects?.find((p) => p._id === idproject),
    )
    state.idproject = idproject
    sessionStorage.idproject = idproject
  },
  updateLastVisited(state, projectId) {
    const selectedOrg = state.user.selectedOrganization

    if (!state.user) {
      return
    } else if (state.user && !state.user.lastVisited) {
      state.user.lastVisited = {}
    }

    state.user.lastVisited[selectedOrg] = projectId
  },
  setProject(state, project) {
    state.idproject = project._id
    sessionStorage.idproject = project._id
    Vue.set(state, 'project', project)
  },
  setIsClientDashboard(state, bool) {
    state.isClientDashboard = bool
  },
  showInfo(state, message) {
    state.snackbarOpened = true
    state.snackColor = 'info'
    state.snackTimeout = 5000
    state.snackText = message
  },
  showSuccess(state, message) {
    state.snackbarOpened = true
    state.snackColor = 'success'
    state.snackTimeout = 5000
    state.snackText = message
  },
  showWarning(state, message) {
    state.snackbarOpened = true
    state.snackColor = 'warning'
    state.snackTimeout = 5000
    state.snackText = message
  },
  showDanger(state, message) {
    state.snackbarOpened = true
    state.snackColor = 'error'
    state.snackTimeout = 5000
    state.snackText = message
  },
  showError(state, err) {
    state.snackbarOpened = true
    state.snackColor = 'error'
    state.snackTimeout = -1
    state.snackText = formatServerError(err)
  },
  closeSnackbar(state) {
    state.snackbarOpened = false
  },
  addToMonthYearList(state, monthYearString) {
    state.monthYearList.push(monthYearString)
  },
  addToDataList(state, dataList) {
    state.dataList.push(...dataList)
  },
  setContractorKpis(state, kpis) {
    state.contractorKpis = kpis
  },
  addToContractorDatePickerList(state, dates) {
    state.contractorDatePickerList.push(...dates)
  },
  addToProjectManagerDatePickerList(state, dates) {
    state.projectManagerDatePickerList.push(...dates)
  },
  clearRootState(state) {
    resetState(state, initialRootState)
  },
  updateGMContractorData(state, data) {
    Vue.set(state, 'generalManagerContractorData', data)
  },
  setSelectedDates(state, dates) {
    Vue.set(state, 'selectedDates', dates)
  },
  setIsCardExpanded(state, boolean) {
    Vue.set(state, 'isCardExpanded', boolean)
  },
}

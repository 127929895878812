import axios from 'axios'
import multipartDataUtil from '@/utils/multipartDataUtil'
import { pick } from 'lodash'

export default {
  async fetchReports({ commit }) {
    const { data } = await axios.get('/reports')
    commit('update', data)
  },

  async fetchById({ commit }, reportId) {
    const { data } = await axios.get(`/reports/${reportId}`)
    commit('updateOne', data)
  },

  async create(_, payload) {
    const { data } = await axios.post(
      '/reports',
      multipartDataUtil
        .createFormDataBuilder()
        .addFields(
          pick(payload, [
            'type',
            'project',
            'source',
            'author',
            'impact',
            'description',
            'nature',
          ]),
        )
        .addFiles(
          'memoPicture',
          payload.memoPicture ? [payload.memoPicture] : [],
        )
        .addFiles('pictures', payload.pictures)
        .build(),
    )
    return data
  },

  async update({ commit }, payload) {
    const { data } = await axios.patch('reports', payload)
    commit('updateOne', data)
  },

  async markAsRead(_, payload) {
    await axios.patch('reports/read', payload)
  },
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",attrs:{"outlined":"","options":_vm.options,"type":"Map"}},[_c('l-map',{ref:"mMap",staticStyle:{"min-height":"none","z-index":"1"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"options":_vm.mapOptions},on:{"update:center":_vm.centerUpdate,"update:zoom":_vm.zoomUpdate}},[_c('l-control-layers',{attrs:{"position":"bottomright"}}),_vm._l((_vm.tileProviders),function(tileProvider){return _c('l-tile-layer',{key:tileProvider.name,attrs:{"name":tileProvider.name,"visible":tileProvider.visible,"url":tileProvider.url,"attribution":tileProvider.attribution,"layer-type":"base"}})}),_c('l-control',[_c('router-link',{staticClass:"toolbar-items",attrs:{"to":_vm.$route.path + '/details'}},[_c('v-icon',[_vm._v("$fas fa-expand-alt")])],1)],1),_vm._l((_vm.shownCircles),function(element,index){return _c('l-circle',{key:index,attrs:{"lat-lng":[
        element.location.coordinates[1],
        element.location.coordinates[0],
      ],"fill-color":_vm.circleColor(element),"radius":_vm.circleRadius(element),"color":_vm.circleColor(element),"fill-opacity":0.8,"opacity":0.8},on:{"click":function($event){return _vm.innerClick(index)}}})})],2),_c('data-point-pop-up',{attrs:{"pop-uptitle":'Data point details',"data-point":_vm.clickedDatapoint,"data-filter-out":[
      '_id',
      'color',
      'cl_attributes',
      'asset_id',
      'group_id',
      'funccclass_id',
      'rubix_conditionclass_id',
      'sub_conditionclass_id',
      'zz_agency_cl_id',
      'ObjectIdTotalpave',
      'ObjectIdClasse1',
    ]},on:{"closePopUp":_vm.closeModal},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import i18n from '@/i18n'
import { defaultGeolocation } from '@/utils/constants'

export default {
  async getCurrentPosition({ commit }) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = [position.coords.latitude, position.coords.longitude]
        commit('setLocalisation', location)
        commit('setUserLocated', true)
      },
      (error) => {
        console.error(error)
        commit('showDanger', i18n.t('views.project.userLocationErrorAction'), {
          root: true,
        })
        commit('setLocalisation', defaultGeolocation)
        commit('setUserLocated', false)
      },
      { enableHighAccuracy: false, maximumAge: 1200, timeout: 10000 },
    )
  },

  async startWatchingPosition({ commit, state }) {
    if (!state.localisationWatcherId) return

    const watcherId = navigator.geolocation.watchPosition(
      (position) => {
        commit('setLocalisation', [position.lat, position.lng])
      },
      () => {},
      {
        enableHighAccuracy: true,
        maximumAge: 2000,
        timeout: 7500,
      },
    )

    commit('setLocalisationWatcherId', watcherId)
  },

  async stopWatchingPosition({ commit, state }) {
    if (!state.localisationWatcherId) return

    navigator.geolocation.clearWatch(state.localisationWatcherId)

    commit('setLocalisationWatcherId', null)
  },
}

<template>
  <v-card
    outlined
    class="fill-height v-card-dense expandable-card"
  >
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        :items-per-page="-1"
        :sort-desc="[true, true]"
        :sort-by="['date', 'sampleNumber']"
        multi-sort
        class="overflow-auto larger-header"
        fixed-header
        height="100%"
      >
        <template #body.prepend="">
          <tr>
            <td
              v-for="header in headers"
              :key="header.value"
              class="sticky-td"
            >
              {{ gmfValue(header.value) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  productTypes,
  asphaltSamplePropertyNames,
  aggregateSamplePropertyNames,
  materialSamplePropertyNames,
} from '@/utils/enum'
import { formatDate } from '@/utils/date'
import { mapGetters } from 'vuex'

export default {
  name: 'TDProductionDialogTable',
  inject: ['type'],

  computed: {
    ...mapGetters('production', [
      'mixFilteredAsphaltSamples',
      'aggregateFilteredSamples',
      'materialFilteredmaterialSamples',
      'selectedMixDesign',
      'selectedAggregate',
    ]),

    headers() {
      const result = [
        {
          text: this.$t('views.technicalDirector.headers.sampleNumber'),
          value: 'sampleNumber',
        },
        {
          text: this.$t('views.technicalDirector.headers.date'),
          value: 'date',
        },
      ]

      if (this.type === productTypes.ASPHALT) {
        return [
          ...result,
          ...asphaltSamplePropertyNames.toList().map((property) => ({
            text: this.$t(`enums.sampleProperties.${property}`),
            value: `asphaltProperties.${property}`,
          })),
        ]
      } else if (this.type === productTypes.AGGREGATE) {
        return [
          ...result,
          ...aggregateSamplePropertyNames.toList().map((property) => ({
            text: this.$t(`enums.aggregateProperties.${property}`),
            value: `aggregateProperties.${property}`,
          })),
        ]
      } else if (this.type === productTypes.MATERIAL) {
        return [
          ...result,
          ...materialSamplePropertyNames.toList().map((property) => ({
            text: this.$t(`enums.aggregateProperties.${property}`),
            value: `materialProperties.${property}`,
          })),
        ]
      }

      return []
    },

    items() {
      if (this.type === productTypes.ASPHALT) {
        return this.mixFilteredAsphaltSamples.map((sample) => {
          return {
            sampleNumber: sample.sampleNumber,
            date: formatDate(sample.date),
            asphaltProperties: sample.asphaltProperties,
          }
        })
      } else if (this.type === productTypes.AGGREGATE) {
        return this.aggregateFilteredSamples.map((sample) => {
          return {
            sampleNumber: sample.sampleNumber,
            date: formatDate(sample.date),
            aggregateProperties: sample.aggregateProperties,
          }
        })
      } else if (this.type === productTypes.MATERIAL) {
        return this.materialFilteredmaterialSamples.map((sample) => {
          return {
            sampleNumber: sample.sampleNumber,
            date: formatDate(sample.date),
            materialProperties: sample.aggregateProperties,
          }
        })
      }

      return []
    },

    specification() {
      if (this.type === productTypes.ASPHALT) {
        return this.formatSpecification(
          this.selectedMixDesign?.specifications,
          'asphaltProperties',
        )
      } else if (this.type === productTypes.AGGREGATE) {
        return this.formatSpecification(
          this.selectedAggregate?.specifications,
          'aggregateProperties',
        )
      } else if (this.type === productTypes.MATERIAL) {
        return this.formatSpecification(
          this.selectedAggregate?.specifications,
          'materialProperties',
        )
      }

      return {}
    },
  },

  methods: {
    formatSpecification(specifications, key) {
      const result = {
        sampleNumber: this.$t('views.technicalDirector.headers.gmf'),
        date: '-',
      }

      for (const [property, range] of Object.entries(specifications || {})) {
        result[`${key}.${property}`] = range[3]
      }

      return result
    },

    gmfValue(property) {
      if (typeof this.specification[property] === 'number') {
        return this.specification[property].toFixed(2)
      } else if (typeof this.specification[property] === 'string') {
        return this.specification[property]
      }
      return ''
    },
  },
}
</script>
<style>
table > tbody > tr > .sticky-td {
  background: #ffffff;
  position: sticky;
  top: 32px;
  z-index: 2;
  font-weight: bold;
}

.larger-header th > span {
  font-size: 14px !important;
}
</style>

<template>
  <l-marker
    :lat-lng="geoJsonToMarker(datapoint.location)"
    :options="{ point: datapoint }"
    @click="$emit('markerClicked')"
  >
    <l-popup>
      <strong>
        {{ datapointHeader }}
      </strong>
      <ul>
        <li>{{ datapoint.station || `Point ${datapointIndex + 1}` }}</li>
        <li
          v-for="(num, index) in datapointDetails.count"
          :key="num"
        >
          {{ datapointDetails.content[index] }}
        </li>
      </ul>
      <br />
      <a @click="$emit('popupActivated', datapointIndex)">{{
        $t('views.widget.learnMore')
      }}</a>
    </l-popup>
    <l-icon
      v-if="!usesCustomIcon"
      :icon-size="[32, 32]"
      :icon-anchor="[16, 16]"
      :icon-url="markerIconUrl()"
    />
    <l-icon
      v-else
      :icon-size="[24, 24]"
      :icon-anchor="[12, 12]"
      :popup-anchor="[0, -12]"
    >
      <v-icon :color="iconColor">
        {{ iconName }}
      </v-icon>
    </l-icon>
  </l-marker>
</template>

<script>
import { geoJsonToMarker } from '@/utils/map'
import { risk as riskEnum, datapoints as datapointTypes } from '@/utils/enum'
import { LMarker, LPopup, LIcon } from 'vue2-leaflet'

export default {
  components: {
    LMarker,
    LPopup,
    LIcon,
  },
  props: {
    datapoint: {
      type: Object,
      required: true,
    },
    datapointIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      geoJsonToMarker,
      datapointTypes,
      compactionTypes: new Set([
        datapointTypes.SITE_AGGREGATE_TEST,
        datapointTypes.SITE_AGGREGATE_STAB_TEST,
        datapointTypes.SITE_AGGREGATE_TESTSLAB_TEST,
        datapointTypes.SITE_ASPHALT_COMPACTION_MTQ,
        datapointTypes.SITE_ASPHALT_COMPACTION_CONTRACTOR,
      ]),
    }
  },
  computed: {
    datapointDetails() {
      if (this.isIRI) {
        return {
          count: 1,
          content: [`IRI: ${this.datapoint.IRI}`],
        }
      } else if (this.isCompaction) {
        const text =
          this.$t('enums.headers.compaction') +
          ': ' +
          this.$t('core.unit.percent', {
            n: this.datapoint.compaction.$numberDecimal,
          })
        return {
          count: 1,
          content: [text],
        }
      } else if (this.isSpreadRate) {
        const thicknessText =
          this.$t('enums.headers.spreadRateThickness') +
          ': ' +
          this.datapoint.spreadRateThickness.$numberDecimal +
          ' mm'
        const spreadRateText =
          this.$t('enums.headers.spreadRate') +
          ': ' +
          this.datapoint.spreadRate.$numberDecimal +
          ' kg/m²'
        return {
          count: 2,
          content: [thicknessText, spreadRateText],
        }
      } else if (this.isTemperature) {
        const surfaceText =
          this.$t('enums.headers.temperatureSurface') +
          ': ' +
          this.datapoint.temperatureSurface.$numberDecimal +
          ' °C'
        const truckText =
          this.$t('enums.headers.temperatureTruck') +
          ': ' +
          this.datapoint.temperatureTruck.$numberDecimal +
          ' °C'
        return {
          count: 2,
          content: [surfaceText, truckText],
        }
      } else if (this.isAsphalt) {
        const asphaltSampleTemperatureText =
          this.$t('enums.headers.asphaltSampleTemperature') +
          ': ' +
          this.datapoint.asphaltSampleTemperature.$numberDecimal +
          ' °C'
        return {
          count: 1,
          content: [asphaltSampleTemperatureText],
        }
      }

      return {
        count: 0,
        content: '',
      }
    },

    isIRI() {
      return this.datapoint.type === datapointTypes.IRI
    },

    isCompaction() {
      return (
        this.compactionTypes.has(this.datapoint.type) &&
        this.datapoint.compaction
      )
    },

    isSpreadRate() {
      return (
        this.datapoint.type === datapointTypes.SITE_ASPHALT_SPREAD_RATE &&
        (this.datapoint.spreadRateThickness || this.datapoint.spreadRate)
      )
    },

    isTemperature() {
      return (
        this.datapoint.type === datapointTypes.SITE_ASPHALT_TEMPERATURE &&
        (this.datapoint.temperatureSurface || this.datapoint.temperatureTruck)
      )
    },

    isAsphalt() {
      return (
        this.datapoint.type === datapointTypes.SITE_ASPHALT_SAMPLE_TEST &&
        this.datapoint.asphaltSampleTemperature
      )
    },

    isSample() {
      return (
        this.datapoint.type === datapointTypes.SITE_AGGREGATE_SAMPLE_TEST ||
        this.datapoint.type === datapointTypes.SITE_ASPHALT_SAMPLE_TEST
      )
    },

    isImage() {
      return this.datapoint.type === datapointTypes.IMAGE
    },

    isSection() {
      return (
        this.datapoint.type === datapointTypes.SITE_AGGREGATE_SECTION ||
        this.datapoint.type === datapointTypes.SITE_ASPHALT_SECTION
      )
    },

    datapointHeader() {
      if (this.isSection) {
        return `${this.$t(`enums.type.${this.datapoint.type}`)} - ${
          this.datapoint.isStart
            ? this.$t('views.project.startTooltip')
            : this.$t('views.project.endTooltip')
        }`
      }

      return `${this.$t(`enums.type.${this.datapoint.type}`)}`
    },

    iconColor() {
      switch (this.datapoint.risk) {
        case riskEnum.LR:
          return '#05A57D'
        case riskEnum.AR:
          return '#FFBD21'
        case riskEnum.HR:
          return '#FF6666'
        default:
          return 'grey'
      }
    },

    iconName() {
      if (this.isSpreadRate) {
        return '$fas fa-ruler-vertical'
      } else if (this.isTemperature) {
        return '$fas fa-thermometer-half'
      } else if (this.isSample) {
        return '$fas fa-flask'
      } else if (this.isImage) {
        return '$fas fa-image'
      }
      return 'fas fa-map-marker-alt'
    },

    usesCustomIcon() {
      return (
        this.isSpreadRate || this.isTemperature || this.isSample || this.isImage
      )
    },
  },

  methods: {
    markerIconUrl() {
      switch (this.datapoint.risk) {
        case riskEnum.LR:
          return '/img/map_marker_green.png'
        case riskEnum.AR:
          return '/img/map_marker_yellow.png'
        case riskEnum.HR:
          return '/img/map_marker_red.png'
        default:
          return '/img/map_marker_gray.png'
      }
    },
  },
}
</script>

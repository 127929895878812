<template>
  <v-wait for="isFetchingDashboard">
    <core-spinner slot="waiting" />
    <FactorySelector
      :items="suppliers"
      :selected-item="selectedSupplierId"
      :show-create="hasAccessToKpis"
      label="views.production-technician.supplier"
      @factory-change="onSupplierChange"
      @create="kpiDialog = true"
    />
    <div class="grid-container">
      <GradeList
        :items="aggregateTableList"
        :selected-aggregate="
          selectedAggregateSpecification + '-' + selectedAggregateStockpile
        "
        class="grade-list"
        @onGradeSelected="onSelectedAggregateChange"
      />
      <GaugeChart
        :kpi="aggregateKpiScore(kpiTypes.IQDDGP)"
        :title="$t('views.dashboard.gauge.siteImpact')"
        :gauge-identifier="'gauge1'"
        class="gauge1"
      />
      <GaugeChart
        :kpi="aggregateKpiScore(kpiTypes.IQGQMAT)"
        :title="$t('views.dashboard.gauge.materialsQuality')"
        :gauge-identifier="'gauge2'"
        class="gauge2"
      />
      <GaugeChart
        :kpi="aggregateKpiScore(kpiTypes.IQDUG)"
        :title="$t('views.dashboard.gauge.dataQuality')"
        :gauge-identifier="'gauge3'"
        class="gauge3"
      />
      <GaugeChart
        :kpi="aggregateKpiScore(kpiTypes.IQTBMPG)"
        :title="$t('views.dashboard.gauge.globalQuality')"
        :gauge-identifier="'gauge4'"
        class="gauge4"
      />
      <VariationChart
        id="variationChart"
        :data="aggregateSampleProperties"
        :title="$t('views.dashboard.variation-chart.title')"
        translation-path="enums.aggregateProperties"
        class="variation-chart"
        @rowclick="onAggregatePropertyRowClick($event)"
      />
      <LineBoundaryChart
        id="RawMaterialsPropertyChart"
        :title="$t('views.dashboard.boundary-chart.title')"
        :subtitle="
          selectedAggregateSampleProperty
            ? $t('enums.aggregateProperties.' + selectedAggregateSampleProperty)
            : $t('enums.sampleProperties.undefined')
        "
        :boundary-data="aggregateSamplePropertyTimeline"
        :upper-h-r="specifications[6] - specifications[3]"
        :upper-a-r="specifications[5] - specifications[3]"
        :upper-l-r="specifications[4] - specifications[3]"
        :zero-r="0"
        :lower-l-r="specifications[2] - specifications[3]"
        :lower-a-r="specifications[1] - specifications[3]"
        :lower-h-r="specifications[0] - specifications[3]"
        class="boundary-chart"
      />
      <v-dialog
        v-if="hasAccessToKpis"
        v-model="kpiDialog"
        scrollable
        max-width="750px"
      >
        <v-card>
          <TableKpiComponent :items="aggregateFilteredKpis" />
        </v-card>
      </v-dialog>
    </div>
  </v-wait>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import VariationChart from '../Charts/Variation.vue'
import LineBoundaryChart from '../Charts/Lines/Boundary.vue'
import GaugeChart from '../Charts/Gauge.vue'
import FactorySelector from './FactorySelector.vue'
import TableKpiComponent from '@/components/DashViews/Charts/Tables/Kpi.vue'
import GradeList from './GradeList.vue'
import productionWeatherMixin from '@/mixins/productionWeatherMixin'
import { kpiTypes, productTypes } from '@/utils/enum'

export default {
  components: {
    GaugeChart,
    GradeList,
    FactorySelector,
    LineBoundaryChart,
    TableKpiComponent,
    VariationChart,
  },

  mixins: [productionWeatherMixin],

  provide: {
    type: productTypes.AGGREGATE,
  },

  props: {
    supplier: {
      type: String,
      default: '',
    },
    specification: {
      type: String,
      default: '',
    },
    stockpile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      kpiDialog: false,
      kpiTypes,
    }
  },
  computed: {
    ...mapState('production', [
      'aggregates',
      'selectedAggregateSampleProperty',
      'selectedAggregateSpecification',
      'selectedAggregateStockpile',
      'selectedSupplierId',
      'suppliers',
    ]),
    ...mapGetters('production', [
      'aggregateFilteredKpis',
      'aggregateKpiScore',
      'aggregateSampleProperties',
      'aggregateSamplePropertyTimeline',
      'aggregateTableList',
      'selectedAggregate',
      'selectedSupplier',
    ]),
    ...mapGetters(['hasAccessToKpis']),
    specifications() {
      return this.selectedAggregate &&
        this.selectedAggregate.specifications &&
        this.selectedAggregateSampleProperty
        ? this.selectedAggregate.specifications[
            this.selectedAggregateSampleProperty
          ]
        : [-3, -2, -1, 0, 1, 2, 3]
    },
  },

  watch: {
    async supplier() {
      this.$store.commit(
        'production/selectSupplier',
        this.supplier || this.suppliers[0]?._id,
      )
      this.setProductionWeather(this.selectedSupplier)

      await this.$store.dispatch(
        'production/fetchAggregateProductionTechnicianDashboard',
        { supplierId: this.selectedSupplierId },
      )
      await this.$store.dispatch('production/changeSelectedAggregate', {
        specification: this.aggregates[0]?.specification,
        stockpile: this.aggregates[0]?.stockpile,
      })
    },
    async specification() {
      this.$store.dispatch('production/changeSelectedAggregate', {
        specification: this.specification,
        stockpile: this.stockpile,
      })
    },
    async stockpile() {
      this.$store.dispatch('production/changeSelectedAggregate', {
        specification: this.specification,
        stockpile: this.stockpile,
      })
    },
  },

  async created() {
    this.$wait.start('isFetchingDashboard')

    if (!this.suppliers.length > 0) {
      await this.$store.dispatch('production/fetchSuppliers')
    }

    if (this.suppliers.length > 0) {
      this.$store.commit(
        'production/selectSupplier',
        this.supplier || this.selectedSupplierId || this.suppliers[0]._id,
      )
    }

    this.setProductionWeather(this.selectedSupplier)

    if (!this.aggregates.length) {
      await this.$store.dispatch(
        'production/fetchAggregateProductionTechnicianDashboard',
        { supplierId: this.selectedSupplierId },
      )
    }

    if (this.aggregates.length) {
      if (this.specification && this.stockpile) {
        await this.$store.dispatch('production/changeSelectedAggregate', {
          specification: this.specification,
          stockpile: this.stockpile,
        })
      } else if (
        this.selectedAggregateSpecification &&
        this.selectedAggregateStockpile
      ) {
        await this.$store.dispatch('production/changeSelectedAggregate', {
          specification: this.selectedAggregateSpecification,
          stockpile: this.selectedAggregateStockpile,
        })
      } else {
        await this.$store.dispatch('production/changeSelectedAggregate', {
          specification: this.aggregates[0].specification,
          stockpile: this.aggregates[0].stockpile,
        })
      }
    }

    this.$wait.end('isFetchingDashboard')
  },
  methods: {
    ...mapMutations('production', ['selectAggregateSampleProperty']),
    onSupplierChange(item) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { supplier: item },
      })
    },
    onSelectedAggregateChange(item) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {
          supplier: this.supplier,
          specification: item.specification,
          stockpile: item.stockpile,
        },
      })
    },
    onAggregatePropertyRowClick(data) {
      this.selectAggregateSampleProperty(data.id)
    },
    getKpiByName(name) {
      return this.aggregateFilteredKpis.find((k) => k.name === name)
    },
  },
}
</script>
<style scoped>
.grade-list {
  grid-row: 1 / span 6;
  grid-column: 1 / span 8;
}
.variation-chart {
  grid-row: 7 / span 6;
  grid-column: 1 / span 6;
}
.boundary-chart {
  grid-row: 7 / span 6;
  grid-column: 7 / span 6;
}
.gauge1 {
  grid-row: 1 / span 3;
  grid-column: 9 / span 2;
}

.gauge2 {
  grid-row: 1 / span 3;
  grid-column: 11 / span 2;
}

.gauge3 {
  grid-row: 4 / span 3;
  grid-column: 9 / span 2;
}

.gauge4 {
  grid-row: 4 / span 3;
  grid-column: 11 / span 2;
}
</style>
